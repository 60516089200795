<template>
    <div>
        <van-nav-bar :title="detailTittle" fixed left-arrow @click-left="onClickLeft" />
        <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
        <div class="mains">
            <div class="inp">
                <van-field  v-model="fieldValue"  placeholder="搜索相关标题文章" clearable />
                <span @click="clickSearch">搜索</span>
            </div>
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="finishedText"
                :immediate-check ='false'
                @load="load_more"
            >
                <ul class="dataList">
                    <li v-for="(item,i) in detailsList"  :key="i+10">
                        <div>
                            <img src="../assets/ziliaodaquan.jpg" >
                            <span>
                                {{item.articleTypeName}}
                                <b>{{item.createTime}}</b>
                            </span>
                        </div>
                        <p @click="gotoRouteArticle(item.id)">
                            <span>{{item.articleTitle}}</span>
                        </p>
                    </li>
                </ul>
            </van-list>
        </div>
    </div>
</template>

<script>
import {articleList} from '../api/http'
import { NavBar,Icon,Cell,Field,List } from 'vant';
    export default {
        created(){
            if(this.$route.params.id){
                this.detailId = this.$route.params.id
                localStorage.setItem("infoDetailId",this.$route.params.id)
            }else{
                this.detailId = localStorage.getItem("infoDetailId")
            }
            if(this.$route.params.detailTitle){
                this.detailTittle =  this.$route.params.detailTitle
                localStorage.setItem("infoDetailTitle",this.$route.params.detailTitle)
            }else{
                this.detailTittle =  localStorage.getItem("infoDetailTitle")
            }
            if(this.$route.params.type){
                this.detailId =  this.$route.params.type
            }
            if(this.$route.params.title){
                this.detailTittle =  this.$route.params.title
            }
            this.onLoad()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-cell':Cell,
            'van-field':Field,
            'van-list':List,
        },
        data() {
            return {
                  detailTittle: '',
                  detailId:'',
                  page:1,
                  detailsList:[],
                  fieldValue:'',
                  loading: false,
                  finished: false,
                  finishedText:'没有更多了',
            }
        },
        methods: {
           onClickLeft() {
                this.$router.push({                                                     
                    name: 'DataAll',             // 路由的名字，地址会隐藏                            
                })
            },
            load_more() {
                this.page += 1;//页数+1
                this.onLoad();
             },  
             onLoad(){
                 this.loading =true
                 let data = {
                     type:localStorage.getItem("infoDetailId"),
                     title:this.fieldValue
                 }
                 articleList(data,this.page).then(res=>{
                     if(res.data.code == 200 && res.data.data.total !=0){
                        
                        if(res.data.data.pageNum === 1){
                        this.detailsList = res.data.data.list
                        }else{
                            this.detailsList = this.detailsList.concat(res.data.data.list)
                        }
                        if (res.data.data.nextPage === 0) {  //数据全部加载完成
                            this.finished = true;
                            this.finishedText = '没有更多了'
                        }else{
                            this.finished = false;
                        }    
                        this.loading = false;   
                    } else if(res.data.data.total ==0){
                        this.finished = true;
                        this.finishedText = '没有数据'
                        this.detailsList = []
                    }
                 })

             },
             clickSearch(){
                 this.detailsList = []
                 this.onLoad()
             },
             gotoRouteArticle(id){
                 this.$router.push({                                                     
                    name: 'ArticleDetails',             // 路由的名字，地址会隐藏                            
                    params: {
                        id:id,
                        // detailTitle:title     
                        type:this.detailId,
                        title:this.detailTittle                         
                    }
                })
                localStorage.setItem('infoId',id)
                localStorage.setItem('infoType',this.detailId)
                localStorage.setItem('infoTitle',this.detailTittle)
             },
            
        },
    }
</script>

<style lang="scss" scoped>
    .mains .inp{
        display: flex;
        justify-content: space-between;
        margin: 15Px;
    }
    .mains .inp span{
        border-radius:8px;
        width: 20%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .dataList li{
        display: flex;
        flex-direction: column;
        margin: 15Px;
        border-radius: 8px;
        border: 1px solid #dcdcdc;
        padding: 15px;
    }
    .dataList li>div{
        display: flex;
        align-items:center;
    }
    .dataList li>div img{
        margin: -20px 10px 0 0;
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        width: 40px;
        height: 40px;
        object-fit: cover;
    }
    .dataList li>div span{
        font-size: 15px;
    }
    .dataList li>div span b{
        margin-left:20px;
        color: #999;
    }
    .dataList li p{
        padding-top: 10px;
        text-align: left;

    }
    .dataList li p span{
        font-weight: 700;
        font-size: 15px;
    }
    /deep/.van-field__body{
        height: 100%;
        display: flex;
        align-items: center;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-cell{
        padding: 0 10px;
        width: 80%;
        height: 40px;
        margin-right:20px;
        background-color: #f5f5f5;
    }
</style>