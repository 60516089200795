<template>
    <div>
        <van-nav-bar title="查询助手" fixed left-arrow @click-left="onClickLeft">
            <template #right >
                <van-icon  @click="showSheet" name="notes-o" size="24" color="#fff"/>
            </template>
        </van-nav-bar>
         <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                title="年份" 
                :default-index="0"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
        </van-popup>
        <van-overlay :show="overlayShow" @click="show = false">
            <div class="overlay-notice">
               <h3>查询助手</h3>
                <p>选择条件可查询号码等条件的历史出现位置。目前提供的条件有：号码、生肖、五行、波色、家野、尾数。</p>
                <h3>如何使用</h3>
                <p>选择条件可查询号码等条件的历史出现位置。目前提供的条件有：号码、生肖、五行、波色、家野、尾数。</p>
                <span @click="overlayShow = false">我知道了</span> 
            </div>
        </van-overlay>
        <div class="inquireMenu">
            <div class="vanDropdownMenu">
                <van-dropdown-menu  >
                    <van-dropdown-item ref="dropdownMenu" title="查询类型" >
                            <van-tree-select
                                v-model:active-id="activeIds"
                                v-model:main-active-index="activeIndex"
                                :items="items"
                                max="3"
                                @click-item="handleClickTabright(activeIds)"
                                @click-nav="handleClickTableft(activeIndex)"
                            />
                        <div class="selectQuery">
                            <span @click="cancel(false)">取消</span>
                            <span @click="confirm(false)">确定</span>
                        </div>
                    </van-dropdown-item>
                </van-dropdown-menu>
            </div>
           
            <div class="inquireSelect">
                 <van-checkbox @click="clickCheckBox" v-model="checked" shape="square" checked-color="#ff4000">仅显示出现的期数</van-checkbox>
                 <img @click="overlayShow = true" style="width:15px;height:15px;" src="../assets/xiangqing.png" alt="">
            </div>
        </div>
        <div class="inquireTable">
            <table id="table" ref="table">
                <thead>
                    <tr>
                        <th>年份/期数</th>
                        <th>一</th>
                        <th>二</th>
                        <th>三</th>
                        <th>四</th>
                        <th>五</th>
                        <th>六</th>
                        <th>特码</th>
                    </tr> 
                </thead>
                <tbody>
                    <tr id="tableTr" v-for="(item,i) in tableData"  :key="i+10" :class="item.isExist > 0?'isExist':'notIsExist'" :isExist="item.isExist > 1?true:false">
                        <td>{{item.issueNo}}</td>
                        <td v-for="(val,b) in item.data" :key="b+10" :class="val.red == '1'?'isCanvas':''">
                            <span :class="[val.red == '1'?'red':'',
                            val.blue == '1'?'blue':'',
                            val.green == '1'?'green':'']">{{val.value}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {openQueryHelper} from '../api/http'
import { NavBar,Icon,Popup,Picker,Overlay,DropdownMenu, DropdownItem,TreeSelect,Checkbox,Toast    } from 'vant'

    export default {
        created(){
            
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-popup':Popup,
            'van-picker':Picker,
            'van-overlay':Overlay,
            'van-checkbox':Checkbox,
            'van-dropdown-menu':DropdownMenu,
            'van-dropdown-item':DropdownItem,
            'van-tree-select':TreeSelect,
        },
        data() {
            return {
                 value1:'查询类型',
                 checked:false,
                 overlayShow:false,
                 items:[
                      { text: '数字',children:[{ text: '01', id:'01',},{ text: '02', id: '02',},{ text: '03', id: '03',},
                      { text: '04', id: '04',},{ text: '05', id: '05',},,{ text: '06', id: '06',},{ text: '07', id: '07',}
                      ,{ text: '08', id: '08',},{ text: '09', id: '09',},{ text: '10', id: '10',},{ text: '11', id: '11',}
                      ,{ text: '12', id: '12',},{ text: '13', id: '13',},{ text: '14', id: '14',},{ text: '15', id: '15',}
                      ,{ text: '16', id: '16',},{ text: '17', id: '17',},{ text: '18', id: '18',},{ text: '19', id: '19',}
                      ,{ text: '20', id: '20',},{ text: '21', id: '21',},{ text: '22', id: '22',},{ text: '23', id: '23',}
                      ,{ text: '24', id: '24',},{ text: '25', id: '25',},{ text: '26', id: '26',},{ text: '27', id: '27',}
                      ,{ text: '28', id: '28',},{ text: '29', id: '29',},{ text: '30', id: '30',},{ text: '31', id: '31',}
                      ,{ text: '32', id: '32',},{ text: '33', id: '33',},{ text: '34', id: '34',},{ text: '35', id: '35',}
                      ,{ text: '36', id: '36',},{ text: '37', id: '37',},{ text: '38', id: '38',},{ text: '39', id: '39',}
                      ,{ text: '40', id: '40',},{ text: '41', id: '41',},{ text: '42', id: '42',},{ text: '43', id: '43',}
                      ,{ text: '44', id: '44',},{ text: '45', id: '45',},{ text: '46', id: '46',},{ text: '47', id: '47',}
                      ,{ text: '48', id: '48',},{ text: '49', id: '49',}]},
                      // // // // // // // // // // // // // // // // // // // // // // // // // 
                      {text:'生肖', children:[{text:'鼠',id:'鼠'},{text:'牛',id:'牛'},{text:'虎',id:'虎'},{text:'兔',id:'兔'},
                      {text:'龙',id:'龙'},{text:'蛇',id:'蛇'},{text:'马',id:'马'},{text:'羊',id:'羊'},{text:'猴',id:'猴'},
                      {text:'鸡',id:'鸡'},{text:'狗',id:'狗'},{text:'猪',id:'猪'},]},
                      // // // // // // // // // // // // // // // // // // // // // // // // // 
                      {text:'五行',children:[{text:'金',id:'金'},{text:'木',id:'木'},{text:'水',id:'水'},{text:'火',id:'火'},
                      {text:'土',id:'土'}]},
                    //   // // // // // // // // // // // // // // // // // // // // // // // // // 
                      {text:'波色',children:[{text:'红波',id:'红'},{text:'蓝波',id:'蓝'},{text:'绿波',id:'绿'}]}
                 ],
                 activeId:'',
                 activeIds:[],
                 activeIndex: 0,  
                 showPicker: false,
                 columns: ['2023', '2024'],
                 pickData:[],
                 pickTabIndex:'0',
                 resData:[],
                 count:0,
                 dropdownMenu:false,
                 loteryYear:'2024',
                 loteryCode:'',
                 tableData:[],
                 isShow:[],
                 pickArrayData:[],
                 isExist:Boolean
            }
        },
        created() {
            if(localStorage.getItem('code')){
                this.loteryCode = localStorage.getItem('code')
            }else{
                this.loteryCode = '71'
            }
            this.getqueryHelper()
            let _this = this
            // _this.clearCanvas()
            setTimeout(function () {_this.initCanvas()},500);
            // console.log(localStorage.getItem('code'),)
            // console.log(this.isExist)
        },
        methods: {
            showSheet(){
                this.showPicker = true
            },
            onConfirm(value) {
                this.value = value;
                this.loteryYear = value
                this.showPicker = false;
                this.getqueryHelper()
                let _this = this
                _this.clearCanvas()
                setTimeout(function () {_this.initCanvas()},500)
            },
            onClickLeft() {
                this.$router.push('/')
                // localStorage.removeItem('code');;
            },
            getqueryHelper(){
                let data = {
                    code:this.loteryCode,
                    year:this.loteryYear,
                    type:this.activeIndex+1,
                    value:this.pickData 
                }
                openQueryHelper(data).then(res=>{
                    if(res.data.code == 200){
                        // console.log(res.data.data)
                        this.tableData = res.data.data
                    }
                    
                }).catch(err=>{
                    console.log(err)
                }) 
            },
            handleClickTableft(index){
                this.pickTabIndex = index
                if(index == 2 || index == 3){
                    this.activeIds = ''
                }else{
                    this.activeIds = []
                }
                this.pickData = []

            },
            handleClickTabright(item){
                if(item.length >=3 && this.pickTabIndex == 0 ||item.length >=3 && this.pickTabIndex == 1){
                    Toast('最多只能选择三个')
                }
                if( this.pickTabIndex==0||this.pickTabIndex==1){
                    this.pickData = item.join(',')
                    this.pickArrayData = item
                }else{
                    this.pickData = item
                }
                
            },
            // handleChange(data){
            //     console.log(data)
            // },
            cancel(val){
                this.$refs.dropdownMenu.toggle(val)
            },
            confirm(val){
                // console.log(this.pickData)
                this.$refs.dropdownMenu.toggle(val)
                this.getqueryHelper()
                let _this = this
                _this.clearCanvas()
                setTimeout(function () {_this.initCanvas()},500)

                // console.log(this.isExist)
            },
            initCanvas() {
                    this.clearCanvas()
                    let cvs1 = document.createElement('canvas')
                    cvs1.id = 'canvas2'
                    cvs1.style.position = 'absolute'
                    cvs1.style.left = 0 + 'px'
                    cvs1.style.top = 90 + 'px'
                    cvs1.style.right = 0 + 'px'
                    cvs1.style.marginLeft = 'auto'
                    cvs1.style.marginRight = 'auto'
                    cvs1.width = this.$refs.table.offsetWidth
                    cvs1.height = this.$refs.table.offsetHeight
                    let TD1 = document.getElementsByClassName('isCanvas')
                    let canList1 = []
                    for (let i=0; i < TD1.length; i++) {
                        canList1.push({left:TD1[i].offsetLeft + (TD1[i].clientWidth / 2), top:TD1[i].offsetTop + (TD1[i].clientHeight / 2)})
                    }
                    let cxt1 = cvs1.getContext('2d')
                    cxt1.beginPath()
                    cxt1.strokeStyle = '#ff0000'
                    cxt1.beginPath()
                    cxt1.moveTo(canList1[0].left, canList1[0].top)
                    for (let i = 0; i < canList1.length; i++) {
                        cxt1.lineTo(canList1[i].left, canList1[i].top)
                    }
                    cxt1.stroke()
                    // console.log(this.pickData.length)
                    if(this.pickArrayData.length<=1 && this.activeIndex == 0){
                         document.getElementById('table').append(cvs1)
                    }
                    },
                clearCanvas(){
                    let canvas2 = document.getElementById('canvas2')
                    if (canvas2 != null) canvas2.parentNode.removeChild(canvas2)
                    // console.log(canvas2,canvas3)
                },
                clickCheckBox(){
                    if(this.checked){
                        let TR = document.getElementsByClassName('isExist')
                        let NTR = document.getElementsByClassName('notIsExist')
                        //   console.log(NTR)
                          this.clearCanvas()
                          for (let i = 0; i < NTR.length; i++) {
                              NTR[i].style.display = 'none';
                          }
                    }else if(!this.checked){
                        let NTR = document.getElementsByClassName('notIsExist')
                        for (let i = 0; i < NTR.length; i++) {
                            NTR[i].style.display = '';
                        }
                        this.getqueryHelper()
                        let _this = this
                        _this.clearCanvas()
                        setTimeout(function () {_this.initCanvas()},500)
                    }
                }
        },
    }
</script>

<style lang="scss" scoped>
    .inquireMenu{
        margin-top: 50px;
        width:100%;
        display: flex;
        align-items: center;
    }
    .inquireSelect{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-around;
        height:20px;
    }
    .vanDropdownMenu{
        width: 50%;
    }
    .selectQuery{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        border-top: 1px solid #eee;
        height:50px;
        line-height: 50px;
        max-width: 600px;
        margin: 0 auto;
    }
    .selectQuery span{
        text-align: center;
        font-size: 15px;
    }
    .selectQuery span:first-child{
        color: #999;
        border-right: 1px solid #eee;
    }
    .selectQuery span:last-child {
        color: #ff5000;
    }
    .overlay-notice{
        top: 20%;
        position: fixed;
        max-width: 500px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        padding: 10px 20px;
        border-radius: 10px;
        width: 80%;
        background-color: #fff;
        font-size: 15px;
    }
    .overlay-notice h3{
        text-align: left;
    }
    .overlay-notice p{
        word-break: break-all;
        text-align: left;
        margin-bottom: 20px;
    }
    .overlay-notice span{
        display: inline-block;
        border-radius: 0 0 5px 5px;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    .inquireTable table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    table{
        width: 100%;
        font-size: .8rem;
        
    }
    table > tr {
        vertical-align: middle;
    }
    .inquireTable table tr td span{
        display: inline-block;
        border-radius:50% ;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 15px;
    }
    .blue{
        color: #fff;
        background-color: #00f;
    }
    .green{
        color: #fff;
        background-color: green;
    }
    .red{
        color: #fff;
        background-color: red;
    }
    .isShow{
        display: none;
    }
    tr:nth-child(2n){
        background: #f8f8f8;
    }
    .inquireTable table tr th{
        height: 20px;
        font-weight: 200;
        color: #a9a9a9;
        font-size: 15px;
    }
    .inquireTable table tr td{
        height: 20px;
        padding: 5px 0;
        text-align: center;
        font-size: 15px;
    }
    td{
        display: table-cell;
        vertical-align: inherit;
    }
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }

    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-tree-select{
        width:100%;
        max-width: 600px;
        margin:0 auto;
    }
    /deep/.van-overlay{
        width:100%;
        max-width: 600px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
    }
    /deep/.van-picker{
        width:100%;
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-checkbox__label{
        font-size: 15px;
    }
</style>