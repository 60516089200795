<template>
    <div>
        <div class="headWrap"> 
            <van-nav-bar title='特码历史' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
            <div class="headQs">
                <a @click="popWrapShow = true">
                    <span>期数</span>
                    <b>{{Qsnum}}</b>
                </a>
            </div>
        </div>
         <div style='width:100%;height:20px;'></div>  <!--增加头部高度，避免盖住内容 -->
         <div class="headerTxt">
             <h3>
                 当前统计期数
                 <span>{{Qsnum}}</span>
             </h3>
         </div>

         <div class="echart1">
             <p>
                <b>特码历史热图</b>
                <span>（所选期数范围内出现的次数）</span>
            </p>
            <div id='echartHot'></div>
         </div>

         <div class="echart2">
             <p>
                <b>特码历史冷图</b>
                <span>（所选期数范围内出现的次数）</span>
            </p>
            <div id='echartCold'></div>
         </div>
         
        <van-overlay :show="popWrapShow" @click="popWrapShow = false">
            <div class="popWrap" @click.stop>
                <div>
                    <span>请填写要统计的期数</span>
                    <input type="text" v-model="inputNum" :placeholder="Qsnum">
                </div>
                <span class="btn" @click="handleComfir">确定</span>
            </div>
        </van-overlay>
        <div style='width:100%;height:20px;'></div>  <!--增加脚部高度，避免盖住内容 -->
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
import {openSpeNumStatistics} from '../api/http'
import { NavBar, Overlay} from 'vant';
    export default {
        created(){
          
        },
        mounted() {
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.$nextTick(()=>{
                this.drawLine();
            })
            
        },
        components:{
            'van-nav-bar':NavBar,
            'van-overlay':Overlay,

        },
        data() {
            return {
                Qsnum:'100',
                code:'',
                popWrapShow:false,
                inputNum:'',
                hotList:[],
                coldList:[],
            }
        },
        methods: {
            onClickLeft(index) {
                this.$router.push("/");
            },
            handleComfir(){
                this.Qsnum = this.inputNum
                this.popWrapShow = false
                this.drawLine()
            },
            drawLine() {
                 let data = {
                    code:this.code,
                    rows:this.Qsnum,
                    jud:'num'
                }
                openSpeNumStatistics(data).then(res => {
                    if(res.data.code == 200){
                        let hotList = res.data.data.hot
                        let coldList = res.data.data.cold
                        let chartDom = document.getElementById('echartHot');
                        let chartDomCold = document.getElementById('echartCold');
                        let myChart = echarts.init(chartDom);
                        let myChartCold = echarts.init(chartDomCold);
                        let option;
                        let optionCold;
                        // 热土历史
                        option = {
                            // 点击点时显示详细信息
                            tooltip: {
                            
                            },
                            xAxis: {
                                name:'号码',
                                type: 'category',
                                data: hotList.x,
                            },
                            yAxis: {
                                type: 'value',
                                name:'次数',
                            },
                            label: {
                                show: true,
                                distance: -25,
                                textStyle: {
                                    fontSize: 20
                                }
                            },
                            series: [
                                {
                                    data: hotList.y,
                                    type: 'line',
                                    itemStyle: {
                                        normal: {
                                            color:'#fa6938', 
                                            lineStyle: {
                                                width:3, //调整 线条的宽度  5已经很宽啦
                                                color : '#fa6938' //线条颜色
                                            }
                                        }
                                    },	
                                    symbolSize : 10 ,
                                }
                            ]
                            
                        };
                        // 冷图历史
                        optionCold = {
                            // 点击点时显示详细信息
                            tooltip: {
                            
                            },
                            xAxis: {
                                name:'号码',
                                type: 'category',
                                data: coldList.x,
                            },
                            yAxis: {
                                type: 'value',
                                name:'次数',
                            },
                            label: {
                                show: true,
                                distance: -25,
                                textStyle: {
                                    fontSize: 20
                                }
                            },
                            series: [
                                {
                                    data: coldList.y,
                                    type: 'line',
                                    itemStyle: {
                                        normal: {
                                            color:'#3333ff', 
                                            lineStyle: {
                                                width:3, //调整 线条的宽度 
                                                color : '#3333ff' //线条颜色
                                            }
                                        }
                                    },	
                                    symbolSize : 10 ,
                                }
                            ]
                        };
                        option && myChart.setOption(option);
                        optionCold && myChartCold.setOption(optionCold);
                        // 根据屏幕自动适应大小
                        myChart.setOption(option, true);
                        myChartCold.setOption(optionCold, true);
                        window.addEventListener('resize', function() {
                            myChart.resize(); 
                            myChartCold.resize(); 
                        }
                        );
                    }
                })
            },
        },
    }
</script>

<style lang='scss' scoped>
    // pc
@media screen and (min-width:700px) {
    .popWrap{
       width: 50%;
       max-width: 400px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        margin-left: 500px;
        z-index: 10;
    }
}
//移动端
@media screen and (max-width:750px) {
    .popWrap{
       width: 70%;
       max-width: 600px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        right: 10px;
        z-index: 10;
    }
}
    .headWrap{
        position: relative;
        width: 100%;
        height: 45px;
        font-size: 15px;
        text-align: center;
        color: #fff;
        background: rgb(174, 115, 252);
    }
    
    .headQs a span{
        font-size: 15px;
        color: #fff;
    }
    .headQs a b{
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px;
    }
    .popWrap{
        position: fixed;
        left: calc(50% - 3.33333rem);
        top: calc(50% - 1.66667rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 150px;
        line-height: 150px;
        background-color: #fff;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .popWrap>div span{
        font-size: 15px;
    }
    .popWrap>div input{
        margin-left: 15px;
        border: 1px solid #c0bcbc;
        border-radius: 5px;
        width: 45px;
        height: 25px;
        text-align: center;
        font-size: 15px;
    }
    .popWrap .btn{
        display: inline-block;
        position: relative;
        bottom: 40px;
        border-radius: 8px;
        width: 100px;
        height: 80px;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        background-color: #ff5000;
    }
    #echartHot{
        width: 100%;
        height: 300px;
    }
    #echartCold{
        width: 100%;
        height: 300px;
    }
    .echart1{
        position: relative;
    }
    .echart1 p{
        padding: 0 10px 15px;
        font-size: 15px;
        position: absolute;
        top: 8px;
        left: 15px;
    }
    .echart1 p span{
        color: #999;
    }
    .echart2{
        position: relative;
    }
    .echart2 p{
        padding: 0 10px 15px;
        font-size: 15px;
        position: absolute;
        top: 8px;
        left: 15px;
    }
    .echart2 p span{
        color: #999;
    }
    .headerTxt h3{
        position: relative;
        top: 10px;
        margin: 10px 0;
        color: #ff5000;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    /deep/.van-overlay{
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
        z-index: 1000;

    }
</style>