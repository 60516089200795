import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import router from './router/index'
import store from './store'
import MetaInfo from 'vue-meta-info'
import { Toast } from 'vant'
import '../src/util/rem'　　

// 动效插件
import "animate.css/animate.min.css";
import "wowjs/css/libs/animate.css";
// 时间转换插件
import moment from 'moment'
app.config.globalProperties.$moment = moment

moment.locale("zh-CN")
// import Tools from  './util/Tools.js'



router.beforeEach((to, from, next) => {
    //设置免登录白名单
    if(to.path === '/login' || to.path === '/' || to.path === '/record' 
        || to.path === '/inquire' || to.path === '/searchIndex' || to.path === '/detail'
        || to.path === '/dataAll' || to.path === '/infoDetails' || to.path === '/articleDetails'
        || to.path === '/user' || to.path === '/register' || to.path === '/forget' || to.path === '/Guess' 
        || to.path === '/Count'|| to.path === '/forum' || to.path === '/forumDetail' || to.path === '/Formula' || to.path === '/FormulaDetail' || to.path === '/Discount'|| to.path === '/Newdiscover' || to.path === '/ActivityDetails'|| to.path === '/data/count'|| to.path === '/data/attribution'|| to.path === '/data/specialCode'|| to.path === '/data/positiveCode'|| to.path === '/data/table'|| to.path === '/data/sxSpecial'|| to.path === '/data/sxPositiveCode'|| to.path === '/data/bsSpecialCode'|| to.path === '/data/bsPositiveCode' || to.path === '/data/specialTwoSides' || to.path === '/data/wsSpecialCode' || to.path === '/data/wsPositiveCode' || to.path === '/data/positiveTwoside' || to.path === '/data/numBandCode' || to.path === '/AboutUs'|| to.path === '/StationNews' || to.path === '/Discover' || to.path === '/amNormalX' || to.path === '/amSpecialCode'){
        next();
    }else{
      let token = window.localStorage.token;
      if(token ==='null' || token ==='' || token === undefined){
        Toast('请登录')
        next({
            path: '/login',
            query: { redirect: to.fullPath } // 将要跳转路由的path作为参数，传递到登录页面
        })
        
      }else{
        next();
      }
    }

 
  })

createApp(App).use(store).use(router).use(MetaInfo).mount('#app')
