<template>
    <div>
         <van-nav-bar title="尾数大小统计图" fixed left-arrow @click-left="onClickLeft">
            <template #right >
                <van-icon  @click="showSheet" name="notes-o" size="24" color="#fff"/>
            </template>
        </van-nav-bar>
        <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                title="年份" 
                :default-index="0"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
        </van-popup>
       <div style='width:100%;height:50px;'></div>   <!-- 增加底部脚部高度，部面盖住内容 -->

        <div class="inquireTable">
            <table>
                <thead>
                    <tr>
                        <th>年份/期数</th>
                        <th>正一</th>
                        <th>正二</th>
                        <th>正三</th>
                        <th>正四</th>
                        <th>正五</th>
                        <th>正六</th>
                        <th>特码</th>
                    </tr> 
                </thead>
                <tbody>
                    <tr v-for="(item,i) in tableData"  :key="i+10" :class="item.isExist > 0?'isExist':'notIsExist'" :isExist="item.isExist > 1?true:false">
                        <td>{{item.issueNo}}</td>
                        <td v-for="(val,b) in item.wsData" :key="b+10" >
                            <span :class="val=='大' ? 'isRedColor':''">{{val}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style='width:100%;height:50px;'></div>   <!-- 增加底部脚部高度，部面盖住内容 -->
    </div>
</template>

<script>
import {openWsNum} from '../api/http'
import { Icon, NavBar, Picker, Popup,  } from 'vant';
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.getTableData()
        },
        components:{
            'van-picker':Picker,
            'van-popup':Popup,
            'van-nav-bar':NavBar,
            'van-icon':Icon,
        },
        data() {
            return {
                columns: ['2024', '2023'],
                showPicker:false,
                code:'',
                pickYear:'2024',
                tableData:[],
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push("/");
            },
            showSheet(){
                this.showPicker = true
            },
            onConfirm(value) {
                this.pickYear = value
                this.showPicker = false
                this.getTableData()
            },
            getTableData(){
                let data = {
                    code:this.code,
                    year:this.pickYear
                }
                openWsNum(data).then(res => {
                    if(res.data.code == 200){
                        this.tableData = res.data.data
                        console.log(this.tableData)

                    }
                })
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-overlay{
        width:100%;
        max-width: 600px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
    }
    /deep/.van-picker{
        width:100%;
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }

    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    .isRedColor{
        color: red;
    }
    .inquireTable table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    table{
        width: 100%;
        font-size: .8rem;
        
    }
    table > tr {
        vertical-align: middle;
    }
    .inquireTable table tr td span{
        display: inline-block;
        border-radius:50% ;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 15px;
    }
    tr:nth-child(2n){
        background: #f8f8f8;
    }
    .inquireTable table tr th{
        height: 20px;
        font-weight: 200;
        color: #a9a9a9;
        font-size: 15px;
    }
    .inquireTable table tr td{
        height: 20px;
        padding: 5px 0;
        text-align: center;
        font-size: 15px;
    }
    td{
        display: table-cell;
        vertical-align: inherit;
    }
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
</style>