<template>
    <div>
      <div class="down-bar">
        <div class="down-bar-boxt">
            <div class="down-bar-boxt-1">
                <img src="../assets/logo639.png" alt="">
            </div>
            <div class="down-bar-boxt-2">
                <div class="down-bar-boxt-2l">
                    <ul>
                        <li>{{gameName}}</li>
                        <li>授权：免费软件</li>
                        <li>大小：19.89MB</li>
                        <li>来源：301彩票</li>
                        <li>环境：Android/iOS</li>
                    </ul>
                </div>
                <div class="down-bar-boxt-2r">
                    <ul>
                        <li>服务全方位首充返利</li>
                        <li>类型：国产软件</li>
                        <li>
                            <img src="../assets/star.png" alt=""><img src="../assets/star.png" alt=""><img src="../assets/star.png" alt=""><img src="../assets/star.png" alt=""><img src="../assets/star.png" alt="">
                        </li>
                        <li>更新：{{ updateTime }}</li>
                        <li>评分：9.9</li>
                    </ul>
                </div>
                <div class="down-bar-rz">
                    <img src="../assets/rz.png" alt="">
                    已向本站押付2000万，请放心娱乐！
                </div>
            </div>
            
        </div>
        <div class="down-bar-boxf">
                    <div class="down-bar-boxf-1"> 
                        <a :href="andLink">
                            <img src="../assets/az.png" alt="">
                            Android下载
                        </a>
                    </div>
                    <div class="down-bar-boxf-2"> 
                        <a :href="IosLink">
                            <img src="../assets/ios.png" alt="">
                            iphone下载 
                        </a>
                    </div>
                    <div class="down-bar-boxf-3"> 
                        <a :href="IeLink">
                            <img src="../assets/ie.png" alt="">
                            访问官网 
                        </a>
                    </div>
        </div>
      </div>
    </div>
</template>

<script>
import {imgLink} from '../api/http'
import { Tabbar, TabbarItem, Toast } from 'vant';
    export default {
        created(){
            this.getName()
            this.getUpdateTime()
            this.getIosLink()
            this.getAndLink()
            this.getIeLink()
        },
        components:{
            'van-tabbar':Tabbar,
            'van-tabbar-item':TabbarItem
        },
        data() {
            return {
                  gameName:'',
                  updateTime:'',
                  IosLink:'',
                  andLink:'',
                  IeLink:'',
            }
        },
        methods: {
            getName(){
                let data = {
                    key:'app-name'//App名字
                }
                imgLink(data).then(res=>{
                    this.gameName = res.data.data
                })
            },
            getUpdateTime(){
                let data = {
                    key:'app-update-time'//App更新时间
                }
                imgLink(data).then(res=>{
                    this.updateTime = res.data.data
                })
            },
            getIosLink(){
                let data = {
                    key:'ios-link'//Appios下载链接
                }
                imgLink(data).then(res=>{
                    this.IosLink = res.data.data
                })
            },
            getAndLink(){
                let data = {
                    key:'android-link'//App Android下载链接
                }
                imgLink(data).then(res=>{
                    this.andLink = res.data.data
                })
            },
            getIeLink(){
                let data = {
                    key:'web-link'//网页访问链接
                }
                imgLink(data).then(res=>{
                    this.IeLink = res.data.data
                })
            },
            
        },
    }
</script>

<style scoped>
    
    .down-bar {
        max-width: 800px;
        line-height: 1.75;
        margin: 0 auto;
        padding: 15px 0;
        box-sizing: border-box;
        font-size: .55rem;
    }
    a{
        color: white;
    }
    /* b{
        font-weight: 700;
    } */
    .down-bar-boxt {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .down-bar-boxt-1 img {
        width: 100px;
        height: auto;
        border-radius: 18px;
        box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    }
    .down-bar-boxt-1 {
        width: 30%;
        min-width: 130px;
        text-align: center;
    }
    .down-bar-boxt-2l {
        width: 50%;
    }
    .down-bar-boxt-2r img {
        width: 22px;
    }
    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .down-bar-rz {
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: #15bc83;
    }
    .down-bar-rz img {
        width: auto;
        height: 24px;
        margin-right: 3px;
    }
    .down-bar-boxf {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 5px;
        text-align: center;
        color: #fff;
    }
    .down-bar-boxt-2 {
        width: 70%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .down-bar-boxt-2l {
        width: 50%;
    }
    .down-bar-boxt-2r {
        width: 50%;
    }
    .down-bar-boxf div {
        display: flex;
        justify-content: center;
        width: 33.33%;
    }
    .down-bar-boxf-1 a {
        background: #c34dff;
    }
    .down-bar-boxf a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        line-height: 40px;
        width: 80%;
        border-radius: 5px;
        transition: all 0.2s;
    }
    .down-bar-boxf a img {
        width: 1.05rem;
        margin-right: 3px;
    }
    .down-bar-boxf div {
        display: flex;
        justify-content: center;
        width: 33.33%;
    }
    .down-bar-boxf-2 a {
        background: #ff5b5b;
    }
    .down-bar-boxf-3 a {
        background: #3ebc40;
    }
</style>