<template>
    <div>
        <van-nav-bar title="历史开奖" fixed left-arrow @click-left="onClickLeft">
            <template #right >
                <van-icon v-if="isnotesShow" @click="showSheet" name="notes-o" size="24" color="#fff"/>
            </template>
        </van-nav-bar>
        <div style="width:100%;height:50px;"></div>
        <div class="record-tabs">
            <span @click="handleChange('0')" :class="spanActive == '0'?'tabs_active':''">开奖记录</span>
            <span @click="handleChange('1')" :class="spanActive == '1'?'tabs_active':''">开奖日期</span>
        </div>
        <div class="down_app2">
            <img src="../assets/down_app2.png" alt="">
        </div>
        <div v-if="!iscalendarShow" class="record-title">
            <h4>{{this.loteryYear}}年历史开奖记录</h4>
            <div>
                <span :class="orderActive == '0'?'order':''" @click="changeOrder('DESC','0')">降序</span>
                <span :class="orderActive == '1'?'order':''" @click="changeOrder('ASC','1')">倒序</span>
            </div>
        </div>
        <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                title="年份" 
                :default-index="0"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
        </van-popup>
        <h3 v-if="iscalendarShow">搅珠日期对照表，可查看当月及下一个月的搅珠日期</h3>
        <van-calendar class="calendar"  v-if="iscalendarShow" :min-date="minDate"
			:max-date="maxDate"   :readonly="true" type="single" :formatter="formatter" :show-confirm="false" :default-date="defaultDate" :poppable="false" :show-title="false"/>
        <p v-if="iscalendarShow" class="openTime">{{openTime}}</p>

        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finishedText"
            @load="load_more"
            :immediate-check='false'
            v-if="!iscalendarShow"
            >
            <van-cell   v-for="(item,i) in newList"  :key="i"   >
                <div class='blankBack'>
                    <div class="homeLotery">
                        <div class='loteryTittle'>第{{item.issueNo.substring(item.issueNo.length-3)}}期最新开奖结果</div>
                        <div class="loteryTime">{{item.prizeTime.slice(0,10)}}</div>
                        <div id="index" class='loteryNums'  v-for="(nb,u) in item.prize" :key="u" >
                            <img :src="require(`../assets/${nb[0]}.png`)" >
                            <div class="loteryScribe">
                                <span>{{nb[1]}}/{{nb[2]}}</span>
                            </div>
                        </div> 
                        <h2 class="jiahao">+</h2>
                    </div>
                </div>
            </van-cell>
        </van-list>
    </div>
</template>

<script>
import { Toast } from 'vant';
import {openMore,openTime} from '../api/http.js'
import { NavBar,Icon,Popup,Picker,Calendar,Cell,List  } from 'vant';
    export default {
        data() {
            return {
                showPicker: false,
                isnotesShow:true,
                iscalendarShow:false,
                sheetshow: false,
                actions: [{ name: '2023' }, { name: '2024' }],
                minDate: new Date(),
				maxDate: new Date(),
				defaultDate: new Date(),
                spanActive:'0',
                orderActive:'0',
                columns: ['2023', '2024'],
                thisTime:this.timeFormat(new Date()),
                loading: false,
                finished: true,
                loteryList:[],
                newLoteryList:[],
                theNewestDate:'',
                newPrizeNums:[],
                newList:[],
                bigList:[],
                dataset:'',
                loteryYear:'2024',
                total: 0, //记录全部的数据
                finishedText:'没有更多了',
                page:1,
                order:'DESC',
                code:'',
                openTime:'',
                newkjTime:[],
                year: new Date().getFullYear(),
                month: new Date().getMonth(),
                nowDay:new Date(),
                showYear:new Date().getFullYear(),
                showMonth:new Date().getMonth(),
                searchDetailId:'',
                searchDetailTitle:'',
                articleId:'',
                postId:'',
                isLastPage:false
            }
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-popup':Popup,
            'van-picker':Picker,
            'van-calendar':Calendar,
            'van-list':List,
            'van-cell':Cell,
        },
        mounted() {
            
        },
        created() {
            this.setMinMaxDay()
            if(this.$route.params.searchDetailId){
                this.searchDetailId = this.$route.params.searchDetailId
            }
            if(this.$route.params.searchDetailTitle){
                this.searchDetailTitle = this.$route.params.searchDetailTitle
            }
            if(this.$route.params.articleId){
                this.articleId = this.$route.params.articleId
            }
            if(this.$route.params.postId){
                this.postId = this.$route.params.postId
            }
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
                this.onLoad()
            }else{
                this.code = '71'
                this.onLoad()

            }
            
        },
        watch:{
            defaultDate(val) {
                console.log(val)
                this.setMinMaxDay();
            }
        },
        methods: {
        showSheet(){
            this.showPicker = true
        },
        onConfirm(value) {
            this.value = value;
            this.loteryYear = value
            this.showPicker = false;
            this.page = 1
            this.newList = []
            this.onLoad()
        },
        onCancel() {
            Toast('取消');
        },
        onClickLeft() {
            if(this.$route.params.searchDetailTitle){
                this.$router.push({
                    name:'Detail',
                    params: {
                            searchDetailTitle:this.searchDetailTitle,
                            searchDetailId:this.searchDetailId                       
                        }
                 })
            }else if(this.$route.params.articleId){
                this.$router.push({
                    name:'ArticleDetails',
                    params: {
                            articleId:this.articleId,
                        }
                 })
            }else if(this.$route.params.guessDetailId){
                this.$router.push({
                    name:'Guess',
                 })
            }else if(this.$route.params.postId){
                    this.$router.push({
                        name:'ForumDetail',
                        params: {
                                postId:this.postId,
                            }
                    })
            }else{
                this.$router.push('./')
            }
            
           
           // localStorage.removeItem('code');;
        },
        formatter(day){
            // console.log(day)
            // console.log(this.newkjTime.indexOf(day.text))
            if(this.newkjTime.indexOf(day.text) > -1){
                day.bottomInfo = '开奖日'
            }else{
                day.bottomInfo = ''
            }
            return day;
        },
        handleChange(num){
            this.spanActive = num
            if(num == 1){
               this.isnotesShow = false
               this.iscalendarShow = true
               let data = {
                   code:this.code,
               }
               openTime(data).then( res => {
                //    console.log(res.data.data.kjTime.replace(/\b(0+)/gi,""))
                   for (let i = 0; i < res.data.data.kjTime.length; i++) {
                    //    去掉数字前面的0
                        this.newkjTime.push(Number(res.data.data.kjTime[i].replace(/\b(0+)/gi,"")))
                   } 
                //    console.log(this.newkjTime)
                   if(res.data.code == 200){
                        this.openTime = res.data.data.today
                    }
               }).catch( err => {

               })
            } else {
                this.isnotesShow = true
               this.iscalendarShow = false
            }
        },
        
        timeFormat(time) {
            // 时间格式化 2019-09-08
            let year = time.getFullYear();
            let month = time.getMonth() + 1 > 10 ? time.getMonth()+1 : '0'+(time.getMonth()+1);
            let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
            return year + "-" + month + "-" + day;
        },
        setMinMaxDay(){
            this.showYear= this.defaultDate.getFullYear();
            this.showMonth = this.defaultDate.getMonth();
            var firstDay = new Date(this.defaultDate);
            firstDay.setDate(1)
            var endDay = new Date(this.showYear,this.showMonth+1,1);
            this.minDate =new Date(
                this.showYear,
                this.showMonth,
                firstDay.getDate()
            )
            endDay.setDate(0)
            this.maxDate =new Date(
                this.showYear,
                this.showMonth,
                endDay.getDate()
            )
        },
        load_more() {
            if(!this.isLastPage){
                this.page+=1;//页数+1
            }
            this.loading = true;
            let data = {
                // code:localStorage.getItem('code'),
                code:this.code,
                order:this.order,
                year:this.loteryYear
            }
            openMore(data,this.page).then(res =>{
                this.loading =true
                if(res.data.code == 200 && res.data.data.total !=0){
                    // this.newList = this.newList.concat(res.data.data.list)
                    if(res.data.data.pageNum === 1){
                        this.newList = res.data.data.list
                    }
                    else if(!this.isLastPage){
                        this.newList = this.newList.concat(res.data.data.list)
                    }
                    this.isLastPage = res.data.data.isLastPage
                    if (res.data.data.nextPage === 0) {  //数据全部加载完成
                        this.finished = true;
                        this.finishedText = '没有更多了'
                    }else{
                        this.finished = false;
                    } 
                    this.loading = false;
                    
                } else if(res.data.data.total ==0){
                    this.finished = true;
                    this.finishedText = '没有数据'
                    this.newList = []
                }
                 
                // console.log(this.newList)
            })
            .catch(err =>{

            })
        },
        onLoad(){
            this.loading = true;
            let data = {
                // code:localStorage.getItem('code'),
                code:this.code,
                order:this.order,
                year:this.loteryYear
            }
            openMore(data,this.page).then(res =>{
                this.loading =true
                if(res.data.code == 200 && res.data.data.total !=0){
                    // this.newList = this.newList.concat(res.data.data.list)
                    if(res.data.data.pageNum === 1){
                        this.newList = res.data.data.list
                    }
                    else if(!this.isLastPage){
                        this.newList = this.newList.concat(res.data.data.list)
                    }
                    this.isLastPage = res.data.data.isLastPage
                    if (res.data.data.nextPage === 0) {  //数据全部加载完成
                        this.finished = true;
                        this.finishedText = '没有更多了'
                    }else{
                        this.finished = false;
                    } 
                    this.loading = false;
                    
                } else if(res.data.data.total ==0){
                    this.finished = true;
                    this.finishedText = '没有数据'
                    this.newList = []
                }
                 
                // console.log(this.newList)
            })
            .catch(err =>{

            })
        },
        changeOrder(order,num){
            this.order = order
            this.orderActive = num
            this.newList = []
            this.page = 1
            this.onLoad()
        },
  },
    }
</script>

<style lang='scss' scoped>
    @import '../style/common.css';
    /* pc端 */
    @media screen and (min-width:700px) {
        .record-tabs{
            top: 100px;
        }
        .calendar{
            top:300px;
        }
        h3{
            padding-top: 60px;
            text-align: center;
            font-size: 18px;
            color: #999;
        }
        .calendar{ //绝对定位居中
            height: 40%;
            width:90%;
            max-width: 600px;
            position: absolute;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto;
            border: 1px solid rgba(212, 212, 212, 0.493);
        }
        .openTime{
            width: 100%;
            max-width: 600px;
            padding: 20px 0;
            font-size: 20px;
            text-align: center;
            position: relative;
            top:550px;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto; 
            z-index: 20;
        }
        .homeLotery{
            position: relative;
            background: url(../assets/kuang.png) no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 90px;
            margin:10px 10px;
            justify-content: center;
            display: flex;
            
        }
        .blankBack{
            height:150px;
            background-color:#fff ;
            display:flex;
            justify-content:center;
            align-items:center;
            border-top: 1px rgba(195, 195, 195, 0.151) solid;

        }
        
        .van-cell{
            padding: 0;
        }
        .loteryTittle{
            display: flex;
            top: -25px;
            font-size: 15px;
            position: absolute;
            left: 10px;
            

        }
        .toRecord{
            padding-left: 10px;
            color:rgb(255, 82, 2);
        }
        .loteryTime{
            position: absolute;
            top:-25px;
            right: 10px;
            color: rgb(136, 133, 133);
        }
        .loteryNums{
            position: relative;
            left: 5px;
            top:15px;
            width: 10%;
            margin:5px 5px;
          }
          .loteryNums img{
            vertical-align: middle;
            width: 80%;
            position: relative;
            bottom: 5px;
          }
          .loteryNums:nth-child(9){
            padding-left: 45px;
          }
          .jiahao{
            position: relative;
            right: 16%;
            top:20px;
            font-size: 20px;
          }
          .loteryScribe{
            text-align: center;
            padding-top: 2px;
            display: block;
            font-size: 13px;
          }
    }
    /* -------------------------------------------------------------------我是分割线---------------------------------------------------------------------------- */
    /* 移动端  */
    @media  screen and (max-width:750px) {
        .record-tabs{
            top: 65px;
        }
        h3{
            // padding-top: 30px;
            text-align: center;
            font-size: 14px;
            color: #999;
            margin: 58px 0 10px 0;
        }
        .calendar{
            height: 59%;
            width:90%;
            max-width: 600px;
            position: absolute;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto;
            border: 1px solid rgba(212, 212, 212, 0.493);
        }
        .openTime{
            padding: 20px 0;
            font-size: 15px;
            text-align: center;
            position: absolute;
            bottom:0px;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto; 
        }

        .homeLotery{
            position: relative;
            background: url(../assets/kuang.png) no-repeat;
            background-size: 100% 100%;
            width: 100%;
            height: 90px;
            margin:10px 10px;
            justify-content: center;
            display: flex;
            
        }
        .blankBack{
            height:150px;
            background-color:#fff;
            display:flex;
            justify-content:center;
            align-items:center;
            border-top: 1px rgba(195, 195, 195, 0.151) solid;

        }
        
        .van-cell{
            padding: 0;
        }
        .loteryTittle{
            display: flex;
            top: -25px;
            font-size: 15px;
            position: absolute;
            left: 10px;
            

        }
        .toRecord{
            padding-left: 10px;
            color:rgb(255, 82, 2);
        }
        .loteryTime{
            position: absolute;
            top:-25px;
            right: 10px;
            color: rgb(136, 133, 133);
        }
        .loteryNums{
            position: relative;
            left: 5px;
            top:15px;
            width: 10%;
            margin:5px 5px;
          }
          .loteryNums img{
            vertical-align: middle;
            width: 80%;
            position: relative;
            bottom: 5px;
          }
          .loteryNums:nth-child(9){
            padding-left: 45px;
          }
          .jiahao{
            position: relative;
            right: 20%;
            top:20px;
            font-size: 20px;
          }
          .loteryScribe{
            text-align: center;
            padding-top: 2px;
            display: block;
            font-size: 13px;
          }
     
        
    }

/* -------------------------------------------------------------------我是分割线---------------------------------------------------------------------------- */
    /deep/.van-nav-bar .van-icon{
        font-size: 22px;
        color: #fff;
    }
    /deep/.van-action-sheet{
        width: 100%;
        max-width: 600px;
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
    }


    /deep/.van-overlay{
        max-width: 600px;
        position: fixed;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        z-index: 20;
        
    }
    .record-tabs{
        display: flex;
        justify-content: space-around;
        width: 80%;
        height: 30px;
        position: relative;
        margin: 0 auto;
    }
    .record-tabs span:last-child{
        border-radius: 0 5px 5px 0;
        border-left-width: 0;
    }
    .record-tabs span:first-child{
        border-radius: 5px 0 0 5px;
    }
    .record-tabs span{
        cursor: pointer;
        border: 1px solid #ff5000;
        width: 50%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #ff5000;
        background-color: #fff;
    }
    .record-tabs span.tabs_active{
        color: #fff;
        background-color: #ff5000;
    }
    .down_app2{
        position: relative;
        top: -25px;
        margin:0 auto;
    }
    .down_app2 img{
        width:90%;
        max-width: 600px;
    }
    /deep/.van-picker{
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }

    /deep/.van-list{
        position: relative;
        top:70px;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }

    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-cell-border{
        color:red;
    }
    /deep/.van-popup--bottom{
        max-width: 600px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
    }
    
    /deep/.van-overlay{
        max-width: 600px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
    }

    .record-title{
        position: relative;
        display: flex;
        justify-content: space-between;
        top: 40px;
        padding: 14px 20px;
    }

    .record-title div{
        position: relative;
        display: flex;
        justify-content: space-around;
        height: 20px;
        right: 0;
        top: 20px;
    }

    .record-title div span.order{
        color: #ff5000;
        background-color: #fff;
    }

    .record-title div span{
        cursor: pointer;
        border: 1px solid #f5f5f5;
        padding: 2px 10px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 13px;
        color: #999;
        background-color: #f5f5f5;
    }

    /deep/.van-calendar__day{
        color:rgb(255, 97, 6);
    }
    /deep/.van-calendar__selected-day{
        background-color:rgb(240, 88, 0);
    }
    
</style>


