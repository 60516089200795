import { createStore } from 'vuex'

const key ='token'

export default createStore({
  // 声明状态
  state(){
    return{
      token:localStorage.getItem('token') ? localStorage.getItem('token') : '',
      username:localStorage.getItem('username') ? localStorage.getItem('username') : '',
      userId:localStorage.getItem('userId') ? localStorage.getItem('userId') : '',
      logname:localStorage.getItem('logname') ? localStorage.getItem('logname') : '',
      password:localStorage.getItem('password') ? localStorage.getItem('password') : '',
      noLogin:localStorage.getItem('token') ? false : true ,
      avatar:localStorage.getItem('avatar') ? localStorage.getItem('avatar') : require('../assets/userAvatar.png'),
      detailId:localStorage.getItem('detailId') ? localStorage.getItem('detailId') : '',
      detailTitle:localStorage.getItem('detailTitle') ? localStorage.getItem('detailTitle') : '',
      imgTypeId:localStorage.getItem('imgTypeId') ? localStorage.getItem('imgTypeId') : '',
      backPathName:localStorage.getItem('backPathName') ? localStorage.getItem('backPathName') : '',
      postId:localStorage.getItem('postId') ? localStorage.getItem('postId') : '',
      userId:localStorage.getItem('userId') ? localStorage.getItem('userId') : '',
      code:localStorage.getItem('code') ? localStorage.getItem('code') : '',
    }
 
  },
  // 获取状态信息
  getters:{
    getSortage(state) {
      if(!state.token){
        state.token =JSON.parse(localStorage.getItem(key))
      }
      return state.token
    },
  },
  // 更新状态
  mutations: {
    $_setStorage(state,value){
      state.token =value;
      localStorage.setItem(key,JSON.stringify(value))
    },
    $_removeStorage(state){
      state.token =null;
      state.avatar = require('../assets/userAvatar.png')
      localStorage.clear()
    },
    setAvatar(state,value){
      state.avatar = value
      localStorage.setItem('avatar',value)
    },
    setLoginStatus(state,value){
      state.noLogin =value;
    },
    setUsername(state,value){
      state.username =value;
      localStorage.setItem('username',JSON.stringify(value))
    },
    setPassword(state,value){
      state.password =value;
      localStorage.setItem('password',JSON.stringify(value))
    },
    setUserid(state,value){
      state.userId =value;
      localStorage.setItem('userId',JSON.stringify(value))
    },
    setLogname(state,value){
      state.logname =value;
      localStorage.setItem('logname',JSON.stringify(value))
    },
    setDetailId(state,value){
      state.detailId =value;
      localStorage.setItem('detailId',value)
    },
    setDetailTitle(state,value){
      state.detailTitle =value;
      localStorage.setItem('detailTitle',value)
    },
    setImgTypeId(state,value){
      state.imgTypeId =value;
      localStorage.setItem('imgTypeId',value)
    },
    setbackPath(state,value){
      state.backPathName =value;
      console.log(state.backPathName)
      localStorage.setItem('backPathName',value)
    },
    setcode(state,value){
      state.backPathName =value;
      localStorage.setItem('code',value)
    },
    setforumPostId(state,value){
      state.postId =value;
      localStorage.setItem('postId',value)
    },
    setforumUserId(state,value){
      state.userId =value;
      localStorage.setItem('userId',value)
    },
  },
  actions: {
  },
  modules: {
  }
})
