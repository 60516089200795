<template>
    <div>
        <van-nav-bar title='站内消息' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:50px;'></div>   <!-- 增加头部脚部高度，部面盖住内容 -->
       
    </div>
</template>

<script>
import { NavBar,} from 'vant';
    export default {
        created(){
        },
        components:{
            'van-nav-bar':NavBar,
            
        },
        data() {
            return {
                  
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/user')
            },
            
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    
</style>