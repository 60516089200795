<template>
    <div class="mainCountain">
         <van-tabbar v-model="active">
            <van-tabbar-item to="/data/count" class="vanTabbarIcon">
                <span>资料统计</span>
                <template #icon>
                    <p :class="active==0?'activeBg':'inactiveBg'">统</p>
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/data/attribution" class="vanTabbarIcon">
                <span>属性参照</span>
                <template #icon>
                    <p :class="active==1?'activeBg':'inactiveBg'">属</p>
                </template>
            </van-tabbar-item >
            <van-tabbar-item to="/data/specialCode" class="vanTabbarIcon">
                <span>特码历史</span>
                <template #icon>
                    <p :class="active==2?'activeBg':'inactiveBg'">特</p>
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/data/positiveCode" class="vanTabbarIcon" >
                <span>正码历史</span>
                <template #icon>
                    <p :class="active==3?'activeBg':'inactiveBg'">正</p>
                </template>
            </van-tabbar-item>
            <van-tabbar-item class="vanTabbarIcon"  @click="moreCodeShow = true">
                <span>更多</span>
                <template #icon>
                    <p :class="active==4?'activeBg':'inactiveBg'">···</p>
                </template>
            </van-tabbar-item>
        </van-tabbar>

        <van-overlay :show="moreCodeShow" @click="moreCodeShow = false">
            <div class="popIndWrap">
                <ul>
                    <li v-for="(item,index) in popList" :key="index+20">
                        <a @click="handleClickChangeCode(item.id)" :class="popActive == item.id?'tabs_active':''">
                            <i>{{item.title}}</i>
                            <span>{{item.txt}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </van-overlay>

         <router-view></router-view>
    </div>
</template>

<script>
import { Tabbar, TabbarItem,NavBar, Overlay, } from 'vant';
    export default {
        created(){
           
        },
        components:{
            'van-nav-bar':NavBar,
            'van-tabbar':Tabbar,
            'van-tabbar-item':TabbarItem,
            'van-overlay':Overlay,
        },
        data() {
            return {
                detailTitle:'',
                Qsnum:'100',
                active:0,
                moreCodeShow:false,
                popActive:'',
                popList:[
                    {id:1,title:'尾',txt:'尾数大小'},
                    {id:2,title:'生',txt:'生肖特码'},
                    {id:3,title:'肖',txt:'生肖正码'},
                    {id:4,title:'波',txt:'波色特码'},
                    {id:5,title:'色',txt:'波色正码'},
                    {id:6,title:'两',txt:'特码两面'},
                    {id:7,title:'尾',txt:'特码尾数'},
                    {id:8,title:'数',txt:'正码尾数'},
                    {id:9,title:'分',txt:'正码总分'},
                    {id:10,title:'段',txt:'号码波段'},
                    // {id:11,title:'禽',txt:'家禽走势'},
                    // {id:12,title:'码',txt:'连码走势'},
                    // {id:13,title:'连',txt:'连肖走势'},
                ]
            }
        },
        methods: {
            onClickLeft(index) {
                this.$router.push("/");
            },
            handleClickChangeCode(id){
                this.popActive = id
                if(id == 1){
                    this.$router.push("/data/table")
                }else if(id == 2){
                    this.$router.push("/data/sxSpecial")
                }else if(id == 3){
                    this.$router.push("/data/sxPositiveCode")
                }else if(id == 4){
                    this.$router.push("/data/bsSpecialCode")
                }else if(id == 5){
                    this.$router.push("/data/bsPositiveCode")
                }else if(id == 6){
                    this.$router.push("/data/specialTwoSides")
                }else if(id == 7){
                    this.$router.push("/data/wsSpecialCode")
                }else if(id == 8){
                    this.$router.push("/data/wsPositiveCode")
                }else if(id == 9){
                    this.$router.push("/data/positiveTwoside")
                }else if(id == 10){
                    this.$router.push("/data/numBandCode")
                }
            },
        },
    }
</script>

<style lang='scss' scoped>
    .vanTabbarIcon p{
        display: inline-block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-style: normal;
        text-align: center;
        color: #fff;
    }
    .activeBg{
        background-color:#c266ff;
    }
    .inactiveBg{
        background-color: #999;
    }
    .headWrap{
        position: relative;
        width: 100%;
        height: 45px;
        font-size: .48rem;
        text-align: center;
        color: #fff;
        background: rgb(174, 115, 252);
    }
    
    .headQs a span{
        font-size: 15px;
        color: #fff;
    }
    .headQs a b{
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px;
    }
    .mainCountain{
        height: 100%;
    }
    .popIndWrap{
        position: fixed;
        left: calc(50% - 50%);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
        bottom: 50px;
        padding: 5px 0;
        border-radius: 5px;
        width: 92%;
        background-color: #fff;
    }
    .popIndWrap ul{
        position: relative;
        display: grid;
        grid-template-columns: repeat(5,1fr);
    }
    .popIndWrap ul::before{
        position: absolute;
        right: 0.4rem;
        bottom: -0.8rem;
        content: "";
        width: 0;
        height: 0;
        border: 0.266667rem solid transparent;
        border-top-color: #fff;
    }
    .popIndWrap ul::after{
        position: absolute;
        right: 0.4rem;
        bottom: -0.8rem;
        content: "";
        width: 0;
        height: 0;
        border: 0.266667rem solid transparent;
        border-top-color: #fff;
    }
    .tabs_active i{
        background-color: #ff531a !important;
    }
    .tabs_active span{
        color: #ff531a;
    }
    .popIndWrap ul li a{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
    }
    .popIndWrap ul li a i{
        display: inline-block;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 13px;
        font-style: normal;
        text-align: center;
        color: #fff;
        background-color: #999;
    }
    .popIndWrap ul li a span{
        margin-top: 5px;
        font-size: 13px;
    }

    /deep/.van-tabbar-item--active{
        color:#c266ff;
    }
    /deep/.van-tabbar{
        max-width:600px;
        left: auto;
    }
    /deep/.van-overlay{
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
        z-index: 1000;

    }
</style>