import request from '../util/request'
export function openLatest(params,page) {
    return request({
      url: '/open/latest',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }
  
  export function openMore(params,page) {
    return request({
      url: '/open/more',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '65'
      },
      params
    })
  }
  
  export function openTime(params,page) {
    return request({
      url: '/open/time',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }
  
  export function openQueryHelper(params,page) {
    return request({
      url: '/open/query/helper',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function imgSearch(params,page) {
    return request({
      url: '/img/search',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function imgQs(params,page) {
    return request({
      url: '/img/qs',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function imgQsDetails(params,page) {
    return request({
      url: '/img/qs/details',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function imgCarousel(params,page) {
    return request({
      url: '/img/my/carousel',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function imgNotice(params,page) {
    return request({
      url: '/img/my/notice',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function imgList(params,page) {
    return request({
      url: '/img/list',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function articleType(params,page) {
    return request({
      url: '/article/type',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function articleList(params,page) {
    return request({
      url: '/article/list',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function articleDetails(params,page) {
    return request({
      url: '/article/details',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userLogin(params,page) {
    return request({
      url: '/user/login',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userQuestionList(params,page) {
    return request({
      url: '/user/question/list',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userRegister(params,page) {
    return request({
      url: '/user/register',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userModifyUser(params,page) {
    return request({
      url: '/user/modify/user',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userModifyPassword(params,page) {
    return request({
      url: 'user/modify/password',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userCheckUser(params,page) {
    return request({
      url: '/user/check/user',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userUserQuestion(params,page) {
    return request({
      url: '/user/user/question',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function resetPassword(params,page) {
    return request({
      url: '/user/reset/password',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function commentList(params,page) {
    return request({
      url: '/comment/list',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userAddRelation(params,page) {
    return request({
      url: '/user/add/relation',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }
  
  export function commentAdd(params,page) {
    return request({
      url: '/comment/add',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }

  export function userCount(params,page) {
    return request({
      url: '/user/count',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }
  export function commentReplyList(params,page) {
    return request({
      url: '/comment/reply/list',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }
  export function guessQs(params,page) {
    return request({
      url: 'guess/qs',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }

  export function guessDetails(params,page) {
    return request({
      url: 'guess/details',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }
  export function guessGetVote(params,page) {
    return request({
      url: 'guess/get/vote',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }
  export function guessVote(params,page) {
    return request({
      url: 'guess/vote',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }
  export function openDataStatistics(params,page) {
    return request({
      url: 'open/data/statistics',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }
  export function forumList(params,page) {
    return request({
      url: '/forum/list',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }
  export function forumType(params,page) {
    return request({
      url: '/forum/type',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '4'
      },
      params
    })
  }
  export function forumMasterList(params,page) {
    return request({
      url: '/forum/master/list',
      method: 'post',
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      params
    })
  }
  export function forumRelease(params) {
    return request({
      url: '/forum/release',
      method: 'post',
      params
    })
  }
  export function forumTestRelease(params) {
    return request({
      url: '/forum/test/release',
      method: 'post',
      params
    })
  }
  export function forumDetails(params) {
    return request({
      url: '/forum/details',
      method: 'post',
      params
    })
  }
  export function forumLastForumPost(params) {
    return request({
      url: '/forum/last/forum/post',
      method: 'post',
      params
    })
  }
  export function forumFormulaList(params,page) {
    return request({
      url: '/forum/formula/list',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '5'
      },
      
    })
  }
  export function userMyLike(params,page) {
    return request({
      url: '/user/my/like',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  export function openDataAttributes(params,page) {
    return request({
      url: '/open/data/attributes',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  export function openSpeNumStatistics(params,page) { //特码
    return request({
      url: '/open/spe/num/statistics',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  export function openComNumStatistics(params,page) { //正码
    return request({
      url: '/open/com/num/statistics',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function openWsNum(params,page) { //尾数大小
    return request({
      url: '/open/ws/num',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function openTwoSides(params,page) { //特码正码两面
    return request({
      url: '/open/two/sides',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  
  export function openNumBand(params,page) { //号码波段
    return request({
      url: '/open/num/band',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  export function imgActivity(params,page) { //优惠活动
    return request({
      url: '/img/my/activity',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function imgLink(params,page) { //跳转链接
    return request({
      url: '/img/my/link',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function userMyFocus(params,page) { //我的关注
    return request({
      url: '/user/my/focus',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  export function userRemoveRelation(params,page) { //取消关注
    return request({
      url: '/user/remove/relation',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function forumHomepageList(params,page) { //他的主页
    return request({
      url: '/forum/homepage/list',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function forumMyPost(params,page) { //我的发布
    return request({
      url: '/forum/my/post',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  export function userMyComment(params,page) { //我的评论
    return request({
      url: '/user/my/comment',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function imgMyFind(params,page) { //发现
    return request({
      url: '/img/my/find',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function predictResult(params,page) { //特码
    return request({
      url: '/predict/result',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }
  export function predictPResult(params,page) { //平肖
    return request({
      url: '/predict/p/result',
      method: 'post',
      params,
      headers: {
        'pageNum': page,
        'pageSize': '15'
      },
      
    })
  }

  export function openLatestSingle(params,page) { //轮询最新开奖结果
    return request({
      url: '/open/latest/single',
      method: 'post',
      params,
    })
  }