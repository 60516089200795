<template>
    <div>
       <div style='width:100%;height:50px;'></div>   <!-- 增加底部脚部高度，部面盖住内容 -->
        <van-tabbar route v-model="active" >
            <van-tabbar-item icon="home-o" to='/'>首页</van-tabbar-item>
            <van-tabbar-item icon="search"  to='/Newdiscover'>图库</van-tabbar-item>
            <van-tabbar-item icon="fire"  @click="goToGame">游戏</van-tabbar-item>
            <!-- <van-tabbar-item icon="chat-o"  badge="5" to='/massege'>消息</van-tabbar-item> -->
            <van-tabbar-item icon="point-gift-o" to='/Discount'>优惠</van-tabbar-item>
            <van-tabbar-item icon="user-o" to='/user'>我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import {imgLink} from '../api/http'
import { Tabbar, TabbarItem, Toast } from 'vant';
    export default {
        created(){
            this.getLink()
        },
        components:{
            'van-tabbar':Tabbar,
            'van-tabbar-item':TabbarItem
        },
        data() {
            return {
                  active: 0,
                  gameLink:'',
                  isShowIframe:true
            }
        },
        methods: {
            getLink(){
                let data = {
                    key:'game-link'//游戏链接
                }
                imgLink(data).then(res=>{
                    this.gameLink = res.data.data
                })
            },
            goToGame(){
                let data = {
                    link:this.gameLink,
                    isShowIframe:this.isShowIframe
                }
                this.$emit("showIframe",data)
            },
            gotoDiscover(){
                Toast("敬请期待 !")
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-tabbar{
        max-width:600px;
        left: auto;
        z-index: 10;
    }
    /deep/.van-icon-fire:before{
        color:red
    }
</style>