<template>
    <div>
        <div class="headWrap"> 
            <van-nav-bar :title='detailTitle' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
            <div class="headQs">
                <a @click="popWrapShow = true">
                    <span>期数</span>
                    <b>{{Qsnum}}</b>
                </a>
            </div>
        </div>
         <div style='width:100%;height:20px;'></div>  <!--增加头部高度，避免盖住内容 -->
        <div class="countMains">
            <ul class="countNumber">
                <li>
                    <p>特码出现期数最多的号码</p>
                    <div>
                        <a v-for="(item,i) in specialNumShow" :key="i+10">
                            <span><img :src="require(`../assets/${item}.png`)" ></span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>特码当前遗漏期数最多的号码</p>
                    <div>
                        <a v-for="(item,i) in specialNumNoShow" :key="i+10">
                            <span><img :src="require(`../assets/${item}.png`)" ></span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码出现期数最多的号码</p>
                    <div>
                        <a v-for="(item,i) in commonNumShow" :key="i+10">
                            <span><img :src="require(`../assets/${item}.png`)" ></span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码当前遗漏期数最多的号码</p>
                    <div>
                        <a v-for="(item,i) in commonNumNoShow" :key="i+10">
                            <span><img :src="require(`../assets/${item}.png`)" ></span>
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="countZodiac">
                <li>
                    <p>特码出现期数最多的生肖</p>
                    <div>
                        <a v-for="(item,i) in specialSXShow" :key="i+10">
                            <span>{{item}}</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>特码当前遗漏期数最多的生肖</p>
                    <div>
                        <a v-for="(item,i) in specialSXNoShow" :key="i+10">
                            <span>{{item}}</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码出现期数最多的生肖</p>
                    <div>
                        <a v-for="(item,i) in commonSxShow" :key="i+10">
                            <span>{{item}}</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码当前遗漏期数最多的生肖</p>
                    <div>
                        <a v-for="(item,i) in commonSXNoShow" :key="i+10">
                            <span>{{item}}</span>
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="countColor">
                <li>
                    <p>特码出现期数最多的波色</p>
                    <div>
                        <a v-for="(item,i) in specialBSShow" :key="i+10">
                            <span v-show="item" :class="[item == '红' ? 'countColorRed':'countColorBlue',item == '绿' ? 'countColorGreen':'',] ">{{item}}波</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>特码当前遗漏期数最多的波色</p>
                    <div>
                        <a v-for="(item,i) in specialBSNoShow" :key="i+10">
                            <span v-show="item" :class="[item == '红' ? 'countColorRed':'countColorBlue',item == '绿' ? 'countColorGreen':'',] ">{{item}}波</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码出现期数最多的波色</p>
                    <div>
                        <a v-for="(item,i) in commonBSShow" :key="i+10">
                            <span v-show="item" :class="[item == '红' ? 'countColorRed':'countColorBlue',item == '绿' ? 'countColorGreen':'',] ">{{item}}波</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码当前遗漏期数最多的波色</p>
                    <div>
                        <a v-for="(item,i) in commonBSNoShow" :key="i+10">
                            <span v-show="item" :class="[item == '红' ? 'countColorRed':'countColorBlue',item == '绿' ? 'countColorGreen':'',] ">{{item}}波</span>
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="countTail">
                <li>
                    <p>特码出现期数最多的尾数</p>
                    <div>
                        <a v-for="(item,i) in specialWSShow" :key="i+10">
                            <span v-show="item">{{item}}尾</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>特码当前遗漏期数最多的尾数</p>
                    <div>
                        <a v-for="(item,i) in specialWSNoShow" :key="i+10">
                            <span v-show="item">{{item}}尾</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码出现期数最多的尾数</p>
                    <div>
                        <a v-for="(item,i) in commonWSShow" :key="i+10">
                            <span v-show="item">{{item}}尾</span>
                        </a>
                    </div>
                </li>
                <li>
                    <p>正码当前遗漏期数最多的尾数</p>
                    <div>
                        <a v-for="(item,i) in commonWSNoShow" :key="i+10">
                            <span v-show="item">{{item}}尾</span>
                        </a>
                    </div>
                </li>
            </ul>
            
        </div>
        <van-overlay :show="popWrapShow" @click="popWrapShow = false">
            <div class="popWrap" @click.stop>
                <div>
                    <span>请填写要统计的期数</span>
                    <input type="text" v-model="inputNum" :placeholder="Qsnum">
                </div>
                <span class="btn" @click="handleComfir">确定</span>
            </div>
        </van-overlay>
        <div style='width:100%;height:40px;'></div>  <!--增加脚部高度，避免盖住内容 -->
        <!-- <van-tabbar v-model="active">
            <van-tabbar-item to="/data/count" class="vanTabbarIcon">
                <span>资料统计</span>
                <template #icon>
                    <p :class="active==0?'activeBg':'inactiveBg'">统</p>
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/data/attribution" class="vanTabbarIcon">
                <span>属性参照</span>
                <template #icon>
                    <p :class="active==1?'activeBg':'inactiveBg'">属</p>
                </template>
            </van-tabbar-item >
            <van-tabbar-item class="vanTabbarIcon">
                <span>特码历史</span>
                <template #icon>
                    <p :class="active==2?'activeBg':'inactiveBg'">特</p>
                </template>
            </van-tabbar-item>
            <van-tabbar-item  class="vanTabbarIcon" >
                <span>正码历史</span>
                <template #icon>
                    <p :class="active==3?'activeBg':'inactiveBg'">正</p>
                </template>
            </van-tabbar-item>
            <van-tabbar-item class="vanTabbarIcon">
                <span>更多</span>
                <template #icon>
                    <p :class="active==4?'activeBg':'inactiveBg'">···</p>
                </template>
            </van-tabbar-item>
        </van-tabbar> -->
    </div>
</template>

<script>
import { Tabbar, TabbarItem,NavBar, Overlay} from 'vant';
import {openDataStatistics} from '../api/http'
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.getCount()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-tabbar':Tabbar,
            'van-tabbar-item':TabbarItem,
            'van-overlay':Overlay,
        },
        data() {
            return {
                detailTitle:'资料统计',
                Qsnum:'100',
                inputNum:'',
                popWrapShow:false,
                specialNumShow:[],
                specialNumNoShow:[],
                commonNumShow:[],
                commonNumNoShow:[],
                specialSXShow:[],
                specialSXNoShow:[],
                commonSxShow:[],
                commonSXNoShow:[],
                specialBSShow:[],
                specialBSNoShow:[],
                commonBSShow:[],
                commonBSNoShow:[],
                specialWSShow:[],
                specialWSNoShow:[],
                commonWSShow:[],
                commonWSNoShow:[],
                active:0,
                icon :{
                    active: require(`../assets/02.png`),
                    inactive: require(`../assets/01.png`),
                }
            }
        },
        methods: {
            onClickLeft(index) {
                this.$router.push("/");
            },
            getCount(){
                let data = {
                    code:this.code,
                    rows:this.Qsnum
                }
                openDataStatistics(data).then(res=>{
                    if(res.data.code == 200){
                        console.log(res)
                        this.specialNumShow = res.data.data.specialNumShow.split(',')
                        this.specialNumNoShow = res.data.data.specialNumNoShow.split(',')
                        this.commonNumShow = res.data.data.commonNumShow.split(',')
                        this.commonNumNoShow = res.data.data.commonNumNoShow.split(',')
                        this.specialSXShow = res.data.data.specialSXShow.split(',')
                        this.specialSXNoShow = res.data.data.specialSXNoShow.split(',')
                        this.commonSxShow = res.data.data.commonSxShow.split(',')
                        this.commonSXNoShow = res.data.data.commonSXNoShow.split(',')
                        this.specialBSShow = res.data.data.specialBSShow.split(',')
                        this.specialBSNoShow = res.data.data.specialBSNoShow.split(',')
                        this.commonBSShow = res.data.data.commonBSShow.split(',')
                        this.commonBSNoShow = res.data.data.commonBSNoShow.split(',')
                        this.specialWSShow = res.data.data.specialWSShow.split(',')
                        this.specialWSNoShow = res.data.data.specialWSNoShow.split(',')
                        this.commonWSShow = res.data.data.commonWSShow.split(',')
                        this.commonWSNoShow = res.data.data.commonWSNoShow.split(',')
                        console.log(this.specialNumShow)
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            handleComfir(){
                this.Qsnum = this.inputNum
                this.popWrapShow = false
                this.getCount()
            },
        },
    }
</script>

<style lang='scss' scoped>
// pc
@media screen and (min-width:700px) {
    .popWrap{
       width: 50%;
       max-width: 400px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        margin-left: 500px;
        z-index: 10;
    }
}
//移动端
@media screen and (max-width:750px) {
    .popWrap{
       width: 70%;
       max-width: 600px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        right: 10px;
        z-index: 10;
    }
}
    .headWrap{
        position: relative;
        width: 100%;
        height: 45px;
        font-size: .48rem;
        text-align: center;
        color: #fff;
        background: rgb(174, 115, 252);
    }
    
    .headQs a span{
        font-size: 15px;
        color: #fff;
    }
    .headQs a b{
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px;
    }
    .popWrap{
        position: fixed;
        left: calc(50% - 3.33333rem);
        top: calc(50% - 1.66667rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 150px;
        line-height: 150px;
        background-color: #fff;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .popWrap>div span{
        font-size: 15px;
    }
    .popWrap>div input{
        margin-left: 15px;
        border: 1px solid #c0bcbc;
        border-radius: 5px;
        width: 45px;
        height: 25px;
        text-align: center;
        font-size: 15px;
    }
    .popWrap .btn{
        display: inline-block;
        position: relative;
        bottom: 40px;
        border-radius: 8px;
        width: 100px;
        height: 80px;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        background-color: #ff5000;
    }
    .countMains{
        padding-bottom: 20px;
    }
    .countMains .countNumber{
        padding: 10Px;
        border-bottom: 15px solid #f5f5f5;
        margin-top: 20px;
    }
    .countNumber li{
        text-align: left;
        height: 80px;
        
    }
    .countNumber li p{
        font-size: 14px;
    }
    .countNumber li>div{
        margin-top: 0.266667rem;
        display: grid;
        grid-template-columns: repeat(10,1fr);
        margin-top: 10px;
    }
    .countNumber li>div a{
        text-align: center;
    }
    .countNumber li>div a img{
        width: 33px;
        
    }
    .countZodiac{
        padding: 10px;
        border-bottom: 15px solid #f5f5f5;
    }
    .countZodiac li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
    }
    .countZodiac li p{
        font-size: 14px;
    }
    .countZodiac li>div{
        display: flex;
    }
    .countZodiac li>div a{
        padding-left: 20px;
    }
    .countZodiac li>div a span{
        font-size:13px;
    }
    .countColor{
        padding: 10Px;
        border-bottom: 15px solid #f5f5f5;
    }
    .countColor li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
    }
    .countColor li p{
        font-size: 14px;
    }
    .countColor li>div{
        display: flex;
    }
    .countColor li>div a{
        padding-left:20px;
    }
    .countColor li>div a span{
        font-size: 14px;
    }
    .countTail{
        padding: 10Px 10Px 0;
    }
    .countTail li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
    }
    .countTail li p{
        font-size: 14px;
    }
    .countTail li>div{
        display: flex;
    }
    .countTail li>div a{
        padding-left:16px ;
    }
    .countTail li>div a span{
        font-size: 14px;
    }
    .countColorRed{
        color: red;
    }
    .countColorBlue{
        color: blue;
    }
    .countColorGreen{
        color: green;
    }
    .vanTabbarIcon p{
        display: inline-block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-style: normal;
        text-align: center;
        color: #fff;
    }
    .activeBg{
        background-color:#c266ff;
    }
    .inactiveBg{
        background-color: #999;
    }
    /deep/.van-overlay{
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
        z-index: 1000;

    }
    /deep/.van-tabbar-item--active{
        color:#c266ff;
    }
    /deep/.van-tabbar{
        max-width:600px;
        left: auto;
    }
</style>