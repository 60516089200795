const baseSize = 32
function setRem(){
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        /*手机端*/
        const scale = document.documentElement.clientWidth / 750
        document.documentElement.style.fontSize = (baseSize * Math.min(scale,2)) + 'px'
    }
    else {
        /*pc端;    */
        const scale = document.documentElement.clientWidth / 2500
        document.documentElement.style.fontSize = (baseSize * Math.min(scale,2)) + 'px'
    }
    

}
setRem()
window.onresize = function(){
    setRem()
}