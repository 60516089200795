<template>
    <div>
        <van-nav-bar title='我的关注' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:50px;'></div>   <!-- 增加头部头部高度，部面盖住内容 -->
       <div class="followList">
           <div class="followItem" v-for="(item,index) in followList" :key="index+20">
               <div class="followItemLeft" @click="gotoTaHomePage(item.userId)">
                   <a>
                       <img :src="item.avatar" alt="">
                       <div>
                           <span>{{item.userName}}</span>
                       </div>
                   </a>
               </div>
               <div class="followItemRight" @click="removeFollow(item.userId,item)">
                   <span :class="item.isFollow?'active':''">{{item.followText}}</span>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
import {userMyFocus,userRemoveRelation,userAddRelation} from '../api/http'
import { NavBar,Tabs,Tab, List, Toast } from 'vant';
    export default {
        created(){
            this.getFollowList()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-tabs':Tabs,
            'van-tab':Tab,
            'van-list':List,
        },
        data() {
            return {
                followList:[],
                // followText:'已关注',
                // isFollow:true
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/user')
            },
            getFollowList(){
                userMyFocus().then(res=>{
                    if(res.data.code == 200){
                        this.followList = res.data.data
                        for (let i = 0; i < this.followList.length; i++) {
                            this.followList[i].isFollow = true
                            this.followList[i].followText = '已关注'
                        }
                    }
                })
            },
            removeFollow(userId,item){
                if(item.isFollow){
                    let data = {
                        type:2,
                        moduleType:100,
                        otherId:userId
                    }
                    userRemoveRelation(data).then(res => {
                        if(res.data.code == 200){
                            Toast('已取消关注')
                            item.isFollow = false
                            item.followText = '+ 关注'
                        }
                    })
                }else{
                    let data = {
                        type:2,
                        moduleType:100,
                        otherId:userId
                    }
                    userAddRelation(data).then(res=>{
                         if(res.data.code == 200){
                             Toast('已关注')
                            item.isFollow = true
                            item.followText = '已关注'
                        }
                    })
                }
            },
            gotoTaHomePage(userId){
                this.$router.push({                                                     
                    name: 'UserHomePage',                        
                    params: {    
                        taUserId:userId,
                    }
                })
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    .followList .followItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.267);

    }
    .followList .followItem .followItemLeft a{
        display: flex;
        align-items: center;
    }
    .followList .followItem .followItemLeft a img{
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: #999;
    }
    .followList .followItem .followItemLeft a div{
        display: flex;
        flex-direction: column;
        margin-left: 10Px;
    }
    .followList .followItem .followItemLeft a div span{
        font-size: 15px;
    }
    .followList .followItem .followItemRight span{
        border-radius: 3px;
        border: 1px solid #ff5000;
        padding: 5px 10px;
        color: #fff;
        background-color: #ff5000;
        font-size: 15px;
    } 
    .followList .followItem .followItemRight span.active{
        border: 1px solid #dcdcdc;
        color: #666;
        background-color: transparent;
    }
</style>