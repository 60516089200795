<template>
    <div>
        <van-nav-bar title='我的设置' fixed left-arrow @click-left="onClickLeft" />
        <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
        <div class="infoSetList">
            <ul>
                <li @click="setMyavatar">
                    <img :src="avatar" alt="">
                    <van-icon name="arrow" />
                </li>
                <li @click="setMyname">
                    <span>我的昵称</span>
                    <div>
                        <span class='Myname'>{{paramUserName}}</span>
                        <van-icon name="arrow" />
                    </div>
                </li>
                <li @click="setNewpassWord">
                    <span>修改登录密码</span>
                    <van-icon name="arrow" />
                </li>
            </ul>
        </div>
        <van-popup v-model:show="setNameshow" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='修改昵称' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="setnamemains">
                <div>请输入您想要的个人昵称</div>
                <van-field
                    v-model="setnameValue"
                    clearable
                    :placeholder="setnamePlaceHolder"
                />
                <div @click="completeSetUserName">
                    <span class="btn">完成</span>
                </div>
            </div>
        </van-popup>

        <van-popup v-model:show="setAvatarshow" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='修改头像' fixed left-arrow @click-left="setClickLeft" >
                <template #right>
                    <span @click="completeSetAvater">完成</span>
                </template>
            </van-nav-bar>
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="setavatarmains">
                <van-grid :column-num="4" square>
                    <van-grid-item v-for="(item,index) in gridList" :key="index+item" @click="handleGrid(item,index)">
                        <img style="width:80px;border-radius:50%;" :src="item.imageUrl" />
                        <p :class="pickIndex === index?'act':''" ><span v-if="pickIndex === index?true:false"><van-icon name="success" /></span></p>
                    </van-grid-item>
                </van-grid>
            </div>
        </van-popup>


        <van-popup v-model:show="setpasswordshow" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='修改密码' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="setpasswordmains">
                <van-field
                    v-model="oldpasswordValue"
                    clearable
                    placeholder="请输入旧密码"
                />
                <van-field
                    v-model="newpasswordValue"
                    clearable
                    :formatter="passwordformatter"
                    :error-message="passwordErrorMsg"
                    placeholder="请输入新密码"
                />
                <van-field
                    v-model="againnewpasswordValue"
                    clearable
                    :formatter="passwordAgainformatter"
                    :error-message="passwordAgainErrorMsg"
                    placeholder="请再次输入新密码"
                />
                <div @click="setNewPassword">
                    <span class="btn">确定</span>
                </div>
            </div>
        </van-popup>
        <div class="infoSetOut" @click="infoSetOut">
            <span class="btn">退出登录</span>
        </div>
    </div>
</template>

<script>
import {userModifyUser,userModifyPassword} from '../api/http'
import { NavBar, Icon,Popup,Field, Grid, GridItem, Toast } from 'vant';
    export default {
        created(){
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-popup':Popup,
            'van-field':Field,
            'van-grid':Grid,
            'van-grid-item':GridItem,
        },
        data() {
            return {
                  setNameshow: false,
                  setpasswordshow: false,
                  setAvatarshow: false,
                  passwordAgain:'',
                  passwordAgainErrorMsg:'',
                  setnameValue:'',
                  oldpasswordValue:'',
                  newpasswordValue:'',
                  againnewpasswordValue:'',
                  pickIndex:'',
                  gridList:[
                        {id:0,imageUrl:require('../assets/avatar1.jpg')},
                        {id:1,imageUrl:require('../assets/avatar2.jpg')},
                        {id:2,imageUrl:require('../assets/avatar3.jpg')},
                        {id:3,imageUrl:require('../assets/avatar4.jpg')},
                        {id:4,imageUrl:require('../assets/avatar5.jpg')},
                        {id:5,imageUrl:require('../assets/avatar6.jpg')},
                        {id:6,imageUrl:require('../assets/avatar7.jpg')},
                        {id:7,imageUrl:require('../assets/avatar8.jpg')},
                        {id:8,imageUrl:require('../assets/avatar9.jpg')},
                        {id:9,imageUrl:require('../assets/avatar10.jpg')},
                        {id:10,imageUrl:require('../assets/avatar11.jpg')},
                        {id:11,imageUrl:require('../assets/avatar12.jpg')},
                        {id:12,imageUrl:require('../assets/avatar13.jpg')},
                        {id:13,imageUrl:require('../assets/avatar14.jpg')},
                        {id:14,imageUrl:require('../assets/avatar15.jpg')},
                        {id:15,imageUrl:require('../assets/avatar16.jpg')},
                        {id:16,imageUrl:require('../assets/avatar17.jpg')},
                        {id:17,imageUrl:require('../assets/avatar18.jpg')},
                        {id:18,imageUrl:require('../assets/avatar19.jpg')},
                        {id:19,imageUrl:require('../assets/avatar20.jpg')},
                        {id:20,imageUrl:require('../assets/avatar21.jpg')},
                  ]
                  
            }
        },
        computed:{
            avatar(){
               return this.$store.state.avatar
            },
            paramUserName(){
                return this.$store.state.username.replace(/\"/g, "");
            }, 
            setnamePlaceHolder(){
                return this.$store.state.username.replace(/\"/g, "");
            }, 
            paramLoginName(){
                return this.$store.state.logname.replace(/\"/g, "");
            }, 
        },
        methods: {
            onClickLeft() {
                 this.$router.go(-1)
            },
            setClickLeft(){
                this.setNameshow = false
                this.setpasswordshow = false
                this.setAvatarshow = false
            },
            setMyavatar(){
                this.setAvatarshow = true
            },
            setMyname(){
                this.setNameshow = true
            },
            setNewpassWord(){
                this.setpasswordshow = true
            },
            infoSetOut(){
                this.$store.commit('$_removeStorage')
                this.$store.commit('setLoginStatus',true)
                this.$router.push({                                                     
                    name: 'User',                         
                })
            },
            handleGrid(item,index){

                this.pickIndex = index
                this.$store.commit('setAvatar',item.imageUrl)
            },  
            completeSetAvater(){
                let data = {
                    userId:this.$store.state.userId.replace(/\"/g, ""),
                    avatar:this.$store.state.avatar
                }
                userModifyUser(data).then(res=>{
                    if(res.data.code == 200){
                        Toast('修改成功')
                        this.$router.push({
                            name: `User`,
                        })
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            completeSetUserName(){
                let data = {
                    userId:this.$store.state.userId.replace(/\"/g, ""),
                    userName:this.setnameValue
                }
                userModifyUser(data).then(res=>{
                    if(res.data.code == 200){
                        Toast('修改成功')
                        this.$router.push({
                            name: `User`,
                        })
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            setNewPassword(){
                if(this.newpasswordValue !== this.againnewpasswordValue){
                    Toast('输入的两次密码不一致！')
                }else{
                    let data = {
                        loginName:this.$store.state.password.replace(/\"/g, ""),
                        oldPassword:this.oldpasswordValue,
                        newPassword:this.newpasswordValue
                    }
                    userModifyPassword(data).then(res=>{
                        if(res.data.code == 200){
                            Toast('修改成功')
                            this.$router.push({
                                name: `User`,
                            })
                        }else{
                            Toast(res.data.msg)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }
                
            },
            passwordformatter(val){
                let reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Z][A-z0-9]{5,11}$/
                 if (!val) return '';
                if (!reg.test(val)) {
                    this.passwordErrorMsg = '密码6-12位且必须首字母大写加小写字母和数字组合';
                } else {
                    this.passwordErrorMsg = '';
                }
                return val;
            },
            passwordAgainformatter(val){
                if (!val) return '';
                if(this.newpasswordValue != val){
                    this.passwordAgainErrorMsg = '两次密码不一致';
                }else{
                    this.passwordAgainErrorMsg = ''
                }
                return val
            },
            
        },
    }
</script>

<style lang='scss' scoped>
    
    ul,li{
        list-style: none;
    }
    ul{
        margin: 0;
        padding: 0;
    }
    .Myname{
        color: rgb(163, 160, 160);
    }
    .infoSetList ul{
        display: flex;
        flex-direction: column;
    }   
    .infoSetList ul li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10Px;
        padding: 15px 0;
        border-bottom: 0.026667rem solid #eee;
    }
    .infoSetList ul li>img{
        border-radius: 50%;
        width: 60px;
        height: 60px;
        vertical-align: middle;
    }
    .infoSetList ul li span{
        font-size: 15px;
    }
    .infoSetOut{
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        bottom: 100px;
    }
    .infoSetOut .btn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 8px;
        width: 300px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .setnamemains>div{
        margin-top:20px;
    }
    .setnamemains .btn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 20px;
        width: 70%;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .setpasswordmains .btn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 20px;
        width: 70%;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .act{
        color: #fff;
        background-color: rgba(0,0,0,.5);
    }
    .setavatarmains p{
        position: absolute;
        // top: 0;
        // left: 0;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        line-height: 80px;

        font-size: 14px;
    }
    /deep/.van-nav-bar .van-icon{
        font-size: 22px;
        color: #fff;
    }
    /deep/.van-icon-arrow{
        color: rgb(177, 176, 176);
    }
    /deep/.van-popup--right{
        max-width: 600px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
    }
    /deep/.van-overlay{
        max-width: 600px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
    }
    /deep/.van-cell{
        border-width: 0;
        margin: 20px auto;
        width: 80%;
        border-radius: 8px;
        margin-bottom: 20Px;
        padding: 0 20px;
        // height: 50px;
        font-size: 15px;
        background-color: #f5f5f5;
    }
    /deep/.van-field__body{
        line-height: 50px;
    }
    ::v-deepinput::-webkit-input-placeholder{
        color:#999393;
    }
    /deep/.van-nav-bar__right{
        color: #fff;
        font-size:16px ;
    }
    /deep/.van-icon-success{
        font-size: 25px;
    }
</style>