<template>
    <div>
        <van-nav-bar :title='title' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:50px;'></div>   <!-- 增加顶部高度，部面盖住内容 -->
        <div>
            <table>
                <tbody width="100%">
                    <div v-for="(item,i) in dataList" :key="i+20">
                        <tr>
                            <td class="c01" >{{item.title}}</td>
                            <td class="c02">{{item.predict}}</td>
                            <td class="c03" >{{item.result}}</td>   
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
        <div style='width:100%;height:10px;'></div>   <!-- 增加脚部高度，部面盖住内容 -->
    </div>
</template>

<script>
import {predictPResult} from '../api/http'
import { NavBar } from 'vant';
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            if(this.code == '71'){
                this.title = '澳门平肖'
            }else if(this.code == '28'){
                this.title = '香港平肖'
            }else{
                this.title = '老澳门平肖'
            }
            this.getList()
        },
        components:{
            'van-nav-bar':NavBar,
        },
        data() {
            return {
                  title:'',
                  dataList:[]
            }
        },
        methods: {
            onClickLeft(){
                this.$router.push('/')
            },
            getList(){
                let data = {
                    code:this.code
                }
                predictPResult(data).then(res=>{
                    if(res.data.code == 200){
                        this.dataList = res.data.data
                        console.log(this.dataList)
                    }
                })
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-icon{
        color: white;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff !important;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    table {
        width: 100%;
        border: none;
    }
    table td.c01,table td.c03 {
        text-align: center;
        background-color: yellow;
    }
    table td.c03{
        width: 15%;
    }
    table td.c01{
        width: 35%;
    }
    table td.c02 {
        text-indent: 10px;
        background-color: #fff;
        padding: 0 8px;
        width: 300px;
        text-align: center;
    }
    table tr{
        width: 100%;
    }
    table td {
        height: 100%;       
        padding: 8px 0;
        word-break: break-all;
        font-size: 14px;
        border: 1px solid rgb(229, 229, 229);
    }
</style>