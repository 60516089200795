<template>
    <div>
        <van-nav-bar title="详情" fixed left-arrow @click-left="onClickLeft" />
        <div style='width:100%;height:50px;'></div>  <!--增加顶部脚部高度，部面盖住内容-->
        <van-swipe class="my-swipe" :autoplay="3000" >
           <van-swipe-item v-for="(image,i) in carouselImg" :key="i+10">
                <img :src="image.url" @click="gotoLink(image.jumpUrl)"/>
           </van-swipe-item>
        </van-swipe>
        <div class="homeLotery">
            <div class='loteryTittle'>第{{theNewestDate}}期最新开奖结果 <span class="toRecord" @click="goToRoute()" >开奖记录></span></div>
            <div class='loteryNums' v-for="(item,i) in newLoteryList" :key="i+10" >
                <img :src="require(`../assets/${item[0]}.png`)" >
                <div class="loteryScribe">
                <span>{{item[1]}}/{{item[2]}}</span>
                </div>
            </div>  
            <h2 class="jiahao">+</h2>
            <div class="drawInfo"><span>第{{theNextDate}}期 {{nextPrizeTime}} {{weekTxt}}</span></div>
        </div>
        <div class='detailPost'>
            <div class='detailUser'>
                <div>
                    <div class="postHeader">
                        <img src="../assets/ziliaodaquan.jpg" >
                        <p>
                            <span>澳门资料员</span>
                            <b>{{createTime}}</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class='detailText'>
                <h3 class='detailTextTitle'>{{articleTitle}}</h3>
                <div style="margin-top: 15px;">
                    <div class="detailTxtContent">
                        <!-- v-html 解析\r\n-->
                        <p style="white-space: pre-wrap;" v-html="articleContent"></p>
                    </div>
                </div>
            </div>
            <!-- <div class='detailImgs'>
                <div>
                    <img src="" alt="">
                </div>
            </div> -->
            <div class="galerryOther">
            <ul>
                <li>
                    <van-icon name="chat-o" />
                    <span>{{totleComments}}</span>
                    <b>评论</b>
                </li>
                 <li @click="countLikeClick">
                    <img style="width:14px" :src="this.totleCountItem.like?require('../assets/like2.png'):require('../assets/like1.png')">
                    <span>{{totleLikes}}</span>
                    <b>点赞</b>
                </li>
                <li @click="countCollectClick">
                    <img style="width:14px" :src="this.totleCountItem.collect?require('../assets/collect.png'):require('../assets/nocollect.png')">
                    <span>{{totleCollects}}</span>
                    <b>收藏</b>
                </li>
                <li>
                    <van-icon name="share-o" />
                </li>
                <li>
                    <van-icon name="eye-o" />
                    <span>{{totleSees}}</span>
                </li>
            </ul>
        </div>
            <div class='detailDown'>
                <img src="" alt="">
            </div>
        </div>
        <h3 v-if="noHotComment" class="commentListTit">热门评论</h3>
        <!-- <van-empty v-if="noHotComment" :image="require('../assets/noComments.png')" description="暂无评论" /> -->
        <div class="commentBox">
            <van-cell class="van-clearfix" v-for="(k,b) in commentHotList" :key="b + 20"  >
                <div class="comment">
                    <div>
                        <div class="commentLeft">
                            <img :src="k.avatar" alt="">
                        </div>
                        <div class="commentRight">
                            <p>
                                <span>{{k.userName}}</span>
                                <b @click="clicklike(k,k.id,b)">
                                    <img :src="k.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                    <i>{{k.commentLikes}}</i>
                                </b>
                            </p>
                            <div @click="pushRelations(k,k.id,k.userId)" class="commentHidden" style="margin-top:5px">
                                <p v-html="k.commentContent"></p>
                                <span class="commentTime">{{k.spacing}}</span>
                            </div>
                            <div v-show="k.index==b" v-for="(a,s) in k.commentMoreList" :key="s+20">
                                    <div  class="commentDetail" v-show="s<k.showNum" >
                                        <div class="commentLeft">
                                            <img :src="a.avatar" alt="">
                                        </div>
                                        <div class=CommentRight>
                                            <p>
                                                <span>{{a.userName}}</span>
                                                <b @click="clicklike(a,a.id,b)">
                                                    <img :src="a.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                                    <i>{{a.commentLikes}}</i>
                                                </b>
                                            </p>
                                            <div @click="pushRelations(a,k.id,a.userId)" class="commentHidden">
                                                <b>
                                                    回复:&nbsp;
                                                    <span>{{a.replyName}}</span>
                                                </b>
                                                <p v-html="a.commentContent"></p>
                                                <span class="commentTime">{{a.spacing}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div  v-if="k.reply > 0" class="commentMore">
                                <span @click="showMoreReply(k,k.id,b)" v-if="!k.dropDown">——显示评论</span>
                                <span @click="noShowMoreReply(k,k.id,b)" v-if="k.dropDown">——收起</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-cell>
        </div>
        <h3 class="commentListTit">最新评论</h3>
        <div class="commentBox">
            <van-list
                v-model:loading="loading"
                :finished="finished"
                :finished-text="finishedText"
                @load="onLoad"
                :immediate-check='false'
                >
                <van-cell class="van-clearfix"  >
                    <div class="comment" > 
                        <div  v-for="(item,index) in commentList" :key="index + 20">
                            <div class="commentLeft">
                                <img :src="item.avatar" alt="">
                            </div>
                            <div class="commentRight">
                                <p>
                                    <span>{{item.userName}}</span>
                                    <b @click="clicklike(item,item.id,index)">
                                        <img :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                        <i>{{item.commentLikes}}</i>
                                    </b>
                                </p>
                                <div @click="pushRelations(item,item.id,item.userId,index)" class="commentHidden" style="margin-top:5px">
                                    <p v-html="item.commentContent"></p>
                                    <span class="commentTime">{{item.spacing}}</span>
                                </div>
                                <div v-show="item.index==index" v-for="(o,p) in item.commentMoreList" :key="p+20">
                                    <div class="commentDetail" v-show="p<item.showNum" >
                                        <div class="commentLeft">
                                            <img :src="o.avatar" alt="">
                                        </div>
                                        <div class=CommentRight>
                                            <p>
                                                <span>{{o.userName}}</span>
                                                <b @click="clicklike(o,o.id,index)">
                                                    <img :src="o.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                                    <i>{{o.commentLikes}}</i>
                                                </b>
                                            </p>
                                            <div @click="pushRelations(o,item.id,o.userId,p)" class="commentHidden">
                                                <b>
                                                    回复:&nbsp;
                                                    <span>{{o.replyName}}</span>
                                                </b>
                                                <p v-html="o.commentContent"></p>
                                                <span class="commentTime">{{o.spacing}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div  v-if="item.reply > 0" class="commentMore">
                                    <span ref="btn" @click="showMoreReply(item,item.id,index)" v-if="!item.dropDown">——显示评论</span>
                                    <span @click="noShowMoreReply(item,item.id,index)" v-if="item.dropDown">——收起</span>
                                </div>
                            </div> 
                        </div>
                    </div>
                </van-cell>
            </van-list>
            <van-empty v-if="noComment" :image="require('../assets/noComments.png')" description="暂无评论" />
            <div class="inp" @click="gotoSetRelations">
                <van-field class="realtaionCell" readonly placeholder="想说点什么"  />
                <span >评论</span>
            </div>
        </div>
        <van-popup v-model:show="putRelations" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='发表评论' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="relationBox">
                <div style="text-align:left;position:relative;left:5%;margin:10px 0;">评论内容</div>
                <van-cell-group inset>
                    <van-field
                        v-model="relationsMessage"
                        rows="5"
                        autosize
                        clearable
                        class="textArea"
                        height='100px'
                        type="textarea"
                        maxlength="120"
                        placeholder="请输入评论内容"
                        show-word-limit
                    />
                </van-cell-group>
                <div @click="completeRelation">
                    <span class="btn">完成</span>
                </div>
            </div>
        </van-popup>
        <div style='width:100%;height:50px;'></div>  <!--增加脚部高度，避免盖住内容-->
        <van-overlay :show="loginsShow" @click="loginsShow = false">
            <div class="loginWrap" @click.stop>
                <div class="top">
                    <img src="../assets/loginTop.png" alt="">
                    <div class="logo">
                        <img src="../assets/userAvatar.png" alt="">
                    </div>
                </div>
                <div class="common">
                    <van-field
                        v-model="username"
                        required
                        class="loginInput"
                        clearable
                        placeholder="请输入用户名"
                    />
                    <van-field
                        v-model="password"
                        required
                        class="loginInput"
                        :type="passwordType"
                        placeholder="请输入密码"
                    >
                        <template #right-icon>
                            <span class="solts" @click="switchPasswordType">
                                <van-icon class="eyes" name="eye" v-if="passwordType==='password'"/>
                                <van-icon class="eyes" name="closed-eye" v-else />
                            </span>
                        </template>
                    </van-field>
                    <div class="verifyFiled">
                        <van-field
                                v-model="verifyCode"
                                required
                                clearable
                                class="verifyInput loginInput"
                                placeholder="请输入验证码"
                            >
                            <template #right-icon>
                                <div class="verify-box" @click="refreshCode">
                                    <Sidentify :identifyCode="identifyCode"></Sidentify>
                                </div>
                            </template>
                        </van-field>
                    </div>
                    <div @click="handleLogin">
                        <span class="btn">登录</span>
                    </div>
                    <div class="bottomTxt">
                        <div  class="bottomOther"><a><span> <a href="#/forget">忘记密码</a>  </span></a></div>
                        <div @click="gotoRegister" class="bottomOther"><a><span>立即注册</span></a></div>
                    </div>
                </div>
                <div class="close" @click="loginsShow = false">
                    <img src="../assets/closeLogin.png" alt="">
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import {articleDetails,imgCarousel,openLatest,userCount,userAddRelation,userLogin,commentAdd,commentReplyList,commentList} from '../api/http'
import { NavBar,Icon,Swipe,SwipeItem,Toast,Overlay, Cell, List, Empty, Field, Popup, CellGroup  } from 'vant';
import Sidentify from '../components/identify.vue' 
    export default {
        created(){
            if(this.$route.params.type){
                this.infoType = this.$route.params.type
            }
            if(this.$route.params.title){
                this.infoTitle = this.$route.params.title
            }
            if(this.$route.params.id){
                this.infoId = this.$route.params.id
                this.goMyLike = false
                this.goMyComment = false
            }else if(this.$route.params.myLikeId){
                this.goMyLike = true
                this.goMyComment = false
            }else if(this.$route.params.myCommentId){
                this.goMyComment = true
                this.goMyLike = false
            }
            if(this.$route.params.articleId){
                this.infoId = this.$route.params.articleId
            }
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.getArticleDetails()
            this.getNewestLotery()
            this.getCarousel()
            this.getTotleInfo()
            this.refreshCode()
            this.getCommentList()
            Sidentify
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-swipe':Swipe,
            'van-swipe-item':SwipeItem,
            'van-overlay':Overlay,
            'van-icon':Icon,
            'van-cell':Cell,
            'van-list':List,
            'van-empty':Empty,
            'van-field':Field,
            'van-popup':Popup,
            'van-cell-group':CellGroup,
            Sidentify
        },
        data() {
            return {
                  infoType: '',
                  infoTitle:'',
                  infoId:'',
                  carouselImg:[],
                  theNewestDate:'',
                  theNextDate:'',
                  nextPrizeTime:'',
                  weekTxt:'',
                  newLoteryList:[],
                  code:'',
                  prizeTime:'',
                  weekText:'',
                  createTime:'',
                  articleTitle:'',
                  articleContent:'',
                  totleCollects:'',
                  totleComments:'',
                  totleLikes:'',
                  totleSees:'',
                  totleCountItem:[],
                  noHotComment:false,
                  commentHotList:[],
                  commentList:[],
                  commentMoreList:[],
                  noComment:false,
                  putRelations:false,
                  relationsMessage:'',
                  parentrelationsMessage:'',
                  loginsShow:false,
                  username:'',
                  password:'',
                  passwordErrorMsg:'',
                  verifyCodeErrorMsg:'',
                  usernameErrorMsg:'',
                  passwordType: 'password',//输入框类型,
                  identifyCode:'',
                  verifyCode:'',
                  page:1,
                  loading:false,
                  finished:true,
                  finishedText:'没有更多了',
                  parentId:'',
                  replyId:'',
                  gallerryId:'',
                  goMyLike:false,
                  goMyComment:false,
            }
        },
        methods: {
            onClickLeft() {
                if(this.goMyLike){
                     this.$router.push('./MyLike')
                }else if(this.goMyComment){
                    this.$router.push('./MyComments')
                }else{
                    this.$router.push({                                                     
                        name: 'InfoDetails',             // 路由的名字，地址会隐藏                            
                        params: {    
                            type:localStorage.getItem('infoType'),
                            title:localStorage.getItem('infoTitle')                                       
                        }
                    })
                    localStorage.removeItem('infoId')
                }
            },
            getArticleDetails(){
                let data = {
                    id:localStorage.getItem('infoId')
                }
                articleDetails(data).then(res=>{
                    if(res.data.code == 200){
                        this.createTime = res.data.data.createTime
                        // 改变v-html中img大小
                        this.articleContent = res.data.data.articleContent.replace(/<img/g,"<img style='max-width:100%;height:auto;'")
                        this.articleTitle = res.data.data.articleTitle
                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            getCarousel(){
                let data = {
                    type:1
                }
                imgCarousel(data).then(res=>{
                    if(res.data.code == 200){
                    // console.log(res.data.data)
                    
                    this.carouselImg = res.data.data
                    }
                }).catch(err =>{
                    console.log(err)
                })
            },
            getNewestLotery(){
                let data = {
                    code : this.code
                }
                openLatest(data).then(res =>{
                    if(res.data.code == 200){
                        this.newLoteryList = res.data.data.prize
                        // console.log(this.newLoteryList)
                        // 截取字段最后三位字符串
                        this.theNewestDate = res.data.data.issueNo.substring(res.data.data.issueNo.length-3)
                        this.theNextDate = res.data.data.nextIssueNo.substring(res.data.data.issueNo.length-3)
                        this.nextPrizeTime = res.data.data.nextPrizeTime
                        this.weekTxt = res.data.data.dayOfTheWeek
                        this.prizeTime = res.data.data.prizeTimeForHome
                        
                        // console.log(res)
                        // 计算星期几
                        var dateArray = this.prizeTime.substring(0,10).split("-"), date;
                        date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
                        let week = date.getDay()
                        switch (week) {
                            case 0:
                                this.weekText = '星期日'
                            break;
                            case 1:
                                this.weekText = '星期一'
                            break;
                            case 2:
                                this.weekText = '星期二'
                            break;
                            case 3:
                                this.weekText = '星期三'
                            break;
                            case 4:
                                this.weekText = '星期四'
                            break;
                            case 5:
                                this.weekText = '星期五'
                            break;
                            case 6:
                                this.weekText = '星期六'
                            break;
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            },
            goToRoute(){
                this.$router.push({
                    name: `Record`,
                    params:{
                        articleId:this.infoId
                    }
                })
            },
            getTotleInfo(){
                let data = {
                    moduleType:1,
                    otherId:localStorage.getItem('infoId')
                }
                userCount(data).then(res=>{
                    if(res.data.code == 200){
                        this.totleCollects = res.data.data.collects
                        this.totleComments = res.data.data.comments
                        this.totleLikes = res.data.data.likes 
                        this.totleSees = res.data.data.sees
                        this.totleCountItem = res.data.data

                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            countCollectClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.collect) {
                        Toast("已经收藏过了哦~")
                    } else {
                        let data = {
                            type:1,
                            moduleType:1,
                            otherId:localStorage.getItem('infoId')
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.collect = true
                                this.totleCollects+=1
                                Toast("已收藏")
                            }
                        })
                    }
                }
            },
            countLikeClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:1,
                            otherId:localStorage.getItem('infoId')
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.like = true
                                this.totleLikes+=1
                                Toast("已点赞")
                            }
                        })
                    }
                }
            },
                        clicklike(item,id,index) {
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    console.log(item)
                    if (item.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:1,
                            otherId:id
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                if(item.commentParentId == 0){
                                    item.like = true
                                    item.commentLikes+=1
                                    // this.getCommentList()
                                }else{
                                    let data = {
                                        id:id
                                    }
                                    commentReplyList(data,this.page).then(res=>{
                                        item.commentMoreList = res.data.data
                                        item.dropDown = true;
                                        item.index = index
                                        item.showNum = item.commentMoreList.length;
                                        item.like = true
                                        item.commentLikes+=1
                                    }).catch(err=>{
                                        console.log(res)
                                    })
                                }
                                Toast("已点赞")
                            }
                        })
                    }
                }
                
            },
            pushRelations(item,parentId,replyId,index){
                console.log(item)
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    this.putRelations = true
                    this.parentId = parentId
                    this.replyId = replyId
                }
            },
            showMoreReply(item,id,index){
                localStorage.setItem("showMoreId",id)
                localStorage.setItem("showMoreIndex",index)
                let data = {
                    id:id
                }
                commentReplyList(data,this.commentMorePage).then(res=>{
                    item.commentMoreList = res.data.data
                    localStorage.setItem("showMoreItemMoreList",JSON.stringify(item.commentMoreList))
                    localStorage.setItem("showMoreItem",JSON.stringify(item))
                    item.dropDown = true;
                    item.index = index
                    item.showNum = item.commentMoreList.length;
                    // console.log(this.commentHotList)
                }).catch(err=>{
                    console.log(err)
                })
            },
            noShowMoreReply(item,id,index){
                     let data = {
                        id:id
                    }
                commentReplyList(data,this.commentMorePage).then(res=>{
                    item.dropDown = false;
                    item.showNum = 0;
                }).catch(err=>{
                    console.log(err)
                })
            },
            
            completeRelation(){
                if(this.parent != ''){
                    let data = {
                        moduleType:1,
                        needCommentId:localStorage.getItem('infoId'),
                        content:this.relationsMessage.split("\n").join("<br />"),
                        parentId:this.parentId,
                        replyUserId:this.replyId
                    }
                    commentAdd(data).then(res=>{
                        if(this.relationsMessage == ''){
                            Toast('请输入内容！')
                        }else{
                            if(res.data.code == 200){
                                // var btn = document.getElementById("btn");
                                setTimeout(()=>{
                                    this.getCommentList()
                                },500)
                                this.getCommentList()
                                this.getTotleInfo()
                                this.putRelations = false
                                Toast(res.data.msg)
                                this.relationsMessage = ''
                                this.parentId = ''
                            }else{
                                Toast(res.data.msg)
                                this.putRelations = false
                                this.relationsMessage = ''
                                this.parentId = ''
                            }
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }else{
                    let data = {
                        moduleType:0,
                        needCommentId:localStorage.getItem('infoId'),
                        content:this.relationsMessage,
                    }
                    commentAdd(data).then(res=>{
                        if(this.relationsMessage == ''){
                            Toast('请输入内容！')
                        }else{
                            if(res.data.code == 200){
                                this.getCommentList()
                                this.putRelations = false
                                Toast(res.data.msg)
                                this.relationsMessage = ''
                            }else{
                                Toast(res.data.msg)
                            }
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }
                
            },
            switchPasswordType() {
                this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
            },
            refreshCode(){
                this.makeCode()
                // console.log('当前验证码',this.identifyCode)
            },
            makeCode(o,l){
                this.identifyCode = String(this.randomNum(2345,9999))
            },
            randomNum(min,max){
                return Math.floor(Math.random() * (max - min) + min)
            },
            handleLogin(){
                console.log(this.verifyCode)
                if(this.username == ''){
                    this.usernameErrorMsg = '用户名不能为空'
                }else if(this.username != ''){
                    this.usernameErrorMsg = ''
                }
                if(this.verifyCode == ''){
                    this.verifyCodeErrorMsg = '请输入验证码'
                }else if(this.verifyCode != ''){
                    this.verifyCodeErrorMsg = ''
                }
                if(this.password == ''){
                    this.passwordErrorMsg = '请输入密码'
                }else if(this.password != ''){
                    this.passwordErrorMsg = ''
                }
                if(this.verifyCode != this.identifyCode){
                    Toast('验证码不正确')
                    this.refreshCode()
                }else{
                    let data = {
                        loginName:this.username,
                        password:this.password
                    }
                    userLogin(data).then(res=>{
                        if(res.data.code == 200){
                            this.$store.commit('$_setStorage',res.data.data.token)
                            this.$store.commit('setUsername',res.data.data.userName)
                            this.$store.commit('setUserid',res.data.data.userId)
                            this.$store.commit('setAvatar',res.data.data.avatar)
                            this.$store.commit('setPassword',this.password)
                            this.$store.commit('setLogname',res.data.data.loginName)
                            this.$store.commit('setLoginStatus',false)
                            Toast('登录成功')
                            this.$router.push(this.$route.path) //跳转到当前页面
                            this.loginsShow=false
                        }else{
                            this.refreshCode()
                            Toast(res.data.msg)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })

                }
            },
            gotoRegister(){
                this.$router.push({
                    name: `Register`,
                })
            },
            onLoad(){
                this.page += 1;//页数+1
                this.getCommentList();
            },
            getCommentList(){
                this.loading = true;
                let data = {
                    moduleType:1,
                    needCommentId:localStorage.getItem('infoId'),
                }
                commentList(data,this.page).then(res=>{
                    if(res.data.code == 200 && res.data.data.common.total !=0){
                        this.commentListTotal = res.data.data.common.total
                        console.log(res.data.data.hot)
                        if(res.data.data.common.pageNum == 1){
                            this.commentList = res.data.data.common.list
                        }else{
                            this.commentList = this.commentList.concat(res.data.data.common.list)

                        }
                        if (res.data.data.common.nextPage == 0) {  //数据全部加载完成
                            this.finished = true;
                            this.finishedText = '没有更多了'
                        }else{
                            this.finished = false;
                        }       
                        this.loading = false;
                        this.noComment = false;
                    }
                    if(res.data.data.hot == undefined || res.data.data.hot.length == 0){
                        this.noHotComment = false
                        this.commentHotList = []
                    }else{
                        this.commentHotList = []
                        this.commentHotList = res.data.data.hot
                        this.noHotComment = true;
                    }
                    if(res.data.data.common.total == 0){
                        this.finished = true;
                        this.noComment = true;
                        this.finishedText = ''
                        this.commentList = []
                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            gotoSetRelations(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    this.putRelations = true
                }
            },
            setClickLeft(){
                this.putRelations = false
            },
            countCollectClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.collect) {
                        Toast("已经收藏过了哦~")
                    } else {
                        let data = {
                            type:1,
                            moduleType:1,
                            otherId:localStorage.getItem('infoId')
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.collect = true
                                this.totleCollects+=1
                                Toast("已收藏")
                            }
                        })
                    }
                }
            },
            countLikeClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:1,
                            otherId:localStorage.getItem('infoId')
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.like = true
                                this.totleLikes+=1
                                Toast("已点赞")
                            }
                        })
                    }
                }
            },
            gotoLink(url){
                window.location.href = url
            },
        },
    }
</script>

<style lang='scss' scoped>
    //PC端
    @media screen and (min-width:700px) {
        .homeLotery{
            position: relative;
            background: url(../assets/kuang.png) no-repeat;
            background-size: 100% 100%;
            height: 160px;
            margin:10px 10px;
            justify-content: center;
            display: flex;
        }
        .loteryTittle{
            display: flex;
            padding-top: 20px;
            position: absolute;
            font-size: 15px;
        }
        .toRecord{
            padding-left: 10px;
            color:rgb(255, 82, 2);
        }
        .loteryNums{
            position: relative;
            top:60px;
            width: 10%;
            margin:5px 5px;
        }
        .loteryNums img{
            vertical-align: middle;
            width: 80%;
            position: relative;
            bottom: 5px;
        }
        .loteryNums:nth-child(8){
            padding-left: 45px;
        }
        .jiahao{
            position: absolute;
            right: 110px;
            top:63px;
            font-size: 20px;
        }
        .loteryScribe{
            text-align: center;
            padding-top: 2px;
            display: block;
            font-size: 13px;
        }
        .drawInfo{
            color: #999;
            position: absolute;
            bottom: 5px;
            font-size: 13px;
        }
    }
    //移动端
    @media screen and (max-width:750px) {
        .homeLotery{
            position: relative;
            background: url(../assets/kuang.png) no-repeat;
            background-size: 100% 100%;
            height: 160px;
            margin:10px 10px;
            justify-content: center;
            display: flex;
        }
        .loteryTittle{
            display: flex;
            padding-top: 20px;
            position: absolute;
            font-size: 15px;
        }
        .toRecord{
            padding-left: 10px;
            color:rgb(255, 82, 2);
        }
        .loteryNums{
            /* display: flex; */
            /* justify-content: space-around;
            align-items: center;
            padding:8px 8px; */
            position: relative;
            top:60px;
            width: 10%;
            margin:0 5px;
        }
        .loteryNums img{
            vertical-align: middle;
            width: 100%;
            position: relative;
            bottom: 5px;
        }
        .loteryNums:nth-child(8){
            padding-left: 45px;
        }
        .jiahao{
            position: absolute;
            right: 60px;
            top:53px;
            font-size: 20px;
        }
        .loteryScribe{
            text-align: center;
            padding-top: 7px;
            display: block;
            font-size: 13px;
        }
        .drawInfo{
            color: #999;
            position: absolute;
            bottom: 10px;
            font-size: 13px;
        }
    }
// 我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线
    .detailPost{
        margin: 15Px 15Px 0;
    }
    .detailUser>div{
        display: flex;
        align-items: center;
    }
    .detailUser>div>.postHeader{
        display: flex;
        align-items: center;
    }
    .detailUser>div>.postHeader img{
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        width: 30px;
        height: 30px;
        vertical-align: middle;
    }
    .detailUser>div>.postHeader p{
        display: flex;
        flex-direction: column;
        margin-left: 20px;
    }
    .detailUser>div>.postHeader p span{
        font-size: 15px;
        text-align: left;
    }
    .detailUser>div>.postHeader p b{
        color: #999;
        font-size: 13px;
    }
    .detailText{
        margin-top:-10px;
    }
    .detailTextTitle{
        border-bottom: 1px solid #eee;
        word-break: break-all;
        font-size: 18px;
        text-align: left;
    }
    .detailTxtContent{
        font-size: 15px;
        font-weight: 400;
        text-align: left;

    }

    .detailImgs{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        margin-top: 15Px;
    }
    .detailImgs>div{
        width: 60px;
        height: 60px;
    }
    .detailImgs>div img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: #f5f5f5;
        vertical-align: middle;
    }
    .detailDown{
        display: inline-block;
        margin: 15Px 0;
    }
    .detailDown img{
        width: 100%;
        vertical-align: middle;
    }
    .my-swipe .van-swipe-item img{
        width: 97%;
    }
    .detailUser{
        margin-bottom: 20px;
    }
    .galerryOther{
        margin: 10px;
        border-bottom: 1px solid #eee;
    }
    .galerryOther ul{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        height: 0.933333rem;
    }
    .galerryOther ul li{
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
    .galerryOther ul li span{
        margin-left: 5px;
        font-weight: 400;
        font-size: 13px;
    }
    .galerryOther ul li b{
        margin-left: 5px;
        font-weight: 400;
        font-size: 13px;
    }
    .commentListTit{
        text-align: left;
        padding: 10px 15Px;
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        background-color: #f5f5f5;
    }
    .comment>div{
        display: flex;
        border-bottom: 2px solid #eee;
        padding: 15Px;
    }
    .commentLeft{
        width: 40px;
        height: 40px;
        margin: -5px 5px;
        
    }
    .commentLeft img{
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        // width: 100%;
        height: 100%;
        vertical-align: middle;
    }
    .commentRight{
        flex: 1;
        padding-left: 10Px;
    }
    .commentRight p {
        display: flex;
        justify-content: space-between;
        overflow-wrap: anywhere;
    }
    .commentRight p span{
        color: #ff5000;
        font-size: 16px;
    }
    .commentRight p b{
        align-items: center;
        color: #999;
        font-size: 14px;
        position: absolute;
        right:0;
    }
    .commentRight p b img{
        width: 13px;
        height: 13px;
    }
    .commentRight p b i{
        margin-left: 10px;
        font-style: normal;
    }
    .commentHidden{
        word-break: break-all;
        font-size: 14px;
    }
    .commentHidden span{
        color: #ec799f;
        font-size: 14px;
    }
    .commentHidden .commentTime{
        display: inline-block;
        color: #999;
        font-size: 12px;
    }
    .commentMore{
        display: flex;
        align-items: center;
        padding-top: 10px;
    }
    .commentMore span{
        color:#999;
    }
    .inp{
        position: fixed;
        bottom: 0;
        padding: 0 20px;
        border-top: 1px solid #dcdcdc;
        width: 94%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        max-width: 600px;
    }
    .inp span{
        border-radius:8px;
        width: 20%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    .textArea{
        height: 160px !important;
        background-color: rgba(214, 214, 214, 0.15) !important;
    }
    .relationBox{
        max-width: 600px;
        margin: 0 auto;
    }
    .relationBox .btn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 25px;
        margin-top:20px;
        width: 300px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .loginWrap{
        position: relative;
        margin: 0 auto;
        border-radius: 0.533333rem 0.533333rem 0.266667rem 0.266667rem;
        width: 80%;
        height: 50%;
        background-color: #fff;
        top: 50%; /*偏移*/
        transform: translateY(-50%);
        max-width: 600px;
    }
    .loginWrap .top{
        height:80px;
    }
    .loginWrap .top>img{
        border-radius: 0.533333rem 0.533333rem 0 0;
        width: 100%;
    }
    .loginWrap .logo{
        position: absolute;
        top: -50px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        width: 100px;
        height: 50px;
    }
    .loginWrap .logo img{
        
        width: 100%;
    }
    .loginWrap .common{
        border-radius: 0.266667rem;
        padding: 20px;
        background-color: #fff;
    }
    .common .btn{
        display: inline-block;
        border: 1px solid #ff5000;
        border-radius: 30px;
        width: 90%;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    
    .loginWrap .close{
        position: absolute;
        top: 10px;
        right: 10px; 
        margin-left: auto; 
        margin-right: auto;
        
    }
    .loginWrap .close img{
        width: 20px;
        height: 20px;
    }
    .loginInput{
        width: 85% !important;
        margin: 25px auto !important;
        border-radius: 8px !important;
        background-color: rgba(194, 194, 194, 0.212) !important;
    }
    .verifyInput{
        height: 38px;
        padding: 0 0 0 15px;

    }
    .verify-box{
        width: 100%;
    }
    .bottomTxt{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin: 20px auto;
        font-size: 15px;
    }
    .bottomOther:last-child a span{
        color: #ff5000;
    }
    .bottomOther a{
        color: rgb(112, 109, 109);
    }
    .fixTop{
        position: sticky;
        top: 0;
        z-index: 1000;
    }
    .commentDetail{
        display: flex;
        margin-top:10px
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-swipe__indicators{
        bottom: 0;
    }
    /deep/.van-swipe__indicator{
        background-color: rgb(61, 2, 255);
    }
</style>