<template>
    <div>
        <van-nav-bar title='高手论坛' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
        <!-- <div style='width:100%;height:50px;'></div>  增加头部脚部高度，部面盖住内容 -->
        <!-- <van-swipe class="my-swipe" :autoplay="3000" >
            <van-swipe-item v-for="(image,i) in carouselImg" :key="i+10">
                    <img :src="image.url" @click="gotoLink(image.jumpUrl)"/>
            </van-swipe-item>
        </van-swipe> -->
        <div class="galleryList">
            <!-- <van-popover class="btnpover" placement="bottom-start" v-model:show="showPopover" :actions="actions" @select="onSelect" >
                <template #reference>
                    <div class="popover">{{btnLabel}}</div>
                </template>
            </van-popover> -->
            <div class="popover" >{{btnLabel}}</div>
            <div class="inp">
                <van-search
                    v-model="fieldValue"
                    show-action
                    :placeholder="placeholderTxt"
                    @search="onSearch"
                    clearable
                    
                    >
                    <template #action>
                        <van-button class="searchBtn" :disabled="isSearchDisabled" @click="onSearch">搜索</van-button>
                        <div class="sxBtn">
                            <van-dropdown-menu >
                                <van-dropdown-item ref="dropdownMenu" title="筛选">
                                    <div v-show="!isMaster" class="popup" v-for="(k,b) in forumType" :key="b+10">
                                        <span :class="this.forumTypeId === k.typeId?'popAct':'' " @click="selectPopQishu(k.typeId,k.typeName,b)">{{k.typeName}}</span>
                                    </div>
                                    <div v-show="isMaster" class="popup" v-for="(k,b) in forumMasterType" :key="b+10">
                                        <span :class="this.forumMasterTypeIndex == b?'popAct':'' " @click="selectMasterPopQishu(k,b)">第{{k}}期</span>
                                    </div>
                                </van-dropdown-item>
                            </van-dropdown-menu>
                        </div>
                    </template>
                </van-search>
            </div>
            
        </div>
        <van-sticky :offset-top="45">
            <van-tabs type="card" v-model:active="tabActive" animated @click-tab="onchangeTab">
                <van-tab>
                     <template #title>
                        <van-badge content="Hot" />
                         高手榜
                      </template>
                </van-tab>
                <van-tab title="综合"></van-tab>
                <van-tab title="精华"></van-tab>
                <van-tab title="最多赞"></van-tab>
                <van-tab title="最新"></van-tab>
                <van-tab title="规则"></van-tab>
            </van-tabs>
        </van-sticky>
        <div class="vantabsContent">
            <div class="vantabPane">
                <!-- 高手榜 -->
                <van-list
                v-if="isHaveMasterData"
                v-model:loading="loading"
                :finished="finished"
                :finished-text="finishedText"
                @load="onLoad"
                :immediate-check='false'
                >
                <div >
                    <div class="comment" > 
                        <div v-show="isMaster" v-for="(item,index) in MasterList" :key="index + 20">
                            <div class="masterLeft">
                                <div class="rank" >
                                    <img v-if="index+1 == 1" src="../assets/master1.png" alt="">
                                    <img v-if="index+1 == 2" src="../assets/master2.png" alt="">
                                    <img v-if="index+1 == 3" src="../assets/master3.png" alt="">
                                    <span class="act">{{index+1}}</span>
                                </div>
                                <div class="logo">
                                    <p>
                                        <img :src="item.avatar" alt="">
                                    </p>
                                </div>
                            </div>
                            <div class="masterRight" @click="goToDetail(item.postId,item.userId)">
                                <a>
                                    <div class="masterTop">
                                        <strong>{{item.userName}}</strong>
                                        <span>{{item.proportion}}</span>
                                    </div>
                                    <div class="masterBottom">
                                        <span>{{item.typeName}}</span>
                                        <span>{{item.jxj}}</span>
                                        <b>近{{item.continuous}}连中</b>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </van-list>
                <!-- 综合和最新 -->
                <van-list
                    v-if="isHaveSynthesisData"
                    v-model:loading="synthesisLoading"
                    :finished="synthesisFinished"
                    :finished-text="synthesisFinishedText"
                    @load="synthesisOnLoad"
                    :immediate-check='false'>
                    <div v-show="isSynthesis" class="Synthesis">
                            <div  v-for="(item,index) in forumSynthesisList" :key="index + 20">
                                <div class="forumUser">
                                    <img :src="item.avatar" alt="">
                                    <p>
                                        <span>{{item.userName}}</span>
                                        <b>{{item.createTime}}</b>
                                    </p>
                                </div>
                                <div class="forumTxt">
                                    <a  @click="goToDetail(item.postId,item.userId)">
                                        <h3 class="forumTxtTit">{{item.postTitle}}</h3>
                                    </a>
                                    <!-- <div style="margin-top:10px"  @click="goToDetail(item.postId,item.userId)">
                                        <a >
                                            <p style="white-space: pre-wrap;" class="formTxtContent overHidden" v-html="item.postContent"></p>
                                        </a>
                                    </div>
                                    <div class="forumImg">
                                        <div v-for="(img,k) in item.imgUrls" :key="k + 20" @click="showImg(img.imgUrl)">
                                            <img :src="img.imgUrl" alt="">
                                        </div>
                                    </div>
                                    <div class="forumOther">
                                        <ul>
                                            <li @click="countLikeClick(item)">
                                                <img style="width:14px" :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')">
                                                <span></span>
                                                <b>{{item.likes}}&nbsp;赞</b>
                                            </li>
                                            <li>
                                                <img src="../assets/eyes.png" alt="">
                                                <span>{{item.sees}}</span>
                                            </li>
                                            <li>
                                                <img src="../assets/comments.png" alt="">
                                                <span></span>
                                                <b>{{item.comments}}&nbsp;评论</b>
                                            </li>
                                        </ul>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                </van-list>
                <!-- 精华 -->
                <van-list
                    v-if="isHaveCreamData"
                    v-model:loading="creamLoading"
                    :finished="creamFinished"
                    :finished-text="creamFinishedText"
                    @load="creamOnLoad"
                    :immediate-check='false'>
                    <div v-show="isCream" class="Synthesis">
                            <div  v-for="(item,index) in forumCreamList" :key="index + 20">
                                <div class="forumUser">
                                    <img :src="item.avatar" alt="">
                                    <p>
                                        <span>{{item.userName}}</span>
                                        <b>{{item.createTime}}</b>
                                    </p>
                                </div>
                                <div class="forumTxt">
                                    <a  @click="goToDetail(item.postId,item.userId)">
                                        <h3 class="forumTxtTit">{{item.postTitle}}</h3>
                                    </a>
                                    <!-- <div style="margin-top:10px"  @click="goToDetail(item.postId,item.userId)">
                                        <a >
                                            <p style="white-space: pre-wrap;" class="formTxtContent overHidden" v-html="item.postContent"></p>
                                        </a>
                                    </div>
                                    <div class="forumImg">
                                        <div v-for="(img,k) in item.imgUrls" :key="k + 20" @click="showImg(img.imgUrl)">
                                            <img :src="img.imgUrl" alt="">
                                        </div>
                                    </div>
                                    <div class="forumOther">
                                        <ul>
                                            <li @click="countLikeClick(item)">
                                                <img style="width:14px" :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')">
                                                <span></span>
                                                <b>{{item.likes}}&nbsp;赞</b>
                                            </li>
                                            <li>
                                                <img src="../assets/eyes.png" alt="">
                                                <span>{{item.sees}}</span>
                                            </li>
                                            <li>
                                                <img src="../assets/comments.png" alt="">
                                                <span></span>
                                                <b>{{item.comments}}&nbsp;评论</b>
                                            </li>
                                        </ul>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                </van-list>
                <!-- 最多赞 -->
                <van-list
                    v-if="isHaveMostLikeData"
                    v-model:loading="mostLikeLoading"
                    :finished="mostLikeFinished"
                    :finished-text="mostLikeFinishedText"
                    @load="mostLikeOnLoad"
                    :immediate-check='false'>
                    <div v-show="isMostLike" class="Synthesis">
                            <div  v-for="(item,index) in forumMostLikeList" :key="index + 20">
                                <div class="forumUser">
                                    <img :src="item.avatar" alt="">
                                    <p>
                                        <span>{{item.userName}}</span>
                                        <b>{{item.createTime}}</b>
                                    </p>
                                </div>
                                <div class="forumTxt" >
                                    <a @click="goToDetail(item.postId,item.userId)">
                                        <h3 class="forumTxtTit">{{item.postTitle}}</h3>
                                    </a>
                                    <!-- <div style="margin-top:10px" @click="goToDetail(item.postId,item.userId)">
                                        <a>
                                            <p style="white-space: pre-wrap;" class="formTxtContent overHidden" v-html="item.postContent"></p>
                                        </a>
                                    </div>
                                    <div class="forumImg" >
                                        <div v-for="(img,k) in item.imgUrls" :key="k + 20" @click="showImg(img.imgUrl)">
                                            <img :src="img.imgUrl" alt="">
                                        </div>
                                    </div>
                                    <div class="forumOther">
                                        <ul>
                                            <li @click="countLikeClick(item)">
                                                <img style="width:14px" :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')">
                                                <span></span>
                                                <b>{{item.likes}}&nbsp;赞</b>
                                            </li>
                                            <li>
                                                <img src="../assets/eyes.png" alt="">
                                                <span>{{item.sees}}</span>
                                            </li>
                                            <li>
                                                <img src="../assets/comments.png" alt="">
                                                <span></span>
                                                <b>{{item.comments}}&nbsp;评论</b>
                                            </li>
                                        </ul>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                </van-list>
                <!-- 规则 -->
                <div v-if="ruler" class="other">
                    <p>
                        1. 禁止发布包含任何网址，任何联系方式，二维码,网站名称！资料请先马赛克处理后在进行分享发布！多次不处理将永久禁言！
                    </p>
                    <p>
                        2. 用户昵称禁止使用敏感词汇以及国家领导人名字，用户头像禁止使用二维码，色情图片,国家领导人,如有发现永久禁言！
                    </p>
                    <p>
                        3. 为不影响用户正常查看心水贴,禁止发表消极内容，重复，空贴，辱骂，跟六合无关，马后炮等多次不听劝阻者永久禁言！
                    </p>
                    <p>
                        4. 禁止索要联系方式,网投网址,外网网址,其他平台名称,一经发现即刻禁言！
                    </p>
                    <p>
                        5. 禁止对用户发表的心水贴冷嘲热讽,辱骂攻击，恶语中伤！违者禁言处理！
                    </p>
                    <p>
                        6. 高手们研究发帖不易，请大家理性参考，心存感恩查阅资料且文明交流对错不怪！因为没人能保证谁的一定准。
                    </p>
                    <p>
                        7. 禁止高手主动要求粉丝给自己打赏，发现当卖料处理。轻者删贴，重者封号！
                    </p>
                    <p>
                        8. 每人每期可以发布三个主题贴，达到上限后可以自行修改已发布的帖子，发帖账号必须是中文昵称三个以上汉字，不能带数字和字母，请联系在线客服修改昵称，望大家悉知！
                    </p>
                </div>
            </div>
            <div class="goPublish" @click="goPublish">
                <img src="../assets/publish.png" alt="">
            </div>
        </div>
        <van-popup v-model:show="putRelations" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='发布(论坛帖)' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="publishMains">
                <ul class="publishLoading">
                    <li @click="getLastPost('内容')"><span>加载上期内容</span></li>
                    <li @click="getLastPost('标题')"><span>加载上期标题</span></li>
                    <li @click="getLastPost('正文')"><span>加载上期正文</span></li>
                </ul>
                <van-cell-group>
                    <div class="cellItem">
                        <van-cell title="标题"/>
                        <van-field clearable v-model="publishTitValue" placeholder="请输入标题" />
                    </div>
                    <div class="cellItem">
                        <van-cell title="选择类型" is-link @click="showTypePicker = true" :value="selecTypeValue"/>
                        <van-popup v-model:show="showTypePicker" position="bottom">
                            <van-picker
                                show-toolbar
                                :columns="TypePickerColumns"
                                :default-index="0"
                                @cancel="showTypePicker = false"
                                @confirm="onConfirm"
                            />
                        </van-popup>
                    </div>
                    <div class="cellItem">
                        <van-cell title="选择彩种" is-link @click="showCodePicker = true" :value="selecCodeValue"/>
                        <van-popup v-model:show="showCodePicker" position="bottom">
                            <van-picker
                                show-toolbar
                                :columns="codePickerColumns"
                                :default-index="0"
                                @cancel="showCodePicker = false"
                                @confirm="onCodeConfirm"
                            />
                        </van-popup>
                    </div>
                    <div class="cellItem">
                        <van-cell title="内容"/>
                        <van-field
                            v-model="publishMessage"
                            rows="5"
                            autosize
                            clearable
                            class="textArea"
                            height='200px'
                            type="textarea"
                            placeholder="请输入内容"
                        />
                        <van-uploader :after-read="goUploader"  @delete="ondeleteImg" result-type="dataUrl" multiple v-model="fileList"  :max-count="countIndex"   :max-size="500 * 1024" @oversize="onOversize">
                            <img src="../assets/upload.png" @click="gocheck" class="uploadImg">
                        </van-uploader>
                    </div>
                    <div @click="completePublish">
                        <span class="publishBtn">发布</span>
                    </div>
                </van-cell-group>
            </div>
        </van-popup>
        <div class="backTop" @click="goTop"  v-show="goTopShow">
            <van-icon class="backTopIcon" name="arrow-up" />
        </div>
    </div>
</template>

<script>
import config from '../config/index' // 路径配置
import { NavBar, Popover, Swipe, SwipeItem, Toast,Field, CellGroup , Search,Sticky, Tabs, Tab, Badge, DropdownMenu, DropdownItem, Button,List, Cell, Icon, Popup, Picker, Uploader,ImagePreview, Loading     } from 'vant';
import {forumList,imgCarousel,forumType,forumMasterList,forumLastForumPost,userAddRelation} from '../api/http' 
import axios from 'axios';
import { nextTick } from '@vue/runtime-core';
    export default {
        mounted() {
             window.addEventListener('scroll', this.handleScroll);
             window.addEventListener('scroll', this.backHandleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        },
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            // this.getFurmList()
            this.getCarousel()
            this.getForumType()
            this.getMasterList()
            
        },
        activated() { //.activated 为keep-alive加载时调用
            if(this.scroll > 0){
                window.scrollTo(0, this.scroll);
                this.scroll = 0;
                window.addEventListener('scroll', this.backHandleScroll);
            }
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
        },
        deactivated(){//页面退出时关闭事件 防止其他页面出现问题
            window.removeEventListener('scroll', this.backHandleScroll);
        },
        components:{
            'van-nav-bar':NavBar,
            'van-swipe':Swipe,
            'van-swipe-item':SwipeItem,
            'van-popover':Popover,
            'van-cell-group':CellGroup,
            'van-field':Field,
            'van-search':Search,
            'van-tabs':Tabs,
            'van-tab':Tab,
            'van-sticky':Sticky,
            'van-badge':Badge,
            'van-dropdown-menu':DropdownMenu,
            'van-dropdown-item':DropdownItem,
            'van-button':Button,
            'van-list':List,
            'van-cell':Cell,
            'van-icon':Icon,
            'van-popup':Popup,
            'van-picker':Picker,
            'van-uploader':Uploader,
            'van-loading':Loading,
            
            [ImagePreview.Component.name]: ImagePreview.Component,
         },
        data() {
            return {
                  active: 0,
                  code:'',
                  carouselImg:[],
                  showPopover:false,
                //   actions:[
                //       {text:'标题'},
                //       {text:'作者'}
                //   ],
                  fieldValue:'',
                  btnLabel:'昵称',
                  tabActive:0,
                  forumType:[],
                  forumMasterType:[],
                  forumTypeId:'',
                  forumSynthesisList:[],
                  forumCreamList:[],
                  forumMostLikeList:[],
                  isSearchDisabled:false,
                  isMaster:true,
                  page:1,
                  masterPage:1,
                  creamPage:1,
                  mostLikePage:1,
                  forumMasterTypeIndex:'0',
                  loading:false,
                  finished:true,
                  finishedText:'没有更多了',
                  synthesisLoading:false,
                  creamLoading:false,
                  mostLikeLoading:false,
                  synthesisFinished:true,
                  creamFinished:true,
                  mostLikeFinished:true,
                  synthesisFinishedText:'',
                  creamFinishedText:'',
                  mostLikeFinishedText:'',
                  MasterList:[],
                  isSynthesis:false,
                  isCream:false,
                  isMostLike:false,
                  ruler:false,
                  putRelations:false,
                  publishTitValue:'',
                  showTypePicker:false,
                  showCodePicker:false,
                  TypePickerColumns:[],
                  codePickerColumns:[
                      {text:'澳门六合彩',id:'71'},
                      {text:'香港六合彩',id:'28'},
                      {text:'老澳门六合彩',id:'75'},
                  ],
                  selecTypeValue:'',
                  selecCodeValue:'',
                  selecTypeId:'',
                  selecCodeId:'',
                  publishMessage:'',
                  fileList:[],
                  imageData: [], // 准备保存的图片列表
                  countIndex: 9, // 可选图片剩余的数量
                  imageSize:'',
                  gallerryImg:'',
                  scrollTop: '',
                  goTopShow: false,
                  isHaveMasterData:false,
                  isHaveSynthesisData:false,
                  isHaveCreamData:false,
                  isHaveMostLikeData:false,
                  selectTabName:'',
                  scroll:'',
                  totleCountItem:[],
                  searchCellTitle:'昵称',
                  placeholderTxt:'请输入昵称'
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/')
                // // localStorage.removeItem('code');;
            },
            getCarousel(){
                let data = {
                    type:1
                }
                imgCarousel(data).then(res=>{
                    if(res.data.code == 200){
                    // console.log(res.data.data)
                    
                    this.carouselImg = res.data.data
                    }
                }).catch(err =>{
                    console.log(err)
                })
            },
            onSearch(){
                // console.log(this.selectTabName)
                if(this.selectTabName == '综合'){
                    this.forumSynthesisList = []
                    this.getFurmSynthesisList()
                    this.fieldValue = ''
                }else if(this.selectTabName == '精华'){
                    this.forumCreamList = []
                    this.getCreamList()
                    this.fieldValue = ''
                }else if(this.selectTabName == '最多赞'){
                    this.forumMostLikeList = []
                    this.getMostLikeList()
                    this.fieldValue = ''
                }else if(this.selectTabName == '最新'){
                    this.forumSynthesisList = []
                    this.getFurmSynthesisList()
                    this.fieldValue = ''
                }else if(this.selectTabName == '' || this.selectTabName == undefined){ //高手榜
                    console.log(123)
                    this.MasterList = []
                    this.getMasterList()
                    this.fieldValue = ''
                }
            },
            // onSelect(action){
            //     this.btnLabel = action.text
            // },
            getForumType(){
                let data = {
                    category:this.code,
                }
                forumType(data).then(res=>{
                    if(res.data.code == 200){
                        this.forumType = res.data.data
                        for (let i = 0; i < res.data.data.length; i++) {
                            let newObj = {
                                text:res.data.data[i].typeName,
                                id:res.data.data[i].typeId
                            }
                            this.TypePickerColumns.push(newObj)
                            
                        }
                        console.log(this.TypePickerColumns)
                        let defaulData = {
                            typeName:'全部类型',
                            typeId:''
                        }
                        this.forumType.unshift(defaulData)
                        // this.forumType[0].typeName = '全部类型'
                        // this.forumType[0].forumTypeId = '007'
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            selectPopQishu(id,name,index){
                this.forumTypeId = id
                console.log(this.forumTypeId)
                this.$refs.dropdownMenu.toggle(false)
                if(this.selectTabName == '综合' || this.selectTabName == '最新'){
                    this.forumSynthesisList = []
                    this.page = 1
                    this.getFurmSynthesisList()
                }else if(this.selectTabName == '精华'){
                    this.forumCreamList = []
                    this.creamPage = 1
                    this.getCreamList()
                }else if(this.selectTabName == '最多赞'){
                    this.forumMostLikeList = []
                    this.mostLikePage = 1
                    this.getMostLikeList()
                }
                

            },
            selectMasterPopQishu(name,index){
                // console.log(name)
                this.forumMasterTypeIndex = index
                this.masterPage = 1
                this.postPeriod = name
                // console.log(this.forumTypeId)
                this.$refs.dropdownMenu.toggle(false)
                this.getMasterList()
            },
            onLoad(){
                this.masterPage += 1;//页数+1
                this.getMasterList();
            },
            
            getMasterList(){ //加载高手列表
                this.loading = true;
                let data = {
                    postCategory:this.code,
                    postPeriod:this.postPeriod,
                    terms:this.fieldValue
                }
                forumMasterList(data,this.masterPage).then(res=>{
                    if(res.data.data.total ==0){
                        this.isHaveMasterData = true
                        this.loading = false;
                        this.finished = true;
                        this.finishedText = '没有更多了'
                    }else if(res.data.code == 200 && res.data.data.masterData.total !=0){
                        this.isHaveMasterData = true
                        // console.log(res.data.data.selectPeriod)
                        this.forumMasterType = res.data.data.selectPeriod
                        // console.log(this.MasterList)
                        if(res.data.data.masterData.pageNum == 1){
                            this.MasterList = res.data.data.masterData.list
                        }else{
                            this.MasterList = this.MasterList.concat(res.data.data.masterData.list)
                        }
                        if (res.data.data.masterData.nextPage == 0) {  //数据全部加载完成
                            this.finished = true;
                            this.finishedText = '没有更多了'
                        }else{
                            this.finished = false;
                        }       
                        this.loading = false;
                    }
                })
            },
            synthesisOnLoad(){
                this.page += 1;//页数+1
                this.getFurmSynthesisList();
            },
            getFurmSynthesisList(){ //加载综合和最新列表
                let data = {
                    postCategory:this.code,
                    postType:this.forumTypeId,
                    postTitle:this.fieldValue,
                    isEssence:'',
                    mostLikes:0
                }
                forumList(data,this.page).then(res=>{
                    if(res.data.data.total ==0){
                        this.isHaveSynthesisData = true
                        this.synthesisLoading = false;
                        this.synthesisFinished = true;
                        this.synthesisFinishedText = '没有更多了'
                    }else if(res.data.code == 200 && res.data.data.total !=0){
                        this.isHaveSynthesisData = true
                        if(res.data.data.pageNum === 1){
                            this.forumSynthesisList = res.data.data.list
                        }else{
                            this.forumSynthesisList = this.forumSynthesisList.concat(res.data.data.list)
                        }
                        if (res.data.data.nextPage === 0) {  //数据全部加载完成
                            this.synthesisFinished = true;
                            this.synthesisFinishedText = '没有更多了'
                        }else{
                            this.synthesisFinished = false;
                        }       
                        this.synthesisLoading = false;
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            creamOnLoad(){
                this.creamPage += 1;//页数+1
                this.getCreamList();
            },
            getCreamList(){ //加载精华列表
                let data = {
                    postCategory:this.code,
                    postType:this.forumTypeId,
                    postTitle:this.fieldValue,
                    isEssence:1,
                    mostLikes:0
                }
                forumList(data,this.creamPage).then(res=>{
                    if(res.data.data.total ==0){
                        this.isHaveCreamData = true
                        this.creamLoading = false;
                        this.creamFinished = true;
                        this.creamFinishedText = '没有更多了'
                    }else if(res.data.code == 200 && res.data.data.total !=0){
                        this.isHaveCreamData = true
                        if(res.data.data.pageNum === 1){
                            this.forumCreamList = res.data.data.list
                        }else{
                            this.forumCreamList = this.forumCreamList.concat(res.data.data.list)
                        }
                        if (res.data.data.nextPage === 0) {  //数据全部加载完成
                            this.creamFinished = true;
                            this.creamFinishedText = '没有更多了'
                        }else{
                            this.creamFinished = false;
                        }       
                        this.creamLoading = false;
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            goPublish(){
                if(this.$store.state.noLogin){
                    Toast.fail("请登录 !")
                }else{
                    this.putRelations = true
                }
                
            },
            mostLikeOnLoad(){
                this.mostLikePage += 1;//页数+1
                this.getMostLikeList();
            },
            getMostLikeList(){  //加载最多赞列表
                let data = {
                    postCategory:this.code,
                    postType:this.forumTypeId,
                    postTitle:this.fieldValue,
                    isEssence:'',
                    mostLikes:1
                }
                forumList(data,this.mostLikePage).then(res=>{
                    if(res.data.data.total ==0){
                        this.isHaveMostLikeData = true
                        this.mostLikeLoading = false;
                        this.mostLikeFinished = true;
                        this.mostLikeFinishedText = '没有更多了'
                    }else if(res.data.code == 200 && res.data.data.total !=0){
                        this.isHaveMostLikeData = true
                        if(res.data.data.pageNum === 1){
                            this.forumMostLikeList = res.data.data.list
                        }else{
                            this.forumMostLikeList = this.forumMostLikeList.concat(res.data.data.list)
                        }
                        if (res.data.data.nextPage === 0) {  //数据全部加载完成
                            this.mostLikeFinished = true;
                            this.mostLikeFinishedText = '没有更多了'
                        }else{
                            this.mostLikeFinished = false;
                        }       
                        this.mostLikeLoading = false;
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            onchangeTab({title}){
                if(title===undefined){ //高手榜
                    this.isSearchDisabled = false
                    this.btnLabel = '昵称'
                    this.placeholderTxt = '请输入昵称'
                    this.isMaster = true
                    this.isSynthesis = false
                    this.isCream = false
                    this.isMostLike = false
                    this.ruler = false
                    this.isHaveSynthesisData = false
                    this.isHaveCreamData = false
                    this.isHaveMostLikeData = false
                    this.finishedText = '没有更多了'
                    this.synthesisFinishedText = ''
                    this.masterPage = 1
                    this.selectTabName = title
                    this.getMasterList()
                }else if(title == '综合'){
                    this.finishedText = ''
                    this.isSearchDisabled = false
                    this.btnLabel = '标题'
                    this.placeholderTxt = '请输入标题或昵称'
                    this.isHaveMasterData = false
                    this.isHaveCreamData = false
                    this.isHaveMostLikeData = false
                    this.isMaster = false
                    this.isSynthesis = true
                    this.isCream = false
                    this.isMostLike = false
                    this.ruler = false
                    this.selectTabName = title
                    this.page = 1
                    this.getFurmSynthesisList()
                }else if(title == '精华'){
                    this.isSearchDisabled = false
                    this.btnLabel = '标题'
                    this.placeholderTxt = '请输入标题或昵称'
                    this.isHaveSynthesisData = false
                    this.isHaveMasterData = false
                    this.isHaveMostLikeData = false
                    this.isMaster = false
                    this.isSynthesis = false
                    this.isCream = true
                    this.isMostLike = false
                    this.ruler = false
                    this.selectTabName = title
                    this.creamPage = 1
                    this.getCreamList()
                }else if(title == '最多赞'){
                    this.isSearchDisabled = false
                    this.btnLabel = '标题'
                    this.placeholderTxt = '请输入标题或昵称'
                    this.isHaveSynthesisData = false
                    this.isHaveMasterData = false
                    this.isHaveCreamData = false
                    this.isMaster = false
                    this.isSynthesis = false
                    this.isCream = false
                    this.isMostLike = true
                    this.ruler = false
                    this.selectTabName = title
                    this.mostLikePage = 1
                    this.getMostLikeList()
                }else if(title == '最新'){
                    this.finishedText = ''
                    this.isSearchDisabled = false
                    this.btnLabel = '标题'
                    this.placeholderTxt = '请输入标题或昵称'
                    this.isHaveMasterData = false
                    this.isHaveCreamData = false
                    this.isHaveMostLikeData = false
                    this.isMaster = false
                    this.isSynthesis = true
                    this.isCream = false
                    this.isMostLike = false
                    this.ruler = false
                    this.selectTabName = title
                    this.page = 1
                    this.getFurmSynthesisList()
                }else if(title == '规则'){
                    this.isSearchDisabled = true
                    this.btnLabel = '标题'
                    this.placeholderTxt = '请输入标题或昵称'
                    this.isHaveSynthesisData = false
                    this.isHaveMasterData = false
                    this.isHaveCreamData = false
                    this.isHaveMostLikeData = false
                    this.isMaster = false
                    this.isSynthesis = false
                    this.isCream = false
                    this.isMostLike = false
                    this.ruler = true
                }
            },
            setClickLeft(){
                this.putRelations = false
            },
            onConfirm(value) {
                this.selecTypeValue = value.text
                this.selecTypeId = value.id
                this.showTypePicker = false
            },
            onCodeConfirm(value) {
                this.selecCodeValue = value.text
                this.selecCodeId = value.id
                this.showCodePicker = false
            },
            onOversize(file){
                Toast('图片太大,所选图片不能大于500kb')
            },
            // 回调：组件选择图片成功后的回调函数

            goUploader(file) {
                if(this.selecCodeId == '' || this.selecTypeId == ''){
                    Toast("请先现选择彩种和类型！")
                    this.fileList = []
                }else{
                    let formData = new FormData()
                    formData.append("postCategory",this.selecCodeId)
                    formData.append("postType",this.selecTypeId)
                    formData.append("postTitle",this.publishTitValue)
                    formData.append("postContent",this.publishMessage.split("\n").join("<br />"))
                    console.log(this.fileList)
                    if(file.length===undefined&&this.fileList.lenghth==1){
                        const element = file.file;
                        formData.append("files",element)
                    }else{
                        for (let i = 0; i < this.fileList.length; i++) {
                            const element = this.fileList[i].file;
                            formData.append("files",element);//添加
                        }
                    }
                    this.imageData = formData
                }
                // if(this.selecCodeId == '' || this.selecTypeId == ''){
                //     Toast("请先现选择彩种和类型！")
                //     this.fileList = []
                //     formData = []
                // }else{
                //     this.imageData = formData
                // }
                // console.log(this.fileList)
            },
            ondeleteImg(){//删除图片时重新调用选择图片后的回调函数使其重新append当前剩余的图片文件
                this.goUploader(this.fileList)
            },
            completePublish(){
                let api = 'forum/release' 
                const toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                if(this.selecCodeValue == ''){
                    Toast.fail("请选择彩种!")
                }else if(this.selecTypeValue == ''){
                    Toast.fail("请选择类型!")
                }else if(this.publishTitValue === '' && this.publishMessage === '' && this.fileList == ''){
                    Toast("请选择标题、内容、图片任一进行填写！")
                }
                else{
                    if(this.fileList == ''){
                        let formData = new FormData()
                        // this.imageData = {
                        //     postCategory:this.selecCodeId,
                        //     postType:this.selecTypeId,
                        //     postTitle:this.publishTitValue,
                        //     postContent:this.publishMessage.split("\n").join("<br />")
                        // }
                        formData.append("postCategory",this.selecCodeId)
                        formData.append("postType",this.selecTypeId)
                        formData.append("postTitle",this.publishTitValue)
                        formData.append("postContent",this.publishMessage.split("\n").join("<br />"))
                        this.imageData = formData
                    }
                    console.log(this.fileList)
                    axios.post(config.baseURL+api,this.imageData,{headers:{'token':this.$store.state.token.replace(/\"/g, "")}}).then(res=>{
                        if(res.data.code == 200){
                            Toast("发布成功")
                            this.isLoading = false
                            this.putRelations = false
                            this.forumSynthesisList = []
                            this.fileList = []
                            this.selecCodeValue = ''
                            this.selecTypeValue = ''
                            this.publishTitValue = ''
                            this.publishMessage = ''
                            this.page = 1
                            this.getFurmSynthesisList()
                        }else{
                            Toast.fail(res.msg);
                        }
                    })
                }
            },
            showImg(images){
                ImagePreview({
                    images:[images],
                    showIndex: false,
                    closeable: true,
                });
                
            },
            goTop() {
                let timer = null,
                _that = this;
                cancelAnimationFrame(timer)
                timer = requestAnimationFrame(function fn() {
                if (_that.scrollTop > 0) {
                    _that.scrollTop -= 50;//回到顶部的速度
                    document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
                    timer = requestAnimationFrame(fn)
                } else {
                    cancelAnimationFrame(timer);
                    _that.goTopShow = false;
                }
                })
            },
            handleScroll() {
                this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                if (this.scrollTop > 150) {//设置页面滑动多少显示按钮
                 this.goTopShow = true
                } else {
                 this.goTopShow = false;
                }
            },
            backHandleScroll(){
                this.scroll  = document.documentElement && document.documentElement.scrollTop
            },
            goToDetail(postId,userId){
                this.$router.push({                                                     
                    name: 'ForumDetail',             // 路由的名字，地址会隐藏                            
                    params: {    
                        postId:postId,
                        userId:userId                          
                    }
                })
            },
            getLastPost(text){
                if(!this.selecCodeValue){
                    Toast('请在下方选择彩种！')
                    return
                }else if(!this.selecTypeValue){
                    Toast("请在下方选择类型！")
                    return
                }
                let data = {
                    postCategory:this.selecCodeId,
                    postType:this.selecTypeId,
                }
                forumLastForumPost(data).then(res => {
                    if(res.data.code == 200){
                        if(res.data.data == null){
                            Toast("该类型或彩种您还未发过帖子~")
                        }else{
                            if(text == '内容'){
                                if(res.data.data.postContent == ''){
                                    Toast('您上期没有发布内容哦~')
                                }else{
                                    this.publishMessage = res.data.data.postContent
                                }
                            }else if(text == '标题'){
                                if(res.data.data.postTitle == ''){
                                    Toast('您上期没有发布标题哦~')
                                }else{
                                    this.publishTitValue = res.data.data.postTitle
                                }
                            }
                        } 
                        console.log(res.data.data)
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            gotoLink(url){
                window.location.href = url
            },
            countLikeClick(item){
                if(this.$store.state.noLogin){
                    Toast.fail("请登录 !")
                }else{
                    // console.log(item)
                    if (item.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:4,
                            otherId:item.postId
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                item.like = true
                                item.likes+=1
                                Toast("已点赞")
                            }
                        })
                    }
                }
            },
        },
    }
</script>

<style lang='scss' scoped>
//PC端
@media screen and (min-width:700px) {
    .goPublish{
        position: fixed;
        margin-left: 500px;
        bottom: 120px;
    }
    .backTop{
        z-index: 10;
        position: fixed;
        cursor: pointer;
        bottom: 50px;
        margin-left: 505px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        transition: all 0.2s ease-in-out;
    }
    .my-swipe .van-swipe-item img{
        width: 100%;
        height: 200px;
    }
}
//移动端
@media screen and (max-width:750px) {
    .goPublish{
        position: fixed;
        right: 28px;
        bottom: 120px;
    }
    .backTop{
        z-index: 10;
        position: fixed;
        cursor: pointer;
        bottom: 50px;
        right: 35px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        transition: all 0.2s ease-in-out;
    }
    .my-swipe .van-swipe-item img{
        width: 100%;
        height: 120px;
    }
}
/* 我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线*/

    .my-swipe{
        padding: 0;
        margin: 0;
    }
    
    .popover{
        border-right: 1px solid #dcdcdc;
        width: 50px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        color: #ff5000;
        font-size: 15px;
        background-color: #f5f5f5;
    }
    .galleryList{
        display: flex;
        justify-content:space-around;
        align-items: center;
        margin: 10px;
    }
    .inp{
        width: 100%;
        padding: 0;
    }
    .searchBtn{
        display: inline-block;
        margin-right: 10px;
        border-radius: 5px;
        width: 50px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    .sxBtn{
        display: inline-block;
        border-radius: 5px;
        width: 50px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        font-size: 15px;
        border:1px solid #ff5000;
        background-color: #fff;
    }
    .popup{
        width: 33%;
        margin-top:5px;
    }
    .popup span{
        display: inline-block;
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        height: 30px;
        width: 100px;
        line-height: 30px;
        margin: 10px 0;
        padding: 5px 10px;
        text-align: center;
        font-size: 15px;
    }
    .popAct{
        color: #ffffff;
        background-color: #ff5000 !important;
    }
    .vantabsContent{
        position: relative;
        // top:20px;
    }
    .comment>div{
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 80px;
        border-bottom: 1px solid #ddd;
    }
    .comment>div .masterLeft{
        display: flex;
        align-items: center;
    }
    .comment>div .masterLeft .rank{
        display: flex;
        flex-direction: column;
        width: 50px;
        height: 100%;
        margin-top:5px;
    }
    .comment>div .masterLeft .rank img{
        width: 20px;
        margin: 5px auto;
    }
    .comment>div .masterLeft .rank span.act{
        font-size: 20px;
        color: #ff5000;
    }
    .comment>div .masterLeft .logo p{
        display: inline-block;
    }
    .comment>div .masterLeft .logo p img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        top:8px;
        left:10px;
    }
    .comment>div .masterRight{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left:20px;
        height: 100%;
    }
    .comment>div .masterRight>a{
        display: inline-block;
        width: 100%;
    }
    .comment>div .masterRight>a .masterTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .comment>div .masterRight>a .masterTop strong{
        font-weight: 400;
        font-size: .8rem;
        position: relative;
        top: 5px;
        left: 10px;
    }
    .comment>div .masterRight>a .masterTop span{
        color: #ff5000;
        font-size: .7rem;
        font-weight: 700;
        margin-right: 20px;
    }
    .comment>div .masterRight>a .masterBottom{
        margin-top: 15px;
        justify-content: flex-start;
        display: flex;
    }
    .comment>div .masterRight>a .masterBottom span{
        padding: 0 5px;
        border-radius: 10px;
        height: 20px;
        font-size: 13.24px;
        margin-left: 5px;
        line-height: 20px;
        color: #ff5000;
        background-color: #ffdccc;
    }
    .comment>div .masterRight>a .masterBottom b{
        margin-left:5px;
        color: #f18109;
        font-size: 13.24px;
        padding-left: 10px;
        font-weight: 400;
        width: 70px;
        height: 20px;
        line-height: 20px;
        background: url(../assets/materBg.png) no-repeat;
        background-size: 100% 100%;
    }
    .Synthesis .forumUser{
        display: flex;
        align-items: center;
        padding: 0 15Px;
    }
    .Synthesis .forumUser img{
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        width: 48px;
        height: 48px;
        -o-object-fit: cover;
        object-fit: cover;
        margin-top: 20px;
    }
    .Synthesis .forumUser p{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }
    .Synthesis .forumUser p span{
        font-size: 15px;
        text-align: left;
        font-weight: bold;
        margin-top: 20px;
    }
    .Synthesis .forumUser p b{
        color: #999;
        font-size: 13px;
    }
    a:active, a:focus, a:hover, a:link, a:visited {
        outline: none;
        background: none;
        text-decoration: none;
    }
    a{
        color: #333;
    }
    .Synthesis .forumTxt{
        width: 100%;
    }
    .Synthesis .forumTxt .forumTxtTit{
        padding: 10Px 15Px;
        border-bottom: 1px solid #eee;
        word-break: break-all;
        font-size: 15px;
        text-align: left;
    }
    .Synthesis .forumTxt>div{
        padding: 0 15Px;
    }
    .Synthesis .forumTxt>div .overHidden{
        overflow: hidden;
    }
    .Synthesis .forumTxt>div .formTxtContent{
        word-break: break-all;
        font-size: 15px;
        text-align: left;
    }
    .Synthesis .forumImg{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 0.4rem;
        margin-top: 15Px;
    }
    .Synthesis .forumImg>div{
        width: 6rem;
        height: 6rem;
    }
    .Synthesis .forumImg>div img{
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        background-color: #f5f5f5;
    }
    .Synthesis .forumOther{
        margin-top: 15Px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #f5f5f5;
    }
    .Synthesis .forumOther ul{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        height: 30px;
    }
    .Synthesis .forumOther ul li{
        display: flex;
        margin: 0 auto;
        align-items: center;
    }
    .Synthesis .forumOther ul li img{
        width: 13px;
        height: 13px;
        vertical-align: middle;
    }
    .Synthesis .forumOther ul li span{
        margin-left:5px;
        font-weight: 400;
        font-size: .32rem;
    }
    .Synthesis .forumOther ul li b{
        margin-left: 5px;
        font-weight: 400;
        font-size:13px;
    }
    li, ol, strong, ul{
        list-style: none;
    }
    ol,ul{
        margin: 0;
        padding: 0;
    }
    
    .goPublish img{
        width: 50px;
        height:50px;
    }
    .publishMains .publishLoading{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        border-bottom: 0.026667rem solid #eee;
    }
    .publishMains .publishLoading li{
        margin: 0 auto;
        padding: 15px 0;
    }
    .publishMains .publishLoading li span{
        display: inline-block;
        border:1px solid #dcdcdc;
        width: 104px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 15px;
    }
    .publishMains .cellItem{
        border-bottom: 8px solid #f5f5f5;
    }
    .textArea{
        height: 160px !important;
    }
    .publishBtn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 25px;
        margin:20px 0;
        width: 300px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .uploadImg{
        width: 6rem;
        height:6rem;
    }
    .galerryInfo{
        text-align: center;
        margin-top:10px;
    }
    .galerryImg{
        background-color: #eee;
    }
    .galerryInfo .galerryImg>div img{
        width: 100%;
    }

    .backTopIcon{
        font-size: 40px;
    }
    .loading{
        position: fixed;
        top: 50%;
        background-color: #333;
        width: 100px;
        height: 100px;
    }
    .vantabPane .other{
        margin-bottom: 0.4rem;
        width: 90%;
        margin:0 auto
    }
    .vantabPane .other p{
        text-align: left;
        margin-top: 0.133333rem;
        line-height: 2;
        text-indent: 2em;
        font-size: 16px;
    }
    /deep/.van-icon{
        color: white;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff !important;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-swipe{
        top:-4px;
    }
    /deep/.van-swipe__indicators{
        bottom: 0;
    }
    /deep/.van-swipe__indicator{
        background-color: rgb(61, 2, 255);
    }
    /deep/.van-search{
        padding: 0;
    }
    /deep/.van-search__field{
        line-height: 33px;
    }
    /deep/.van-cell{
        height: 43px;

    }
    /deep/.van-search__action:active{
        background: transparent;
    }
    /deep/.van-badge{
        position: absolute;
        top: -2px;
        right: 0Px;
    }
    /deep/.van-tabs{
        background-color: #fff;
        height: 2rem;
        border-bottom: 0.5px solid #ddd;
    }
    /deep/.van-tabs__nav--card{
        border:0;
    }
    /deep/.van-tabs__nav--card .van-tab{
        border-right: 0;
        color:#999;
        padding-top: 10px;
    }
    /deep/.van-tabs__nav--card .van-tab.van-tab--active{
        color:rgb(251, 96, 48);
        background-color: transparent;
    }
    /deep/.van-dropdown-menu__title{
        padding: 0 5px 0 5px;
        font-size: 15px;
        color: #fff !important;
    }
    /deep/.van-dropdown-menu__bar{
        box-shadow: none;
        background: #ff5000;
        border-radius: 20px;
        background-color: transparent;
        margin-top: -3px;
    }
    /deep/.van-dropdown-item__content{
        display: flex;
        flex-wrap: inherit;
        white-space: nowrap;
    }
    /deep/.van-dropdown-item{
        width: 100%;
        max-width: 600px;
        margin:0 auto;
        flex-flow: wrap;
        flex-wrap: wrap;
    }
    /deep/.sxBtn .van-dropdown-menu__title{
        color: #ff5000 !important;
    }
    /deep/.van-dropdown-menu__title::after{
        right: -2px;
    }
    /deep/.van-dropdown-menu__title--active{
        color:rgb(83, 199, 253) !important;
    }
    /deep/.van-button__content{
        display: block;
    }
    /deep/.van-button--normal{
        padding:0;
    }
    /deep/.van-popup,.van-overlay{
        max-width: 600px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    /deep/.van-overlay{
        max-width: 600px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    /deep/.van-cell__title{
        text-align: left;
    }
    /deep/.van-picker__confirm{
        color: #000000bc;
    }
    /deep/.van-ellipsis{
        color: rgb(71, 71, 71);
    }
    /deep/.van-uploader__wrapper{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 0.266667rem;
    }
    /deep/.van-uploader__preview-image{
            margin: 0 auto;
            width: 6rem;
            height: 6rem;
            -o-object-fit: cover;
            object-fit: cover;
    }
    /deep/.van-image-preview__index{
        left: 200% !important;
    }
</style>