<template>
    <div>
        <van-nav-bar :title='title' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:50px;'></div>   <!-- 增加顶部高度，部面盖住内容 -->
        <div>
            <table>
                <tbody width="100%">
                    <div v-for="(item,i) in dataList" :key="i+20">
                            
                        <div v-for="(n,b) in item" :key="b+20">
                            <div v-if="i!=0" class="trTitle">{{n[0].issueNo.substring(n[0].issueNo.length-3)}}期--长期跟踪，稳赚不赔！</div>
                            <tr v-for="(o,p) in n" :key="p+20">
                                <td class="c01" >{{o.issueNo.substring(o.issueNo.length-3)}}期<span class="issueNo">{{o.num}}</span>肖<span></span></td>
                                <td class="c02" v-html="eachColor(o.sxPredict,o.sxResult)"></td>
                                <td class="c03" >{{o.sxResult}}{{o.numResult}}<span v-if="o.flag || i==0">中</span><span v-else>错</span></td>   
                            </tr>
                        </div>
                    </div>
                </tbody>
            </table>
        </div>
        <div style='width:100%;height:10px;'></div>   <!-- 增加脚部高度，部面盖住内容 -->
    </div>
</template>

<script>
import {predictResult} from '../api/http'
import { NavBar } from 'vant';
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            if(this.code == '71'){
                this.title = '澳门特码'
            }else if(this.code == '28'){
                this.title = '香港特码'
            }else{
                this.title = '老澳门特码'
            }
            this.getList()
        },
        components:{
            'van-nav-bar':NavBar,
        },
        data() {
            return {
                  title:'',
                  code:'',
                  dataList:[]
            }
        },
        methods: {
            onClickLeft(){
                this.$router.push('/')
            },
            getList(){
                let data = {
                    code:this.code
                }
                predictResult(data).then(res=>{
                    if(res.data.code == 200){
                        this.dataList = res.data.data
                        console.log(this.dataList)
                    }
                })
            },
            eachColor(content,searchVal){
                let nt='<span style="background:yellow">'+searchVal+'</span>';
                return content.replace(searchVal,nt)+'&nbsp;';
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-icon{
        color: white;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff !important;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    table {
        width: 100%;
        border: none;
    }
    table td.c01,table td.c03 {
        text-align: center;
        background-color: #ff8953;
    }
    table td.c03{
        width: 15%;
    }
    table td.c01{
        width: 35%;
    }
    table td.c02 {
        text-indent: 10px;
        background-color: #fff;
        text-align: left;
        padding: 0 8px;
        width: 300px;
    }
    table tr{
        width: 100%;
    }
    table td {
        height: 100%;       
        padding: 8px 0;
        word-break: break-all;
        font-size: 14px;
        border: 1px solid rgb(229, 229, 229);
    }
    .trTitle{
        margin: 0 auto;
        padding: 5px 0;
        background-color: #fff;
        text-align: center;
        color: #555;
        font-size: 13px;
        
    }
    .issueNo{
        border-radius: 50%;
        width: 10px;
        height: 10px;
        padding: 1px;
        border: 1px solid rgba(63, 62, 62, 0.867);
        text-align: center;
        line-height: 10px;
        font-size: 10px;
        margin-left: 10px;
        display: inline-block;
    }
</style>