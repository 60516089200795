<template>
    <div>
        <van-nav-bar title='关于我们' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:40px;'></div>   <!-- 增加头部脚部高度，部面盖住内容 -->
        <div class="bg">
            <img src="../assets/userAvatar.png" alt="">
        </div>
        <div class="linkTxt" @click="goToLink">官网：{{codeLink}}</div>
    </div>
</template>

<script>
import {imgLink} from '../api/http'
import { NavBar,} from 'vant';
    export default {
        created(){
            this.getLink()
        },
        components:{
            'van-nav-bar':NavBar,
            
        },
        data() {
            return {
                codeLink:'' , 
                downloadLink:''
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/user')
            },
            getLink(){
                let data = {
                    key:'index-link'
                }
                imgLink(data).then(res => {
                    if(res.data.code == 200){
                        this.codeLink = res.data.data
                    }
                })
            },
            goToLink(){
                window.location.href = this.codeLink
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    .bg{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 260px;
        background: url(../assets/taHomeBg.png) no-repeat;
        background-size: 100% 100%;
    }
    .bg img{
        border-radius: 15px;
        width: 111px;
        height: 111px;
    }
    .linkTxt{
        border-bottom: 1px solid #eee;
        padding-left: 20px;
        height: 48px;
        line-height: 48px;
        text-align: left;
        font-size: 14px;
    }
</style>