<template>
    <div>
        <van-nav-bar title='六合公式' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
        <div style='width:100%;height:50px;'></div>  <!--增加头部高度，部面盖住内容-->
        <van-swipe class="my-swipe" :autoplay="3000" >
            <van-swipe-item v-for="(image,i) in carouselImg" :key="i+10">
                   <img :src="image.url" @click="gotoLink(image.jumpUrl)"/>
            </van-swipe-item>
        </van-swipe>
        <div class="galleryList">
            <div class="popover">标题</div>
            <div class="inp">
                <van-search
                    v-model="fieldValue"
                    show-action
                    placeholder="输入你想要的内容或昵称"
                    @search="onSearch"
                    clearable
                    >
                    <template #action>
                        <van-button class="searchBtn"  @click="onSearch">搜索</van-button>
                    </template>
                </van-search>
            </div>
        </div>
        <van-list
                v-model:loading="loading"
                :finished="finished"
                :finished-text="finishedText"
                @load="onLoad"
                :immediate-check='false'>
                <ul class="formulaList">
                    <li v-for="(item,index) in formulaList" :key="index+20">
                        <div>
                            <img :src="item.avatar" alt="">
                            <p>
                                <span>{{item.userName}}</span>
                                <b>{{item.createTime}}</b>
                            </p>
                        </div>
                        <a @click="goToDetail(item.postId)">
                            <span>{{item.postTitle}}</span>
                        </a>
                    </li>
                </ul>
        </van-list>
        <div class="goPublish" @click="goPublish">
            <img src="../assets/publish.png" alt="">
        </div>
        <van-popup v-model:show="putRelations" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='发布(论坛帖)' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="publishMains">
                <ul class="publishLoading">
                    <li @click="getLastPost('内容')"><span>加载上期内容</span></li>
                    <li @click="getLastPost('标题')"><span>加载上期标题</span></li>
                    <li @click="getLastPost('正文')"><span>加载上期正文</span></li>
                </ul>
                <van-cell-group>
                    <div class="cellItem">
                        <van-cell title="标题"/>
                        <van-field clearable v-model="publishTitValue" placeholder="请输入标题" />
                    </div>
                    <div class="cellItem">
                        <van-cell title="选择类型" is-link @click="showTypePicker = true" :value="selecTypeValue"/>
                        <van-popup v-model:show="showTypePicker" position="bottom">
                            <van-picker
                                show-toolbar
                                :columns="TypePickerColumns"
                                :default-index="0"
                                @cancel="showTypePicker = false"
                                @confirm="onConfirm"
                            />
                        </van-popup>
                    </div>
                    <div class="cellItem">
                        <van-cell title="选择彩种" is-link @click="showCodePicker = true" :value="selecCodeValue"/>
                        <van-popup v-model:show="showCodePicker" position="bottom">
                            <van-picker
                                show-toolbar
                                :columns="codePickerColumns"
                                :default-index="0"
                                @cancel="showCodePicker = false"
                                @confirm="onCodeConfirm"
                            />
                        </van-popup>
                    </div>
                    <div class="cellItem">
                        <van-cell title="内容"/>
                        <van-field
                            v-model="publishMessage"
                            rows="5"
                            autosize
                            clearable
                            class="textArea"
                            height='200px'
                            type="textarea"
                            placeholder="请输入内容"
                        />
                        <van-uploader :after-read="goUploader" @delete="ondeleteImg" result-type="dataUrl" multiple v-model="fileList"  :max-count="countIndex"  :max-size="500 * 1024" @oversize="onOversize">
                            <img src="../assets/upload.png" class="uploadImg">
                        </van-uploader>
                    </div>
                    <div @click="completePublish">
                        <span class="publishBtn">发布</span>
                    </div>
                </van-cell-group>
            </div>
        </van-popup>
    </div>
</template>

<script>
import config from '../config/index' // 路径配置
import axios from 'axios';
import {imgCarousel,forumFormulaList,forumLastForumPost,forumType} from '../api/http'
import { Button, Cell, CellGroup, Field, List, NavBar, Picker, Popup, Search, Swipe, SwipeItem, Uploader,Toast  } from 'vant';
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.getCarousel()
            this.getFormulaList()
            this.getForumType()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-swipe':Swipe,
            'van-swipe-item':SwipeItem,
            'van-search':Search,
            'van-button':Button,
            'van-list':List,
            'van-cell':Cell,
            'van-popup':Popup,
            'van-cell-group':CellGroup,
            'van-field':Field,
            'van-picker':Picker,
            'van-uploader':Uploader,
        },
        data() {
            return {
                  carouselImg:[],
                  fieldValue:'',
                  code:'',
                  page:1,
                  loading:false,
                  finished:true,
                  finishedText:'没有更多了',
                  formulaList:[],
                  putRelations:false,
                  showTypePicker:false,
                  showCodePicker:false,
                  TypePickerColumns:[],
                  codePickerColumns:[
                      {text:'澳门六合彩',id:'71'},
                      {text:'香港六合彩',id:'28'},
                      {text:'老澳门六合彩',id:'75'},
                  ],
                  selecTypeValue:'',
                  selecCodeValue:'',
                  selecTypeId:'',
                  selecCodeId:'',
                  publishMessage:'',
                  fileList:[],
                  imageData: [], // 准备保存的图片列表
                  countIndex: 9, // 可选图片剩余的数量
            }
        },
        methods: {
            getCarousel(){
                let data = {
                    type:1
                }
                imgCarousel(data).then(res=>{
                    if(res.data.code == 200){
                    // console.log(res.data.data)
                    
                    this.carouselImg = res.data.data
                    }
                }).catch(err =>{
                    console.log(err)
                })
            },
            onClickLeft() {
                this.$router.push('/')
                localStorage.removeItem('code');
            },
            onLoad(){
                this.page += 1
                this.getFormulaList()
            },
            getFormulaList(){
                this.loading = true;
                let data = {
                    postCategory:this.code,
                    postTitle:this.fieldValue
                }
                forumFormulaList(data,this.page).then(res => {
                    if(res.data.code == 200 && res.data.data.total !=0){
                        if(res.data.data.pageNum === 1){
                            this.formulaList = res.data.data.list
                        }else{
                            this.formulaList = this.formulaList.concat(res.data.data.list)

                        }
                        if (res.data.data.nextPage === 0) {  //数据全部加载完成
                            this.finished = true;
                            this.finishedText = '没有更多了'
                        }else{
                            this.finished = false;
                        }      
                        this.loading = false;
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            onSearch(){
                this.formulaList = []
                this.getFormulaList()
            },
            goToDetail(postId){
                this.$router.push({                                                     
                    name: 'FormulaDetail',             // 路由的名字，地址会隐藏                            
                    params: {    
                        postId:postId                               
                    }
                })
            },
            goPublish(){
                if(this.$store.state.noLogin){
                    Toast.fail("请登录 !")
                }else{
                    this.putRelations = true
                }
                
            },
            setClickLeft(){
                this.putRelations = false
            },
            getLastPost(text){
                if(!this.selecCodeValue){
                    Toast('请在下方选择彩种！')
                    return
                }else if(!this.selecTypeValue){
                    Toast("请在下方选择类型！")
                    return
                }
                let data = {
                    postCategory:this.selecCodeId,
                    postType:this.selecTypeId,
                }
                forumLastForumPost(data).then(res => {
                    if(res.data.code == 200){
                        if(res.data.data == null){
                            Toast("该类型或彩种您还未发过帖子~")
                        }else{
                            if(text == '内容'){
                                if(res.data.data.postContent == ''){
                                    Toast('您上期没有发布内容哦~')
                                }else{
                                    this.publishMessage = res.data.data.postContent
                                }
                            }else if(text == '标题'){
                                if(res.data.data.postTitle == ''){
                                    Toast('您上期没有发布标题哦~')
                                }else{
                                    this.publishTitValue = res.data.data.postTitle
                                }
                            }
                        } 
                        console.log(res.data.data)
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            getForumType(){
                let data = {
                    category:this.code,
                }
                forumType(data).then(res=>{
                    if(res.data.code == 200){
                        for (let i = 0; i < res.data.data.length; i++) {
                            let newObj = {
                                text:res.data.data[i].typeName,
                                id:res.data.data[i].typeId
                            }
                            this.TypePickerColumns.push(newObj)
                            
                        }
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            onConfirm(value) {
                this.selecTypeValue = value.text
                this.selecTypeId = value.id
                this.showTypePicker = false
            },
            onCodeConfirm(value) {
                this.selecCodeValue = value.text
                this.selecCodeId = value.id
                this.showCodePicker = false
            },
            onOversize(file){
                Toast('图片太大,所选图片不能大于500kb')
            },
            // 回调：组件选择图片成功后的回调函数
            goUploader(file) {
                if(this.selecCodeId == '' || this.selecTypeId == ''){
                    Toast("请先现选择彩种和类型！")
                    this.fileList = []
                }else{
                    let formData = new FormData()
                    formData.append("postCategory",this.selecCodeId)
                    formData.append("postType",this.selecTypeId)
                    formData.append("postTitle",this.publishTitValue)
                    formData.append("postContent",this.publishMessage.split("\n").join("<br />"))
                    console.log(this.fileList)
                    if(file.length===undefined&&this.fileList.lenghth==1){
                        const element = file.file;
                        formData.append("files",element)
                    }else{
                        for (let i = 0; i < this.fileList.length; i++) {
                            const element = this.fileList[i].file;
                            formData.append("files",element);//添加
                        }
                    }
                    this.imageData = formData
                }
            },
            ondeleteImg(){//删除图片时重新调用选择图片后的回调函数使其重新append当前剩余的图片文件
                this.goUploader(this.fileList)
            },
            completePublish(){
                let api = 'forum/release' 
                const toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                if(this.selecCodeValue == ''){
                    Toast.fail("请选择彩种!")
                }else if(this.selecTypeValue == ''){
                    Toast.fail("请选择类型!")
                }else if(this.publishTitValue === '' && this.publishMessage === '' && this.fileList == ''){
                    Toast("请选择标题、内容、图片任一进行填写！")
                }
                else{
                    if(this.fileList == ''){
                       let formData = new FormData()
                        formData.append("postCategory",this.selecCodeId)
                        formData.append("postType",this.selecTypeId)
                        formData.append("postTitle",this.publishTitValue)
                        formData.append("postContent",this.publishMessage.split("\n").join("<br />"))
                        this.imageData = formData
                    }
                    axios.post(config.baseURL+api,this.imageData,{headers:{'token':this.$store.state.token.replace(/\"/g, "")}}).then(res=>{
                        if(res.data.code == 200){
                            Toast("发布成功")
                            this.isLoading = false
                            this.putRelations = false
                            this.fileList = []
                            this.selecCodeValue = ''
                            this.selecTypeValue = ''
                            this.publishTitValue = ''
                            this.publishMessage = ''
                            setTimeout(()=>{
                                this.getFormulaList()
                            },500)
                        }else{
                            Toast.fail(res.msg);
                        }
                    })
                }
            },
            gotoLink(url){
                window.location.href = url
            },
        },
    }
</script>

<style lang='scss' scoped>
//PC端
@media screen and (min-width:700px) {
    .my-swipe .van-swipe-item img{
        width: 100%;
        height: 200px;
    }
    .goPublish{
        position: fixed;
        margin-left: 500px;
        bottom: 120px;
    }
}
//移动端
@media screen and (max-width:750px) {
    .my-swipe .van-swipe-item img{
        width: 100%;
        height: 120px;
    }
    .goPublish{
        position: fixed;
        right: 28px;
        bottom: 120px;
    }
}
/* 我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线*/
    .galleryList{
        display: flex;
        justify-content:space-around;
        align-items: center;
        margin-left: 20px;
        margin-top: 10px;
    }
    .inp{
        width: 100%;
        padding: 0;
    }
    .popover{
        border-right: 1px solid #dcdcdc;
        width: 50px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        color: #ff5000;
        font-size: 15px;
        background-color: #f5f5f5;
    }
    .searchBtn{
        display: inline-block;
        margin-right: 10px;
        border-radius: 5px;
        width: 70px;
        height: 43px;
        padding: 5px 10px;
        line-height: 43px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    .formulaList{
        margin-top: 20px;
    }
    .formulaList li{
        display: flex;
        flex-direction: column;
        border-bottom: 0.026667rem solid #eee;
        padding: 0.266667rem;
        text-align: left;
    }
    .formulaList li>div{
        display: flex;
    }
    .formulaList li>div img{
        border-radius: 50%;
        border: 1px solid #eee;
        width: 40px;
        height: 40px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .formulaList li>div p{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10Px;
    }
    .formulaList li>div p span{
        font-size: 15px;
    }
    .formulaList li>div p b{
        color: #999;
        font-size: 13px;
    }
    .formulaList li a{
        // padding-top: 10px;
    }
    .formulaList li a span{
        font-weight: 700;
        font-size: 15px;
    }
    .goPublish img{
        width: 50px;
        height:50px;
    }
    .publishMains .publishLoading{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        border-bottom: 0.026667rem solid #eee;
    }
    .publishMains .publishLoading li{
        margin: 0 auto;
        padding: 15px 0;
    }
    .publishMains .publishLoading li span{
        display: inline-block;
        border:1px solid #dcdcdc;
        width: 104px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 15px;
    }
    .publishMains .cellItem{
        border-bottom: 8px solid #f5f5f5;
    }
    .textArea{
        height: 160px !important;
    }
    .publishBtn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 25px;
        margin:20px 0;
        width: 300px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .uploadImg{
        width: 6rem;
        height:6rem;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff !important;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-swipe{
        top:-4px;
    }
    /deep/.van-swipe__indicators{
        bottom: 0;
    }
    /deep/.van-swipe__indicator{
        background-color: rgb(61, 2, 255);
    }
    /deep/.van-search{
        padding: 0;
    }
    /deep/.van-search__field{
        line-height: 33px;
    }
    /deep/.van-search__action:active{
        background: transparent;
    }
    /deep/.van-button__content{
        display: block;
    }
    /deep/.van-button--normal{
        padding:0;
    }
    /deep/.van-popup,.van-overlay{
        max-width: 600px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    /deep/.van-overlay{
        max-width: 600px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    /deep/.van-cell__title{
        text-align: left;
    }
    /deep/.van-picker__confirm{
        color: #000000bc;
    }
    /deep/.van-ellipsis{
        color: rgb(71, 71, 71);
    }
    /deep/.van-uploader__wrapper{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 0.266667rem;
    }
    /deep/.van-uploader__preview-image{
            margin: 0 auto;
            width: 6rem;
            height: 6rem;
            -o-object-fit: cover;
            object-fit: cover;
    }
    /deep/.van-image-preview__index{
        left: 200% !important;
    }
</style>