<template>
    <div>
     <van-nav-bar title="图库目录" fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
     <div style='width:100%;height:50px;'></div>   <!-- 增加头部头部高度，部面盖住内容 -->
     <div class="middle-tab">
        <div class="tuku">
          <!-- <b>图库目录</b> -->
            <van-radio-group v-model="radio" direction="horizontal" class="radioBox">
                <van-radio name="1" shape="square" checked-color="#ee0a24" @click="handleChange(color='1')">彩色</van-radio>
                <van-radio name="2" shape="square" checked-color="#ee0a24" @click="handleChange(color='2')">黑白</van-radio>
            </van-radio-group>
            <ul class="caiseyeqian">
                <li @click="checkRouterTo(year = '2024')"><img :src="tukuImg" alt=""><b style="font-weight:400;">{{checkText1}}</b></li>
                <li @click="checkRouterTo(year = '2023')"><img :src="tukuImg" alt=""><b style="font-weight:400;">{{checkText2}}</b></li>
            </ul>

        </div>
    </div>
     <div class="waterFallWrap">
        <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              :immediate-check ='false'
              @load="load_more"
              >
              <van-row class="van-clearfix">
                <van-col span="12">
                    <div @click="imgRouterTo(item)" class="image-item" v-for="(item,i) in imgListLeft" :key="i+10">
                      <div>
                        <img :src="item.imgUrl" alt="" />
                        <div class="item-body">
                          <div class="item-desc">{{item.imgTitle}}</div>
                        </div>
                      </div>
                    </div>
                </van-col>
                <van-col span="12">
                  <div @click="imgRouterTo(p)" class="image-item" v-for="(p,q) in imgListRight" :key="q+10">
                      <div>
                        <img :src="p.imgUrl" alt="" />
                        <div class="item-body">
                          <div class="item-desc">{{p.imgTitle}}</div>
                        </div>
                      </div>
                    </div>
                </van-col>
              </van-row>

        </van-list>
      </div>
     <v-BackTop></v-BackTop>>
      <h4 v-show="isShowText" style="position:relative;bottom:50px; left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; ">暂无数据</h4>
    </div>
</template>

<script>
import BackTop from '../components/backTop.vue' 
import caise from '../assets/caise.png'
import heibai from '../assets/heibai.png'
import {imgList} from '../api/http.js'

import { Icon, NavBar,Swipe,SwipeItem,Grid,GridItem,Overlay,NoticeBar,RadioGroup,Radio,List,Col,Row,Toast,  } from 'vant';
    export default {
        name: 'Discover',
        components:{
            'van-overlay':Overlay,
            'van-notice-bar':NoticeBar,
            'van-radio-group':RadioGroup,
            'van-radio':Radio,
            'van-list':List,
            'van-col':Col,
            'van-row':Row,
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'v-BackTop':BackTop
        },
  
        data() {
            return {
                code:'',
                tukuImg:caise,
                radio:'1',
                homepage:1,
                checkText1:'2024彩色',
                checkText2:'2023彩色',
                loading: false,
                finished: false,
                finishedText:'没有更多了',
                imgListLeft:[],
                imgListRight:[],
                scroll:'',// 为keep-alive加载时调用
            }
        },
        activated() { //.activated 为keep-alive加载时调用
            if(this.scroll > 0){
                window.scrollTo(0, this.scroll);
                this.scroll = 0;
                window.addEventListener('scroll', this.backHandleScroll);
            }
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
        },
        deactivated(){//页面退出时关闭事件 防止其他页面出现问题
            window.removeEventListener('scroll', this.backHandleScroll);
        },
        mounted() {// 为keep-alive加载时调用
                window.addEventListener('scroll', this.backHandleScroll);
        },
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.getImgList()
        },
        methods: {
            // 为keep-alive加载时调用
            backHandleScroll(){
                this.scroll  = document.documentElement && document.documentElement.scrollTop
            },
             onClickLeft(){
                this.$router.push('/') 
             },
             handleChange(color){
                if(color == 2){
                    this.tukuImg = heibai
                    this.checkColor = '1'
                    this.checkText1 = '2024黑白'
                    this.checkText2 = '2023黑白'
                }else{
                    this.tukuImg = caise
                    this.checkColor = '0'
                    this.checkText1 = '2024彩色'
                    this.checkText2 = '2023彩色'
                }
            },
            checkRouterTo(year){
                this.$router.push({                                                     
                    name: 'SearchIndex',             // 路由的名字，地址会隐藏                            
                    params: {
                            color:this.checkColor,
                            year:year                                               
                    }
                })
            },
             load_more() {
                this.homepage += 1;//页数+1
                this.getImgList();
            },
            getImgList(){
                this.loading = true
                let data = {
                    code:this.code 
                }
                this.isShowText = true
                imgList(data,this.homepage).then(res=>{
                    if(res.data.code == 200  && res.data.data.total !=0 ){
                    this.loading = false;
                    let imgList = res.data.data.list
                    for (let i = 0; i < imgList.length; i++) {
                        if(i%2 == 0){
                        this.imgListLeft =  this.imgListLeft.concat(imgList[i])
                        }else if(i%2 != 0){
                        this.imgListRight = this.imgListRight.concat(imgList[i])
                        }
                    }
                    //  console.log(res.data.data.total)
                    if(res.data.data.total == 0){
                        this.isShowText = true
                    }else{
                        this.isShowText = false
                    }

                    if (res.data.data.nextPage === 0) {  //数据全部加载完成
                            this.finished = true;
                            this.finishedText = '没有更多了'
                        }else{
                            this.finished = false;
                        } 
                    }else if(res.data.data.total ==0){
                            this.finished = true;
                            this.finishedText = '没有数据'
                            this.imgListLeft = []
                            this.imgListRight = []
                    }
                }).catch(err =>{
                    console.log(err)
                })
            },
            imgRouterTo(data){
                // console.log(data)
                this.$router.push({                                                     
                name: 'Detail',             // 路由的名字，地址会隐藏                            
                params: {
                        homeImgid:data.id,
                        tittle:data.imgTitle,
                        homeImgTypeId:data.imgTypeId                                                
                }
            })
            },
        },
        }
</script>

<style lang="scss" scoped>
    @import '../style/common.css';
    /deep/ .van-icon-arrow:before{
        font-size: 15px;
    }
    
    .middle-tab{
    position: relative;
    max-width: 600px;
    width: 100%;
    }
    .waterFallWrap{
        margin-top:50px;
    }
    .item-body{
        padding: 5px 20px;
    }
    .item-desc{
        display: flex;
        justify-content: space-between;
        font-size: 15px;
    }
    h4{
        color:rgb(131, 125, 125);
    }
    .image-item{
        border: 1px solid #ccc;
        box-shadow: 0 0 20px #ccc;
        margin: 10px 15px;
    }
    .image-item>div{
        display: inline-block;
        width: 100%;
    }
    .image-item>div>img{
        width: 100%;
        min-height: 2.666667rem;
        background-size: cover;
        vertical-align: middle;

    }

    .caiseyeqian{

        display: flex;
        position: absolute;
        top:40px;
        background-color: #fff;
        margin: 10px -10px;
        width: 100%;
        max-width: 600px;

    }

    .caiseyeqian li{
     padding:0 10px;

    }

    .caiseyeqian li b{
        padding:0px 10px;
        position: relative;
        bottom: 5px;
        font-size: 15px;
    }
    .tuku{
        position: sticky;
        top: 100px;
        display: flex;
        justify-content: space-evenly;
        padding: 0 20px;
        height: 60px;
        line-height: 60px;
        background-color: #fff;
        /* z-index: 10; */
        align-content: stretch;
        flex-direction: column-reverse;
    }
    .caiseyeqian li img{
        width: 22px;
        /* margin: 0px 0px -10px 0px; */
    }
    /deep/.radioBox{
        position: absolute;
    }
    /deep/.van-radio__label{
        font-size: 15px;
    }
</style>