<template>
    <div>
        <van-nav-bar title='我的发布' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:50px;'></div>   <!-- 增加头部头部高度，部面盖住内容 -->
       <div  class="Synthesis">
            <div  v-for="(item,index) in forumSynthesisList" :key="index + 20">
                <div class="forumUser">
                    <img :src="item.avatar" alt="">
                    <p>
                        <span>{{item.userName}}</span>
                        <b>{{item.createTime}}</b>
                    </p>
                </div>
                <div class="forumTxt">
                    <a  @click="goToDetail(item.postId,item.userId)">
                        <h3 class="forumTxtTit">{{item.postTitle}}</h3>
                    </a>
                    <div style="margin-top:10px"  @click="goToDetail(item.postId,item.userId)">
                        <a >
                            <p style="white-space: pre-wrap;" class="formTxtContent overHidden" v-html="item.postContent"></p>
                        </a>
                    </div>
                    <div class="forumImg">
                        <div v-for="(img,k) in item.imgUrls" :key="k + 20" @click="showImg(img.imgUrl)">
                            <img :src="img.imgUrl" alt="">
                        </div>
                    </div>
                    <div class="forumOther">
                        <ul>
                            <li @click="countLikeClick(item)">
                                <img style="width:14px" :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')">
                                <span></span>
                                <b>{{item.likes}}&nbsp;赞</b>
                            </li>
                            <li>
                                <img src="../assets/eyes.png" alt="">
                                <span>{{item.sees}}</span>
                            </li>
                            <li>
                                <img src="../assets/comments.png" alt="">
                                <span></span>
                                <b>{{item.comments}}&nbsp;评论</b>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {forumMyPost,userAddRelation} from '../api/http'
import { NavBar,ImagePreview,Toast} from 'vant';
    export default {
        created(){
            this.getPostList()
        },
        components:{
            'van-nav-bar':NavBar,
            [ImagePreview.Component.name]: ImagePreview.Component,
            
        },
        data() {
            return {
                forumSynthesisList:[],
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/user')
            },
            getPostList(){
                forumMyPost().then(res => {
                    if(res.data.code == 200){
                        this.forumSynthesisList = res.data.data
                    }
                })
            },
            showImg(images){
                ImagePreview({
                    images:[images],
                    showIndex: false,
                    closeable: true,
                });
                
            },
            goToDetail(postId,userId){
                this.$router.push({                                                     
                    name: 'ForumDetail',                                 
                    params: {    
                        myPostId:postId,
                        userId:userId,
                    }
                })
            },
             countLikeClick(item){
                if(this.$store.state.noLogin){
                    Toast.fail("请登录 !")
                }else{
                    // console.log(item)
                    if (item.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:4,
                            otherId:item.postId
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                item.like = true
                                item.likes+=1
                                Toast("已点赞")
                            }
                        })
                    }
                }
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    .Synthesis .forumUser{
        display: flex;
        align-items: center;
        padding: 0 15Px;
    }
    .Synthesis .forumUser img{
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        width: 48px;
        height: 48px;
        -o-object-fit: cover;
        object-fit: cover;
        margin-top: 20px;
    }
    .Synthesis .forumUser p{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }
    .Synthesis .forumUser p span{
        font-size: 15px;
        text-align: left;
        font-weight: bold;
        margin-top: 20px;
    }
    .Synthesis .forumUser p b{
        color: #999;
        font-size: 13px;
    }
    a:active, a:focus, a:hover, a:link, a:visited {
        outline: none;
        background: none;
        text-decoration: none;
    }
    a{
        color: #333;
    }
    .Synthesis .forumTxt{
        width: 100%;
    }
    .Synthesis .forumTxt .forumTxtTit{
        padding: 10Px 15Px;
        border-bottom: 1px solid #eee;
        word-break: break-all;
        font-size: 15px;
        text-align: left;
    }
    .Synthesis .forumTxt>div{
        padding: 0 15Px;
    }
    .Synthesis .forumTxt>div .overHidden{
        overflow: hidden;
    }
    .Synthesis .forumTxt>div .formTxtContent{
        word-break: break-all;
        font-size: 15px;
        text-align: left;
    }
    .Synthesis .forumImg{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 0.4rem;
        margin-top: 15Px;
    }
    .Synthesis .forumImg>div{
        width: 6rem;
        height: 6rem;
    }
    .Synthesis .forumImg>div img{
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        background-color: #f5f5f5;
    }
    .Synthesis .forumOther{
        margin-top: 15Px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #f5f5f5;
    }
    .Synthesis .forumOther ul{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        height: 30px;
    }
    .Synthesis .forumOther ul li{
        display: flex;
        margin: 0 auto;
        align-items: center;
    }
    .Synthesis .forumOther ul li img{
        width: 13px;
        height: 13px;
        vertical-align: middle;
    }
    .Synthesis .forumOther ul li span{
        margin-left:5px;
        font-weight: 400;
        font-size: .32rem;
    }
    .Synthesis .forumOther ul li b{
        margin-left: 5px;
        font-weight: 400;
        font-size:13px;
    }
</style>