<template>
    <div>
        <van-nav-bar title="消息" fixed/>
        <v-footer @showIframe="showIframe($event)"></v-footer>
    </div>
</template>

<script>
import Footer from '../components/footer'
    export default {
        data() {
            return {
                active: 3,
            }
        },
        components:{
            'v-footer':Footer,
        },
        
    }
</script>

<style >
    @import '../style/common.css';
</style>