<template>
    <div>
        <van-nav-bar title="资料大全" fixed left-arrow @click-left="onClickLeft" />
        <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
        <div class="mains">
            <div class="inp">
                <van-field  v-model="fieldValue"  placeholder="搜索相关标题文章" clearable />
                <span @click="clickSearch">搜索</span>
            </div>
            <ul class="dataList">
                <li v-for="(item,i) in dataList" :key="i+10" @click="clickToDetail(item.id,item.articleTypeName)"><span>{{item.articleTypeName}}</span></li>
            </ul>
        </div>
    </div>
</template>

<script>
import {articleType} from '../api/http'
import { NavBar,Icon,Cell,Field,CellGroup } from 'vant';
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
                this.getClassify()
            }else{
                this.code = '71'
                this.getClassify()

            }
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-cell':Cell,
            'van-field':Field,
        },
        data() {
            return {
                  fieldValue: '',
                  code:'',
                  dataList:[]
            }
        },
        methods: {
           onClickLeft() {
                this.$router.push('/')
                // localStorage.removeItem('code');;
            },
            getClassify(){
                let data = {
                   code:this.code,
                   name:this.fieldValue 
                }
                articleType(data).then(res=>{
                    if(res.data.code == 200){
                        this.dataList = res.data.data
                        // console.log(res.data.data)
                    }
                })
            },
            clickSearch(){
                this.getClassify()
            },
            clickToDetail(id,title){
                this.$router.push({                                                     
                    name: 'InfoDetails',             // 路由的名字，地址会隐藏                            
                    params: {
                        id:id,
                        detailTitle:title                                                
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .mains .inp{
        display: flex;
        justify-content: space-between;
        margin: 15Px;
    }
    .mains .inp span{
        border-radius:8px;
        width: 20%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    .mains .dataList{
        margin: 15Px;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 10px;
    }
    .dataList li{
        border: 1px solid #dcdcdc;
        border-radius: 7px;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 15px;
    }
    .dataList li span{
        display: inline-block;
        width: 100%;
        height: 100%;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    li{
        list-style: none;
    }
    /deep/.van-field__body{
        height: 100%;
        display: flex;
        align-items: center;
    }
    /deep/.van-nav-bar .van-icon{
        font-size: 22px;
        color: #fff;
    }
    /deep/.van-cell{
        padding: 0 10px;
        width: 80%;
        height: 40px;
        margin-right:20px;
        background-color: #f5f5f5;
    }
</style>