<template>
    <div>
        <van-nav-bar title='注册' fixed left-arrow @click-left="onClickLeft">
            <template #right>
                <span @click="goToLogin" style="color:#fff;font-size:15px;">登录</span>
            </template>
        </van-nav-bar>
        <div style='width:100%;height:50px;'></div>  <!--增加底部顶部高度，部面盖住内容-->
        <van-cell-group inset>
            <van-field
                v-model="username"
                required
                :formatter="userformatter"
                :error-message="usernameErrorMsg"
                placeholder="请填写用户名"
            />
            <van-field
                v-model="password"
                required
                :formatter="passwordformatter"
                :error-message="passwordErrorMsg"
                placeholder="请填写密码"
            />
            <van-field
                v-model="passwordAgain"
                required
                :formatter="passwordAgainformatter"
                :error-message="passwordAgainErrorMsg"
                placeholder="请再次输入密码"
            />
            <van-field
                v-model="selectMibao"
                required
                readonly
                class="selectMibao"
                :placeholder="mibaoPlaceHolder"
                @click="openPick"
            >
                <template #right-icon>
                    <van-icon name="arrow-down" />
                </template>
            </van-field>
            
            <van-field
                v-model="mibaoAnswer"
                @blur="mibaoformatter"
                :error-message="mibaoErrorMsg"
                required
                placeholder="请填写密保答案"
            />
            <!-- <van-field
                v-model="yaoqingCode"
                placeholder="请填写邀请码"
            /> -->
            <div @click="handleRegister">
                <span class="btn">注册</span>
            </div>
        </van-cell-group>
        <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                title="选择密保问题" 
                :default-index="0"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import {userQuestionList,userRegister} from '../api/http'
import { mapActions } from 'vuex'
import { NavBar,DropdownMenu, DropdownItem,Popup,Picker,Field, CellGroup,Icon, Toast  } from 'vant';
    export default {
        mounted(){
            
        },
        created(){
            this.getMibaoList()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-dropdown-menu':DropdownMenu,
            'van-dropdown-item':DropdownItem,
            'van-cell-group':CellGroup,
            'van-field':Field,
            'van-popup':Popup,
            'van-picker':Picker,
            'van-icon':Icon,
        },
        data() {
            return {
                username:'',
                password:'',
                passwordAgain:'',
                mibaoAnswer:'', 
                yaoqingCode:'',
                columns:[],
                showPicker:false,
                mibaoPlaceHolder:'请选择密保问题',
                usernameErrorMsg:'',
                passwordErrorMsg:'',
                passwordAgainErrorMsg:'',
                mibaoErrorMsg:'',
                mibaoId:'',
                paramUserName:'',
                paramLoginName:'',
                paramUserId:''
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            goToLogin(){
                this.$router.push({                                                     
                    name: 'Login',                                  
                })
            },
            getMibaoList(){
                userQuestionList().then(res=>{
                    if(res.data.code == 200){
                        for (let i = 0; i < res.data.data.length; i++) {
                            this.columns.push(res.data.data[i].securityQuestion)
                            
                        }
                        console.log(this.columns)

                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            openPick(){
                this.showPicker = true
            },
            onConfirm (value, index){
                this.mibaoPlaceHolder = value
                this.mibaoId = index + 1
                this.showPicker = false;
            },
            userformatter(val){
                let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
                if (!val) return '';
                if (!reg.test(val)) {
                    this.usernameErrorMsg = '账号长度6-12位且必须数字加字母组合';
                } else {
                    this.usernameErrorMsg = '';
                }
                return val;
            },
            passwordformatter(val){
                let reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Z][A-z0-9]{5,11}$/
                 if (!val) return '';
                if (!reg.test(val)) {
                    this.passwordErrorMsg = '密码6-12位且必须首字母大写加小写字母和数字组合';
                } else {
                    this.passwordErrorMsg = '';
                }
                return val;
            },
            passwordAgainformatter(val){
                if (!val) return '';
                if(this.password != val){
                    this.passwordAgainErrorMsg = '两次密码不一致';
                }else{
                    this.passwordAgainErrorMsg = ''
                }
                return val
            },
            mibaoformatter(){
                if (this.mibaoAnswer == ''){
                    this.mibaoErrorMsg = '密保答案不能为空'
                }else{
                    this.mibaoErrorMsg = ''
                };
            },
            handleRegister(){
                if(this.username == ''){
                    Toast('用户名不能为空')
                    return
                }
                else if(this.password == ''){
                    Toast('密码不能为空')
                    return
                }
                else if(this.password != this.passwordAgain){
                    Toast('两次密码不一致')
                    return
                }
                else if(this.mibaoPlaceHolder == '请选择密保问题'){
                    Toast('请选择密保问题')
                    return
                }
                else if(this.mibaoAnswer == ''){
                    Toast('请填写密保答案')
                    return
                }else{
                    let data ={
                        loginName:this.username,
                        password:this.password,
                        questionId:this.mibaoId,
                        questionAnswer:this.mibaoAnswer
                    }
                    userRegister(data).then(res=>{
                        if(res.data.code == 200){
                            //token的值储存到localsortage
                            this.$store.commit('$_setStorage',res.data.data.token)
                            this.$store.commit('setLoginStatus',false)
                            this.paramUserName = res.data.data.userName
                            this.paramLoginName= res.data.data.loginName
                            this.paramUserId = res.data.data.userId
                            this.$store.commit('setUsername',this.paramUserName)
                            this.$store.commit('setPassword',this.password)
                            this.$store.commit('setUserid',this.paramUserId)
                            this.$store.commit('setLogname',this.paramLoginName)
                            this.$router.push({
                                name: `User`,
                            })
                            
                        }else{
                            Toast(res.data.msg)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })

                }
    
            },
            ...mapActions({
                saveUserName: 'handleRegister'
            })
        },
    }
</script>

<style lang='scss' scoped>
    .btn{
        display: inline-block;
        border: 1px solid #ff5000;
        border-radius: 30px;
        width: 85%;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    /deep/.van-nav-bar .van-icon{
        font-size: 22px;
        color: #fff;
    }
    /deep/.van-nav-bar__content{
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/#van-field-4-input::-webkit-input-placeholder{
        color: rgb(77, 76, 76);
    }
    ::v-deepinput::-webkit-input-placeholder {
        color: #726f6fa6 ;
    }
    /deep/.van-cell{
        width: 85%;
        margin: 20px auto;
        border-radius: 8px;
        background-color:rgba(194, 194, 194, 0.123) ;
    }
</style>