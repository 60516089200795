<template>
    <div id="content">
        <van-nav-bar title="六合图库" fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
        <div style="width:100%;height:50px;"></div>
        <div class="galleryMain">
                <div class="galleryList">
                        <van-popover class="btnpover" placement="bottom-start" v-model:show="showPopover" :actions="actions" @select="onSelect" >
                            <template #reference>
                                <div class="popover">{{btnLabel}}</div>
                            </template>
                        </van-popover>
                        <div class="inp">
                            <van-search
                                v-model="value"
                                show-action
                                placeholder="请输入搜索关键词"
                                @search="onSearch"
                                >
                                <template #action>
                                    <div class="searchBtn" @click="onSearch">搜索</div>
                                </template>
                            </van-search>
                        </div>
                </div>
                <van-index-bar  @select="selectIndex">
                    <van-index-anchor v-for="(item,i) in searchInfoList" :key="i+10" :index="i">
                        {{i}}
                        <van-cell v-for="(k,v) in item" :key="v+10" @click="clickToDetail(k.imgTypeName,k.id,k.imgTypeId)">{{k.imgTypeName}}
                            <span class="searchCount">{{k.count}}</span>
                        </van-cell>
                    </van-index-anchor>
                </van-index-bar>
        </div>
    </div>
</template>

<script>
import {imgSearch,imgQsDetails} from '../api/http'
import { NavBar,Icon,Search,Popover,Button,Toast,IndexBar, IndexAnchor,Cell   } from 'vant';
    export default {
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-search':Search,
            'van-popover':Popover,
            'van-button':Button,
            'van-index-bar':IndexBar,
            'van-index-anchor':IndexAnchor,
            'van-cell':Cell,
        },
        mounted() {
            

        },
        created(){
            if(this.$route.params.color == '1'){
                this.btnLabel = '黑白'
                this.searchType = 1

            }else{
                this.btnLabel = '彩色'
                this.searchType = 0

            }
            if(this.$route.params.year){

            }
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            if(this.$route.params.homeImgTypeId){
                this.$store.commit('setDetailId',this.$route.params.homeImgTypeId)
                this.$store.commit('setbackPath','/Newdiscover')
            }
            this.getSearchInfo()
            console.log(this.$route.params.color)
            
            // this.getSearchInfo()
        },
        data() {
            return {
                btnLabel:'彩色',
                value:'',
                code:'',
                showPopover:false,
                actions:[
                    { text: '彩色' },
                    { text: '黑白' },
                ],
                searchType:'0',
                searchInfoList:[],
            }
        },
        computed:{
            backPathName(){
               return this.$store.state.backPathName
            },
        },
        methods: {
            onClickLeft(){
                this.$router.push(this.backPathName)
                // localStorage.removeItem('code');;
            },
            onSelect(action){
                this.btnLabel = action.text
                if(action.text == '黑白'){
                    this.searchType = 1
                }else{
                    this.searchType = 0
                }
                 this.getSearchInfo()
            },
            getSearchInfo(){
                let data = {
                    type:this.searchType,
                    search:this.value,
                    code:this.code,
                }
                imgSearch(data).then(res=>{
                    if(res.data.code == 200){
                        let result = res.data.data
                        // console.log(result)
                        this.searchInfoList = result

                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            selectIndex(index){
                // console.log(index)
            },
            onSearch(){
                this.getSearchInfo()
                // console.log(this.searchInfoList)
                if(this.searchInfoList=[] && this.value!=''){
                    Toast('数据还在来的路上哦~')
                }
            },
            clickToDetail(title,id,imgTypeId){
                this.$router.push({                                                     
                    name: 'Detail',             // 路由的名字，地址会隐藏                            
                    params: {
                        id:id,
                        detailTitle:title,
                        searchImgTypeId:imgTypeId                                                
                    }
                })
                // localStorage.setItem('searchDetailId',id)
                // localStorage.setItem('searchdetailTitle',title)
            }
        },
    }
</script>

<style lang="scss" scoped>
/*PC端*/ 
@media screen and (min-width:700px) {
    /deep/.van-index-bar__sidebar{
        left:0;
        margin-left:560px;
    }
}
/* 移动端 */
@media screen and (max-width:750px) {}
    .searchBtn{
        border-radius: 5px;
        width: 50px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    h4{
        color:#7c7c7c;
    }
    .inp{
        width: 100%;
        padding: 0;
    }
    .galleryList{
        display: flex;
        justify-content:space-around;
        align-items: center;
        margin: 10px;
    }
    .galleryMain{
        width: 100%;
        position: relative;
    }
    .popover{
        border-right: 1px solid #dcdcdc;
        width: 50px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #ff5000;
        font-size: 15px;
        background-color: #f5f5f5;
    }
    .searchCount{
        position: absolute;
        right:0;
        height: 20px;
        line-height: 20px;
        border-radius: 25%;
        width: 50px;
        text-align: center;
        font-size: 15px;
        color: #999;
        background-color: #f5f5f5;
    }
    /deep/.van-index-bar__index{
        padding:2px 0;
    }
    /deep/.van-index-bar{
        text-align: left;
        justify-content:space-between;
    }
    /deep/.van-search{
        padding: 0;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
</style>