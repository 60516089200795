<template>
<div>
      <router-view v-slot="{ Component }">
          <!-- 缓存组件跳转的页面 -->
            <keep-alive>
              <component v-if="$route.meta.keepAlive" :is="Component" :key="checkKeepAlive()"/>
            </keep-alive>
        <!-- 非缓存组件跳转页面 -->
            <component v-if="!$route.meta.keepAlive" :is="Component" />
      </router-view>
</div>
</template>
<script>
import {imgLink,} from './api/http'
  export default {
    mounted () {
      // const script = document.createElement('script')
      // script.src = '"https://s4.cnzz.com/z_stat.php?id=1281020853&web_id=1281020853'
      // script.language = 'JavaScript'
      // document.body.appendChild(script)
      this.getLink()

      
    },
    data() {
        return {
          webTitlle:'',
        }
    },
    created(){
      

    },
    watch: {
      //友盟代码统计
      // '$route' () {
      //   if (window._czc) {
      //     let location = window.location
      //     let contentUrl = location.pathname + location.hash
      //     let refererUrl = '/'
      //     window._czc.push(['_trackPageview', contentUrl, refererUrl])
      //   }
      // }
    },
    methods:{
      checkKeepAlive() {
        if (!this.$route.meta.keepAlive) {
          // console.log('输出',this.$route.name)
          return this.$route.name+Math.random();
        }
      },
      getLink(){
        let data = {
          key:'web-title'//下载链接
        }
        imgLink(data).then(res=>{
          this.webTitlle = res.data.data
        })
      },
    },
  }
</script>
<style lang="scss">
@media screen and (min-width:700px) {
/* pc端 */
    #app{
      margin: auto auto;

    }
    
}
/* 移动端 */
@media screen and (max-width:600px) {
  

}
[title='站长统计'] {
    display: none;
}
.el-tabs__nav{
  display: flex;
  float: none;
}
.el-tabs__item{
  flex:1;
}
#app {
  font-family: 'MicrosoftYaHei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 600px !important;
}
.el-carousel__arrow{
  display: none;
}

a {text-decoration: none}
</style>
