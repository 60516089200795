<template>
    <div>
     <van-nav-bar title="发现" fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
     <div style='width:100%;height:50px;'></div>   <!-- 增加头部头部高度，部面盖住内容 -->
     <div class="disCoverList">
           <div class="disCoverItem" v-for="(item,index) in disCoverList" :key="index+20">
               <div class="disCoverItemLeft" @click="gotoLink(item.jumpUrl)">
                   <a>
                       <img :src="item.url" alt="">
                       <div>
                           <span>{{item.findDescribe}}</span>
                       </div>
                   </a>
               </div>
               <div  @click="gotoLink(item.jumpUrl)">
                   <span ><van-icon name="arrow" /></span>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
import{imgMyFind}from '../api/http'
import { Icon, NavBar,} from 'vant';
    export default {
        created(){
            this.getDiscoverImg()
        },
        data() {
            return {
                disCoverList:[],
            }
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
        },
        methods: {
             onClickLeft(){
                 this.$router.go(-1);
             },
             getDiscoverImg(){
                imgMyFind().then(res => {
                    if(res.data.code == 200){
                        this.disCoverList = res.data.data
                    }
                })
             },
             gotoLink(url){
                 window.location.href = url
             },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../style/common.css';
    /deep/ .van-icon-arrow:before{
        font-size: 15px;
    }
    .disCoverList .disCoverItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid rgba(153, 153, 153, 0.267);

    }
    .disCoverList .disCoverItem .disCoverItemLeft a{
        display: flex;
        align-items: center;
    }
    .disCoverList .disCoverItem .disCoverItemLeft a img{
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: #999;
    }
    .disCoverList .disCoverItem .disCoverItemLeft a div{
        display: flex;
        flex-direction: column;
        margin-left: 10Px;
    }
    .disCoverList .disCoverItem .disCoverItemLeft a div span{
        font-size: 15px;
    }
    .disCoverList .disCoverItem .disCoverItemRight span{
        border-radius: 3px;
        border: 1px solid #ff5000;
        padding: 5px 10px;
        color: #fff;
        background-color: #ff5000;
        font-size: 15px;
    } 
    .disCoverList .disCoverItem .disCoverItemRight span.active{
        border: 1px solid #dcdcdc;
        color: #666;
        background-color: transparent;
    }
</style>