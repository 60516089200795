<template>
    <div>
        <div class="headWrap"> 
            <van-nav-bar title='波色正码冷热图' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
            <div class="headQs">
                <a @click="popWrapShow = true">
                    <span>期数</span>
                    <b>{{Qsnum}}</b>
                </a>
            </div>
        </div>
        <div class="attributionTabs">
            <span @click="handleChange('0')" :class="spanActive == '0'?'tabs_active':''">热图</span>
            <span @click="handleChange('1')" :class="spanActive == '1'?'tabs_active':''">冷图</span>
        </div>
       <div class="headerTxt">
             <h3>
                 当前统计期数
                 <span>{{Qsnum}}</span>
             </h3>
        </div>
        <div v-if="isHot" >
            <div id='echartHot'></div>
        </div>
        <div  v-if="isCold">
            <div id='echartCold'></div>
        </div>
       <van-overlay :show="popWrapShow" @click="popWrapShow = false">
            <div class="popWrap" @click.stop>
                <div>
                    <span>请填写要统计的期数</span>
                    <input type="text" v-model="inputNum" :placeholder="Qsnum">
                </div>
                <span class="btn" @click="handleComfir">确定</span>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);
import {openComNumStatistics} from '../api/http'
import { NavBar, Overlay} from 'vant';
    export default {
        created(){
            
        },
        components:{
            'van-nav-bar':NavBar,
            'van-overlay':Overlay,
        },
        data() {
            return {
                Qsnum:'100',
                popWrapShow:false,
                spanActive:'0',
                inputNum:'',
                isHot:true,
                isCold:false
            }
        },
        mounted() {
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.$nextTick(()=>{
                this.drawLine();
            })
            
        },
        methods: {
            onClickLeft(index) {
                this.$router.push("/");
            },
            handleComfir(){
                this.Qsnum = this.inputNum
                this.popWrapShow = false
                this.drawLine()
                this.drawLineCold()
            },
            handleChange(num){
                this.spanActive = num
                if(num == 0){
                    this.isHot = true
                    this.isCold = false
                    this.$nextTick(()=>{
                        this.drawLine();
                    })
                }else{
                    this.isHot = false
                    this.isCold = true
                    this.$nextTick(()=>{
                        this.drawLineCold();
                    })
                }
            },
            drawLine() {
                 let data = {
                    code:this.code,
                    rows:this.Qsnum,
                    jud:'bs'
                }
                openComNumStatistics(data).then(res => {
                    if(res.data.code == 200){
                        let sxHotData = res.data.data.hot
                        let chartDom = document.getElementById('echartHot');
                        let myChart = echarts.init(chartDom);
                        let option;
                        option = {
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                // 垂直，水平
                                // orient: 'vertical',
                                orient:'horizontal',
                                bottom:'-0',
                                // itemWidth: 24,   // 设置图例图形的宽
                                // itemHeight: 18,  // 设置图例图形的高

                            },
                            avoidLabelOverlap: false,// 避免lable重叠
                            itemStyle: { // 环形图中间加白条
                                borderWidth: 3,
                                borderColor: '#fff'
                            },
                            series: [
                                {
                                type: 'pie',
                                radius: ['30%', '70%'],  // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
                                center: ['50%', '40%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
                                data: sxHotData,
                                label: {                        // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
                                    normal: {
                                        show: true,             // 是否显示标签[ default: false ]
                                        position: 'outside',    // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                                        formatter: '{b}{d}%\n({c}次)   ',  // 标签内容
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                                }
                            ]
                        };
                        option && myChart.setOption(option);
                        myChart.setOption(option, true);
                        window.addEventListener('resize', function() {
                            myChart.resize(); 
                        }
                        );
                    }
                })
            },
            drawLineCold() {
                 let data = {
                    code:this.code,
                    rows:this.Qsnum,
                    jud:'bs'
                }
                openComNumStatistics(data).then(res => {
                    if(res.data.code == 200){
                        let sxColdData = res.data.data.cold
                        let chartDomCold = document.getElementById('echartCold');
                        let myChartCold = echarts.init(chartDomCold);
                        let optionCold;
                        optionCold = {
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                // 垂直，水平
                                // orient: 'vertical',
                                orient:'horizontal',
                                bottom:'-0',
                                // itemWidth: 24,   // 设置图例图形的宽
                                // itemHeight: 18,  // 设置图例图形的高

                            },
                            avoidLabelOverlap: false,// 避免lable重叠
                            itemStyle: { // 环形图中间加白条
                                borderWidth: 3,
                                borderColor: '#fff'
                            },
                            series: [
                                {
                                type: 'pie',
                                radius: ['30%', '70%'],  // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
                                center: ['50%', '40%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
                                data: sxColdData,
                                label: {                        // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
                                    normal: {
                                        show: true,             // 是否显示标签[ default: false ]
                                        position: 'outside',    // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                                        formatter: '{b}{d}%\n({c}次)   ',  // 标签内容
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                                }
                            ]
                        };
                        optionCold && myChartCold.setOption(optionCold);
                        myChartCold.setOption(optionCold, true);
                        window.addEventListener('resize', function() {
                            myChartCold.resize(); 
                        }
                        );
                    }
                })
            }
        },
    }
</script>

<style lang='scss' scoped>
        // pc
@media screen and (min-width:700px) {
    .popWrap{
       width: 50%;
       max-width: 400px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        margin-left: 500px;
        z-index: 10;
    }
}
//移动端
@media screen and (max-width:750px) {
    .popWrap{
       width: 70%;
       max-width: 600px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        right: 10px;
        z-index: 10;
    }
}
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }

    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }       
    .headWrap{
        position: relative;
        width: 100%;
        height: 45px;
        font-size: 15px;
        text-align: center;
        color: #fff;
        background: rgb(174, 115, 252);
    }
    
    .headQs a span{
        font-size: 15px;
        color: #fff;
    }
    .headQs a b{
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px;
    }
    .popWrap{
        position: fixed;
        left: calc(50% - 3.33333rem);
        top: calc(50% - 1.66667rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 150px;
        line-height: 150px;
        background-color: #fff;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .popWrap>div span{
        font-size: 15px;
    }
    .popWrap>div input{
        margin-left: 15px;
        border: 1px solid #c0bcbc;
        border-radius: 5px;
        width: 45px;
        height: 25px;
        text-align: center;
        font-size: 15px;
    }
    .popWrap .btn{
        display: inline-block;
        position: relative;
        bottom: 40px;
        border-radius: 8px;
        width: 100px;
        height: 80px;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        background-color: #ff5000;
    }
    .headerTxt h3{
        position: relative;
        top: 30px;
        margin: 10px 0;
        color: #ff5000;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    #echartHot{
        // position: relative;
        // top: 7rem;
        position: absolute;
        top: 0; 
        left: 0;
        right: 0;
        bottom: 0; 
        margin-top: auto; 
        margin-bottom: auto;
        margin-left: auto; 
        margin-right: auto;
        width: 100%;
        height: 300px;  
    }
    #echartCold{
        // position: relative;
        // top: 7rem;
        position: absolute;
        top: 0; 
        left: 0;
        right: 0;
        bottom: 0; 
        margin-top: auto; 
        margin-bottom: auto;
        margin-left: auto; 
        margin-right: auto;
        width: 100%;
        height: 300px;  
    }
    .attributionTabs{
        display: flex;
        justify-content: space-around;
        width: 80%;
        height: 30px;
        position: relative;
        margin: 0 auto;
        top:25px;
    }
    .attributionTabs span:last-child{
        border-radius: 0 5px 5px 0;
        border-left-width: 0;
    }
    .attributionTabs span:first-child{
        border-radius: 5px 0 0 5px;
    }
    .attributionTabs span{
        cursor: pointer;
        border: 1px solid #ff5000;
        width: 50%;
        height: 33px;
        line-height: 33px;
        text-align: center;
        font-size: 20px;
        color: #ff5000;
        background-color: #fff;
    }
    .attributionTabs span.tabs_active{
        color: #fff;
        background-color: #ff5000;
    }
</style>