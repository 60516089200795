import axios from 'axios'
import config from '../config/index' // 路径配置
import store from '../store/index'; 

// 创建axios 实例
const service = axios.create({
    baseURL: config.baseURL, // api的base_url
    timeout: 10000, // 请求超时时间
})


// request拦截器
service.interceptors.request.use(
    config => {
        if (store.state.token) {
            // console.log(store.state.token);
            config.headers['token'] = store.state.token.replace(/\"/g, "") // 让每个请求携带自定义token 请根据实际情况自行修改
          }
        return config;
    },
    data => {
      tryHideFullScreenLoading()
      return data
    },
    error => {
          //当返回信息为未登录或者登录失效的时候重定向为登录页面
        if (error.response.status == '401') {
          localStorage.clear()
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath } //从哪个页面跳转
          })
        }
        tryHideFullScreenLoading()
        // 请求错误处理
        return Promise.reject(error);
    }
)



export default service