<template>
    <div>
        <div class="headWrap"> 
            <van-nav-bar title='特码两面柱形图' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
            <div class="headQs">
                <a @click="popWrapShow = true">
                    <span>期数</span>
                    <b>{{Qsnum}}</b>
                </a>
            </div>
        </div>

       <div class="headerTxt">
             <h3>
                 当前统计期数
                 <span>{{Qsnum}}</span>
             </h3>
        </div>
  
       <van-overlay :show="popWrapShow" @click="popWrapShow = false">
            <div class="popWrap" @click.stop>
                <div>
                    <span>请填写要统计的期数</span>
                    <input type="text" v-model="inputNum" :placeholder="Qsnum">
                </div>
                <span class="btn" @click="handleComfir">确定</span>
            </div>
        </van-overlay>
        <div id="main"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GridComponent, BarChart, CanvasRenderer]);
import {openTwoSides} from '../api/http'
import { NavBar, Overlay} from 'vant';
    export default {
        created(){
            
        },
        components:{
            'van-nav-bar':NavBar,
            'van-overlay':Overlay,
        },
        data() {
            return {
                Qsnum:'100',
                popWrapShow:false,
                spanActive:'0',
                inputNum:'',
                isHot:true,
                isCold:false
            }
        },
        mounted() {
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.$nextTick(()=>{
                this.drawLine();
            })
            
        },
        methods: {
            onClickLeft(index) {
                this.$router.push("/");
            },
            handleComfir(){
                this.Qsnum = this.inputNum
                this.popWrapShow = false
                this.drawLine()
            },
            drawLine() {
                 let data = {
                    code:this.code,
                    rows:this.Qsnum,
                    type:'spe'
                }
                openTwoSides(data).then(res => {
                    if(res.data.code == 200){
                        let xData = res.data.data.x
                        let yData = res.data.data.y
                        let chartDom = document.getElementById('main');
                        let myChart = echarts.init(chartDom);
                        let option;

                        option = {
                            xAxis: {
                                name:'号码',
                                type: 'category',
                                data: xData
                            },
                            yAxis: {
                                name:'次数',
                                type: 'value'
                            },
                            series: [
                                {
                                data: yData,
                                type: 'bar'
                                }
                            ]
                        };

                        option && myChart.setOption(option);
                        myChart.setOption(option, true);
                            window.addEventListener('resize', function() {
                                myChart.resize(); 
                            }
                        );
                    }
                })
            },
          
       
        },
    }
</script>

<style lang='scss' scoped>
        // pc
@media screen and (min-width:700px) {
    .popWrap{
       width: 50%;
       max-width: 400px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        margin-left: 500px;
        z-index: 10;
    }
}
//移动端
@media screen and (max-width:750px) {
    .popWrap{
       width: 70%;
       max-width: 600px;
    }
    .headQs{
        position: fixed;
        top: 15px;
        right: 10px;
        z-index: 10;
    }
}
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }

    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }       
    .headWrap{
        position: relative;
        width: 100%;
        height: 45px;
        font-size: 15px;
        text-align: center;
        color: #fff;
        background: rgb(174, 115, 252);
    }
    
    .headQs a span{
        font-size: 15px;
        color: #fff;
    }
    .headQs a b{
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px;
    }
    .popWrap{
        position: fixed;
        left: calc(50% - 3.33333rem);
        top: calc(50% - 1.66667rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 150px;
        line-height: 150px;
        background-color: #fff;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .popWrap>div span{
        font-size: 15px;
    }
    .popWrap>div input{
        margin-left: 15px;
        border: 1px solid #c0bcbc;
        border-radius: 5px;
        width: 45px;
        height: 25px;
        text-align: center;
        font-size: 15px;
    }
    .popWrap .btn{
        display: inline-block;
        position: relative;
        bottom: 40px;
        border-radius: 8px;
        width: 100px;
        height: 80px;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        background-color: #ff5000;
    }
    .headerTxt h3{
        position: relative;
        top: 30px;
        margin: 10px 0;
        color: #ff5000;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
    }
    #main{
        position: absolute;
        top: 0; 
        left: 0;
        right: 0;
        bottom: 0; 
        margin-top: auto; 
        margin-bottom: auto;
        margin-left: auto; 
        margin-right: auto;
        width: 100%;
        height: 300px;  
        max-width: 600px;
    }
</style>