<template>
    <div>
        <van-nav-bar title='我的点赞' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:50px;'></div>   <!-- 增加头部脚部高度，部面盖住内容 -->
       <van-tabs v-model="activeName" @click="handleChangeCode" :active="active">
            <van-tab title="资料大全" name="1"></van-tab>
            <van-tab title="六合图库" name="0"></van-tab>
            <van-tab title="幽默猜测" name="3"></van-tab>
        </van-tabs>
        <div class="likeItem"  v-for="(item,index) in myLikeList" :key="index+20">
            <div @click="clickRouterTo(item)">
                <div class="likeItemLeft">
                    <img src="../assets/formula.jpg" alt="">
                    <div>
                        <span class="likeItemName">{{item.title}}</span>
                        <b>{{item.createTime}}</b>
                    </div>
                </div>
                <div class="likeItemRight">
                    <span v-if="item.category=='71'">澳门</span>
                    <span v-if="item.category=='28'">香港</span>
                    <span v-if="item.category=='75'">老澳门</span>
                </div>
            </div>
            <div class="likeItemTxt">
                <a>
                    <p></p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {userMyLike} from '../api/http'
import { NavBar,Tabs,Tab, List } from 'vant';
    export default {
        created(){
            this.getLikeList()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-tabs':Tabs,
            'van-tab':Tab,
            'van-list':List,
        },
        data() {
            return {
                  active: 1,
                  myLikeList:[],
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/user')
            },
            handleChangeCode(name) {
                 this.active = name
                 this.getLikeList()
            },
            getLikeList(){
                let data = {
                    type:0,//点赞
                    moduleType:this.active
                }
                userMyLike(data).then(res=>{
                    if(res.data.code == 200 && res.data.data != []){
                        this.myLikeList = res.data.data
                    }
                })
            },
            clickRouterTo(data){
                if(this.active == 0){
                    this.$router.push({                                                     
                        name: 'Detail',                                    
                        params: {
                                myLikeId:data.id,
                                myLikeTitle:data.title,
                        }
                    })
                }else if(this.active == 1){
                    this.$router.push({                                                     
                        name: 'ArticleDetails',                             
                        params: {
                            myLikeId:data.id,
                        }
                    })
                    localStorage.setItem('infoId',data.id)
                }else if(this.active == 3){
                    this.$router.push({                                                     
                        name: 'Guess',                             
                        params: {
                            myLikeId:data.id,
                        }
                    })
                }
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    /deep/.van-tabs__line{
        background-color:rgb(248, 99, 13);
        bottom: 25px;
        width: 70px;
    }
    .likeItem{
        border-bottom: 1px solid #eee;
        margin: 0 10Px;
        padding: 20px 0;
    }
    .likeItem>div{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .likeItem>div .likeItemLeft{
        display: flex;
        align-items: center;
    }
    .likeItem>div .likeItemLeft img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .likeItem>div .likeItemLeft div{
        display: flex;
        flex-direction: column;
        margin-left: 10Px;
    }
    .likeItem>div .likeItemLeft div span{
        text-align: left;
        font-size: 15px;
    }
    .likeItem>div .likeItemLeft div b{
        color: #999;
        font-size: 13px;
        text-align: left;
    }
    .likeItem>div .likeItemRight span{
        border-radius: 20px;
        padding: 1px 8px;
        font-size: 13px;
        color: #fff;
        background-color: #ff5000;
        display: inline-block;
        width: 30px;
    }
    .likeItemTxt a p{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 5Px;
        width: 100%;
        font-size: 15px;
    }
</style>