<template>
    <div>
        <div class="fixTop">
            <van-nav-bar :title='detailTitle' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
            <div style='width:100%;height:40px;'></div>  <!--增加头部高度，避免盖住内容-->
            <van-swipe class="my-swipe" :autoplay="3000" >
                <van-swipe-item v-for="(image,i) in carouselImg" :key="i+10">
                        <img :src="image.url" @click="gotoLink(image.jumpUrl)"/>
                </van-swipe-item>
            </van-swipe>
            <div class="galleryYears">
                <span @click="changeActive(0,'2024')" :class="yearActive == '0'?'tabs_active':'' ">2024年</span>
                <span @click="changeActive(1,'2023')" :class="yearActive == '1'?'tabs_active':'' ">2023年</span>
            </div>
            <div class="galleryQishu" id="scrollBox">
                <van-row justify="space-between"  flex>
                    <van-col span="19" class=" do-jump">
                        <div class="tabsPeriod">
                            <div class="tabsPeriodBd">
                                <span v-for="(item,i) in qishuList" :key="i+10" 
                                :class="qishuActive == i?'qishuAct':'' " :id="bindId(i)" @click="changeQishu(i,item.name,item.id)">{{item.name}}</span>
                            </div>
                        </div>
                    </van-col>
                    <van-col span="5">
                        <van-dropdown-menu active-color="#1989fa">
                            <van-dropdown-item ref="dropdownMenu" :title="defaultQishuName">
                                <div class="popup" v-for="(k,b) in qishuList" :key="b+10">
                                    <span :class="this.itemID == k.id?'popAct':'' " @click="selectPopQishu(k.id,k.name,b)">{{k.name}}</span>
                                </div>
                            </van-dropdown-item>
                        </van-dropdown-menu>
                    </van-col>
                </van-row>
            </div>
        </div>
        
        <div class="galerryInfo">
            <div class="galerryImg" @click="showImg(images)">
                <div>
                    <img :src="gallerryImg" >
                </div>
            </div>
        </div>

        <!-- <div @click="goToLink" class="downImg">
            <img src="../assets/down_app.png" style='max-width:600px;width:95%;margin-top:10px' alt="">
        </div> -->
        <div class="galerryOther">
            <ul>
                <li>
                    <van-icon name="chat-o" />
                    <span>{{totleComments}}</span>
                    <b>评论</b>
                </li>
                <li @click="countLikeClick">
                    <img style="width:14px" :src="this.totleCountItem.like?require('../assets/like2.png'):require('../assets/like1.png')">
                    <span>{{totleLikes}}</span>
                    <b>点赞</b>
                </li>
                <li @click="countCollectClick">
                    <img style="width:14px" :src="this.totleCountItem.collect?require('../assets/collect.png'):require('../assets/nocollect.png')">
                    <span>{{totleCollects}}</span>
                    <b>收藏</b>
                </li>
                <li>
                    <van-icon name="share-o" />
                </li>
                <li>
                    <van-icon name="eye-o" />
                    <span>{{totleSees}}</span>
                </li>
            </ul>
        </div>
        <div class="homeLotery">
            <div class='loteryTittle'>第{{theNewestDate}}期最新开奖结果 <span class="toRecord" @click="goToRoute()" >开奖记录></span></div>
            <div class='loteryNums' v-for="(item,i) in newLoteryList" :key="i+10" >
                <img :src="require(`../assets/${item[0]}.png`)" >
                <div class="loteryScribe">
                <span>{{item[1]}}/{{item[2]}}</span>
                </div>
            </div>  
            <h2 class="jiahao">+</h2>
            <div class="drawInfo"><span>第{{theNextDate}}期 {{nextPrizeTime}} {{weekTxt}}</span></div>
        </div>
        <h3 v-if="noHotComment" class="commentListTit">热门评论</h3>
        <!-- <van-empty v-if="noHotComment" :image="require('../assets/noComments.png')" description="暂无评论" /> -->
        <div class="commentBox">
            <van-cell class="van-clearfix" v-for="(k,b) in commentHotList" :key="b + 20"  >
                <div class="comment">
                    <div>
                        <div class="commentLeft">
                            <img :src="k.avatar" alt="">
                        </div>
                        <div class="commentRight">
                            <p>
                                <span>{{k.userName}}</span>
                                <b @click="clicklike(k,k.id,b)">
                                    <img :src="k.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                    <i>{{k.commentLikes}}</i>
                                </b>
                            </p>
                            <div @click="pushRelations(k,k.id,k.userId)" class="commentHidden" style="margin-top:5px">
                                <p v-html="k.commentContent"></p>
                                <span class="commentTime">{{k.spacing}}</span>
                            </div>
                            <div v-show="k.index==b" v-for="(a,s) in k.commentMoreList" :key="s+20">
                                    <div class="commentDetail"  v-show="s<k.showNum">
                                        <div class="commentLeft">
                                            <img :src="a.avatar" alt="">
                                        </div>
                                        <div class=CommentRight>
                                            <p>
                                                <span>{{a.userName}}</span>
                                                <b @click="clicklike(a,a.id,b)">
                                                    <img :src="a.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                                    <i>{{a.commentLikes}}</i>
                                                </b>
                                            </p>
                                            <div @click="pushRelations(a,k.id,a.userId)" class="commentHidden">
                                                <b>
                                                    回复:&nbsp;
                                                    <span>{{a.replyName}}</span>
                                                </b>
                                                <p v-html="a.commentContent"></p>
                                                <span class="commentTime">{{a.spacing}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div  v-if="k.reply > 0" class="commentMore">
                                <span id="btn" @click="showMoreReply(k,k.id,b)" v-if="!k.dropDown">——显示评论</span>
                                <span @click="noShowMoreReply(k,k.id,b)" v-if="k.dropDown">——收起</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-cell>
        </div>
        <h3 class="commentListTit">最新评论</h3>
        <div class="commentBox">
            <van-list
                v-model:loading="loading"
                :finished="finished"
                :finished-text="finishedText"
                @load="onLoad"
                :immediate-check='false'
                >
                <van-cell class="van-clearfix"  >
                    <div class="comment" > 
                        <div  v-for="(item,index) in commentList" :key="index + 20">
                            <div class="commentLeft">
                                <img :src="item.avatar" alt="">
                            </div>
                            <div class="commentRight">
                                <p>
                                    <span>{{item.userName}}</span>
                                    <b @click="clicklike(item,item.id,index)">
                                        <img :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                        <i>{{item.commentLikes}}</i>
                                    </b>
                                </p>
                                <div @click="pushRelations(item,item.id,item.userId,index)" class="commentHidden" style="margin-top:5px">
                                    <p v-html="item.commentContent"></p>
                                    <span class="commentTime">{{item.spacing}}</span>
                                </div>
                                <div v-show="item.index==index" v-for="(o,p) in item.commentMoreList" :key="p+20">
                                    <div class="commentDetail"  v-show="p<item.showNum">
                                        <div class="commentLeft">
                                            <img :src="o.avatar" alt="">
                                        </div>
                                        <div class=CommentRight>
                                            <p>
                                                <span>{{o.userName}}</span>
                                                <b @click="clicklike(o,o.id,index)">
                                                    <img :src="o.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                                    <i>{{o.commentLikes}}</i>
                                                </b>
                                            </p>
                                            <div @click="pushRelations(o,item.id,o.userId,p)" class="commentHidden">
                                                <b>
                                                    回复:&nbsp;
                                                    <span>{{o.replyName}}</span>
                                                </b>
                                                <p v-html="o.commentContent"></p>
                                                <span class="commentTime">{{o.spacing}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div  v-if="item.reply > 0" class="commentMore">
                                    <span id="btn" @click="showMoreReply(item,item.id,index)" v-if="!item.dropDown">——显示评论</span>
                                    <span @click="noShowMoreReply(item,item.id,index)" v-if="item.dropDown">——收起</span>
                                </div>
                            </div> 
                        </div>
                    </div>
                </van-cell>
            </van-list>
            <van-empty v-if="noComment" :image="require('../assets/noComments.png')" description="暂无评论" />
            <div class="inp" @click="gotoSetRelations">
                <van-field class="realtaionCell" readonly placeholder="想说点什么"  />
                <span >评论</span>
            </div>
        </div>
 
        <div style='width:100%;height:50px;'></div>  <!--增加脚部高度，避免盖住内容-->
        <van-popup v-model:show="putRelations" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='发表评论' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="relationBox">
                <div style="text-align:left;position:relative;left:5%;margin:10px 0;">评论内容</div>
                <van-cell-group inset>
                    <van-field
                        v-model="relationsMessage"
                        rows="5"
                        autosize
                        clearable
                        class="textArea"
                        height='100px'
                        type="textarea"
                        maxlength="120"
                        placeholder="请输入评论内容"
                        show-word-limit
                    />
                </van-cell-group>
                <div @click="completeRelation">
                    <span class="btn">完成</span>
                </div>
            </div>
        </van-popup>
        
        <van-overlay :show="loginsShow" @click="loginsShow = false">
            <div class="loginWrap" @click.stop>
                <div class="top">
                    <img src="../assets/loginTop.png" alt="">
                    <div class="logo">
                        <img src="../assets/userAvatar.png" alt="">
                    </div>
                </div>
                <div class="common">
                    <van-field
                        v-model="username"
                        required
                        class="loginInput"
                        clearable
                        placeholder="请输入用户名"
                    />
                    <van-field
                        v-model="password"
                        required
                        class="loginInput"
                        :type="passwordType"
                        placeholder="请输入密码"
                    >
                        <template #right-icon>
                            <span class="solts" @click="switchPasswordType">
                                <van-icon class="eyes" name="eye" v-if="passwordType==='password'"/>
                                <van-icon class="eyes" name="closed-eye" v-else />
                            </span>
                        </template>
                    </van-field>
                    <div class="verifyFiled">
                        <van-field
                                v-model="verifyCode"
                                required
                                clearable
                                class="verifyInput loginInput"
                                placeholder="请输入验证码"
                            >
                            <template #right-icon>
                                <div class="verify-box" @click="refreshCode">
                                    <Sidentify :identifyCode="identifyCode"></Sidentify>
                                </div>
                            </template>
                        </van-field>
                    </div>
                    <div @click="handleLogin">
                        <span class="btn">登录</span>
                    </div>
                    <div class="bottomTxt">
                        <div  class="bottomOther"><a><span> <a href="#/forget">忘记密码</a>  </span></a></div>
                        <div @click="gotoRegister" class="bottomOther"><a><span>立即注册</span></a></div>
                    </div>
                </div>
                <div class="close" @click="loginsShow = false">
                    <img src="../assets/closeLogin.png" alt="">
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script> 
import Sidentify from '../components/identify.vue' 
import { NavBar,Swipe,SwipeItem,Col,Row,DropdownMenu,
DropdownItem,ImagePreview ,List ,Cell ,Icon, Toast, Empty, Field, Popup, 
CellGroup, Overlay} from 'vant';
import {imgQs,imgQsDetails,openLatest,imgCarousel,commentList,
userAddRelation,userLogin,commentAdd,userCount,commentReplyList,imgLink} from '../api/http'
    export default {
        created(){
            if(this.$route.params.tittle){
                this.$store.commit('setDetailTitle',this.$route.params.tittle)
                
            }else if(this.$route.params.detailTitle){
                this.$store.commit('setDetailTitle',this.$route.params.detailTitle)
            }else if(this.$route.params.myLikeTitle){
                this.$store.commit('setDetailTitle',this.$route.params.myLikeTitle)
            }else if(this.$route.params.myCommentTitle){
                this.$store.commit('setDetailTitle',this.$route.params.myCommentTitle)
            }
            if(this.$route.params.homeImgTypeId){
                this.$store.commit('setImgTypeId',this.$route.params.homeImgTypeId)
                
            }else if(this.$route.params.searchImgTypeId){
                this.$store.commit('setImgTypeId',this.$route.params.searchImgTypeId)
            }
            if(this.$route.params.homeImgTypeId){
                this.$store.commit('setDetailId',this.$route.params.homeImgTypeId)
                this.$store.commit('setbackPath','/Newdiscover')
            }else if(this.$route.params.id){
                this.$store.commit('setDetailId',this.$route.params.id)
                this.$store.commit('setbackPath','/searchIndex')
            }else if(this.$route.params.myLikeId){
                this.$store.commit('setDetailId',this.$route.params.myLikeId)
                this.$store.commit('setbackPath','/MyLike')
            }else if(this.$route.params.myCollectId){
                this.$store.commit('setDetailId',this.$route.params.myCollectId)
                this.$store.commit('setbackPath','/MyCollect')
            }else if(this.$route.params.myCommentId){
                this.$store.commit('setDetailId',this.$route.params.myCommentId)
                this.$store.commit('setbackPath','/MyComments')
            }else if(this.$route.params.myCommentId){
                this.$store.commit('setDetailId',this.$route.params.myCommentId)
                this.$store.commit('setbackPath','/MyComments')
            }
            if(this.$route.params.searchDetailId){
                this.$store.commit('setDetailId',this.$route.params.searchDetailId)
            }
            
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            // this.getSearchInfo()

            this.getQishu()
            this.getNewestLotery()
            this.getCarousel()
             
            this.refreshCode()
            this.getLink()
            
        },
        // mounted() {
        //      window.addEventListener('scroll', this.handleScroll);
        //      window.addEventListener('scroll', this.backHandleScroll);
        // },
        // destroyed() {
        //     window.removeEventListener('scroll', this.handleScroll)
        // },
        // activated() { //.activated 为keep-alive加载时调用
        //     if(this.scroll > 0){
        //         window.scrollTo(0, this.scroll);
        //         this.scroll = 0;
        //         window.addEventListener('scroll', this.backHandleScroll);
        //     }
        //     // if(localStorage.getItem('code')){
        //     //     this.code = localStorage.getItem('code')
        //     // }else{
        //     //     this.code = '71'
        //     // }
        //     if(localStorage.getItem('code')){
        //         this.code = localStorage.getItem('code')
        //     }else{
        //         this.code = '71'
        //     }
        // },
        // deactivated(){//页面退出时关闭事件 防止其他页面出现问题
        //     window.removeEventListener('scroll', this.backHandleScroll);
        // }, 
        computed:{
            detailId(){
               return this.$store.state.detailId
            },
            detailTitle(){
               return this.$store.state.detailTitle
            },
            imgTypeId(){
               return this.$store.state.imgTypeId
            },
            backPathName(){
               return this.$store.state.backPathName
            },
        },
        components:{
            'van-nav-bar':NavBar,
            'van-swipe':Swipe,
            'van-swipe-item':SwipeItem,
            'van-row':Row,
            'van-col':Col,
            'van-dropdown-menu':DropdownMenu,
            'van-dropdown-item':DropdownItem,
            'van-list':List,
            'van-cell':Cell,
            [ImagePreview.Component.name]: ImagePreview.Component,
            'van-icon':Icon,
            'van-empty':Empty,
            'van-field':Field,
            'van-popup':Popup,
            'van-cell-group':CellGroup,
            'van-overlay':Overlay,
            Sidentify
        },
        data() {
            return {
                  yearActive: 0,
                  qishuActive:0,
                  defaultQishuName:'',
                  spanYear: '2024',
                  code:'',
                  itemID:'',
                  qishuList:[],
                  value:0,
                  dropdownMenu:false,
                  popQishuName:'',
                  popQishuId:'',
                  imgShow:false,
                  gallerryImg:'',
                  images:[''],
                  theNewestDate:'',
                  theNextDate:'',
                  nextPrizeTime:'',
                  weekTxt:'',
                  newLoteryList:[],
                  prizeTime:'',
                  carouselImg:[],
                  page:1,
                  loading:false,
                  finished:true,
                  finishedText:'没有更多了',
                  commentList:[],
                  commentHotList:[],
                  num: '',//点赞数
                  commentLikes:'',
                  noComment:false,
                  noHotComment:false,
                  putRelations:false,
                  putparentRelations:true,
                  relationsMessage:'',
                  parentrelationsMessage:'',
                  loginsShow:false,
                  username:'',
                  password:'',
                  passwordErrorMsg:'',
                  verifyCodeErrorMsg:'',
                  usernameErrorMsg:'',
                  passwordType: 'password',//输入框类型,
                  identifyCode:'',
                  verifyCode:'',
                  totleCollects:'',
                  totleComments:'',
                  totleLikes:'',
                  totleSees:'',
                  parentId:'',
                  replyId:'',
                  isReply:'',
                  commenMoreText:'',
                  commentMorePage:1,
                  commentMoreList:[],
                  commentMoreId:'',
                  hasReply:true,
                  moreReply:false,
                  noMoreReply:false,
                  commentListTotal:'',
                  commentId:[],
                  next:Boolean,
                  clickTime:0,
                  isShowMore: true,
                  showNum: 0,
                  ishotShowActive:Boolean,
                  totleCountItem:[],
                  gallerryId:'',
                  downloadLink:'',
                  scroll:'',
                //   detailId:this.detailId,
                //   detailTitle:this.detailTitle
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push(this.backPathName)
                // localStorage.removeItem('code');;
                // localStorage.removeItem('homeDetailTitle');
                // localStorage.removeItem('homeDetailId');
                // localStorage.removeItem('searchdetailTitle');
                // localStorage.removeItem('searchDetailId');
                localStorage.removeItem('detailId');
                localStorage.removeItem('detailTitle');
                localStorage.removeItem('imgTypeId');
            },
            changeActive(num,year){
                this.yearActive = num
                this.spanYear = year
                this.getDetailInfo()
                this.getQishu()
            },
            randomNum(min,max){
                return Math.floor(Math.random() * (max - min) + min)
            },
            refreshCode(){
                this.makeCode()
                // console.log('当前验证码',this.identifyCode)
            },
            makeCode(o,l){
                this.identifyCode = String(this.randomNum(2345,9999))
            },
            switchPasswordType() {
                this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
            },
            getQishu(){
                let data = {
                    typeId:this.detailId,
                    code:this.code,
                    year:this.spanYear
                }
                imgQs(data).then(res=>{
                    // console.log(res)
                    if(res.data.code == 200){
                        // console.log(res.data.data)
                        this.qishuList = res.data.data
                        for (let i = 0; i < this.qishuList.length; i++) {
                            this.defaultQishuName = this.qishuList[0].name
                            this.itemID = this.qishuList[0].id
                        }
                    this.getDetailInfo()
                    this.getCommentList() 
                    this.getTotleInfo() 
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            getDetailInfo(){
                let data = {
                    id:this.itemID,
                    code:this.code,
                    year:this.spanYear
                }
                imgQsDetails(data).then(res=>{
                    // console.log(res)
                    if(res.data.code == 200){
                        // console.log(res.data.data)
                        this.gallerryImg = res.data.data.imgUrl
                        this.images = res.data.data.imgUrl
                        this.gallerryId = res.data.data.id
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            changeQishu(index,name,id){
                this.defaultQishuName = name
                this.qishuActive = index
                this.itemID = id
                this.$store.commit('setDetailId',id)
                this.getDetailInfo()
                this.getCommentList() 
                this.getTotleInfo() 
            },
            selectPopQishu(id,name,index){
                this.popQishuName = name
                this.defaultQishuName = name
                this.popQishuId = id
                this.itemID = id
                this.$store.commit('setDetailId',id)
                this.qishuActive = index
                const selector = "#anchor"+index
                // console.log(document.querySelector(selector).scrollIntoView)
                document.querySelector(selector).scrollIntoView({
                    block:'end',
                    behavior: "smooth"
                });
                this.$refs.dropdownMenu.toggle(false)
                this.getDetailInfo()
                this.getCommentList() 
                this.getTotleInfo() 
                // console.log(this.defaultQishuName)
            },
            showImg(images){
                ImagePreview({
                    images:[images],
                    showIndex: true,
                    closeable: true,
                });
                
            },
            bindId(i){
                return "anchor" + i
            },
            getNewestLotery(){
                let data = {
                    code : this.code
                }
                openLatest(data).then(res =>{
                    if(res.data.code == 200){
                        this.newLoteryList = res.data.data.prize
                        // console.log(this.newLoteryList)
                        // 截取字段最后三位字符串
                        this.theNewestDate = res.data.data.issueNo.substring(res.data.data.issueNo.length-3)
                        this.theNextDate = res.data.data.nextIssueNo.substring(res.data.data.issueNo.length-3)
                        this.nextPrizeTime = res.data.data.nextPrizeTime
                        this.weekTxt = res.data.data.dayOfTheWeek
                        this.prizeTime = res.data.data.prizeTimeForHome
                        
                        // console.log(res)
                        // 计算星期几
                        var dateArray = this.prizeTime.substring(0,10).split("-"), date;
                        date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
                        let week = date.getDay()
                        switch (week) {
                            case 0:
                                this.weekText = '星期日'
                            break;
                            case 1:
                                this.weekText = '星期一'
                            break;
                            case 2:
                                this.weekText = '星期二'
                            break;
                            case 3:
                                this.weekText = '星期三'
                            break;
                            case 4:
                                this.weekText = '星期四'
                            break;
                            case 5:
                                this.weekText = '星期五'
                            break;
                            case 6:
                                this.weekText = '星期六'
                            break;
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            },
            goToRoute(){
                this.$router.push({
                    name: `Record`,
                    params:{
                        searchDetailId:this.detailId,
                        searchDetailTitle:this.detailTitle,
                    }
                })
            },
            getCarousel(){
                let data = {
                    type:1
                }
                imgCarousel(data).then(res=>{
                    if(res.data.code == 200){
                    // console.log(res.data.data)
                    
                    this.carouselImg = res.data.data
                    }
                }).catch(err =>{
                    console.log(err)
                })
            },
            onLoad(){
                this.page += 1;//页数+1
                this.getCommentList();
            },
            getCommentList(){
                this.loading = true;
                let data = {
                    moduleType:0,
                    needCommentId:this.itemID,
                }
                commentList(data,this.page).then(res=>{
                    if(res.data.code == 200 && res.data.data.common.total !=0){
                        this.commentListTotal = res.data.data.common.total
                        // console.log(res.data.data.hot)
                        if(res.data.data.common.pageNum == 1){
                            this.commentList = res.data.data.common.list
                        }else{
                            this.commentList = this.commentList.concat(res.data.data.common.list)

                        }
                        if (res.data.data.common.nextPage == 0) {  //数据全部加载完成
                            this.finished = true;
                            this.finishedText = '没有更多了'
                        }else{
                            this.finished = false;
                        }       
                        this.loading = false;
                        this.noComment = false;
                    }
                    if(res.data.data.hot == undefined || res.data.data.hot.length == 0){
                        this.noHotComment = false
                        this.commentHotList = []
                    }else{
                        this.commentHotList = []
                        this.commentHotList = res.data.data.hot
                        this.noHotComment = true;
                    }
                    if(res.data.data.common.total == 0){
                        this.finished = true;
                        this.noComment = true;
                        this.finishedText = ''
                        this.commentList = []
                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            clicklike(item,id,index) {
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (item.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:0,
                            otherId:id
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                if(item.commentParentId == 0){
                                    item.like = true
                                    item.commentLikes+=1
                                }
                                Toast("已点赞")
                            }
                        })
                    }
                }
                
            },
            gotoSetRelations(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    this.putRelations = true
                }
            },
            setClickLeft(){
                this.putRelations = false
            },
            completeRelation(){
                if(this.parent != ''){
                    let data = {
                        moduleType:0,
                        needCommentId:this.detailId,
                        content:this.relationsMessage.split("\n").join("<br />"),
                        parentId:this.parentId,
                        replyUserId:this.replyId
                    }
                    commentAdd(data).then(res=>{
                        if(this.relationsMessage == ''){
                            Toast('请输入内容！')
                        }else{
                            if(res.data.code == 200){
                                // var btn = document.getElementById("btn");
                                setTimeout(()=>{
                                    this.getCommentList()
                                },500)
                                this.putRelations = false
                                Toast(res.data.msg)
                                this.relationsMessage = ''
                                this.parentId = ''
                                this.getCommentList()
                                this.getTotleInfo()
                            }else{
                                Toast(res.data.msg)
                                this.putRelations = false
                                this.relationsMessage = ''
                                this.parentId = ''
                            }
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }else{
                    let data = {
                        moduleType:0,
                        needCommentId:this.detailId,
                        content:this.relationsMessage,
                    }
                    commentAdd(data).then(res=>{
                        if(this.relationsMessage == ''){
                            Toast('请输入内容！')
                        }else{
                            if(res.data.code == 200){
                                this.getCommentList()
                                this.putRelations = false
                                Toast(res.data.msg)
                                this.relationsMessage = ''
                            }else{
                                Toast(res.data.msg)
                            }
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }
                
            },
            handleLogin(){
                // console.log(this.verifyCode)
                if(this.username == ''){
                    this.usernameErrorMsg = '用户名不能为空'
                }else if(this.username != ''){
                    this.usernameErrorMsg = ''
                }
                if(this.verifyCode == ''){
                    this.verifyCodeErrorMsg = '请输入验证码'
                }else if(this.verifyCode != ''){
                    this.verifyCodeErrorMsg = ''
                }
                if(this.password == ''){
                    this.passwordErrorMsg = '请输入密码'
                }else if(this.password != ''){
                    this.passwordErrorMsg = ''
                }
                if(this.verifyCode != this.identifyCode){
                    Toast('验证码不正确')
                    this.refreshCode()
                }else{
                    let data = {
                        loginName:this.username,
                        password:this.password
                    }
                    userLogin(data).then(res=>{
                        if(res.data.code == 200){
                            this.$store.commit('$_setStorage',res.data.data.token)
                            this.$store.commit('setUsername',res.data.data.userName)
                            this.$store.commit('setUserid',res.data.data.userId)
                            this.$store.commit('setAvatar',res.data.data.avatar)
                            this.$store.commit('setPassword',this.password)
                            this.$store.commit('setLogname',res.data.data.loginName)
                            this.$store.commit('setLoginStatus',false)
                            Toast('登录成功')
                            this.$router.push(this.$route.path) //跳转到当前页面
                            this.loginsShow=false
                        }else{
                            this.refreshCode()
                            Toast(res.data.msg)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })

                }
                

            },
            gotoRegister(){
                this.$router.push({
                    name: `Register`,
                })
            },
            getTotleInfo(){
                let data = {
                    moduleType:0,
                    otherId:this.itemID,
                }
                userCount(data).then(res=>{
                    if(res.data.code == 200){
                        this.totleCollects = res.data.data.collects
                        this.totleComments = res.data.data.comments
                        this.totleLikes = res.data.data.likes 
                        this.totleSees = res.data.data.sees
                        this.totleCountItem = res.data.data

                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            pushRelations(item,parentId,replyId,index){
                // console.log(item)
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    this.putRelations = true
                    this.parentId = parentId
                    this.replyId = replyId
                }
            },
            showMoreReply(item,id,index){
                let data = {
                    id:id
                }
                commentReplyList(data,this.commentMorePage).then(res=>{
                    item.commentMoreList = res.data.data
                    item.dropDown = true;
                    item.index = index
                    item.showNum = item.commentMoreList.length;
                }).catch(err=>{
                    console.log(err)
                })
            },
            noShowMoreReply(item,id,index){
                     let data = {
                        id:id
                    }
                commentReplyList(data,this.commentMorePage).then(res=>{
                    item.dropDown = false;
                    item.showNum = 0;
                }).catch(err=>{
                    console.log(err)
                })
            },
            countCollectClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.collect) {
                        Toast("已经收藏过了哦~")
                    } else {
                        let data = {
                            type:1,
                            moduleType:0,
                            otherId:this.gallerryId
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.collect = true
                                this.totleCollects+=1
                                Toast("已收藏")
                            }
                        })
                    }
                }
            },
            countLikeClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:0,
                            otherId:this.gallerryId
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.like = true
                                this.totleLikes+=1
                                Toast("已点赞")
                            }
                        })
                    }
                }
            },
            gotoLink(url){
                window.location.href = url
            },
            getLink(){
                let data = {
                    key:'download-link'//下载链接
                }
                imgLink(data).then(res=>{
                    this.downloadLink = res.data.data
                })
            },
            goToLink(){
                window.location.href = this.downloadLink
            },
            // backHandleScroll(){
            //     this.scroll  = document.documentElement && document.documentElement.scrollTop
            // },  
        }
    }
</script>

<style lang='scss' scoped>
//PC端
@media screen and (min-width:700px) {
    .homeLotery{
      position: relative;
      background: url(../assets/kuang.png) no-repeat;
      background-size: 100% 100%;
      height: 160px;
      margin:10px 10px;
      justify-content: center;
      display: flex;
    }
    .loteryTittle{
        display: flex;
        padding-top: 20px;
        position: absolute;
        font-size: 15px;
    }
    .toRecord{
      padding-left: 10px;
      color:rgb(255, 82, 2);
    }
    .loteryNums{
      position: relative;
      top:60px;
      width: 10%;
      margin:5px 5px;
    }
    .loteryNums img{
      vertical-align: middle;
      width: 80%;
      position: relative;
      bottom: 5px;
    }
    .loteryNums:nth-child(8){
      padding-left: 45px;
    }
    .jiahao{
      position: absolute;
      right: 110px;
      top:63px;
      font-size: 20px;
    }
    .loteryScribe{
      text-align: center;
      padding-top: 2px;
      display: block;
      font-size: 13px;
    }
    .drawInfo{
      color: #999;
      position: absolute;
      bottom: 5px;
      font-size: 13px;
    }
    .my-swipe .van-swipe-item img{
        width: 100%;
        height: 200px;
    }
}
//移动端
@media screen and (max-width:750px) {
    .homeLotery{
        position: relative;
        background: url(../assets/kuang.png) no-repeat;
        background-size: 100% 100%;
        height: 160px;
        margin:10px 10px;
        justify-content: center;
        display: flex;
    }
    .loteryTittle{
        display: flex;
        padding-top: 20px;
        position: absolute;
        font-size: 15px;
    }
    .toRecord{
        padding-left: 10px;
        color:rgb(255, 82, 2);
    }
    .loteryNums{
        /* display: flex; */
        /* justify-content: space-around;
        align-items: center;
        padding:8px 8px; */
        position: relative;
        top:60px;
        width: 10%;
        margin:0 5px;
    }
    .loteryNums img{
        vertical-align: middle;
        width: 100%;
        position: relative;
        bottom: 5px;
    }
    .loteryNums:nth-child(8){
        padding-left: 45px;
    }
    .jiahao{
        position: absolute;
        right: 60px;
        top:53px;
        font-size: 20px;
    }
    .loteryScribe{
        text-align: center;
        padding-top: 7px;
        display: block;
        font-size: 13px;
    }
    .drawInfo{
        color: #999;
        position: absolute;
        bottom: 10px;
        font-size: 13px;
    }
    .my-swipe .van-swipe-item img{
        width: 100%;
        height: 120px;
    }
}
/* 我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线*/
    .galleryYears{
        text-align: left;
        border-bottom: 0.026667rem solid #eee;
        padding: 20px 20Px;
        height: 0.933333rem;
        line-height: .933333rem;
        background-color: #fff;
        margin-top: -5px;
    }
    .tabs_active{
        color: #ff5000;
        background-color: #ffdccc;
    }
    .galleryYears span{
        border-radius: 0.266667rem;
        padding: 0.053333rem 0.4rem;
        font-size: 15px;
    }
    .galleryQishu{    
        background-color: #fff;
        padding: 10px;
    }
    .tabsPeriod{
        overflow-x: auto;
    }
    .tabsPeriodBd{
        white-space: nowrap;
        min-width: 100%;
        overflow: hidden; //隐藏滚动条
    }
    .tabsPeriodBd span{
        display: inline-block;
        width: 25%;
        text-align: center;
        font-size: 15px;
        // padding: 0 50px;
    }
    .qishuAct{
        color: #ff5000;
    }
    .popup{
        width: 25%;
        margin-top:5px;
    }
    .popup span{
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        height: 30px;
        width: 100px;
        line-height: 30px;
        margin: 10px 0;
        padding: 5px 10px;
        text-align: center;
        font-size: 15px;
    }
    .popAct{
        color: #ff5000;
        border-color: #ff5000 !important;
    }
    .galerryInfo{
        text-align: center;
        margin-top:10px;
    }
    .galerryImg{
        background-color: #eee;
    }
    .galerryInfo .galerryImg>div img{
        width: 100%;
    }
    .element::-webkit-scrollbar { width: 0 !important }
    .element { -ms-overflow-style: none; }
    .element { overflow: -moz-scrollbars-none; }
    .my-swipe{
        padding: 0;
        margin: 0;
    }
    
    .commentListTit{
        text-align: left;
        padding: 10px 15Px;
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        background-color: #f5f5f5;
    }
    .comment>div{
        display: flex;
        border-bottom: 2px solid #eee;
        padding: 15Px;
    }
    .commentLeft{
        width: 40px;
        height: 40px;
        margin: -5px 5px;
        
    }
    .commentLeft img{
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        // width: 100%;
        height: 100%;
        vertical-align: middle;
    }
    .commentRight{
        flex: 1;
        padding-left: 10Px;
    }
    .commentRight p {
        display: flex;
        justify-content: space-between;
        overflow-wrap: anywhere;
    }
    .commentRight p span{
        color: #ff5000;
        font-size: 16px;
    }
    .commentRight p b{
        align-items: center;
        color: #999;
        font-size: 14px;
        position: absolute;
        right:0;
    }
    .commentRight p b img{
        width: 13px;
        height: 13px;
    }
    .commentRight p b i{
        margin-left: 10px;
        font-style: normal;
    }
    .commentHidden{
        word-break: break-all;
        font-size: 14px;
    }
    .commentHidden span{
        color: #ec799f;
        font-size: 14px;
    }
    .commentHidden .commentTime{
        display: inline-block;
        color: #999;
        font-size: 12px;
    }
    .commentMore{
        display: flex;
        align-items: center;
        padding-top: 10px;
    }
    .commentMore span{
        color:#999;
    }
    .inp{
        position: fixed;
        bottom: 0;
        padding: 0 20px;
        border-top: 1px solid #dcdcdc;
        width: 94%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        max-width: 600px;
    }
    .inp span{
        border-radius:8px;
        width: 20%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    .textArea{
        height: 160px !important;
        background-color: rgba(214, 214, 214, 0.15) !important;
    }
    .relationBox{
        max-width: 600px;
        margin: 0 auto;
    }
    .relationBox .btn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 25px;
        margin-top:20px;
        width: 300px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .loginWrap{
        position: relative;
        margin: 0 auto;
        border-radius: 0.533333rem 0.533333rem 0.266667rem 0.266667rem;
        width: 80%;
        height: 50%;
        background-color: #fff;
        top: 50%; /*偏移*/
        transform: translateY(-50%);
        max-width: 600px;
    }
    .loginWrap .top{
        height:80px;
    }
    .loginWrap .top>img{
        border-radius: 0.533333rem 0.533333rem 0 0;
        width: 100%;
    }
    .loginWrap .logo{
        position: absolute;
        top: -50px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        width: 100px;
        height: 50px;
    }
    .loginWrap .logo img{
        
        width: 100%;
    }
    .loginWrap .common{
        border-radius: 0.266667rem;
        padding: 20px;
        background-color: #fff;
    }
    .common .btn{
        display: inline-block;
        border: 1px solid #ff5000;
        border-radius: 30px;
        width: 90%;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    
    .loginWrap .close{
        position: absolute;
        top: 10px;
        right: 10px; 
        margin-left: auto; 
        margin-right: auto;
        
    }
    .loginWrap .close img{
        width: 20px;
        height: 20px;
    }
    .verifyInput{
        height: 38px;
        padding: 0 0 0 15px;

    }
    .verify-box{
        width: 100%;
    }
    .bottomTxt{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin: 20px auto;
        font-size: 15px;
    }
    .bottomOther:last-child a span{
        color: #ff5000;
    }
    .bottomOther a{
        color: rgb(112, 109, 109);
    }
    .fixTop{
        position: sticky;
        top: 0;
        z-index: 1000;
    }
    .galerryOther{
        margin: 10px;
        border-bottom: 1px solid #eee;
    }
    .galerryOther ul{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        height: 0.933333rem;
    }
    .galerryOther ul li{
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
    .galerryOther ul li span{
        margin-left: 5px;
        font-weight: 400;
        font-size: 13px;
    }
    .galerryOther ul li b{
        margin-left: 5px;
        font-weight: 400;
        font-size: 13px;
    }
    .commentDetail{
        display: flex;
        margin-top:10px
    }
    /deep/.van-icon-clear{
        color:rgb(173, 169, 169);
        font-size:20px;
    }
    .loginInput{
        width: 85% !important;
        margin: 25px auto !important;
        border-radius: 8px !important;
        background-color:rgba(194, 194, 194, 0.212) !important ;
    }
    ::v-deepinput::-webkit-input-placeholder {
        color: #726f6f ;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    /deep/.van-dropdown-menu__title{
        padding: 0 5px 0 5px;
        font-size: 15px;
        color: #fff !important;
    }
    /deep/.van-dropdown-menu__bar{
        height: auto;
        box-shadow: none;
        background: #ff5000;
        border-radius: 20px;
    }
    /deep/.van-dropdown-item__content{
        display: flex;
        flex-wrap: inherit;
        white-space: nowrap;
    }
    /deep/.van-dropdown-item{
        width: 100%;
        max-width: 600px;
        margin:0 auto;
        flex-flow: wrap;
        flex-wrap: wrap;
    }
    /deep/.van-swipe__indicators{
        bottom: 0;
    }
    /deep/.van-swipe__indicator{
        background-color: rgb(61, 2, 255);
    }
    .realtaionCell{
        margin-right:10px !important;
        border-radius: 8px !important;
        height: 40px !important;
        background-color:rgba(214, 213, 213, 0.301) !important;
    }
    ::v-deepinput::-webkit-input-placeholder {
        color: #726f6f ;
    }
    /deep/.van-overlay{
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
        z-index: 1000;

    }
    /deep/.van-empty__image img{
        width: 70%;
        height: 70%;
        margin-top:40px;
    }
</style>