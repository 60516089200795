// 一些全局的config配置
const modeUrlObj = {
    // 生产环境
    'production': {
      baseURL: 'https://am49.app/', //线上地址
      authBaseURL: ''
    },
    // 开发环境
    'development': {
      // baseURL: 'http://192.168.10.4:8091/',//柯南地址
      baseURL: 'https://am49.app/', //线上地址
      authBaseURL: ''
    },
    // 测试环境
    'test': {
      baseURL: 'http://xxxx:9091/test/',
      authBaseURL: ''
    }
  }
  export default modeUrlObj[process.env.NODE_ENV]