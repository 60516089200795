<template>
    <div>
       <van-nav-bar title='TA的主页' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:40px;'></div>   <!-- 增加底部顶部高度，部面盖住内容 -->
        <div class="userInfo">
            <img class="avatar" :src="userList.avatar" alt="">
            <div class="nickName">
                <b>{{userList.userName}}</b>
            </div>
            <div>
                <p>
                    <img src="../assets/like2.png" alt="">
                    <b>{{userList.sumPostLikes}}</b>
                </p>
                <p @click="removeFollow">
                    <b>{{followText}}</b>
                </p>
            </div>
        </div>
        <div class="userOther">
            <ul>
                <li>
                    <a>
                        <b>{{userList.myFocusNum}}</b>
                        <span>关注的人</span>
                    </a>
                </li>
                <li>
                    <a>
                        <b>{{userList.focusMeNum}}</b>
                        <span>关注粉丝</span>
                    </a>
                </li>
                <li>
                    <a>
                        <b>{{userList.myPostCount}}</b>
                        <span>发布帖子</span>
                    </a>
                </li>
            </ul>
        </div>
        <van-list
            v-model:loading="synthesisLoading"
            :finished="synthesisFinished"
            :finished-text="synthesisFinishedText"
            @load="synthesisOnLoad"
            :immediate-check='false'>
            <div class="Synthesis">
                    <div  v-for="(item,index) in forumSynthesisList" :key="index + 20">
                        <div class="forumUser">
                            <img :src="item.avatar" alt="">
                            <p>
                                <span>{{item.userName}}</span>
                                <b>{{item.createTime}}</b>
                            </p>
                        </div>
                        <div class="forumTxt">
                            <a  @click="goToDetail(item.postId,item.userId)">
                                <h3 class="forumTxtTit">{{item.postTitle}}</h3>
                            </a>
                            <div style="margin-top:10px"  @click="goToDetail(item.postId,item.userId)">
                                <a >
                                    <p style="white-space: pre-wrap;" class="formTxtContent overHidden" v-html="item.postContent"></p>
                                </a>
                            </div>
                            <div class="forumImg">
                                <div v-for="(img,k) in item.imgUrls" :key="k + 20" @click="showImg(img.imgUrl)">
                                    <img :src="img.imgUrl" alt="">
                                </div>
                            </div>
                            <div class="forumOther">
                                <ul>
                                    <li @click="countLikeClick(item)">
                                        <img style="width:14px" :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')">
                                        <span></span>
                                        <b>{{item.likes}}&nbsp;赞</b>
                                    </li>
                                    <li>
                                        <img src="../assets/eyes.png" alt="">
                                        <span>{{item.sees}}</span>
                                    </li>
                                    <li>
                                        <img src="../assets/comments.png" alt="">
                                        <span></span>
                                        <b>{{item.comments}}&nbsp;评论</b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        </van-list>
    </div>
</template>

<script>
import {forumHomepageList,userAddRelation,userRemoveRelation} from '../api/http'
import { List, NavBar, Toast ,ImagePreview} from 'vant';
    export default {
        created(){
            if(this.$route.params.taUserId){
                this.taUserId = this.$route.params.taUserId
            }
            this.getTaHomePageDet()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-list':List,
            [ImagePreview.Component.name]: ImagePreview.Component,
        },
        data() {
            return {
                  taUserId:'',
                  page:0,
                  userList:[],
                  followText:'已关注',
                  isFollow:true,
                  synthesisLoading:false,
                  synthesisFinished:true,
                  synthesisFinishedText:'',
                  forumSynthesisList:[],
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/MyFollow')
            },
            synthesisOnLoad(){
                this.page += 1;//页数+1
                this.getTaHomePageDet();
            },
            getTaHomePageDet(){
                let data = {
                    taUserId:this.taUserId
                }
                forumHomepageList(data,this.page).then(res => {
                    if(res.data.code == 200){
                        this.userList = res.data.data.user
                    }
                    if(res.data.code == 200 && res.data.data.list.total != 0){
                        if( res.data.data.list.pageNum == 1){
                            this.forumSynthesisList = res.data.data.list.list
                        }else{
                            this.forumSynthesisList = this.forumSynthesisList.concat(res.data.data.list.list)
                        }
                        if (res.data.data.list.nextPage == 0) {  //数据全部加载完成
                            this.synthesisFinished = true;
                            this.synthesisFinishedText = '没有更多了'
                        }else{
                            this.synthesisFinished = false;
                        }       
                        this.synthesisLoading = false;
                    }
                })
            },
            goToDetail(postId,userId){
                this.$router.push({                                                     
                    name: 'ForumDetail',                                 
                    params: {    
                        homePagePostId:postId,
                        userId:userId,
                        taUserId:this.taUserId                         
                    }
                })
            },
             countLikeClick(item){
                if(this.$store.state.noLogin){
                    Toast.fail("请登录 !")
                }else{
                    // console.log(item)
                    if (item.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:4,
                            otherId:item.postId
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                item.like = true
                                item.likes+=1
                                Toast("已点赞")
                            }
                        })
                    }
                }
            },
            removeFollow(){
                if(this.isFollow){
                    let data = {
                        type:2,
                        moduleType:100,
                        otherId:this.taUserId
                    }
                    userRemoveRelation(data).then(res => {
                        if(res.data.code == 200){
                            Toast('已取消关注')
                            this.isFollow = false
                            this.followText = '+ 关注'
                        }
                    })
                }else{
                    let data = {
                        type:2,
                        moduleType:100,
                        otherId:this.taUserId
                    }
                    userAddRelation(data).then(res=>{
                         if(res.data.code == 200){
                             Toast('已关注')
                            this.isFollow = true
                            this.followText = '已关注'
                        }
                    })
                }
            },
            showImg(images){
                ImagePreview({
                    images:[images],
                    showIndex: false,
                    closeable: true,
                });
                
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    .userInfo{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 260px;
        background: url(../assets/taHomeBg.png) no-repeat;
        background-size: 100% 100%;
    }
    .userInfo .avatar{
        margin-top: 25px;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .userInfo .nickName{
        min-height: 50px;
        line-height: 50px;
    }
    .userInfo .nickName b{
        color: #fff;
        font-size: 18px;
    }
    .userInfo div{
        margin-top: 20px;
        display: flex;
    }
    .userInfo div p:nth-child(1){
        margin: 0 15px;
    }
    .userInfo div p{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 60px;
        height: 20px;
        background: #fff;
    }
    .userInfo div p img{
        width: 13px;
        height: 13px;
    }
    .userInfo div p b{
        margin-left: 10px;
        color: #ff5000;
        font-size: 13px;
    }
    .userOther ul{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        padding-top: 20px;
    }
    .userOther ul li a{
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 18px;
    }
    .userOther ul li a span{
        color: #999;
        font-size: 13px;
    }
    .Synthesis .forumUser{
        display: flex;
        align-items: center;
        padding: 0 15Px;
    }
    .Synthesis .forumUser img{
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        width: 48px;
        height: 48px;
        -o-object-fit: cover;
        object-fit: cover;
        margin-top: 20px;
    }
    .Synthesis .forumUser p{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }
    .Synthesis .forumUser p span{
        font-size: 15px;
        text-align: left;
        font-weight: bold;
        margin-top: 20px;
    }
    .Synthesis .forumUser p b{
        color: #999;
        font-size: 13px;
    }
    a:active, a:focus, a:hover, a:link, a:visited {
        outline: none;
        background: none;
        text-decoration: none;
    }
    a{
        color: #333;
    }
    .Synthesis .forumTxt{
        width: 100%;
    }
    .Synthesis .forumTxt .forumTxtTit{
        padding: 10Px 15Px;
        border-bottom: 1px solid #eee;
        word-break: break-all;
        font-size: 15px;
        text-align: left;
    }
    .Synthesis .forumTxt>div{
        padding: 0 15Px;
    }
    .Synthesis .forumTxt>div .overHidden{
        overflow: hidden;
    }
    .Synthesis .forumTxt>div .formTxtContent{
        word-break: break-all;
        font-size: 15px;
        text-align: left;
    }
    .Synthesis .forumImg{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 0.4rem;
        margin-top: 15Px;
    }
    .Synthesis .forumImg>div{
        width: 6rem;
        height: 6rem;
    }
    .Synthesis .forumImg>div img{
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        background-color: #f5f5f5;
    }
    .Synthesis .forumOther{
        margin-top: 15Px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #f5f5f5;
    }
    .Synthesis .forumOther ul{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        height: 30px;
    }
    .Synthesis .forumOther ul li{
        display: flex;
        margin: 0 auto;
        align-items: center;
    }
    .Synthesis .forumOther ul li img{
        width: 13px;
        height: 13px;
        vertical-align: middle;
    }
    .Synthesis .forumOther ul li span{
        margin-left:5px;
        font-weight: 400;
        font-size: .32rem;
    }
    .Synthesis .forumOther ul li b{
        margin-left: 5px;
        font-weight: 400;
        font-size:13px;
    }
</style>