import { createRouter, createWebHistory,createWebHashHistory} from 'vue-router'
import Home from '../views/Home'
import Massege from '../views/Massege'
import Record from '../views/record'
import Inquire from '../views/inquire'
import SearchIndex from '../views/searchIndex'
import Detail from '../views/Detail'
import DataAll from '../views/DataAll'
import InfoDetails from '../views/InfoDetails'
import ArticleDetails  from '../views/ArticleDetails'
import User  from '../views/User'
import Login  from '../views/login'
import Register  from '../views/Register'
import InfoSet  from '../views/infoSet'
import Forget  from '../views/Forget'
import Test  from '../views/Test'
import Guess  from '../views/Guess'
import Count  from '../views/zlCount'
import Forum  from '../views/Forum'
import ForumDetail  from '../views/forumDetail'
import Formula  from '../views/Formula'
import FormulaDetail  from '../views/FormulaDetail'
import Tools  from '../views/Tools'
import MyLike  from '../views/MyLike'
import MyCollect  from '../views/MyCollect'
import MyFollow  from '../views/MyFollow'
import Attribution  from '../views/Attribution'
import CountData  from '../views/CountData'
import SpecialCode  from '../views/specialCode'
import PositiveCode  from '../views/positiveCode'
import WsNum  from '../views/wsNum'
import SxSpeCiaCode  from '../views/sxSpeciaCode'
import SxPositiveCode  from '../views/sxPositiveCode'
import BsSpecialCode  from '../views/bsSpecialCode'
import BsPositiveCode  from '../views/bsPositiveCode'
import SpecialTwoSides  from '../views/specialTwoSides'
import WsSpecialCode  from '../views/wsSpecialCode'
import WsPositiveCode  from '../views/wsPositiveCode'
import PositiveTwoside  from '../views/positiveTwoside'
import NumBandCode  from '../views/numBandCode'
import Discount  from '../views/Discount'
import ActivityDetails  from '../views/ActivityDetails'
import UserHomePage  from '../views/UserHomePage'
import MyPost  from '../views/MyPost'
import MyComments  from '../views/MyComments'
import StationNews  from '../views/StationNews'
import AboutUs  from '../views/AboutUs'
import Discover  from '../views/Discover'
import Newdiscover  from '../views/Newdiscover'
import amSpecialCode  from '../views/amSpecialCode'
import amNormalX  from '../views/amNormalX'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      keepAlive: true // 需要缓存 
  }, 
  },
  {
    path: '/massege',
    name: 'Massege',
    component: Massege
  },
  {
    path: '/record',
    name: 'Record',
    component: Record
  },
  {
    path: '/inquire',
    name: 'Inquire',
    component: Inquire
  },
  {
    path: '/searchIndex',
    name: 'SearchIndex',
    component: SearchIndex,
    // children:[{ //page2和page3都要写在page1的children属性下，这样才能在page1中展示
    //   name:'page2',
    //   path:'/page1/page2',
    //   component:page2
    // }]    
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail,
    // meta: { 
    //     keepAlive: true // 需要缓存 
    // }, 
  },
  {
    path: '/dataAll',
    name: 'DataAll',
    component: DataAll
  },
  {
    path: '/infoDetails',
    name: 'InfoDetails',
    component: InfoDetails
  },
  {
    path: '/articleDetails',
    name: 'ArticleDetails',
    component: ArticleDetails
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/infoSet',
    name: 'InfoSet',
    component: InfoSet
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test
  },
  {
    path: '/Guess',
    name: 'Guess',
    component: Guess
  },
  { path: '/data', component: CountData,
    children: [
      { path: '', component: Count },
      { path: 'count', component: Count },
      { path: 'attribution', component: Attribution },
      { path: 'specialCode', component: SpecialCode },
      { path: 'positiveCode', component: PositiveCode },
      { path: 'table', component: WsNum },
      { path: 'sxSpecial', component: SxSpeCiaCode },
      { path: 'sxPositiveCode', component: SxPositiveCode },
      { path: 'bsSpecialCode', component: BsSpecialCode },
      { path: 'bsPositiveCode', component: BsPositiveCode },
      { path: 'specialTwoSides', component: SpecialTwoSides },
      { path: 'wsSpecialCode', component: WsSpecialCode },
      { path: 'wsPositiveCode', component: WsPositiveCode },
      { path: 'positiveTwoside', component: PositiveTwoside },
      { path: 'numBandCode', component: NumBandCode },
    ]
  },
  // {
  //   path: '/Count',
  //   name: 'Count',
  //   component: Count,
  // },
  // {
  //   path: '/attribution',
  //   name: 'Attribution',
  //   component: Count,
  // },
  {
    path: '/positivecode',
    name: 'Positivecode',
    component: Count,
  },
  {
    path: '/forum',
    name: 'Forum',
    component: Forum,
    meta: { 
        keepAlive: true // 需要缓存 
    }, 
  },
  {
    path: '/forumDetail',
    name: 'ForumDetail',
    component: ForumDetail,
  },
  {
    path: '/Formula',
    name: 'Formula',
    component: Formula,
  },
  {
    path: '/FormulaDetail',
    name: 'FormulaDetail',
    component: FormulaDetail,
  },
  {
    path: '/Tools',
    name: 'Tools',
    component: Tools,
  },
  {
    path: '/MyLike',
    name: 'MyLike',
    component: MyLike,
  },
  {
    path: '/MyCollect',
    name: 'MyCollect',
    component: MyCollect,
  },
  {
    path: '/MyFollow',
    name: 'MyFollow',
    component: MyFollow,
  },
  {
    path: '/Discount',
    name: 'Discount',
    component: Discount,
    meta: { 
      keepAlive: true // 需要缓存 
    }, 
  },
  {
    path: '/ActivityDetails',
    name: 'ActivityDetails',
    component: ActivityDetails,
  },
  {
    path: '/UserHomePage',
    name: 'UserHomePage',
    component: UserHomePage,
  },
  {
    path: '/MyPost',
    name: 'MyPost',
    component: MyPost,
  },
  {
    path: '/MyComments',
    name: 'MyComments',
    component: MyComments,
  },
  {
    path: '/StationNews',
    name: 'StationNews',
    component: StationNews,
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/Discover',
    name: 'Discover',
    component: Discover,
  },
  {
    path: '/Newdiscover',
    name: 'Newdiscover',
    component: Newdiscover,
    meta: { 
        keepAlive: true // 需要缓存 
    }, 
  },
  {
    path: '/amSpecialCode',
    name: 'amSpecialCode',
    component: amSpecialCode,
  },
  {
    path: '/amNormalX',
    name: 'amNormalX',
    component: amNormalX,
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
