<template>
    <div>
        <van-nav-bar title="活动详情" fixed left-arrow @click-left="onClickLeft" />
       <div style='width:100%;height:50px;'></div>   <!-- 增加底部脚部高度，部面盖住内容 -->
       <div style="white-space: pre-wrap;padding:10px 20px" v-html="detailContent"></div>
    </div>
</template>

<script>
import { NavBar, } from 'vant';
    export default {
        created(){
            if(this.$route.params.content){
                this.detailContent = this.$route.params.content
            }
        },
        components:{
            'van-nav-bar':NavBar,
        },
        data() {
            return {
                detailContent:''
            }
        },
        methods: {
            onClickLeft(index) {
                this.$router.go(-1);
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }

    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    } 
</style>