<template>
    <div class="userWrap">
        <div v-if="isShowIframe" class="backIcon" @click="goback">返回澳门六合</div>
        <iframe v-if="isShowIframe"  class="iframe" ref="iframe" :src="iframeSrc" ></iframe>
        <div class="userTop">
            <span>我的</span>
        </div>
        <div class="topIcon">
            <van-icon name="qr" />
            <van-icon @click="gotoSet" name="setting-o" />
        </div>
        <div class="userInfo">
            <div class='userInfoSignIn'>
                <div class="infoSignInLeft">
                    <img :src="avatar" alt="">
                    <!-- <img src="../assets/61363e416129f.jpg" alt=""> -->
                </div>
                <div class="infoSignInRight">
                    <div class="loginBlock" v-if="noLogin">
                        点击
                        <span @click="login">登陆</span>
                        或
                        <span @click="gotoRegister">注册</span>
                    </div>
                    <p v-if="noLogin" class="welcom">欢迎加入六合宝典</p>
                    <div class="user" v-if="!noLogin">
                        <p>
                            <span>{{paramUserName}}</span>
                        </p>
                        <div>
                            <span>用户名：{{paramLoginName}}</span>
                        </div>
                        <div>
                            <span>ID号：{{paramUserId}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="userRoad">
            <div class="title">我的频道</div>
            <van-grid :column-num="3" square :border="false" >
                <van-grid-item v-for="(item,index) in gridList" :key="index" @click="handleGrid(item)">
                    <img style="width:40px;" :src="item.image" />
                    <span style="font-size:15px;padding-top:10px;">{{item.title}}</span>
                </van-grid-item>
            </van-grid>
            
        </div> 
        <div class="pingtaiInfo">
            <div class="title">平台信息</div>
            <van-grid :column-num="3" square :border="false" >
                <van-grid-item v-for="(k,b) in ptGridList" :key="b + 10" @click="handleGrid(k)">
                    <img style="width:40px;" :src="k.image" />
                    <span style="font-size:15px;padding-top:10px;">{{k.title}}</span>
                </van-grid-item>
            </van-grid>
        </div>
        <v-footer @showIframe="showIframe($event)"></v-footer>
    </div>
</template>

<script>
import Footer from '../components/footer'
import defaultAvatar from '../assets/userAvatar.png'
import {userLogin,imgLink} from '../api/http' 
 
import { Tabbar, TabbarItem,Grid,GridItem,Icon } from 'vant';
    export default {
        created(){
            if(!this.$store.state.avatar){
                this.$store.commit('setAvatar',defaultAvatar)
            }else{

            }
            this.getLink()
        },
        mounted(){
            if(!this.$store.state.noLogin){
                this.refreshInfo()
            }
        },
        components:{
            'v-footer':Footer,
            'van-tabbar':Tabbar,
            'van-tabbar-item':TabbarItem,
            'van-grid':Grid,
            'van-grid-item':GridItem,
            'van-icon':Icon,
        },
        
        data() {
            return {
                  gridList:[
                    {id:0,image:require('../assets/myGz.png'),title:'我的关注'},
                    {id:1,image:require('../assets/mySc.png'),title:'我的收藏'},
                    {id:2,image:require('../assets/myDz.png'),title:'我的点赞'},
                    {id:3,image:require('../assets/myPl.png'),title:'我的评论'},
                    {id:4,image:require('../assets/myFb.png'),title:'我的发布'},
                    {id:5,image:require('../assets/tools2.png'),title:'敬请期待'},
                  ],
                  ptGridList:[
                    {id:10,image:require('../assets/znInfo.png'),title:'站内消息'},
                    // {id:1,image:require('../assets/yijian.png'),title:'意见反馈'},
                    {id:11,image:require('../assets/aboutUs.png'),title:'关于我们'},
                    {id:12,image:require('../assets/xiazaiApp.png'),title:'下载APP'},
                  ],
                  downloadLink:'',
                  isShowIframe:false,
                  iframeSrc:''
                //   paramUserId:'',
                //   paramUserName:'',
                //   paramLoginName:'',
            }
        },
        computed:{
            avatar(){
               return this.$store.state.avatar
            },
            noLogin(){
               return this.$store.state.noLogin
            },
            paramUserName(){
                return this.$store.state.username.replace(/\"/g, "");
            }, 
            paramUserId(){
                return this.$store.state.userId.replace(/\"/g, "");
            }, 
            paramLoginName(){
                return this.$store.state.logname.replace(/\"/g, "");
            }, 
            paramPassword(){
                return this.$store.state.password.replace(/\"/g, "");
            }, 
        },
        methods: {
            login() {
                this.$router.push({
                    name: `Login`,
                }) 
            },
            gotoRegister(){
                this.$router.push({
                    name: `Register`,
                }) 
            },
            gotoSet(){
                this.$router.push({
                    name:`InfoSet`
                })
            },
            refreshInfo(){
                let data = {
                    loginName:this.$store.state.logname.replace(/\"/g, ""),
                    password:this.paramPassword,
                }
                userLogin(data).then(res=>{
                        if(res.data.code == 200){
                            this.$store.commit('setUsername',res.data.data.userName)
                            this.$store.commit('setAvatar',res.data.data.avatar)

                        }
                    }).catch(err=>{
                        console.log(err)
                    })
            },
            handleGrid(val){
                if(val.id == 2){
                    this.$router.push({ //我的点赞
                        name: `MyLike`,
                    })
                } else if(val.id == 1){ //我的收藏
                    this.$router.push({
                        name: `MyCollect`,
                    })
                }else if(val.id == 0){ //我的关注
                    this.$router.push({
                        name: `MyFollow`,
                    })
                }else if(val.id == 4){ //我的发布
                    this.$router.push({
                        name: `MyPost`,
                    })
                }else if(val.id == 3){ //我的评论
                    this.$router.push({
                        name: `MyComments`,
                    })
                }else if(val.id == 10){ //站内消息
                    this.$router.push({
                        name: `StationNews`,
                    })
                }else if(val.id == 11){ //关于我们
                    this.$router.push({
                        name: `AboutUs`,
                    })
                }else if(val.id == 12){ //下载App
                    window.location.href = this.downloadLink

                }
            },
            getLink(){
                let data = {
                    key:'download-link'//下载链接
                }
                imgLink(data).then(res=>{
                    this.downloadLink = res.data.data
                })
            },
            goback(){
                this.$router.push('/user')
                this.isShowIframe = false
            },
            showIframe(val){
                this.isShowIframe = val.isShowIframe
                this.iframeSrc = val.link
            },
        },
    }
</script>

<style lang='scss' scoped>
    .userWrap{
        background-color: #f5f5f5;
        // position: relative;
    }
    .userTop{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        background-color: rgb(174, 115, 252);;
    }
    .userTop span{
        font-size: 20px;
        color: #fff;
    }
    .userInfoSignIn{
        display: flex;
        align-items: center;
        height: 100px;
        background-color: rgb(174, 115, 252);;
        padding-bottom: 30px;        
    }
    .infoSignInLeft{
        margin: 0 20Px;
    }
    .infoSignInLeft img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .infoSignInRight{
        text-align: left;
    }
    .infoSignInRight .loginBlock{
        color: #fff;
        font-size: 18px;
    }
    .infoSignInRight .loginBlock span{
        color: #ff0;
    }
    // .infoSignInRight .user{
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    // }
    .infoSignInRight .user p{
        margin:0;
        margin-bottom: 5px;
    }
    .infoSignInRight .user p span{
        margin-right: 5Px;
        font-size: 20px;
        color: #fff;
    }
    .infoSignInRight .user>div{
        display: flex;
        align-items: center;
    }
    .infoSignInRight .user>div span{
        display: inline-block;
        font-size: 15px;
        color: #fff;
    }
    .infoSignInRight .welcom{
        margin-top: 5Px;
        font-size: 16px;
        color: #fff;
    }
    .userRoad{
        position: relative;
        width: 90%;
        margin: 0 auto;
        top:-40px; 
        margin: 20px;
        border-radius: 10px;
        padding: 10px 0;
        background-color: #fff;
    }
    .pingtaiInfo{
        position: relative;
        width: 90%;
        margin: 0 auto;
        top:-40px; 
        margin: 20px;
        border-radius: 10px;
        padding: 10px 0;
        background-color: #fff;
    }
    a:active, a:focus, a:hover, a:link, a:visited{
        outline: none;
        background: none;
        text-decoration: none
    }
    .userRoad .title{
        text-align: left;
        margin-left: 8%;
        font-weight: bold;
        font-size: 18px;
    }

    .pingtaiInfo .title{
        text-align: left;
        margin-left: 8%;
        font-weight: bold;
        font-size: 18px;
    }
    .topIcon{
        position: absolute;
        top: 16px;
        right: 0.266667rem;
    }
    /deep/.van-icon-qr,.van-icon-setting-o{
        color: #fff;
        font-size: 20px;
        margin-right: 10px;
    }
    .topIcon:first-child{
        margin-right: 10Px;
    }
    .iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        border: none;
        max-width: 600px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .backIcon{
        position: absolute;
        font-size: 12px;
        margin: 15px 50px;
        z-index: 100000;
        color: goldenrod;
        background: black;
        border-radius: 4px;
        padding: 2px;
        font-weight: bold;
    }
</style>