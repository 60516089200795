<template>
  <div id="content">
    <div v-if="isShowService">
      <div class="serviceBackIcon" @click="servicegoback">返回六合宝典</div>
      <iframe class="iframe" ref="iframe" :src="serviceSrc" ></iframe>
    </div>
    <div v-if="isShowIframe">
      <div class="backIcon" @click="goback">返回六合宝典</div>
      <iframe class="iframe" ref="iframe" :src="iframeSrc" ></iframe>
    </div>
    <div v-if="isShowService === false && isShowIframe === false">
      <v-header @changeCode="changeCode($event)"></v-header>
      <van-swipe class="my-swipe" :autoplay="3000">
        <van-swipe-item v-for="(image,i) in carouselImg" :key="i+10" >
          <img :src="image.url" @click="gotoLink(image.jumpUrl,i)"/>
        </van-swipe-item>
      </van-swipe>
      <van-notice-bar
        left-icon="volume"
        scrollable
        :wrapable="false"
      >
      <span v-for="(k,b) in noticeText" :key="b+10">{{b+1}}.{{k}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </van-notice-bar>
      <!-- <v-downBar></v-downBar> -->

      <!-- <van-divider/> -->
      <div class="icon-btn" @click="showOverlay"></div>
      <van-overlay :show="show" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="kefu" @click="goToService"><span class='kefutext'>在线客服</span><img src="../assets/kefu.png" alt=""></div>
          <div class="chat" ><div class='chattext'>聊天室</div><img src="../assets/chatRoom.png" alt=""></div>
          <!-- <div class="down" @click="goToLink"><div class='downtext'>下载App</div><img style=" width:40px;" src="../assets/downApp.png" alt=""></div> -->
        </div>
      </van-overlay>
      <!-- <div class="homeLotery">
        <div class='loteryTittle'>第{{theNewestDate}}期最新开奖结果 <span class="toRecord" @click="goToRoute()" to="/record">开奖记录></span></div>
        <div class='loteryNums' v-for="(item,i) in newLoteryList" :key="i+10" >
          <img :src="require(`../assets/${item[0]}.png`)" >
          <div class="loteryScribe">
            <span>{{item[1]}}/{{item[2]}}</span>
          </div>
        </div>  
        <h2 class="jiahao">+</h2>
        <div class="drawInfo"><span>第{{theNextDate}}期 {{nextPrizeTime}} {{weekTxt}}</span></div>
      </div> -->
      <v-kaijiang ></v-kaijiang>
      <van-grid :column-num="4" square :border="false">
        <van-grid-item v-for="(item,index) in gridList" :key="index" @click="handleGrid(item)">
            <img style="width:50px;" :src="item.image" />
            <span style="font-size:15px;padding-top:10px;">{{item.title}}</span>
        </van-grid-item>
      </van-grid>
      <!-- <div  @click="goToLink">
        <img src="../assets/down_app.png" style='max-width:600px;width:100%;' alt="">
      </div> -->
      <!-- <div class="middle-tab">
        <div class="tuku">
          <b>图库目录</b>
          <van-radio-group v-model="radio" direction="horizontal" >
            <van-radio name="1" shape="square" checked-color="#ee0a24" @click="handleChange(color='1')">彩色</van-radio>
            <van-radio name="2" shape="square" checked-color="#ee0a24" @click="handleChange(color='2')">黑白</van-radio>
          </van-radio-group>
          <ul class="caiseyeqian">
            <li @click="checkRouterTo(year = '2024')"><img :src="tukuImg" alt=""><b style="font-weight:400;">{{checkText1}}</b></li>
            <li @click="checkRouterTo(year = '2023')"><img :src="tukuImg" alt=""><b style="font-weight:400;">{{checkText2}}</b></li>
          </ul>
        </div>
      </div>
      <div class="waterFallWrap">
        <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              :immediate-check ='false'
              @load="load_more"
              >
              <van-row class="van-clearfix">
                <van-col span="12">
                    <div @click="imgRouterTo(item)" class="image-item" v-for="(item,i) in imgListLeft" :key="i+10">
                      <div>
                        <img :src="item.imgUrl" alt="" />
                        <div class="item-body">
                          <div class="item-desc">{{item.imgTitle}}</div>
                        </div>
                      </div>
                    </div>
                </van-col>
                <van-col span="12">
                  <div @click="imgRouterTo(p)" class="image-item" v-for="(p,q) in imgListRight" :key="q+10">
                      <div>
                        <img :src="p.imgUrl" alt="" />
                        <div class="item-body">
                          <div class="item-desc">{{p.imgTitle}}</div>
                        </div>
                      </div>
                    </div>
                </van-col>
              </van-row>

        </van-list>
      </div> -->
      <v-Forum v-if="sonRefresh"></v-Forum>
      <v-footer @showIframe="showIframe($event)"></v-footer>
      <!-- <h4 v-show="isShowText" style="position:relative;bottom:50px; left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; ">暂无数据</h4> -->
    </div>
  </div>
</template>

<script>
import Footer from '../components/footer'
import Header from '../components/header'
import Forum from './Forum.vue'
import DownBar from '../components/downBar.vue'
import kaijiang from '../components/kaijiang.vue'
import caise from '../assets/caise.png'
import heibai from '../assets/heibai.png'
import {openLatest,imgCarousel,imgNotice,imgList,imgLink} from '../api/http.js'
import { Swipe,SwipeItem,Grid,GridItem,Overlay,NoticeBar,RadioGroup,Radio,List,Col,Row,Toast  } from 'vant';
// import bus from '../js/eventBus'
// import { List } from 'vant'
export default {
  name: 'Home',
  components:{
    'v-footer':Footer,
    'v-header':Header,
    'van-swipe':Swipe,
    'van-swipe-item':SwipeItem,
    'van-grid':Grid,
    'van-grid-item':GridItem,
    'van-overlay':Overlay,
    'van-notice-bar':NoticeBar,
    'van-radio-group':RadioGroup,
    'van-radio':Radio,
    'van-list':List,
    'van-col':Col,
    'van-row':Row,
    'v-Forum':Forum,
    'v-downBar':DownBar,
    'v-kaijiang':kaijiang,
  },
  
  data () {
    return {
      show: false,
      radio:'1',
      tukuImg:caise,
      prizeNumber:[],
      sxNumber:[],
      wxNumber:[],
      list:[],
      newLoteryList:[],
      theNewestDate:'',
      theNextDate:'',
      nextPrizeTime:'',
      weekTxt:'',
      prizeTime:'',
      weekText:'',
      code:'',
      codeTitle:'澳门特码',
      normalxTitle:'澳门平肖',
      gridList:[
         {id:0,image:require('../assets/kaijiang.png'),title:'开奖现场'},
         {id:1,image:require('../assets/ziliao.png'),title:'资料大全'},
         {id:2,image:require('../assets/tongji.png'),title:'资料统计'},
         {id:3,image:require('../assets/searchZhushou.png'),title:'查询助手'},
         {id:4,image:require('../assets/luntan.png'),title:null},
         {id:5,image:require('../assets/youmo.png'),title:'幽默猜测'},
         {id:6,image:require('../assets/liuhe.png'),title:null},
         {id:7,image:require('../assets/baoxiang.png'),title:'工具宝箱'},
        //  {id:8,image:require('../assets/luntan.png'),title:'高手论坛'},
      ],
      FatherData:'',
      carouselImg:[],
      noticeText:[],
      homepage:1,
      loading: false,
      finished: false,
      finishedText:'没有更多了',
      imgListLeft:[],
      imgListRight:[],
      checkColor:'0',
      checkYear:'',
      isShowText:Boolean,
      checkText1:'2024彩色',
      checkText2:'2023彩色',
      scroll:'',
      downloadLink:'',
      isShowIframe:false,
      isShowService:false,
      iframeSrc:'',
      gameLink:'',
      serviceSrc:'',
      sonRefresh: true,
      webTitlle:'',
      description:'',
      keyWords:'',

    }
  },
  metaInfo(){
    return {
      meta: [{
        name: 'title',
        content: this.webTitlle  //变量或字符串
      }, {
        name: 'description',
        content: this.description
      },{
        name: 'keyWords',
        content: this.keyWords
      },
    ]
    }
  },
  computed: {
    computedTabsActive() {
      return this.gridList[4].title = this.codeTitle;
    },
    computedTabsActive2() {
      return this.gridList[6].title = this.normalxTitle;
    },
  },
  activated() { //.activated 为keep-alive加载时调用
      if(this.scroll > 0){
          window.scrollTo(0, this.scroll);
          this.scroll = 0;
          window.addEventListener('scroll', this.backHandleScroll);
      }
      if(localStorage.getItem('code')){
          this.code = localStorage.getItem('code')
      }else{
          this.code = '71'
      }
  },
  deactivated(){//页面退出时关闭事件 防止其他页面出现问题
      window.removeEventListener('scroll', this.backHandleScroll);
  },
  mounted() {
        window.addEventListener('scroll', this.backHandleScroll);
         
  },
  created() {
    this.getkeyWords()
    this.getDescription()
    if(localStorage.getItem('code')){
        this.code = localStorage.getItem('code')
    }else{
        this.code = '71'

    }
          if(this.code === '71'){
            this.codeTitle = '澳门特码'
            this.normalxTitle = '澳门平肖'
          }else if(this.code === '28') {
              this.codeTitle = '香港特码'
              this.normalxTitle = '香港平肖'
          }else{
              this.codeTitle = '老澳门特码'
              this.normalxTitle = '老澳门平肖'
          }
        this.computedTabsActive
        this.computedTabsActive2

    
    // this.getNewestLotery()
    this.getCarousel()
    this.getNotice()
    this.getImgList()
    this.getLink()
    // this.getGameLink()
  },
  
   methods: {
    getkeyWords(){
      let data = {
        key:'web-keyword'
      }
      imgLink(data).then(res=>{
        this.keyWords = res.data.data
      })
    },
    getDescription(){
      let data = {
        key:'web-description'
      }
      imgLink(data).then(res=>{
        this.description = res.data.data
      })
    },
     showOverlay(){
       if(this.show){
         this.show = false
       
       }else{
         this.show = true
       }
     },
     handleChange(color){
       if(color == 2){
         this.tukuImg = heibai
         this.checkColor = '1'
         this.checkText1 = '2024黑白'
         this.checkText2 = '2023黑白'
       }else{
          this.tukuImg = caise
          this.checkColor = '0'
          this.checkText1 = '2024彩色'
          this.checkText2 = '2023彩色'
       }
     },
     getNewestLotery(){
        let data = {
          code : this.code
        }
         openLatest(data).then(res =>{
           if(res.data.code == 200){
            //  this.prizeNumber = res.data.data.prizeNumber.split(",")
            //  this.sxNumber = res.data.data.sxNumber.split(",")
            //  this.wxNumber = res.data.data.wxNumber.split(",")
            //  for (let i = 0; i < 7; i++) {
            //     this.list=[
            //       {'prize':this.prizeNumber[i]},
            //       {'sx':this.sxNumber[i]},
            //       {'wx':this.wxNumber[i]},
            //     ]
            //    this.newLoteryList.push(this.list)
            //  }
            this.newLoteryList = res.data.data.prize
           }
            // console.log(this.newLoteryList)
            // 截取字段最后三位字符串
            this.theNewestDate = res.data.data.issueNo.substring(res.data.data.issueNo.length-3)
            this.theNextDate = res.data.data.nextIssueNo.substring(res.data.data.issueNo.length-3)
            this.nextPrizeTime = res.data.data.nextPrizeTime
            this.weekTxt = res.data.data.dayOfTheWeek
            this.prizeTime = res.data.data.prizeTimeForHome
            
            
            // console.log(res)
            // 计算星期几
          var dateArray = this.prizeTime.substring(0,10).split("-"), date;
          date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
          let week = date.getDay()
          switch (week) {
            case 0:
                this.weekText = '星期日'
              break;
            case 1:
                this.weekText = '星期一'
              break;
            case 2:
                this.weekText = '星期二'
              break;
            case 3:
                this.weekText = '星期三'
              break;
            case 4:
                this.weekText = '星期四'
              break;
            case 5:
                this.weekText = '星期五'
              break;
            case 6:
                this.weekText = '星期六'
              break;
          }


        })
        .catch(err => {
          console.log(err);
        });
     },
     goToRoute(){
       this.$router.push({
         name: `Record`,
        })
     },
     handleGrid(val){
      //  console.log(val)
       if(val.id == 3){
         this.$router.push({
          name: `Inquire`,
        })
       } else if(val.id == 1){
        this.$router.push({
          name: `DataAll`,
        })
       } else if(val.id == 5){
        this.$router.push({
          name: `Guess`,
        })
       } else if(val.id == 2){
        this.$router.push('/data/count')
       } else if(val.id == 4){
        this.$router.push({
          name: `amSpecialCode`,
        })
       } else if(val.id == 6){
        this.$router.push({
          name: `amNormalX`,
        })
       } else if(val.id == 7){
        this.$router.push({
          name: `Tools`,
        })
       }  else if(val.id == 0){
        // this.$router.push({
        //   name: `Tools`,
        // })
        // this.getGameLink()
        Toast('敬请期待 !')
       }else if(val.id == 8){
        this.$router.push({
          name: `Forum`,
        })
       }
     },
     changeCode(code){
       this.code = code
      if(this.code == '28'){
        this.codeTitle = '香港特码'
        this.normalxTitle = '香港平肖'
        this.computedTabsActive
        this.computedTabsActive2
         this.sonRefresh= false;
        this.$nextTick(() => {
          this.sonRefresh= true;
        });
        
      }else if(this.code == '75'){
        this.codeTitle = '老澳门特码'
        this.normalxTitle = '老澳门平肖'
        this.computedTabsActive
        this.computedTabsActive2
        this.sonRefresh= false;
        this.$nextTick(() => {
          this.sonRefresh= true;
        });

      }else{
        this.codeTitle = '澳门特码'
        this.normalxTitle = '澳门平肖'
        this.computedTabsActive
        this.computedTabsActive2
        this.sonRefresh= false;
        this.$nextTick(() => {
          this.sonRefresh= true;
        });
      }
       localStorage.setItem('code',this.code)
       this.imgListLeft = []
       this.imgListRight = []
       this.homepage = 1
       this.getNewestLotery()
       this.getImgList()
       
     },
     getCarousel(){
       let data = {
         type:0
       }
       imgCarousel(data).then(res=>{
         if(res.data.code == 200){
          //  console.log(res.data.data)
           
           this.carouselImg = res.data.data
         }
       }).catch(err =>{
         console.log(err)
       })
     },
     getNotice(){
       let data = {
         code:this.code
       }
       imgNotice(data).then(res=>{
         if(res.data.code == 200){
          //  console.log(res.data.data)
           let noticefy = res.data.data
           for (let i = 0; i < noticefy.length; i++) {
             this.noticeText.push(noticefy[i].content)
           }
          //  console.log(this.noticeText)
         }
       }).catch(err =>{
         console.log(err)
       })
     },
     load_more() {
        this.homepage += 1;//页数+1
        this.getImgList();
    },
     getImgList(){
       this.loading = true
       let data = {
         code:this.code 
       }
       this.isShowText = true
       imgList(data,this.homepage).then(res=>{
         if(res.data.code == 200  && res.data.data.total !=0 ){
           this.loading = false;
           let imgList = res.data.data.list
          for (let i = 0; i < imgList.length; i++) {
              if(i%2 == 0){
               this.imgListLeft =  this.imgListLeft.concat(imgList[i])
              }else if(i%2 != 0){
              this.imgListRight = this.imgListRight.concat(imgList[i])
              }
           }
          //  console.log(res.data.data.total)
           if(res.data.data.total == 0){
             this.isShowText = true
           }else{
             this.isShowText = false
           }

           if (res.data.data.nextPage === 0) {  //数据全部加载完成
                this.finished = true;
                this.finishedText = '没有更多了'
            }else{
                this.finished = false;
            } 
         }else if(res.data.data.total ==0){
                this.finished = true;
                this.finishedText = '没有数据'
                this.imgListLeft = []
                this.imgListRight = []
         }
       }).catch(err =>{
         console.log(err)
       })
     },
     checkRouterTo(year){
        this.$router.push({                                                     
          name: 'SearchIndex',             // 路由的名字，地址会隐藏                            
          params: {
                color:this.checkColor,
                year:year                                               
          }
      })
     },
     imgRouterTo(data){
       console.log(data)
        this.$router.push({                                                     
          name: 'Detail',             // 路由的名字，地址会隐藏                            
          params: {
                homeImgid:data.id,
                tittle:data.imgTitle,
                homeImgTypeId:data.imgTypeId                                                
          }
      })
     },
    backHandleScroll(){
        this.scroll  = document.documentElement && document.documentElement.scrollTop
    },
    gotoLink(url,index){
      if(index == '0'){
        LA.track('lunPan_click');
      }
      window.location.href = url
    },
    getLink(){
      let data = {
        key:'download-link'//下载链接
      }
      imgLink(data).then(res=>{
        this.downloadLink = res.data.data
      })
    },
    goToLink(){
      window.location.href = this.downloadLink
    },
    goback(){
      this.$router.push('/')
      this.isShowIframe = false
    },
    servicegoback(){
      this.$router.push('/')
      this.isShowService = false
    },
    showIframe(val){
      this.isShowIframe = val.isShowIframe
      this.iframeSrc = val.link
    },
    getGameLink(){
        let data = {
            key:'game-link'//游戏链接
        }
        imgLink(data).then(res=>{
            this.isShowIframe = true
            this.iframeSrc = res.data.data
        })
    },
    goToService(){
        let data = {
            key:'online-service'//在线客服链接
        }
        imgLink(data).then(res=>{
            this.isShowService = true
            this.serviceSrc = res.data.data
        })
    },
    gotoEntry(){
      window.location.href = 'https://011168.cc/entry/register'
    },
  }
}
</script>
<style lang='scss' scoped>
@media screen and (min-width:700px) {
/* pc端 */
    .icon-btn{
         background-image: url('../assets/icon.png');
          background-size: 100% 100%;
          width: 40px;
          height: 40px;
          position: fixed;
          margin-left: 560px;
          bottom:205px;
          z-index: 1000;
    }
    .kefu{
      position: absolute;
      right:50px;
      bottom:290px;
      animation:kefutanchu 160ms;
    }
    @keyframes kefutanchu {
      0% { right:0px; bottom:205px;}
      25% { right:20px; bottom:230px;}
      50% { right:30px; bottom:250px;}
      75% { right:40px; bottom:270px;}
      100% {right:50px ;bottom:290px;}
    }
    .kefutext{
      position: absolute;
      top:60px;
      left: -13px;
      color: #fff;
      line-height: 10px;
      font-size: 15px;
      width: 80px;
      display: inline-block;
    }
    .chat{
      position: absolute;
      right:120px;
      bottom:210px;
      animation:chattanchu 160ms;
    }
    @keyframes chattanchu {
      0% { right:0px; bottom:205px;}
      25% { right:22px; bottom:207px;}
      50% { right:44px; bottom:208px;}
      75% { right:66px; bottom:209px;}
      100% {right:120px ;bottom:210px;}
    }
    .chattext{
      position: absolute;
      top:60px;
      left: -14px;
      color: #fff;
      line-height: 10px;
      font-size: 15px;
      width: 80px;
      display: inline-block;
    }
    .down{
      position: absolute;
      right:50px;
      bottom:130px;
      animation:downtanchu 160ms;
    }
    @keyframes downtanchu {
      0% { right:0px; bottom:205px;}
      25% { right:10px; bottom:198px;}
      50% { right:20px; bottom:179px;}
      75% { right:30px; bottom:159px;}
      100% {right:50px ;bottom:130px;}
    }
    .downtext{
      position: absolute;
      top:60px;
      left: -15px;
      color: #fff;
      line-height: 10px;
      font-size: 15px;
      width: 80px;
      display: inline-block;
    }
    .homeLotery{
      position: relative;
      background: url(../assets/kuang.png) no-repeat;
      background-size: 100% 100%;
      height: 160px;
      margin:10px 10px;
      justify-content: center;
      display: flex;
    }
    .loteryTittle{
      display: flex;
      padding-top: 20px;
      position: absolute;
      font-size: 15px;
    }
    .toRecord{
      padding-left: 10px;
      color:rgb(255, 82, 2);
    }
    .loteryNums{
      position: relative;
      top:60px;
      width: 10%;
      margin:5px 5px;
    }
    .loteryNums img{
      vertical-align: middle;
      width: 80%;
      position: relative;
      bottom: 5px;
    }
    .loteryNums:nth-child(8){
      padding-left: 45px;
    }
    .jiahao{
      position: absolute;
      right: 110px;
      top:63px;
      font-size: 20px;
    }
    .loteryScribe{
      text-align: center;
      padding-top: 2px;
      display: block;
      font-size: 13px;
    }
    .drawInfo{
      color: #999;
      position: absolute;
      bottom: 5px;
      font-size: 13px;
    }
}
/* 我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线*/
/* 移动端 */
@media screen and (max-width:750px) {
       .icon-btn{
          background-image: url('../assets/icon.png');
          background-size: 100% 100%;
          width: 40px;
          height: 40px;
          position: fixed;
          right:0;
          bottom:180px;
          z-index: 1000;
        }     
         .kefu{
            position: absolute;
            right:50px;
            bottom:260px;
            animation:kefutanchu 160ms;

          }
          @keyframes kefutanchu {
            0% { right:0px; bottom:180px;}
            25% { right:20px; bottom:226px;}
            50% { right:30px; bottom:240px;}
            75% { right:40px; bottom:250px;}
            100% {right:50px ;bottom:260px;}
          }
          .kefutext{
            position: absolute;
            top:60px;
            left: -13px;
            color: #fff;
            line-height: 10px;
            width: 80px;
            font-size: 15px;
            display: inline-block;
            
          }
         .chat{
            position: absolute;
            right:110px;
            bottom:185px;
            animation:chattanchu 160ms;
          }
          @keyframes chattanchu {
            0% { right:0px; bottom:180px;}
            25% { right:22px; bottom:181px;}
            50% { right:44px; bottom:182px;}
            75% { right:66px; bottom:183px;}
            100% {right:110px ;bottom:185px;}
          }
          .chattext{
            position: absolute;
            top:60px;
            left: -14px;
            color: #fff;
            line-height: 10px;
            font-size: 15px;
            width: 80px;
            display: inline-block;
          }
         .down{
            position: absolute;
            right:50px;
            bottom:110px;
            animation:downtanchu 160ms;
          }
          @keyframes downtanchu {
            0% { right:0px; bottom:180px;}
            25% { right:10px; bottom:150px;}
            50% { right:20px; bottom:130px;}
            75% { right:30px; bottom:120px;}
            100% {right:50px ;bottom:110px;}
          }
          .downtext{
            position: absolute;
            top:60px;
            left: -15px;
            color: #fff;
            line-height: 10px;
            font-size: 15px;
            width: 80px;
            display: inline-block;
          }
          .homeLotery{
            position: relative;
            background: url(../assets/kuang.png) no-repeat;
            background-size: 100% 100%;
            height: 160px;
            margin:10px 10px;
            justify-content: center;
            display: flex;
          }
          .loteryTittle{
            display: flex;
            padding-top: 20px;
            position: absolute;
            font-size: 15px;
          }
          .toRecord{
            padding-left: 10px;
            color:rgb(255, 82, 2);
          }
          .loteryNums{
            /* display: flex; */
            /* justify-content: space-around;
            align-items: center;
            padding:8px 8px; */
            position: relative;
            top:60px;
            width: 10%;
            margin:0 5px;
          }
          .loteryNums img{
            vertical-align: middle;
            width: 100%;
            position: relative;
            bottom: 5px;
          }
          .loteryNums:nth-child(8){
            padding-left: 45px;
          }
          .jiahao{
            position: absolute;
            right: 60px;
            top:53px;
            font-size: 20px;
          }
          .loteryScribe{
            text-align: center;
            padding-top: 7px;
            display: block;
            font-size: 13px;
          }
          .drawInfo{
            color: #999;
            position: absolute;
            bottom: 10px;
            font-size: 13px;
          }
}
  // .my-swipe .van-swipe-item{
  //   color: #fff;
  //   font-size: 20px;
  //   line-height: 150px;
  //   text-align: center;
  //   background-color: #39a9ed;
  // }
  .my-swipe .van-swipe-item img{
    width: 97%;
  }
  .van-notice-bar {
    color:rgb(255, 60, 0);
    font-size: 15.456px;
    font-family: MicrosoftYaHei;
    background-color:#ffffff;
    height: 50px;
    line-height: 25px;
  }
  // .van-divider{
  //   margin:0;
  //   font-size: 20px;
  //   color: #000000;
  //   border-color:rgba(68, 68, 70, 0.219);
  
  // }
.van-overlay{
  max-width: 600px;
  position: fixed;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  z-index: 20;
  
}
.kefu{
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #fff;
  // line-height: 70px;
  border-radius: 50%;
  /* box-shadow: 0 0.04rem 0.02rem 0 rgb(0 0 0); */
}
.kefu img{
  position: relative;
  top:5px;
}
.chat{
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #fff;
  // line-height: 80px;
  border-radius: 50%;
  /* box-shadow: 0 0.04rem 0.02rem 0 rgb(0 0 0); */
}
.chat img{
  position: relative;
  top:5px;
}
.down{
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #fff;
  // line-height: 80px;
  border-radius: 50%;
  /* box-shadow: 0 0.04rem 0.02rem 0 rgb(0 0 0); */
}
.down img{
  position: relative;
  top:5px;
}
.van-grid-item{
  border:.2px solid rgba(82, 79, 79, 0.123);
}
.tuku{
  position:sticky;
  top:100px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  background-color:#fff;
  /* z-index: 10; */
}
.caiseyeqian{

  display: flex;
  position: absolute;
  top:40px;
  background-color: #fff;
  margin: 10px -10px;
  width: 100%;
  max-width: 600px;

}

.caiseyeqian li{
  padding:0 10px;
  
}

.caiseyeqian li b{
  padding:0px 10px;
  position: relative;
  bottom: 5px;
  font-size: 15px;
}

.caiseyeqian li img{
  width: 22px;
  /* margin: 0px 0px -10px 0px; */
}

.middle-tab{
  position: relative;
  max-width: 600px;
  width: 100%;
}
.van-swipe-item{
  margin-top:100px ;
}
.image-item{
    border: 1px solid #ccc;
    box-shadow: 0 0 20px #ccc;
    margin: 10px 15px;
}
.image-item>div{
    display: inline-block;
    width: 100%;
}
.image-item>div>img{
    width: 100%;
    min-height: 2.666667rem;
    background-size: cover;
    vertical-align: middle;

}
.item-body{
    padding: 5px 20px;
}
.item-desc{
    display: flex;
    justify-content: space-between;
    font-size: 15px;
}
h4{
  color:rgb(131, 125, 125);
}

.iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    border: none;
    max-width: 600px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.backIcon{
    position: absolute;
    font-size: 12px;
    margin: 15px 50px;
    z-index: 100000;
    color: goldenrod;
    background: black;
    border-radius: 4px;
    padding: 2px;
    font-weight: bold;
    cursor: pointer;
}
.serviceBackIcon{
    position: absolute;
    font-size: 12px;
    margin: 36px 132px;
    z-index: 100000;
    color: goldenrod;
    background: black;
    border-radius: 4px;
    padding: 2px;
    font-weight: bold;
    cursor: pointer;
}
.gotoEntry img{
  max-width:600px;
  width:90%;
  margin-top: 10px;
}
/deep/.van-swipe__indicators{
  bottom: 0;
}
/deep/.van-swipe__indicator{
  background-color: rgb(61, 2, 255);
}
/deep/.van-radio__label{
  font-size: 20px;
}
</style>
