<template>
    <div id="header">
    <van-nav-bar title="六合宝典">
        <template #left>
            <van-icon name="share" size="22" color="#fff" @click="copy(shareLink)" class="tag-read"/>
        </template>
        <template #right>
            <van-icon @click="clickRouteTo" name="search" size="22" color="#fff"/>
        </template>
    </van-nav-bar>

    <van-tabs  @click="handleChangeCode" :active="active">
        <van-tab title="澳门六合彩" name="71"></van-tab>
        <van-tab title="香港六合彩" name="28"></van-tab>
        <!-- <van-tab title="老澳门六合彩" name="75"></van-tab> -->
    </van-tabs>
    </div>
</template>

<script>
// import bus from '../js/eventBus'
import emitter from "../util/eventBus";

import{imgLink}from '../api/http'
import { NavBar,Icon,Tab, Tabs, Toast } from 'vant';
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
                this.active = localStorage.getItem('code')
            }else{
                this.code = '71'
                this.active = '71'
            }
            this.getLink()
            this.getWebTittle()
        },
        data() {
            return {
            //   activeName: 'aomen',
              code:'',
              active:'',
              shareLink:'',
              webTittle:''
            }
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-tabs':Tabs,
            'van-tab':Tab,
        },
        methods: {
             handleChangeCode(name) {
                 this.code = name
                 this.active = name
                //  bus.$emit('sendBybus',this.code)
                this.$emit("changeCode",this.code)

                // 前面是事件，后面是要传入的参数
                emitter.emit("response", this.code)

                // console.log(this.code)
            },
            getWebTittle(){
                let data = {
                key:'web-title'//下载链接
                }
                imgLink(data).then(res=>{
                this.webTittle = res.data.data
                })
            },
            clickRouteTo(){
                this.$router.push({
                    name: `SearchIndex`,
                })
            },
            getLink(){
                let data = {
                    key:'share-link'//分享链接
                }
                imgLink(data).then(res=>{
                    this.shareLink = res.data.data
                    // this.copy(this.shareLink)
                })
            },
            copy (shareLink) {
                let OrderNumber = shareLink;
                // 创建一个input 元素
                // createElement() 方法通过指定名称创建一个元素
                let newInput = document.createElement("input");
                // 讲存储的数据赋值给input的value值
                newInput.value = OrderNumber;
                // appendChild() 方法向节点添加最后一个子节点。
                document.body.appendChild(newInput);
                // 选中input元素中的文本
                // select() 方法用于选择该元素中的文本。
                newInput.select();
                // 执行浏览器复制命令
                //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
                document.execCommand("Copy");
                // 清空输入框
                newInput.remove();
                // 清空输入框
                Toast('复制链接成功')
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../style/common.css';
    /deep/.van-tabs__line{
        background-color:rgb(248, 99, 13);
        bottom: 25px;
        width: 70px;
    }


    #header{
        position: fixed;
        width: 100%;
        max-width: 600px;
        z-index: 10;
    }
</style>