<template>
    <div>
        <div class="headWrap"> 
            <van-nav-bar title='属性参照' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
        </div>
         <div style='width:100%;height:20px;'></div>  <!--增加头部高度，避免盖住内容 -->
        <div class="attributionTabs">
            <span @click="handleChange('0',bs)" :class="spanActive == '0'?'tabs_active':''">波色</span>
            <span @click="handleChange('1',sx)" :class="spanActive == '1'?'tabs_active':''">生肖</span>
            <span @click="handleChange('2',wx)" :class="spanActive == '2'?'tabs_active':''">五行</span>
        </div>
        <div v-if="isBs" class="colorList">
            <div v-for="(item,index) in bsList" :key="index+20">
                <p>{{index}}波</p>
                <div>
                    <a v-for="(img,i) in item" :key="i+20">
                        <img :src="require(`../assets/${img}.png`)" alt="">
                    </a>
                </div>
            </div>
        </div>
        
        <div v-if="isSx" class="sxList">
            <div v-for="(item,index) in sxList" :key="index+20">
                <p>{{index}}</p>
                <div>
                    <a v-for="(img,i) in item" :key="i+20">
                        <img :src="require(`../assets/${img}.png`)" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div v-if="isWx" class="sxList">
            <div v-for="(item,index) in wxList" :key="index+20">
                <p>{{index}}</p>
                <div>
                    <a v-for="(img,i) in item" :key="i+20">
                        <img :src="require(`../assets/${img}.png`)" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div style='width:100%;height:80px;'></div>  <!--增加脚部高度，避免盖住内容 -->
    </div>
</template>

<script>
import {openDataAttributes} from '../api/http'
import { NavBar} from 'vant';
    export default {
        created(){
           this.getAttDetail()
        },
        components:{
            'van-nav-bar':NavBar,

        },
        data() {
            return {
                spanActive:0,
                bsList:[],
                wuList:[],
                sxList:[],
                isBs:true,
                isWx:false,
                isSx:false,
            }
        },
        methods: {
            onClickLeft(index) {
                this.$router.push("/");
            },
            handleChange(num){
                this.spanActive = num
                if(num == 0){ //波色
                    this.isBs = true
                    this.isWx = false
                    this.isSx = false
                }else if(num == 1){ //生肖
                    this.isBs=false
                    this.isWx=false
                    this.isSx=true
                }else if(num == 2){ //五行
                    this.isBs=false
                    this.isWx=true
                    this.isSx=false
                }
            },
            getAttDetail(){
                openDataAttributes().then(res=>{
                    this.bsList = res.data.data.bs
                    this.wxList = res.data.data.wx
                    this.sxList = res.data.data.sx
                })
            },
        },
    }
</script>

<style lang='scss' scoped>
    .headWrap{
        position: relative;
        width: 100%;
        height: 45px;
        font-size: 15px;
        text-align: center;
        color: #fff;
        background: rgb(174, 115, 252);
    }
    .attributionTabs{
        display: flex;
        justify-content: space-around;
        width: 80%;
        height: 30px;
        position: relative;
        margin: 0 auto;
        top:25px;
    }
    .attributionTabs span:last-child{
        border-radius: 0 5px 5px 0;
        border-left-width: 0;
    }
    .attributionTabs span:first-child{
        border-radius: 5px 0 0 5px;
    }
    .attributionTabs span{
        cursor: pointer;
        border: 1px solid #ff5000;
        width: 50%;
        height: 33px;
        line-height: 33px;
        text-align: center;
        font-size: 20px;
        color: #ff5000;
        background-color: #fff;
    }
    .attributionTabs span.tabs_active{
        color: #fff;
        background-color: #ff5000;
    }
    .guessInfo{
        margin-top: 30px;
        text-align: center;
    }
    .guessInfo p{
        margin: 10Px 10px;
        font-size: 17px;
        text-align: left;
    } 
    .colorList{
        position: relative;
        top: 50px;
        text-align: left;
    }   
    .colorList>div{
        display: grid;
        grid-gap: 10px;
        padding: 10px 10px;
        border-bottom: 1px solid #eee;
    } 
    .colorList>div p{
        margin-left: 15px;
        font-size: 15px;
        color: #999;
    }
    .colorList>div>div a {
        display: inline-block;
        margin: 3px;
    }
    .colorList>div>div a img{
        width: 30px;
        height: 30px;
    }
    .sxList{
        position: relative;
        top: 40px;
        text-align: left;
    }
    .sxList>div{
        display: flex;
        align-items: flex-start;
        padding: 10px 10px;
        border-bottom: 1px solid #eee;
    }
    .sxList>div p{
        margin: 10px 5px 0;
        font-size: 15px;
        color: #999;
    }
    .sxList>div>div a{
        display: inline-block;
        margin: 3px;
    }
    .sxList>div>div a img{
        width: 30px;
        height: 30px;
    }
</style>