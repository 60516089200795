<template>
    <div>
        <van-nav-bar title='忘记密码' fixed left-arrow @click-left="onClickLeft" >
            <template #right>
                <span @click="gotoRegister">注册</span>
            </template>
        </van-nav-bar>
        <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
        <div v-if="isExsit">
            <van-field
                v-model="loginName"
                clearable
                placeholder="请输入用户名"
            />
            <div @click="nextStep">
                <span class="btn">下一步</span>
            </div>
        </div>
        
        <div v-if="!isExsit">
            <van-cell-group inset>
                <van-field
                    v-model="question"
                    readonly
                />
                <van-field
                    v-model="questionAnswer"
                    clearable
                    placeholder="请输入问题答案"
                />
                <van-field
                    v-model="password"
                    clearable
                    required
                    :formatter="passwordformatter"
                    :error-message="passwordErrorMsg"
                    placeholder="请输入密码"
                />
                <van-field
                    v-model="passwordAgain"
                    clearable
                    required
                    :formatter="passwordAgainformatter"
                    :error-message="passwordAgainErrorMsg"
                    placeholder="请再次输入密码"
                />
            </van-cell-group>
            <div @click="comfirm">
                <span class="btn">确定</span>
            </div>
        </div>
    </div>
</template>

<script>
import { NavBar,Icon,Field, Toast,CellGroup } from 'vant';
import { userCheckUser,userUserQuestion,resetPassword } from '../api/http';

    export default {
        data() {
            return {
                loginName:'',
                isExsit:true,
                passwordErrorMsg:'',
                passwordAgainErrorMsg:'',
                questionAnswer:'',
                password:'',
                passwordAgain:'',
                question:''
            }
        },
        components:{
            'van-nav-bar':NavBar,
            'van-icon':Icon,
            'van-field':Field,
            'van-cell-group':CellGroup,
        },
        methods: {
             gotoRegister(name) {
                 this.$router.push({
                     name:`Register`
                 })
            },
            onClickLeft(){
                this.$router.go(-1)
            },
            nextStep(){
                if(this.loginName == ''){
                    Toast('请填写用户名')
                    return
                }else{
                    let data = {
                        loginName:this.loginName
                    }
                    userCheckUser(data).then(res=>{
                        if(res.data.code == 200){
                            this.isExsit = false
                            this.getUserQuestion()
                        }else{
                            this.isExsit = true
                            Toast(res.data.msg)
                        }

                    }).catch(err=>{
                        console.log(err)
                    })
                }
            },
            getUserQuestion(){
                let data = {
                    loginName:this.loginName
                }
                userUserQuestion(data).then(res=>{
                    if(res.data.code == 200){
                        this.question = res.data.data
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            comfirm(){
                if(this.password == ''){
                    Toast('密码不能为空')
                    return
                }
                else if(this.password != this.passwordAgain){
                    Toast('两次密码不一致')
                    return
                }else if(this.questionAnswer == ''){
                    Toast('请填写密保答案')
                    return
                }else{
                    let data = {
                        loginName:this.loginName,
                        questionAnswer:this.questionAnswer,
                        password:this.password
                    }
                    resetPassword(data).then(res=>{
                        if(res.data.code == 200){
                            if(res.data.code == 200){
                                Toast('修改成功，请登录！')
                                this.$router.push({
                                    name:`Login`
                                })
                            }else{
                                Toast(res.data.msg)
                            }
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }
            },
            passwordformatter(val){
                console.log(val)
                let reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Z][A-z0-9]{5,11}$/
                 if (!val) return '';
                if (!reg.test(val)) {
                    this.passwordErrorMsg = '密码6-12位且必须首字母大写加小写字母和数字组合';
                } else {
                    this.passwordErrorMsg = '';
                }
                return val;
            },
            passwordAgainformatter(val){
                if (!val) return '';
                if(this.password != val){
                    this.passwordAgainErrorMsg = '两次密码不一致';
                }else{
                    this.passwordAgainErrorMsg = ''
                }
                return val
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../style/common.css';
    .btn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 25px;
        width: 330px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    /deep/.van-cell{
        width: 85%;
        margin: 20px auto;
        border-radius: 8px;
        background-color:rgba(194, 194, 194, 0.123) ;
    }
</style>