<template>
    <div>
        <div class="loginWrap">
            <div class="loginLogo">
                <img src="../assets/userAvatar.png" alt="">
            </div>
            <div class="loginCommon">
                    <van-field
                        v-model="username"
                        required
                        clearable
                        placeholder="请输入用户名"
                       
                    />
                    <!-- <van-field
                        v-model="username"
                        required
                        clearable
                        placeholder="请输入用户名"
                        :formatter="userformatter"
                        :error-message="usernameErrorMsg"
                    /> -->
                    <!-- <van-field
                        v-model="password"
                        required
                        :type="passwordType"
                        placeholder="请输入密码"
                        :formatter="passwordformatter"
                        :error-message="passwordErrorMsg"
                    > -->
                    <van-field
                        v-model="password"
                        required
                        :type="passwordType"
                        placeholder="请输入密码"
                    >
                        <template #right-icon>
                            <span class="solts" @click="switchPasswordType">
                                <van-icon class="eyes" name="eye" v-if="passwordType==='password'"/>
                                <van-icon class="eyes" name="closed-eye" v-else />
                            </span>
                        </template>
                    </van-field>
                    <div class="verifyFiled">
                        <van-field
                                v-model="verifyCode"
                                required
                                clearable
                                placeholder="请输入验证码"
                                class="verifyInput"
                                :error-message="verifyCodeErrorMsg"
                            >
                            <template #right-icon>
                                <div class="verify-box" @click="refreshCode">
                                    <Sidentify :identifyCode="identifyCode"></Sidentify>
                                </div>
                            </template>
                        </van-field>
                    </div>
                    <div @click="handleLogin">
                        <span class="btn">登录</span>
                    </div>
                    <div class="bottomTxt">
                        <div  class="bottomOther"><a><span> <a href="#/forget">忘记密码</a>  </span></a></div>
                        <div @click="gotoRegister" class="bottomOther"><a><span>立即注册</span></a></div>
                    </div>
            </div>
            <div class="loginOther">
                <p>
                    登录注册即代表同意
                    <a href="/">用户协议</a>
                    和
                    <a href="/">隐私政策</a>
                </p>
            </div>
            <div class="loginLeftArrow" @click="goBack">
                <van-icon name="arrow-left" />
            </div>
            
        </div>
    </div>
</template>

<script>
import Sidentify from '../components/identify.vue' 
import { Toast } from 'vant';
import { Tabbar, TabbarItem,icon, Field, CellGroup} from 'vant';
import {userLogin} from '../api/http'
    export default {
        created(){
            this.refreshCode()
        },
        components:{
            'van-tabbar':Tabbar,
            'van-tabbar-item':TabbarItem,
            'van-icon':icon,
            'van-cell-group':CellGroup,
            'van-field':Field,
            Sidentify
        },
        data() {
            return {
                  active: 0,
                  username:'',
                  password:'',
                  passwordErrorMsg:'',
                  verifyCodeErrorMsg:'',
                  usernameErrorMsg:'',
                  passwordType: 'password',//输入框类型,
                  identifyCode:'',
                  verifyCode:'',
                  
            }
        },
        methods: {
            goBack() {
                 this.$router.go(-1) 
            },
            switchPasswordType() {
                this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
            },
            randomNum(min,max){
                return Math.floor(Math.random() * (max - min) + min)
            },
            refreshCode(){
                this.makeCode()
                // console.log('当前验证码',this.identifyCode)
            },
            makeCode(o,l){
                this.identifyCode = String(this.randomNum(2345,9999))
            },
            handleLogin(){
                console.log(this.verifyCode)
                if(this.username == ''){
                    this.usernameErrorMsg = '用户名不能为空'
                }else if(this.username != ''){
                    this.usernameErrorMsg = ''
                }
                if(this.verifyCode == ''){
                    this.verifyCodeErrorMsg = '请输入验证码'
                }else if(this.verifyCode != ''){
                    this.verifyCodeErrorMsg = ''
                }
                if(this.password == ''){
                    this.passwordErrorMsg = '请输入密码'
                }else if(this.password != ''){
                    this.passwordErrorMsg = ''
                }
                if(this.verifyCode != this.identifyCode){
                    Toast('验证码不正确')
                    this.refreshCode()
                }else{
                    let data = {
                        loginName:this.username,
                        password:this.password
                    }
                    userLogin(data).then(res=>{
                        if(res.data.code == 200){
                            this.$store.commit('$_setStorage',res.data.data.token)
                            this.$store.commit('setUsername',res.data.data.userName)
                            this.$store.commit('setUserid',res.data.data.userId)
                            this.$store.commit('setAvatar',res.data.data.avatar)
                            this.$store.commit('setPassword',this.password)
                            this.$store.commit('setLogname',res.data.data.loginName)
                            this.$store.commit('setLoginStatus',false)
                            // this.$router.push({
                            //     name: `User`,
                            // })
                            // this.$router.replace({
                            //     path: '/login',
                            //     query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面
                            // })
                            Toast('登录成功')
                            if (this.$route.query.redirect) { //如果存在参数
                                let redirect = this.$route.query.redirect
                                console.log(redirect)
                                if(redirect == '/infoSet'){
                                    this.$router.push('/user')
                                }else{
                                    this.$router.push(redirect)//则跳转至进入登录页前的路由
                                }
                            }else{
                                this.$router.push('/user')//否则跳转我的页面
                            }
                        }else{
                            Toast(res.data.msg)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })

                }
                

            },
            userformatter(val){
                let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
                 if (!val) return '';
                if (!reg.test(val)) {
                    this.usernameErrorMsg = '用户名格式不正确';
                } else {
                    this.usernameErrorMsg = '';
                }
                return val;
            },
            passwordformatter(val){
                let reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Z][A-z0-9]{5,11}$/
                 if (!val) return '';
                if (!reg.test(val)) {
                    this.passwordErrorMsg = '密码格式不正确';
                } else {
                    this.passwordErrorMsg = '';
                }
                return val;
            },
            gotoRegister(){
                this.$router.push({
                    name: `Register`,
                })
            },
           
        },
    }
</script>

<style lang='scss' scoped>
    .loginWrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100vh;
        background: url(../assets/loginBg.png) no-repeat;
        background-size: 100% 100%;
    }
    .loginLeftArrow{
        position: absolute;
        top: 0.4rem;
        left: 0.4rem;
    }
    .loginLogo{
        width: 25%;
        vertical-align: middle;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
    }
    .loginLogo img{
        width: 100%;
    }
    .loginCommon{
        border-radius: 0.266667rem;
        padding: 0.266667rem;
        width: 75%;
        background-color: #fff;
        margin-top: 27px;
    }
    .eyes{
        color: rgb(173, 169, 169) !important;
    }
    .loginCommon .btn{
        display: inline-block;
        border: 1px solid #ff5000;
        border-radius: 30px;
        width: 90%;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .bottomTxt{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin: 10px auto;
        font-size: 15px;
    }
    .bottomOther:last-child a span{
        color: #ff5000;
    }
    .bottomOther a{
        color: rgb(112, 109, 109);
    }
    a:active{
        text-decoration: none;
    }
 
    .verifyInput{
        height: 38px;
        padding: 0 0 0 15px;

    }
    .verify-box{
        width: 100%;
    }
    .loginOther p{
        margin-top: 20px;
        font-size:15px;
        color: #666;
    }
    .loginOther p a{
        color: #ff5000;
        outline: none;
        background: none;
        text-decoration: none;
    }
    /deep/.van-icon{
        color:#fff;
        font-size:20px;
    }
    /deep/.van-icon-clear{
        color:rgb(173, 169, 169);
        font-size:20px;
    }
    /deep/.van-cell{
        width: 85%;
        margin: 25px auto;
        border-radius: 8px;
        background-color:rgba(194, 194, 194, 0.212) ;
    }
    ::v-deepinput::-webkit-input-placeholder {
        color: #726f6f ;
    }
</style>