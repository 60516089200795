<template>
	<div class="info-span04" style="color: #333333;">

        <div  class="homeLotery">
            <div class='loteryTittle'>第{{theNewestDate}}期最新开奖结果 <span class="toRecord" @click="goToRoute()" to="/record">开奖记录></span></div>
                <div class="kaiJiangING" v-if="isLoop">开奖中. . . .</div>
                <van-loading v-if="isLoop" color="#0094ff" />
                <div v-else class='loteryNums newLoteryList' v-for="(item,i) in newLoteryList" :key="i+10" >
                    <div v-if="item[0] !== '-'">
                        <img class="wow bounceInDown" :data-wow-delay="'0.'+item.delay+'s'" :src="require(`../assets/${item[0]}.png`)" >
                        <div  class="loteryScribe wow bounceInDown" :data-wow-delay="'0.'+item.delay+'s'" >
                            <span>{{item[1]}}/{{item[2]}}</span>
                        </div> 
                    </div>
                    <div style="color:red;font-weight: 800;" v-else class="wow bounceInDown" :data-wow-delay="'0.'+item.delay+'s'">?</div>

                    
                </div>  
                    <h2 class="jiahao">+</h2>
                <div class="drawInfo"><span>第{{theNextDate}}期 {{nextPrizeTime}} {{weekTxt}}</span></div>
            </div>
            <!-- <h1>{{countTime}}</h1> -->
        </div>
</template>

<script>
import { WOW } from 'wowjs'
import moment from 'moment'
import emitter from "../util/eventBus";
import {openLatest,openLatestSingle} from '../api/http.js'
import { CountDown, Loading } from 'vant';
	export default {
		name: 'numberRolling',
        components:{
            'van-loading':Loading,
            'van-count-down':CountDown,
        },
		data () {
			return {
				interval: null,
				interval_one: null,
				interval_two: null,
				awardCode: [
					{name: 'oneDigit', value: '?'},
					{name: 'tenDigit', value: '?'},
					{name: 'hundredsDigit', value: '?'}
				],
                newLoteryList:[],
                theNewestDate:'',
                theNextDate:'',
                nextPrizeTime:'',
                weekTxt:'',
                prizeTime:'',
                weekText:'',
                code:'',
                list:[],
                polling: '',
                isLoop:false,
                lunxunTime:'',
                lunxunnextPrizeTime:'',
                currentdate:'',
                timer: null, // 定时器
                timer2: null, // 定时器
                time:'',
                countTime:'',
                nowTime:'',
                nextPrizeseconds:'',
                nowseconds:'',
                date1: ["15:12:43", "16:15:35", "18:35:56"], // 时间数组，时分秒
                date2: ["16:12:43", "18:19:34", "19:35:50"], // 时间数组，时分秒
                arr: [], // 时间差计算结果的存放数组
                isStatCount:false,
                count:''
			}
		},
		props:{
			
		},
        mounted(){
            this.Time() //调用定时器
        },
        // activated () {
        //     this.getlunxunData()
        // },
        deactivated () {
            // 页面关闭（路由跳转）时清除定时器
        },
        destroyed() {
            if (this.timer) {
                window.clearInterval(this.timer);
            }
            if (this.timer2) {
                window.clearInterval(this.timer2);
            }
        },
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            // 这样两个组件间就可以进行传值通信了
            emitter.on("response", (response) => {
                this.code = response
                this.getNewestLotery()
            })
            this.getNewestLotery()
            
            
        },
        watch: {
                newLoteryList() {
                    this.$nextTick(() => {
                        let wow =  new WOW({
                            boxClass: "wow", // animated element css class (default is wow)
                            animateClass: "animated", // animation css class (default is animated)
                            offset: 0, // distance to the element when triggering the animation (default is 0)
                            mobile: true, // trigger animations on mobile devices (default is true)
                            live: false, // act on asynchronously loaded content (default is true)
                            callback: function (box) {

                            },
                            scrollContainer: null, // optional scroll container selector, otherwise use window
                        });
                        wow.init()
                    });
                },
            },
		// 开奖效果方法
        
		methods: {
            // 倒计时定时器
            Time() {
                if(this.timer != null){
                    window.clearInterval(this.timer);
                }
                this.timer =  setInterval(() => {
                    this.countTime -= 1
                    if(this.countTime<=0){
                        this.Time2()
                    }
                }, 1000)
            },
            // 轮询定时器
            Time2(){
                window.clearInterval(this.timer)
                if(this.timer2 != null){
                    window.clearInterval(this.timer2);
                }
                this.timer2 = setInterval(() => {
                    this.getDateLoop()
                },3000)
            },
            getDateLoop() { 
                    this.isLoop = true
                    let data = {
                        code : this.code
                    }
                    openLatestSingle(data).then(res =>{
                        if(res.data.code == 200){
                            this.newLoteryList = res.data.data.prize
                            this.$nextTick(() => {
                                this.isLoop = false
                            },3000);
                            for (let i = 0; i < this.newLoteryList.length ; i++) {
                                this.newLoteryList[i].delay = i+3
                                // console.log(this.newLoteryList[i].indexOf('-'))
                                if(this.newLoteryList[6].indexOf('-')==-1){
                                    window.clearInterval(this.timer2) // 结束轮询
                                    //轮询结束重新调用计算倒计时时间
                                    this.$nextTick(() => {
                                        this.times(); // 页面加载时调用
                                    });
                                    // 并启用倒计时 准备下次轮询事件
                                    this.Time()
                                }
                            }
                        }
                            this.theNewestDate = res.data.data.issueNo.substring(res.data.data.issueNo.length-3)
                            this.theNextDate = res.data.data.nextIssueNo.substring(res.data.data.issueNo.length-3)
                            this.nextPrizeTime = res.data.data.nextPrizeTime
                            this.weekTxt = res.data.data.dayOfTheWeek
                            this.prizeTime = res.data.data.prizeTimeForHome
                            
                            // 截取字符串最后两位数字
                            // this.lunxunTime = this.getNowFormatDate().slice(0, -1 ).substring(this.getNowFormatDate().slice(0, -1 ).length-2)
                            // this.lunxunnextPrizeTime = this.nextPrizeTime.substring(this.nextPrizeTime.length-2)-1

                            // if(this.lunxunTime != this.lunxunnextPrizeTime ){// 结束轮询
                            //     window.clearInterval(this.timer2) // 结束轮询
                            //     this.timer2 = null
                            //     this.$nextTick(() => {
                            //         this.isLoop = false
                            //     });
                            // }
                            // 计算星期几
                            var dateArray = this.prizeTime.substring(0,10).split("-"), date;
                            date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
                            let week = date.getDay()
                            switch (week) {
                            case 0:
                                this.weekText = '星期日'
                            break;
                            case 1:
                                this.weekText = '星期一'
                            break;
                            case 2:
                                this.weekText = '星期二'
                            break;
                            case 3:
                                this.weekText = '星期三'
                            break;
                            case 4:
                                this.weekText = '星期四'
                            break;
                            case 5:
                                this.weekText = '星期五'
                            break;
                            case 6:
                                this.weekText = '星期六'
                            break;
                        }
                        })
                        .catch(err => {
                            console.log(err);
                            
                        });
            },
            getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var seperator2 = ":";
                //获取当前时间前1分钟时间
                var minutes=parseInt("0");  
                var interTimes=minutes*60*1000;
                var interTimes=parseInt(interTimes);  
                    date=new   Date(Date.parse(date)-interTimes);
                    var month = date.getMonth() + 1;
                    var strDate = date.getDate();
                    var hour = date.getHours();
                    var minutes = date.getMinutes();
                    var seconds = date.getSeconds();
                    if (month >= 1 && month <= 9) {
                        month = "0" + month;
                    }
                    if (strDate >= 0 && strDate <= 9) {
                        strDate = "0" + strDate;
                    }
                    if (hour >= 0 && hour <= 9) {
                            hour = "0" + hour;
                    }
                    if (minutes >= 0 && minutes <= 9) {
                            minutes = "0" + minutes;
                    }
                    if (seconds >= 0 && seconds <= 9) {
                        seconds = "0" + seconds;
                    }
                    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
                            + " " + hour + seperator2 + minutes 
                            + seperator2 + seconds
                    this.currentdate = currentdate
                    this.lunxunTime = this.currentdate.slice(0, -1 ).substring(this.currentdate.slice(0, -1 ).length-2)
                    this.lunxunnextPrizeTime = this.nextPrizeTime.substring(this.nextPrizeTime.length-2)-1
                    return currentdate
            },
            // formatHMStoS(value) {
			// 	var h, m= 0
			// 	if (value.indexOf(":") != -1) {
			// 		h = value.split(":")[0];
			// 		m = value.split(":")[1].split(':')[0];
            //         // console.log(m)
			// 	} 
            //     else if (value.indexOf(":") == -1) {
			// 			h=0
			// 			m=0
			// 	}
			// 	var ss = h * 60 * 60 + m * 60 
            //     this.countTime = ss * 1000
            //     // console.log(ss)
			// 	return ss;
			// },
            getNewestLotery(){
                let data = {
                code : this.code
                }
                openLatest(data).then(res =>{
                if(res.data.code == 200){
                    this.newLoteryList = res.data.data.prize
                    for (let i = 0; i < this.newLoteryList.length ; i++) {
                        this.newLoteryList[i].delay = i+3
                    }
                }

                    this.theNewestDate = res.data.data.issueNo.substring(res.data.data.issueNo.length-3)
                    this.theNextDate = res.data.data.nextIssueNo.substring(res.data.data.issueNo.length-3)
                    this.nextPrizeTime = res.data.data.nextPrizeTime
                    this.weekTxt = res.data.data.dayOfTheWeek
                    this.prizeTime = res.data.data.prizeTimeForHome
                    console.log(this.nextPrizeTime,this.getNowFormatDate())
                    this.lunxunTime = this.getNowFormatDate().slice(0, -1 ).substring(this.getNowFormatDate().slice(0, -1 ).length-2)
                    this.lunxunnextPrizeTime = this.nextPrizeTime.substring(this.nextPrizeTime.length-2)-1
                    this.time = this.nextPrizeTime.substring(this.nextPrizeTime.length-5)
                    this.nowTime = this.getNowFormatDate().slice(0, -1 ).substring(this.getNowFormatDate().length-6)

                    this.nextPrizeseconds = parseInt(Date.parse(this.nextPrizeTime));
                    this.nowseconds = parseInt(new Date().getTime() )
                    // console.log(this.time,this.nowTime)
                    this.$nextTick(() => {
                        this.times(); // 页面加载时调用
                    });
 
                    // this.formatHMStoS(this.time)
                    // this.formatHMStoS(this.nowTime)
        
                var dateArray = this.prizeTime.substring(0,10).split("-"), date;
                date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
                let week = date.getDay()
                switch (week) {
                    case 0:
                        this.weekText = '星期日'
                    break;
                    case 1:
                        this.weekText = '星期一'
                    break;
                    case 2:
                        this.weekText = '星期二'
                    break;
                    case 3:
                        this.weekText = '星期三'
                    break;
                    case 4:
                        this.weekText = '星期四'
                    break;
                    case 5:
                        this.weekText = '星期五'
                    break;
                    case 6:
                        this.weekText = '星期六'
                    break;
                }
                })
                .catch(err => {
                console.log(err);
                });
            },
            goToRoute(){
            this.$router.push({
                name: `Record`,
                })
            },
            times() {
                /*  let days = this.$moment(endTime,指定运算的时间("hh:mm:ss")).diff(  //
                    this.$moment(startTime,指定运算的时间),
                    "seconds" 指定运算的结果是秒
                ); */

                    //计算两个日期相差都少小时分钟秒
                    // console.log(this.time,this.nowTime)
                    // 得到秒数
                    let days = moment(this.nextPrizeTime, "YYYY-MM-DD hh:mm:ss").diff(
                        moment(this.getNowFormatDate(), "YYYY-MM-DD hh:mm:ss"),
                    "seconds"
                    );
                    // console.log(days)
                    this.countTime = days
                    // this.showTime(Math.abs(days));
             
                },
                // 时间日期转换，将秒数转换成02:20:22形式
                // showTime(val) {
                // if (val < 60) {
                //     return val + "秒";
                // } else {
                //     var min_total = Math.floor(val / 60); // 分钟
                //     var sec =
                //     Math.floor(val % 60) > 9
                //         ? Math.floor(val % 60)
                //         : "0" + Math.floor(val % 60); // 余秒
                //     if (min_total < 60) {
                //     return "00:" + min_total + ":" + sec;
                //     } else {
                //     var hour_total =
                //         Math.floor(min_total / 60) > 9
                //         ? Math.floor(min_total / 60)
                //         : "0" + Math.floor(min_total / 60); // 小时数
                //     var min =
                //         Math.floor(min_total % 60) > 9
                //         ? Math.floor(min_total % 60)
                //         : "0" + Math.floor(min_total % 60); // 余分钟
                //         // console.log(hour_total)
                //     return hour_total + ":" + min + ":" + sec;
                //     }
                // }
                // },
		},
	}

</script>

<style src="wowjs/css/libs/animate.css"></style>
<style lang="scss" scoped>
h1 {
  color: rgb(16, 123, 218);
}
@media screen and (min-width:700px) {
/* pc端 */
    .downtext{
      position: absolute;
      top:60px;
      left: -15px;
      color: #fff;
      line-height: 10px;
      font-size: 15px;
      width: 80px;
      display: inline-block;
    }
    .homeLotery{
      position: relative;
      background: url(../assets/kuang.png) no-repeat;
      background-size: 100% 100%;
      height: 160px;
      margin:10px 10px;
      justify-content: center;
      display: flex;
    }
    .loteryTittle{
      display: flex;
      padding-top: 20px;
      position: absolute;
      font-size: 15px;
    }
    .toRecord{
      padding-left: 10px;
      color:rgb(255, 82, 2);
    }
    .loteryNums{
      position: relative;
      top:60px;
      width: 10%;
      margin:5px 5px;
    }
    .loteryNums img{
      vertical-align: middle;
      width: 80%;
      position: relative;
      bottom: 5px;
    }
    .loteryNums:nth-child(8){
      padding-left: 45px;
    }
    .jiahao{
      position: absolute;
      right: 110px;
      top:63px;
      font-size: 20px;
    }
    .loteryScribe{
      text-align: center;
      padding-top: 2px;
      display: block;
      font-size: 13px;
    }
    .drawInfo{
      color: #999;
      position: absolute;
      bottom: 5px;
      font-size: 13px;
    }
}
/* 我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线我是分割线*/
/* 移动端 */
@media screen and (max-width:750px) {
          .homeLotery{
            position: relative;
            background: url(../assets/kuang.png) no-repeat;
            background-size: 100% 100%;
            height: 160px;
            margin:10px 10px;
            justify-content: center;
            display: flex;
          }
          .loteryTittle{
            display: flex;
            padding-top: 20px;
            position: absolute;
            font-size: 15px;
          }
          .toRecord{
            padding-left: 10px;
            color:rgb(255, 82, 2);
          }
          .loteryNums{
            /* display: flex; */
            /* justify-content: space-around;
            align-items: center;
            padding:8px 8px; */
            position: relative;
            top:60px;
            width: 10%;
            margin:0 5px;
          }
          .loteryNums img{
            vertical-align: middle;
            width: 100%;
            position: relative;
            bottom: 5px;
          }
          .loteryNums:nth-child(8){
            padding-left: 45px;
          }
          .jiahao{
            position: absolute;
            right: 60px;
            top:53px;
            font-size: 20px;
          }
          .loteryScribe{
            text-align: center;
            padding-top: 7px;
            display: block;
            font-size: 13px;
          }
          .drawInfo{
            color: #999;
            position: absolute;
            bottom: 10px;
            font-size: 13px;
          }
}
	.openNumber {
		display: inline-block;
		width: vw(52);
		height: vw(52);
		padding-right: vw(36);
		.num {
			width: vw(52);
			height: vw(52);
			overflow: hidden;
			.span {
				color: #ff0404;
				width: vw(52);
				height: vw(52);
				font-weight: bold;
				float: left;
				.span div {
					text-align: center;
				}
			}
			.down-up-translate-fade-enter-active,
			.down-up-translate-fade-leave-active {
				transition: all .1s;
				-webkit-transition: all .1s;
				-moz-transition: all .1s;
				-o-transition: all .1s;
			}
			.down-up-translate-fade-enter,
			.down-up-translate-fade-leave-active {
				opacity: 1;
			}
			.down-up-translate-fade-leave-active {
				transform: translateY(-50px);
				-webkit-transform: translateY(-50px);
				-moz-transform: translateY(-50px);
				-o-transform: translateY(-50px);
			}
			.value {
				background: url('../assets/youmo.png') no-repeat top center;
				background-size: 100% 100%;
				width: 2rem;
				height: 2rem;
				line-height: 2rem;
				font-size: .6rem;
				font-weight: bold;
                margin:.2rem;
			}
            
		}
	}
    .kaiJiangING{
        position: absolute !important;
        top: 50%;
        font-size: 1rem;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        color: #0094ff;
        font-weight:600;
    }
    /deep/.van-loading {
        position: relative;
        color: var(--van-loading-spinner-color);
        font-size: 0;
        vertical-align: middle;
        top: 35%;
    }
</style>
