<template>
    <div>
        <van-nav-bar title='我的评论' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
       <div style='width:100%;height:50px;'></div>   <!-- 增加头部脚部高度，部面盖住内容 -->
       <van-tabs  @click="handleChangeCode" :active="active">
            <van-tab title="资料大全" name="1"></van-tab>
            <van-tab title="六合图库" name="0"></van-tab>
            <van-tab title="幽默猜测" name="3"></van-tab>
        </van-tabs>
        <h3 v-if="isHaveComment">暂无评论</h3>
        <div class="commentList">
            <div class="commentItem" v-for="(item,index) in myCommentList" :key="index+20">
                <div>
                    <div class="commentItemLeft">
                        <img src="../assets/formula.jpg" alt="">
                        <div>
                            <span class="commentItemName">{{item.title}}</span>
                            <b>{{item.createTime}}</b>
                        </div>
                    </div>
                    <div class="commentItemRight">
                        <span v-if="item.category=='71'">澳门</span>
                        <span v-if="item.category=='28'">香港</span>
                        <span v-if="item.category=='75'">老澳门</span>
                    </div>
                </div>
                <div class="commentItemTxt">
                    <p></p>
                    <div class="meComment" @click="clickRouterTo(item)">
                        <a>
                            <div>
                                <b>我：</b>
                            </div>
                            <p>
                                <b>{{item.commentContent}}</b>
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {userMyComment} from '../api/http'
import { NavBar,Tabs,Tab, List } from 'vant';
    export default {
        created(){
            this.getCommentList()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-tabs':Tabs,
            'van-tab':Tab,
            'van-list':List,
        },
        data() {
            return {
                  active: 1,
                  myCommentList:[],
                  isHaveComment:false,
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/user')
            },
            handleChangeCode(name) {
                 this.active = name
                 this.getCommentList()
            },
            getCommentList(){
                let data = {
                    moduleType:this.active
                }
                userMyComment(data).then(res=>{
                    if(res.data.code == 200){
                        this.myCommentList = res.data.data
                        if(this.myCommentList.length == 0){
                            this.isHaveComment = true
                        }else{
                            this.isHaveComment = false
                        }
                    }
                })
            },
            clickRouterTo(data){
                if(this.active == 0){
                    this.$router.push({                                                     
                        name: 'Detail',                                    
                        params: {
                                myCommentId:data.id,
                                myCommentTitle:data.title,
                        }
                    })
                }else if(this.active == 1){
                    this.$router.push({                                                     
                        name: 'ArticleDetails',                             
                        params: {
                            myCommentId:data.id,
                        }
                    })
                    localStorage.setItem('infoId',data.id)
                }else if(this.active == 3){
                    this.$router.push({                                                     
                        name: 'Guess',                             
                        params: {
                            myCommentId:data.id,
                        }
                    })
                }
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    /deep/.van-tabs__line{
        background-color:rgb(248, 99, 13);
        bottom: 25px;
        width: 70px;
    }
    .commentList .commentItem{
        border-bottom: 1px solid #eee;
        margin: 0 10Px;
        padding: 10Px 0;
    }
    .commentList .commentItem>div{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .commentList .commentItem>div .commentItemLeft{
        display: flex;
        align-items: center;
    }
    .commentList .commentItem>div .commentItemLeft>img{
        border-radius: 50%;
        width: 33px;
        height: 33px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .commentList .commentItem>div .commentItemLeft div{
        display: flex;
        flex-direction: column;
        margin-left: 10Px;
    }
    .commentList .commentItem>div .commentItemLeft div span{
        font-size: 15px;
        text-align: left;
    }
    .commentList .commentItem>div .commentItemLeft div b{
        color: #999;
        font-size: 13px;
        text-align: left;
    }
    .commentList .commentItem>div .commentItemRight span{
        border-radius: 8px;
        padding: 1px 7px;
        font-size: 14px;
        color: #fff;
        background-color: #ff5000;
    }
    .commentList .commentItem .commentItemTxt{
        display: flex;
        flex-direction: column;
        align-items: flex-start
    }
    .commentList .commentItem .commentItemTxt>p{
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 5px;
        width: 70%;
        font-size: 15px;
    }
    .commentList .commentItem .commentItemTxt .meComment{
        display: flex;
        flex-direction: column;
        margin: 10px 0 0 50px;
        width: calc(100% - 30%);
        height: 60px;
        background-color: #f5f5f5;
    }
    .commentList .commentItem .commentItemTxt .meComment a{
        display: inline-block;
        background-color: rgba(221, 221, 221, 0.26);
    }
    .commentList .commentItem .commentItemTxt .meComment a div{
        display: flex;
        align-items: center;
        margin: 10Px 10Px 0;
    }
    .commentList .commentItem .commentItemTxt .meComment a div img{
        border-radius: 50%;
        width: 21px;
        height: 21px;
    }
    .commentList .commentItem .commentItemTxt .meComment a div b{
        padding-left: 30px;
        font-size: 13px;
        color: #ff5000;
    }
    .commentList .commentItem .commentItemTxt .meComment a p{
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0 0 30px;
        width: 70%;
        height:22px;
        font-size: 15px;
        text-align: left;
    }
    h3{
        color: #ddd;
        font-size:18px ;
    }
</style>