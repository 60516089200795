<template>
    <div>
        <div>
            <van-nav-bar title='工具宝箱' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
            <div style='width:100%;height:50px;'></div>  <!--增加底部头部高度，部面盖住内容-->
            <div class="toolsList">
                <div class="toolsItem">
                    <p @click="gotoDate">
                        <img src="../assets/tools1.png" alt="">
                        <span>搅珠日期</span>
                    </p>
                </div>
                <div class="toolsItem">
                    <p>
                        <img src="../assets/tools2.png" alt="">
                        <span>敬请期待</span>
                    </p>
                </div>
            </div>
        </div>
        <van-popup v-model:show="showDate" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='搅珠日期' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <h3>搅珠日期对照表，可查看当月及下一个月的搅珠日期</h3>
            <van-calendar class="calendar" :min-date="minDate"
                :max-date="maxDate"   :readonly="true" type="single" :formatter="formatter" :show-confirm="false" :default-date="defaultDate" :poppable="false" :show-title="false"/>
            <p class="openTime">{{openTime}}</p>
        </van-popup>
    </div>
</template>

<script>
import {openTime} from '../api/http'
import { Calendar, NavBar, Popup, } from 'vant';
    export default {
        created(){
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.setMinMaxDay()
            this.handleChange()
        },
        components:{
            'van-nav-bar':NavBar,
            'van-popup':Popup,
            'van-calendar':Calendar,
        },
        data() {
            return {
                code:'',
                showDate:false,
                minDate: new Date(),
                maxDate: new Date(),
                defaultDate: new Date(),
                openTime:'',
                newkjTime:[],
                year: new Date().getFullYear(),
                month: new Date().getMonth(),
                nowDay:new Date(),
                showYear:new Date().getFullYear(),
                showMonth:new Date().getMonth(),
            }
        },
        watch:{
            defaultDate(val) {
                console.log(val)
                this.setMinMaxDay();
            }
        },
        methods: {
            onClickLeft() {
                this.$router.push('/')
                // localStorage.removeItem('code');;
            },
            gotoDate(){
                 this.showDate = true
            },
            setClickLeft(){
                this.showDate = false
            },
            formatter(day){
                // console.log(day)
                // console.log(this.newkjTime.indexOf(day.text))
                if(this.newkjTime.indexOf(day.text) > -1){
                    day.bottomInfo = '开奖日'
                }else{
                    day.bottomInfo = ''
                }
                return day;
            },
            setMinMaxDay(){
                this.showYear= this.defaultDate.getFullYear();
                this.showMonth = this.defaultDate.getMonth();
                var firstDay = new Date(this.defaultDate);
                firstDay.setDate(1)
                var endDay = new Date(this.showYear,this.showMonth+1,1);
                this.minDate =new Date(
                    this.showYear,
                    this.showMonth,
                    firstDay.getDate()
                )
                endDay.setDate(0)
                this.maxDate =new Date(
                    this.showYear,
                    this.showMonth,
                    endDay.getDate()
                )
            },
            handleChange(num){
                let data = {
                    code:this.code,
                }
                openTime(data).then( res => {
                    //    console.log(res.data.data.kjTime.replace(/\b(0+)/gi,""))
                    for (let i = 0; i < res.data.data.kjTime.length; i++) {
                        //    去掉数字前面的0
                            this.newkjTime.push(Number(res.data.data.kjTime[i].replace(/\b(0+)/gi,"")))
                    } 
                    //    console.log(this.newkjTime)
                    if(res.data.code == 200){
                            this.openTime = res.data.data.today
                        }
                }).catch( err => {

                })
            } 
        },
    }
</script>

<style lang='scss' scoped>
   @media screen and (min-width:700px) {
        h3{
            padding-top: 17px;
            text-align: center;
            font-size: 18px;
            color: #999;
            margin: 17px 0 10px 0;
        }
        .calendar{ //绝对定位居中
            height: 40%;
            width:90%;
            max-width: 600px;
            position: absolute;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto;
            border: 1px solid rgba(212, 212, 212, 0.493);
        }
        .openTime{
            width: 100%;
            max-width: 600px;
            padding: 20px 0;
            font-size: 20px;
            text-align: center;
            position: relative;
            top:550px;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto; 
            z-index: 20;
        }
    }
    /* -------------------------------------------------------------------我是分割线---------------------------------------------------------------------------- */
    /* 移动端  */
    @media  screen and (max-width:750px) {
        h3{
            // padding-top: 30px;
            text-align: center;
            font-size: 14px;
            color: #999;
            margin: 17px 0 10px 0;
        }
        .calendar{
            height: 63%;
            width:90%;
            max-width: 600px;
            position: absolute;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto;
            border: 1px solid rgba(212, 212, 212, 0.493);
        }
        .openTime{
            padding: 20px 0;
            font-size: 15px;
            text-align: center;
            position: absolute;
            bottom:0px;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto; 
        }
    }

/* -------------------------------------------------------------------我是分割线---------------------------------------------------------------------------- */
    .toolsList{
        margin: 15Px;
        display: flex;
        flex-wrap: wrap;
    }
    .toolsList .toolsItem{
        margin-bottom: 15Px;
        width: 25%;
    }
    .toolsList .toolsItem p{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .toolsList .toolsItem p img{
        width: 50px;
        height:50px;
    }
    .toolsList .toolsItem p span{
        padding-top: 10px;
        font-size: 13px;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff !important;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-calendar__day{
        color:rgb(255, 97, 6);
    }
    /deep/.van-calendar__selected-day{
        background-color:rgb(240, 88, 0);
    }
</style>