<template>
    <div>
        <van-nav-bar :title='spanYear+"年"+headQs' fixed left-arrow @click-left="onClickLeft"></van-nav-bar>
        <div style='width:100%;height:40px;'></div>  <!--增加头部高度，避免盖住内容-->
        <van-swipe class="my-swipe" :autoplay="3000" >
            <van-swipe-item v-for="(image,i) in carouselImg" :key="i+10">
                    <img :src="image.url" @click="gotoLink(image.jumpUrl)"/>
            </van-swipe-item>
        </van-swipe>
        <div class="galleryYears">
            <span @click="changeActive(0,'2024')" :class="yearActive == '0'?'tabs_active':'' ">2024年</span>
            <span @click="changeActive(1,'2023')" :class="yearActive == '1'?'tabs_active':'' ">2023年</span>
        </div>
        <div class="galleryQishu" id="scrollBox">
            <van-row justify="space-between"  flex>
                <van-col span="19" class=" do-jump">
                    <div class="tabsPeriod">
                        <div class="tabsPeriodBd">
                            <span v-for="(item,i) in qishuList" :key="i+10" 
                            :class="qishuActive == i?'qishuAct':'' " :id="bindId(i)" @click="changeQishu(i,item.name,item.id)">{{item.name}}</span>
                        </div>
                    </div>
                </van-col>
                <van-col span="5">
                    <van-dropdown-menu active-color="#1989fa">
                        <van-dropdown-item ref="dropdownMenu" :title="defaultQishuName">
                            <div class="popup" v-for="(k,b) in qishuList" :key="b+10">
                                <span :class="this.defaultId == k.id?'popAct':'' " @click="selectPopQishu(k.id,k.name,b)">{{k.name}}</span>
                            </div>
                        </van-dropdown-item>
                    </van-dropdown-menu>
                </van-col>
            </van-row>
        </div>
        <div class="guessTabs">
            <span @click="handleChange('0')" :class="spanActive == '0'?'tabs_active':''">图片猜测</span>
            <span @click="handleChange('1')" :class="spanActive == '1'?'tabs_active':''">视频猜测</span>
        </div>
        <div class="guessInfo">
            <h3>{{guessDetailTitle}}</h3>
            <p class="guessDetailContent" v-html="guessDetailContent"></p>
            <div class="guessImgs"></div>
            <div class="guessProgress" v-if="isShowVote">
                <div v-for="(item,i) in guessVoteList" :key="i+10" class="guessProgressBox">
                    <span>{{item.title}}</span>
                    <van-progress class="guessProportion" pivot-color="#ff761a"
                    color="linear-gradient(to right, #ffb380, #ff6600)" 
                    :percentage="item.proportion.replace(/%/g, '')" stroke-width="30" />
                </div>
            </div>
            <div class="guessShow" v-if="isShowVote">
                <span @click="goPre">上一期</span>
                <span @click="voteShow = true">投票</span>
                <span @click="goNext">下一期</span>
            </div>
            <div class="guessUnfold">
                <span class="unfoldBtn" @click="changeUnfold()">{{unfoldText}}</span>
            </div>
        </div>
        <van-overlay :show="voteShow" @click="voteShow = false">
            <div class="unfoldWrap" @click.stop>
                <div class="title">
                    <div>{{spanYear+"年"+headQs}}</div>
                    <van-icon name="cross" @click="voteShow = false" />
                </div>
                <div class="grid">
                    <div @click="selectVote(item,i)" :class="voteActive == i ? 'act' : '' " v-for="(item,i) in guessVoteList" :key="i+10">
                        <span>{{item.title}}</span>
                        <b :class="voteActive == i ? 'act' : '' ">{{item.num}}</b>
                    </div>
                </div>
                <div @click="goVote" class="btn" >
                    <span>投票</span>
                </div>
            </div>
        </van-overlay>
        <!-- <div @click="goToLink" class="downImg">
            <img src="../assets/down_app.png" style='max-width:600px;width:95%;margin-top:10px' alt="">
        </div> -->
        <div class="galerryOther">
            <ul>
                <li>
                    <van-icon name="chat-o" />
                    <span>{{totleComments}}</span>
                    <b>评论</b>
                </li>
                 <li @click="countLikeClick">
                    <img style="width:14px" :src="this.totleCountItem.like?require('../assets/like2.png'):require('../assets/like1.png')">
                    <span>{{totleLikes}}</span>
                    <b>点赞</b>
                </li>
                <li @click="countCollectClick">
                    <img style="width:14px" :src="this.totleCountItem.collect?require('../assets/collect.png'):require('../assets/nocollect.png')">
                    <span>{{totleCollects}}</span>
                    <b>收藏</b>
                </li>
                <li>
                    <van-icon name="share-o" />
                </li>
                <li>
                    <van-icon name="eye-o" />
                    <span>{{totleSees}}</span>
                </li>
            </ul>
        </div>
        <div class="homeLotery">
            <div class='loteryTittle'>第{{theNewestDate}}期最新开奖结果 <span class="toRecord" @click="goToRoute()" >开奖记录></span></div>
            <div class='loteryNums' v-for="(item,i) in newLoteryList" :key="i+10" >
                <img :src="require(`../assets/${item[0]}.png`)" >
                <div class="loteryScribe">
                <span>{{item[1]}}/{{item[2]}}</span>
                </div>
            </div>  
            <h2 class="jiahao">+</h2>
            <div class="drawInfo"><span>第{{theNextDate}}期 {{nextPrizeTime}} {{weekTxt}}</span></div>
        </div>
        <h3 v-if="noHotComment" class="commentListTit">热门评论</h3>
        <!-- <van-empty v-if="noHotComment" :image="require('../assets/noComments.png')" description="暂无评论" /> -->
        <div class="commentBox">
            <van-cell class="van-clearfix" v-for="(k,b) in commentHotList" :key="b + 20"  >
                <div class="comment">
                    <div>
                        <div class="commentLeft">
                            <img :src="k.avatar" alt="">
                        </div>
                        <div class="commentRight">
                            <p>
                                <span>{{k.userName}}</span>
                                <b @click="clicklike(k,k.id,b)">
                                    <img :src="k.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                    <i>{{k.commentLikes}}</i>
                                </b>
                            </p>
                            <div @click="pushRelations(k,k.id,k.userId)" class="commentHidden" style="margin-top:5px">
                                <p v-html="k.commentContent"></p>
                                <span class="commentTime">{{k.spacing}}</span>
                            </div>
                            <div v-show="k.index==b" v-for="(a,s) in k.commentMoreList" :key="s+20">
                                    <div  class="commentDetail" v-show="s<k.showNum" >
                                        <div class="commentLeft">
                                            <img :src="a.avatar" alt="">
                                        </div>
                                        <div class=CommentRight>
                                            <p>
                                                <span>{{a.userName}}</span>
                                                <b @click="clicklike(a,a.id,b)">
                                                    <img :src="a.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                                    <i>{{a.commentLikes}}</i>
                                                </b>
                                            </p>
                                            <div @click="pushRelations(a,k.id,a.userId)" class="commentHidden">
                                                <b>
                                                    回复:&nbsp;
                                                    <span>{{a.replyName}}</span>
                                                </b>
                                                <p v-html="a.commentContent"></p>
                                                <span class="commentTime">{{a.spacing}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div  v-if="k.reply > 0" class="commentMore">
                                <span @click="showMoreReply(k,k.id,b)" v-if="!k.dropDown">——显示评论</span>
                                <span @click="noShowMoreReply(k,k.id,b)" v-if="k.dropDown">——收起</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-cell>
        </div>
        <h3 class="commentListTit">最新评论</h3>
        <div class="commentBox">
            <van-list
                v-model:loading="loading"
                :finished="finished"
                :finished-text="finishedText"
                @load="onLoad"
                :immediate-check='false'
                >
                <van-cell class="van-clearfix"  >
                    <div class="comment" > 
                        <div  v-for="(item,index) in commentList" :key="index + 20">
                            <div class="commentLeft">
                                <img :src="item.avatar" alt="">
                            </div>
                            <div class="commentRight">
                                <p>
                                    <span>{{item.userName}}</span>
                                    <b @click="clicklike(item,item.id,index)">
                                        <img :src="item.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                        <i>{{item.commentLikes}}</i>
                                    </b>
                                </p>
                                <div @click="pushRelations(item,item.id,item.userId,index)" class="commentHidden" style="margin-top:5px">
                                    <p v-html="item.commentContent"></p>
                                    <span class="commentTime">{{item.spacing}}</span>
                                </div>
                                <div v-show="item.index==index" v-for="(o,p) in item.commentMoreList" :key="p+20">
                                    <div class="commentDetail" v-show="p<item.showNum" >
                                        <div class="commentLeft">
                                            <img :src="o.avatar" alt="">
                                        </div>
                                        <div class=CommentRight>
                                            <p>
                                                <span>{{o.userName}}</span>
                                                <b @click="clicklike(o,o.id,index)">
                                                    <img :src="o.like?require('../assets/like2.png'):require('../assets/like1.png')" alt="">
                                                    <i>{{o.commentLikes}}</i>
                                                </b>
                                            </p>
                                            <div @click="pushRelations(o,item.id,o.userId,p)" class="commentHidden">
                                                <b>
                                                    回复:&nbsp;
                                                    <span>{{o.replyName}}</span>
                                                </b>
                                                <p v-html="o.commentContent"></p>
                                                <span class="commentTime">{{o.spacing}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div  v-if="item.reply > 0" class="commentMore">
                                    <span ref="btn" @click="showMoreReply(item,item.id,index)" v-if="!item.dropDown">——显示评论</span>
                                    <span @click="noShowMoreReply(item,item.id,index)" v-if="item.dropDown">——收起</span>
                                </div>
                            </div> 
                        </div>
                    </div>
                </van-cell>
            </van-list>
            <van-empty v-if="noComment" :image="require('../assets/noComments.png')" description="暂无评论" />
            <div class="inp" @click="gotoSetRelations">
                <van-field class="realtaionCell" readonly placeholder="想说点什么"  />
                <span >评论</span>
            </div>
        </div>
        <van-popup v-model:show="putRelations" position="right" :style="{ height: '100%',width:'100%' }" >
            <van-nav-bar title='发表评论' fixed left-arrow @click-left="setClickLeft" />
            <div style='width:100%;height:50px;'></div>  <!--增加顶部高度，部面盖住内容-->
            <div class="relationBox">
                <div style="text-align:left;position:relative;left:5%;margin:10px 0;">评论内容</div>
                <van-cell-group inset>
                    <van-field
                        v-model="relationsMessage"
                        rows="5"
                        autosize
                        clearable
                        class="textArea"
                        height='100px'
                        type="textarea"
                        maxlength="120"
                        placeholder="请输入评论内容"
                        show-word-limit
                    />
                </van-cell-group>
                <div @click="completeRelation">
                    <span class="btn">完成</span>
                </div>
            </div>
        </van-popup>
        <div style='width:100%;height:50px;'></div>  <!--增加脚部高度，避免盖住内容-->
        <van-overlay :show="loginsShow" @click="loginsShow = false">
            <div class="loginWrap" @click.stop>
                <div class="top">
                    <img src="../assets/loginTop.png" alt="">
                    <div class="logo">
                        <img src="../assets/userAvatar.png" alt="">
                    </div>
                </div>
                <div class="common">
                    <van-field
                        v-model="username"
                        required
                        class="loginInput"
                        clearable
                        placeholder="请输入用户名"
                    />
                    <van-field
                        v-model="password"
                        required
                        class="loginInput"
                        :type="passwordType"
                        placeholder="请输入密码"
                    >
                        <template #right-icon>
                            <span class="solts" @click="switchPasswordType">
                                <van-icon class="eyes" name="eye" v-if="passwordType==='password'"/>
                                <van-icon class="eyes" name="closed-eye" v-else />
                            </span>
                        </template>
                    </van-field>
                    <div class="verifyFiled">
                        <van-field
                                v-model="verifyCode"
                                required
                                clearable
                                class="verifyInput loginInput"
                                placeholder="请输入验证码"
                            >
                            <template #right-icon>
                                <div class="verify-box" @click="refreshCode">
                                    <Sidentify :identifyCode="identifyCode"></Sidentify>
                                </div>
                            </template>
                        </van-field>
                    </div>
                    <div @click="handleLogin">
                        <span class="btn">登录</span>
                    </div>
                    <div class="bottomTxt">
                        <div  class="bottomOther"><a><span> <a href="#/forget">忘记密码</a>  </span></a></div>
                        <div @click="gotoRegister" class="bottomOther"><a><span>立即注册</span></a></div>
                    </div>
                </div>
                <div class="close" @click="loginsShow = false">
                    <img src="../assets/closeLogin.png" alt="">
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import { Col, DropdownItem, DropdownMenu, NavBar, Row, Swipe,SwipeItem,Progress, Toast,Overlay, Icon, Cell, List, Empty, Field, Popup, CellGroup } from 'vant';
import {imgCarousel,guessQs,guessDetails,guessGetVote,guessVote,userCount,openLatest,userAddRelation,userLogin,commentAdd,commentReplyList,commentList,imgLink} from '../api/http'
import Sidentify from '../components/identify.vue' 
    export default {
        created(){
            if(this.$route.params.myLikeId){
                this.defaultId = this.$route.params.myLikeId
            }else if(this.$route.params.myCommentId){
                this.defaultId = this.$route.params.myCommentId
            }
            if(localStorage.getItem('code')){
                this.code = localStorage.getItem('code')
            }else{
                this.code = '71'
            }
            this.getCarousel()
            this.getQishu()
            this.getNewestLotery()
            this.refreshCode()
            this.getLink()
            Sidentify
            
        },
        components:{
            'van-nav-bar':NavBar,
            'van-swipe':Swipe,
            'van-swipe-item':SwipeItem,
            'van-row':Row,
            'van-col':Col,
            'van-dropdown-menu':DropdownMenu,
            'van-dropdown-item':DropdownItem,
            'van-progress':Progress,
            'van-overlay':Overlay,
            'van-icon':Icon,
            'van-cell':Cell,
            'van-list':List,
            'van-empty':Empty,
            'van-field':Field,
            'van-popup':Popup,
            'van-cell-group':CellGroup,
            Sidentify
        },
        data() {
            return {
                headYear:'',
                headQs:'',
                code:'',
                carouselImg:[],
                yearActive: 0,
                qishuActive:0,
                spanActive:0,
                headQs:'',
                spanYear: '2024',
                qishuList:[],
                dropdownMenu:false,
                popQishuName:'',
                popQishuId:'',
                defaultQishuName:'',
                defaultId:'',
                percentageNum:'50',
                guessDetailTitle:'',
                guessDetailContent:'',
                guessId:'',
                guessVoteList:[],
                unfoldText:'展开投票',
                isShowVote:false,
                firstQiId:'',
                lastQiId:'',
                voteShow:false,
                voteActive:'',
                selectSx:'',
                totleCollects:'',
                totleComments:'',
                totleLikes:'',
                totleSees:'',
                theNewestDate:'',
                theNextDate:'',
                nextPrizeTime:'',
                weekTxt:'',
                newLoteryList:[],
                prizeTime:'',
                noHotComment:false,
                commentHotList:[],
                commentList:[],
                commentMoreList:[],
                noComment:false,
                putRelations:false,
                relationsMessage:'',
                parentrelationsMessage:'',
                loginsShow:false,
                username:'',
                password:'',
                passwordErrorMsg:'',
                verifyCodeErrorMsg:'',
                usernameErrorMsg:'',
                passwordType: 'password',//输入框类型,
                identifyCode:'',
                verifyCode:'',
                page:1,
                loading:false,
                finished:true,
                finishedText:'没有更多了',
                parentId:'',
                replyId:'',
                totleCountItem:[],
                gallerryId:'',
                downloadLink:'',
            }
        },
        computed:{
            
        },
        methods: {
            getCarousel(){
                let data = {
                    type:0
                }
                imgCarousel(data).then(res=>{
                    if(res.data.code == 200){
                    // console.log(res.data.data)
                    
                    this.carouselImg = res.data.data
                    }
                }).catch(err =>{
                    console.log(err)
                })
            },
            onClickLeft() {
                this.$router.go(-1)
                // localStorage.removeItem('code');;
            },
            changeActive(num,year){
                this.yearActive = num
                this.spanYear = year
                // this.getDetailInfo()
                this.getQishu()
            },
            getQishu(){
                let data = {
                    type:0,
                    code:this.code,
                    year:this.spanYear
                }
                guessQs(data).then(res=>{
                    if(res.data.code == 200){
                        this.qishuList = res.data.data
                        for (let i = 0; i < this.qishuList.length; i++) {
                            this.defaultQishuName = this.qishuList[0].name
                            this.headQs= this.qishuList[0].name.slice(1.4)
                            this.defaultId = this.qishuList[0].id
                            this.lastQiId = this.qishuList[0].id
                            this.firstQiId = this.qishuList[this.qishuList.length-1].id
                        }
                        this.getGuessDetails()
                        setTimeout(()=>{
                            this.getGuessVote()
                            this.getCommentList()  
                        },200)
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            bindId(i){
                return "anchor" + i
            },
            changeQishu(index,name,id){
                this.headQs = name.slice(1.4)
                this.defaultQishuName = name
                this.qishuActive = index
                localStorage.setItem('qishuActive',index)
                this.defaultId = id
                this.getGuessDetails()
                setTimeout(()=>{
                    this.getGuessVote()
                    this.getCommentList()  
                },200)
                // this.$store.commit('setDetailId',id)
                // this.getDetailInfo()
            },
            selectPopQishu(id,name,index){
                this.headQs = name.slice(1.4)
                this.popQishuName = name
                this.defaultQishuName = name
                this.popQishuId = id
                this.defaultId = id
                this.qishuActive = index
                localStorage.setItem('qishuActive',index)
                const selector = "#anchor"+index
                // console.log(document.querySelector(selector).scrollIntoView)
                document.querySelector(selector).scrollIntoView({
                    block:'end',
                    behavior: "smooth"
                });
                this.$refs.dropdownMenu.toggle(false)
                this.getGuessDetails()
                setTimeout(()=>{
                    this.getGuessVote()
                    this.getCommentList()  
                },200)
                // console.log(this.defaultQishuName)
            },
            handleChange(num){
                this.spanActive = num
                if(num == 1){
                this.isnotesShow = false
                this.iscalendarShow = true
                let data = {
                    code:this.code,
                }
                // openTime(data).then( res => {
                //     //    console.log(res.data.data.kjTime.replace(/\b(0+)/gi,""))
                //     for (let i = 0; i < res.data.data.kjTime.length; i++) {
                //         //    去掉数字前面的0
                //             this.newkjTime.push(Number(res.data.data.kjTime[i].replace(/\b(0+)/gi,"")))
                //     } 
                //     //    console.log(this.newkjTime)
                //     if(res.data.code == 200){
                //             this.openTime = res.data.data.today
                //         }
                // }).catch( err => {

                // })
                } else {
                    this.isnotesShow = true
                this.iscalendarShow = false
                }
            },
            getGuessDetails(){
                let data = {
                    id:this.defaultId
                }
                guessDetails(data).then(res => {
                    if(res.data.code == 200){
                        console.log(res)
                        this.guessDetailTitle = res.data.data.title
                        this.guessDetailContent = res.data.data.content
                        this.guessId = res.data.data.id
                        console.log(this.guessId)
                        this.getTotleInfo()
                    }
                }).catch(err=>{
                    console.log(err)
                })
            },
            getGuessVote(){
                let data ={
                    guessId:this.guessId
                }
                guessGetVote(data).then(res => {
                    if(res.data.code == 200){
                        this.guessVoteList = res.data.data
                        console.log(this.guessVoteList)
                    }
                }).catch(err=>{
                    console.log(console.log(err))
                })
            },
            changeUnfold(){
                this.isShowVote = !this.isShowVote
                if(this.isShowVote){
                    this.unfoldText = '收起投票'
                }else{
                    this.unfoldText = '展开投票'
                }
            },
            goPre(){
                console.log(this.defaultQishuName.replace(/[^\d]/g,' '))
                if(this.defaultId<=this.firstQiId){
                    this.defaultId += 1
                }
                if(this.defaultId<=this.firstQiId){
                    this.qishuActive += 1
                    let newName = this.defaultQishuName.replace(/[^\d]/g,' ')
                    newName -= 1
                    this.defaultQishuName = '第'+newName+'期'
                     const selector = "#anchor"+this.qishuActive
                    // console.log(document.querySelector(selector).scrollIntoView)
                    document.querySelector(selector).scrollIntoView({
                        block:'end',
                        behavior: "smooth"
                    });
                }
                if(this.defaultId>this.firstQiId){
                    Toast('没有啦！')
                    this.defaultId  -= 1
                }else{
                    this.getTotleInfo()
                    let data = {
                        id:this.defaultId
                    }
                    guessDetails(data).then(res => {
                        if(res.data.code == 200){
                            console.log(res)
                            this.guessDetailTitle = res.data.data.title
                            this.guessDetailContent = res.data.data.content
                            this.guessId = res.data.data.id
                            setTimeout(()=>{
                                this.getGuessVote()
                                this.getCommentList()  
                            },200)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })}
            },
            goNext(){
                if(this.defaultId>=this.lastQiId){
                    this.defaultId -= 1
                }
                if(this.defaultId>=this.lastQiId){
                    this.qishuActive -= 1
                    let newName = Number(this.defaultQishuName.replace(/[^\d]/g,' ')) 
                    newName += 1
                    this.defaultQishuName = '第'+newName+'期'
                    const selector = "#anchor"+this.qishuActive
                    // console.log(document.querySelector(selector).scrollIntoView)
                    document.querySelector(selector).scrollIntoView({
                        block:'end',
                        behavior: "smooth"
                    });
                }
                if(this.defaultId<this.lastQiId){
                    Toast('没有啦！')
                    this.defaultId += 1
                }else{
                    this.getTotleInfo()
                    let data = {
                        id:this.defaultId
                    }
                    guessDetails(data).then(res => {
                        if(res.data.code == 200){
                            console.log(res)
                            this.guessDetailTitle = res.data.data.title
                            this.guessDetailContent = res.data.data.content
                            this.guessId = res.data.data.id
                            setTimeout(()=>{
                                this.getGuessVote()
                                this.getCommentList()  
                            },200)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })}
            },
            selectVote(item,index){
                this.voteActive = index
                this.selectSx = item.title
            },
            goVote(){
                let data ={
                    guessId:this.guessId,
                    sx:this.selectSx
                }
                guessVote(data).then(res=>{
                    if(res.data.code == 200){
                        Toast(res.data.msg)
                        this.voteShow = false
                        this.getGuessVote()
                    }else{
                        Toast(res.data.msg)
                        this.voteShow = false
                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            getTotleInfo(){
                let data = {
                    moduleType:3,
                    otherId:this.defaultId,
                }
                userCount(data).then(res=>{
                    if(res.data.code == 200){
                        this.totleCollects = res.data.data.collects
                        this.totleComments = res.data.data.comments
                        this.totleLikes = res.data.data.likes 
                        this.totleSees = res.data.data.sees
                        this.totleCountItem = res.data.data

                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            getNewestLotery(){
                let data = {
                    code : this.code
                }
                openLatest(data).then(res =>{
                    if(res.data.code == 200){
                        this.newLoteryList = res.data.data.prize
                        // console.log(this.newLoteryList)
                        // 截取字段最后三位字符串
                        this.theNewestDate = res.data.data.issueNo.substring(res.data.data.issueNo.length-3)
                        this.theNextDate = res.data.data.nextIssueNo.substring(res.data.data.issueNo.length-3)
                        this.nextPrizeTime = res.data.data.nextPrizeTime
                        this.weekTxt = res.data.data.dayOfTheWeek
                        this.prizeTime = res.data.data.prizeTimeForHome
                        
                        // console.log(res)
                       
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            goToRoute(){
                this.$router.push({
                    name: `Record`,
                    params:{
                        guessDetailId:this.defaultId,
                    }
                })
            },
            clicklike(item,id,index) {
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    console.log(item)
                    if (item.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:3,
                            otherId:id
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                if(item.commentParentId == 0){
                                    item.like = true
                                    item.commentLikes+=1
                                    // this.getCommentList()
                                }else{
                                    let data = {
                                        id:id
                                    }
                                    commentReplyList(data,this.page).then(res=>{
                                        item.commentMoreList = res.data.data
                                        item.dropDown = true;
                                        item.index = index
                                        item.showNum = item.commentMoreList.length;
                                        item.like = true
                                        item.commentLikes+=1
                                    }).catch(err=>{
                                        console.log(res)
                                    })
                                }
                                Toast("已点赞")
                            }
                        })
                    }
                }
                
            },
            pushRelations(item,parentId,replyId,index){
                console.log(item)
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    this.putRelations = true
                    this.parentId = parentId
                    this.replyId = replyId
                }
            },
            showMoreReply(item,id,index){
                localStorage.setItem("showMoreId",id)
                localStorage.setItem("showMoreIndex",index)
                let data = {
                    id:id
                }
                commentReplyList(data,this.commentMorePage).then(res=>{
                    item.commentMoreList = res.data.data
                    localStorage.setItem("showMoreItemMoreList",JSON.stringify(item.commentMoreList))
                    localStorage.setItem("showMoreItem",JSON.stringify(item))
                    item.dropDown = true;
                    item.index = index
                    item.showNum = item.commentMoreList.length;
                    // console.log(this.commentHotList)
                }).catch(err=>{
                    console.log(err)
                })
            },
            noShowMoreReply(item,id,index){
                     let data = {
                        id:id
                    }
                commentReplyList(data,this.commentMorePage).then(res=>{
                    item.dropDown = false;
                    item.showNum = 0;
                }).catch(err=>{
                    console.log(err)
                })
            },
            completeRelation(){
                if(this.parent != ''){
                    let data = {
                        moduleType:3,
                        needCommentId:this.defaultId,
                        content:this.relationsMessage.split("\n").join("<br />"),
                        parentId:this.parentId,
                        replyUserId:this.replyId
                    }
                    commentAdd(data).then(res=>{
                        if(this.relationsMessage == ''){
                            Toast('请输入内容！')
                        }else{
                            if(res.data.code == 200){
                                // var btn = document.getElementById("btn");
                                setTimeout(()=>{
                                    this.getCommentList()
                                },500)
                                this.getCommentList()
                                this.getTotleInfo()
                                this.putRelations = false
                                Toast(res.data.msg)
                                this.relationsMessage = ''
                                this.parentId = ''
                            }else{
                                Toast(res.data.msg)
                                this.putRelations = false
                                this.relationsMessage = ''
                                this.parentId = ''
                            }
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }else{
                    let data = {
                        moduleType:3,
                        needCommentId:this.defaultId,
                        content:this.relationsMessage,
                    }
                    commentAdd(data).then(res=>{
                        if(this.relationsMessage == ''){
                            Toast('请输入内容！')
                        }else{
                            if(res.data.code == 200){
                                this.getCommentList()
                                this.putRelations = false
                                Toast(res.data.msg)
                                this.relationsMessage = ''
                            }else{
                                Toast(res.data.msg)
                            }
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }
                
            },
            switchPasswordType() {
                this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
            },
            refreshCode(){
                this.makeCode()
                // console.log('当前验证码',this.identifyCode)
            },
            makeCode(o,l){
                this.identifyCode = String(this.randomNum(2345,9999))
            },
            randomNum(min,max){
                return Math.floor(Math.random() * (max - min) + min)
            },
            handleLogin(){
                console.log(this.verifyCode)
                if(this.username == ''){
                    this.usernameErrorMsg = '用户名不能为空'
                }else if(this.username != ''){
                    this.usernameErrorMsg = ''
                }
                if(this.verifyCode == ''){
                    this.verifyCodeErrorMsg = '请输入验证码'
                }else if(this.verifyCode != ''){
                    this.verifyCodeErrorMsg = ''
                }
                if(this.password == ''){
                    this.passwordErrorMsg = '请输入密码'
                }else if(this.password != ''){
                    this.passwordErrorMsg = ''
                }
                if(this.verifyCode != this.identifyCode){
                    Toast('验证码不正确')
                    this.refreshCode()
                }else{
                    let data = {
                        loginName:this.username,
                        password:this.password
                    }
                    userLogin(data).then(res=>{
                        if(res.data.code == 200){
                            this.$store.commit('$_setStorage',res.data.data.token)
                            this.$store.commit('setUsername',res.data.data.userName)
                            this.$store.commit('setUserid',res.data.data.userId)
                            this.$store.commit('setAvatar',res.data.data.avatar)
                            this.$store.commit('setPassword',this.password)
                            this.$store.commit('setLogname',res.data.data.loginName)
                            this.$store.commit('setLoginStatus',false)
                            Toast('登录成功')
                            this.$router.push(this.$route.path) //跳转到当前页面
                            this.loginsShow=false
                        }else{
                            this.refreshCode()
                            Toast(res.data.msg)
                        }
                    }).catch(err=>{
                        console.log(err)
                    })

                }
            },
            gotoRegister(){
                this.$router.push({
                    name: `Register`,
                })
            },
            onLoad(){
                this.page += 1;//页数+1
                this.getCommentList();
            },
            getCommentList(){
                this.loading = true;
                let data = {
                    moduleType:3,
                    needCommentId:this.defaultId,
                }
                commentList(data,this.page).then(res=>{
                    if(res.data.code == 200 && res.data.data.common.total !=0){
                        this.commentListTotal = res.data.data.common.total
                        console.log(res.data.data.hot)
                        if(res.data.data.common.pageNum == 1){
                            this.commentList = res.data.data.common.list
                        }else{
                            this.commentList = this.commentList.concat(res.data.data.common.list)

                        }
                        if (res.data.data.common.nextPage == 0) {  //数据全部加载完成
                            this.finished = true;
                            this.finishedText = '没有更多了'
                        }else{
                            this.finished = false;
                        }       
                        this.loading = false;
                        this.noComment = false;
                    }
                    if(res.data.data.hot == undefined || res.data.data.hot.length == 0){
                        this.noHotComment = false
                        this.commentHotList = []
                    }else{
                        this.commentHotList = []
                        this.commentHotList = res.data.data.hot
                        this.noHotComment = true;
                    }
                    if(res.data.data.common.total == 0){
                        this.finished = true;
                        this.noComment = true;
                        this.finishedText = ''
                        this.commentList = []
                    }
                }).catch(err=>{
                    console.log(err)
                })

            },
            gotoSetRelations(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    this.putRelations = true
                }
            },
            setClickLeft(){
                this.putRelations = false
            },
            countCollectClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.collect) {
                        Toast("已经收藏过了哦~")
                    } else {
                        let data = {
                            type:1,
                            moduleType:3,
                            otherId:this.guessId
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.collect = true
                                this.totleCollects+=1
                                Toast("已收藏")
                            }
                        })
                    }
                }
            },
            countLikeClick(){
                if(this.$store.state.noLogin){
                    this.loginsShow = true
                }else{
                    // console.log(item)
                    if (this.totleCountItem.like) {
                        Toast("已经点过赞了哦~")
                    } else {
                        let data = {
                            type:0,
                            moduleType:3,
                            otherId:this.guessId
                        }
                        userAddRelation(data).then(res=>{
                            if(res.data.code == 200){
                                this.totleCountItem.like = true
                                this.totleLikes+=1
                                Toast("已点赞")
                            }
                        })
                    }
                }
            },
            gotoLink(url){
                window.location.href = url
            },
            getLink(){
                let data = {
                    key:'download-link'//下载链接
                }
                imgLink(data).then(res=>{
                    this.downloadLink = res.data.data
                })
            },
            goToLink(){
                window.location.href = this.downloadLink
            },
        },
    }
</script>

<style lang='scss' scoped>
/* pc端 */
    @media screen and (min-width:700px) {
        .homeLotery{
                position: relative;
                background: url(../assets/kuang.png) no-repeat;
                background-size: 100% 100%;
                height: 160px;
                margin:10px 10px;
                justify-content: center;
                display: flex;
            }
            .loteryTittle{
                display: flex;
                padding-top: 20px;
                position: absolute;
                font-size: 15px;
            }
            .toRecord{
                padding-left: 10px;
                color:rgb(255, 82, 2);
            }
            .loteryNums{
                position: relative;
                top:60px;
                width: 10%;
                margin:5px 5px;
            }
            .loteryNums img{
                vertical-align: middle;
                width: 80%;
                position: relative;
                bottom: 5px;
            }
            .loteryNums:nth-child(8){
                padding-left: 45px;
            }
            .jiahao{
                position: absolute;
                right: 110px;
                top:63px;
                font-size: 20px;
            }
            .loteryScribe{
                text-align: center;
                padding-top: 2px;
                display: block;
                font-size: 13px;
            }
            .drawInfo{
                color: #999;
                position: absolute;
                bottom: 5px;
                font-size: 13px;
            }
            .my-swipe .van-swipe-item img{
                width: 100%;
                height: 200px;
            }
        }
    /* -------------------------------------------------------------------我是分割线---------------------------------------------------------------------------- */
    /* 移动端  */
    @media  screen and (max-width:750px) {
        .homeLotery{
                position: relative;
                background: url(../assets/kuang.png) no-repeat;
                background-size: 100% 100%;
                height: 160px;
                margin:10px 10px;
                justify-content: center;
                display: flex;
            }
            .loteryTittle{
                display: flex;
                padding-top: 20px;
                position: absolute;
                font-size: 15px;
            }
            .toRecord{
                padding-left: 10px;
                color:rgb(255, 82, 2);
            }
            .loteryNums{
                /* display: flex; */
                /* justify-content: space-around;
                align-items: center;
                padding:8px 8px; */
                position: relative;
                top:60px;
                width: 10%;
                margin:0 5px;
            }
            .loteryNums img{
                vertical-align: middle;
                width: 100%;
                position: relative;
                bottom: 5px;
            }
            .loteryNums:nth-child(8){
                padding-left: 45px;
            }
            .jiahao{
                position: absolute;
                right: 60px;
                top:53px;
                font-size: 20px;
            }
            .loteryScribe{
                text-align: center;
                padding-top: 7px;
                display: block;
                font-size: 13px;
            }
            .drawInfo{
                color: #999;
                position: absolute;
                bottom: 10px;
                font-size: 13px;
            }
            .my-swipe .van-swipe-item img{
                width: 100%;
                height: 120px;
            }
        }

/* -------------------------------------------------------------------我是分割线---------------------------------------------------------------------------- */
    .my-swipe{
        padding: 0;
        margin: 0;
    }
    
    .galleryYears{
        text-align: left;
        border-bottom: 0.026667rem solid #eee;
        padding: 20px 20Px;
        height: 0.933333rem;
        line-height: .933333rem;
        background-color: #fff;
        margin-top: -5px;
    }
    .tabs_active{
        color: #ff5000;
        background-color: #ffdccc;
    }
    .galleryYears span{
        border-radius: 0.266667rem;
        padding: 0.053333rem 0.4rem;
        font-size: 15px;
    }
   .galleryQishu{    
        background-color: #fff;
        padding: 10px;
    }
    .tabsPeriod{
        overflow-x: auto;
    }
    .tabsPeriodBd{
        white-space: nowrap;
        min-width: 100%;
        overflow: hidden; //隐藏滚动条
    }
    .tabsPeriodBd span{
        display: inline-block;
        width: 25%;
        text-align: center;
        font-size: 15px;
        // padding: 0 50px;
    }
    .qishuAct{
        color: #ff5000;
    }
    .popup{
        width: 25%;
        margin-top:5px;
    }
    .popup span{
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        height: 30px;
        width: 100px;
        line-height: 30px;
        margin: 10px 0;
        padding: 5px 10px;
        text-align: center;
        font-size: 15px;
    }
    .popAct{
        color: #ff5000;
        border-color: #ff5000 !important;
    }
    .fixTop{
        position: sticky;
        top: 0;
        z-index: 1000;
    }
    .element::-webkit-scrollbar { width: 0 !important }
    .element { -ms-overflow-style: none; }
    .element { overflow: -moz-scrollbars-none; }
    .guessTabs{
        display: flex;
        justify-content: space-around;
        width: 80%;
        height: 30px;
        position: relative;
        margin: 0 auto;
        top:0;
    }
    .guessTabs span:last-child{
        border-radius: 0 5px 5px 0;
        border-left-width: 0;
    }
    .guessTabs span:first-child{
        border-radius: 5px 0 0 5px;
    }
    .guessTabs span{
        cursor: pointer;
        border: 1px solid #ff5000;
        width: 50%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #ff5000;
        background-color: #fff;
    }
    .guessTabs span.tabs_active{
        color: #fff;
        background-color: #ff5000;
    }
    .guessInfo{
        margin-top: 30px;
        text-align: center;
    }
    .guessInfo p{
        margin: 10Px 10px;
        font-size: 17px;
        text-align: left;
    }
    .guessProgress{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10Px;
        margin: 10Px;
    }
    .guessProgress .guessProgressBox{
        display: flex;
    }
    .guessProgress .guessProgressBox span{
        margin-right: 10Px;
        font-size: 15px;
    }
    .guessProportion{
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        background-color: #f5f5f5;
    }
    .guessShow{
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }
    .guessShow span{
        display: inline-block;
        border: 1px solid #ff5000;
        border-radius: 8px;
        width: 100px;
        height: 40px;
        line-height: 40px;
        color: #ff5000;
        font-size: 15px;
    }
    .guessShow span:nth-child(2){
        margin: 0 10px;
        border-width: 0;
        width: 120px;
        color: #fff;
        background-color: #ff5000;
    }
    .guessUnfold{
        background: url(../assets/voteBar.png) no-repeat;
        background-size: 100%;
    }
    .unfoldBtn{
        display: inline-block;
        height: 30px;
        line-height:30px;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
    }
    .unfoldWrap{
        border-radius: 8px;
        background-color: #fff;
        position: relative;
        top: 30%;
        width: 90%;
        margin: 0 auto;
    }
    .unfoldWrap .title{
        display: flex;
        justify-content: center;
        align-content: center;
        position: relative;
        padding: 15Px 0;
    }
    .unfoldWrap .grid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 10px;
        padding: 20px;
        margin: 20px 0;
    }
    .unfoldWrap .grid>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #eee;
        border-radius: 8px;
        height: 50px;
    }
    .unfoldWrap .grid>div span{
        font-size: 15px;
    }
    .unfoldWrap .grid>div b{
        color: #999;
        font-size: 15px;
    }
    .unfoldWrap .grid>div b.act{
        color:#fff
    }
    .unfoldWrap .grid>div.act{
        border-color: #0f93f7;
        background-color: #0f93f7;
        color:#fff;
    }
    .unfoldWrap .btn{
        display: flex;
        justify-content: center;
        padding: 15px 0;
    }
    .unfoldWrap .btn span{
        display: inline-block;
        border: 1px solid #ff5000;
        border-radius: 8px;
        height: 40px;
        line-height: 40px;
        width: 120px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        background-color: #ff5000;
    }
    .commentListTit{
        text-align: left;
        padding: 10px 15Px;
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        background-color: #f5f5f5;
    }
    .comment>div{
        display: flex;
        border-bottom: 2px solid #eee;
        padding: 15Px;
    }
    .commentLeft{
        width: 40px;
        height: 40px;
        margin: -5px 5px;
        
    }
    .commentLeft img{
        border-radius: 50%;
        border: 1px solid #dcdcdc;
        // width: 100%;
        height: 100%;
        vertical-align: middle;
    }
    .commentRight{
        flex: 1;
        padding-left: 10Px;
    }
    .commentRight p {
        display: flex;
        justify-content: space-between;
        overflow-wrap: anywhere;
    }
    .commentRight p span{
        color: #ff5000;
        font-size: 16px;
    }
    .commentRight p b{
        align-items: center;
        color: #999;
        font-size: 14px;
        position: absolute;
        right:0;
    }
    .commentRight p b img{
        width: 13px;
        height: 13px;
    }
    .commentRight p b i{
        margin-left: 10px;
        font-style: normal;
    }
    .commentHidden{
        word-break: break-all;
        font-size: 14px;
    }
    .commentHidden span{
        color: #ec799f;
        font-size: 14px;
    }
    .commentHidden .commentTime{
        display: inline-block;
        color: #999;
        font-size: 12px;
    }
    .commentMore{
        display: flex;
        align-items: center;
        padding-top: 10px;
    }
    .commentMore span{
        color:#999;
    }
    .inp{
        position: fixed;
        bottom: 0;
        padding: 0 20px;
        border-top: 1px solid #dcdcdc;
        width: 94%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        max-width: 600px;
    }
    .inp span{
        border-radius:8px;
        width: 20%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background-color: #ff5000;
    }
    .textArea{
        height: 160px !important;
        background-color: rgba(214, 214, 214, 0.15) !important;
    }
    .relationBox{
        max-width: 600px;
        margin: 0 auto;
    }
    .relationBox .btn{
        display: inline-block;
        border: 1 solid #ff5000;
        border-radius: 25px;
        margin-top:20px;
        width: 300px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    .loginWrap{
        position: relative;
        margin: 0 auto;
        border-radius: 0.533333rem 0.533333rem 0.266667rem 0.266667rem;
        width: 80%;
        height: 50%;
        background-color: #fff;
        top: 50%; /*偏移*/
        transform: translateY(-50%);
        max-width: 600px;
    }
    .loginWrap .top{
        height:80px;
    }
    .loginWrap .top>img{
        border-radius: 0.533333rem 0.533333rem 0 0;
        width: 100%;
    }
    .loginWrap .logo{
        position: absolute;
        top: -50px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        width: 100px;
        height: 50px;
    }
    .loginWrap .logo img{
        
        width: 100%;
    }
    .loginWrap .common{
        border-radius: 0.266667rem;
        padding: 20px;
        background-color: #fff;
    }
    .common .btn{
        display: inline-block;
        border: 1px solid #ff5000;
        border-radius: 30px;
        width: 90%;
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        background-color: #ff5000;
    }
    
    .loginWrap .close{
        position: absolute;
        top: 10px;
        right: 10px; 
        margin-left: auto; 
        margin-right: auto;
        
    }
    .loginWrap .close img{
        width: 20px;
        height: 20px;
    }
    .loginInput{
        width: 85% !important;
        margin: 25px auto !important;
        border-radius: 8px !important;
        background-color: rgba(194, 194, 194, 0.212) !important;
    }
    .verifyInput{
        height: 38px;
        padding: 0 0 0 15px;

    }
    .verify-box{
        width: 100%;
    }
    .bottomTxt{
        display: flex;
        justify-content: space-between;
        width: 70%;
        margin: 20px auto;
        font-size: 15px;
    }
    .bottomOther:last-child a span{
        color: #ff5000;
    }
    .bottomOther a{
        color: rgb(112, 109, 109);
    }
    .fixTop{
        position: sticky;
        top: 0;
        z-index: 1000;
    }
    .galerryOther{
        margin: 10px;
        border-bottom: 1px solid #eee;
    }
    .galerryOther ul{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        height: 0.933333rem;
    }
    .galerryOther ul li{
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
    .galerryOther ul li span{
        margin-left: 5px;
        font-weight: 400;
        font-size: 13px;
    }
    .galerryOther ul li b{
        margin-left: 5px;
        font-weight: 400;
        font-size: 13px;
    }
    .commentDetail{
        display: flex;
        margin-top:10px
    }
    // 调整v-html中img尺寸过大问题
    /deep/ img{
        max-width:100%;
    }
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }
    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }
    /deep/.van-nav-bar--fixed{
        z-index: 10;
    }
    /deep/.van-swipe__indicators{
        bottom: 0;
    }
    /deep/.van-swipe__indicator{
        background-color: rgb(61, 2, 255);
    }
    /deep/.van-dropdown-menu__title{
        padding: 0 5px 0 5px;
        font-size: 15px;
        color: #fff !important;
    }
    /deep/.van-dropdown-menu__bar{
        height: auto;
        box-shadow: none;
        background: #ff5000;
        border-radius: 20px;
    }
    /deep/.van-dropdown-item__content{
        display: flex;
        flex-wrap: inherit;
        white-space: nowrap;
    }
    /deep/.van-dropdown-item{
        width: 100%;
        max-width: 600px;
        margin:0 auto;
        flex-flow: wrap;
        flex-wrap: wrap;
    }
    /deep/.van-progress__pivot{
        height: 30px;
        line-height: 30px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }
    /deep/.van-overlay{
        max-width: 600px;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
    } 
    /deep/.van-icon-cross{
        position: absolute;
        right: 10px;
        color:rgb(179, 179, 179);
        font-size: 18px;
    }
    /deep/.van-icon{
        font-size: 16px;
    }
</style>