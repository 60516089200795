<template>
    <div>
        <div v-if="isShowIframe">
            <div  class="backIcon" @click="goback">返回澳门六合</div>
            <iframe class="iframe" ref="iframe" :src="iframeSrc" ></iframe>
        </div>
        
        <div v-if="!isShowIframe">
            <van-nav-bar title="优惠活动" fixed />
            <div style='width:100%;height:50px;'></div>   <!-- 增加底部脚部高度，部面盖住内容 -->
            <div class="attributionTabs">
                <span @click="handleChange('0')" :class="spanActive == '0'?'tabs_active':''">平台活动</span>
                <span @click="handleChange('1')" :class="spanActive == '1'?'tabs_active':''">六合彩活动</span>
                <span @click="handleChange('2')" :class="spanActive == '2'?'tabs_active':''">棋牌活动</span>
                <span @click="handleChange('3')" :class="spanActive == '3'?'tabs_active':''">捕鱼活动</span>
            </div>
            <div class="activityList"> 
                <div class="item" v-for="(item,index) in activeList" :key="index+20">
                    <a >
                        <img :src="item.bannerUrl" alt="" @click="goToDetail(item.content)">
                    </a>
                </div>
            </div>
            <h3 v-if="isContent">暂无活动</h3>
            <v-footer @showIframe="showIframe($event)"></v-footer>
        </div>
       
    </div>
</template>

<script>
import {imgActivity} from '../api/http'
import { NavBar,  } from 'vant';
import Footer from '../components/footer'
    export default {
        created(){
            this.getActivityDet()
        },
        activated() { //.activated 为keep-alive加载时调用
            if(this.scroll > 0){
                window.scrollTo(0, this.scroll);
                this.scroll = 0;
                window.addEventListener('scroll', this.backHandleScroll);
            }
        },
        deactivated(){//页面退出时关闭事件 防止其他页面出现问题
            window.removeEventListener('scroll', this.backHandleScroll);
        },
        mounted() {
            window.addEventListener('scroll', this.backHandleScroll);
        },
        components:{
            'van-nav-bar':NavBar,
            'v-footer':Footer,
            
        },
        data() {
            return {
                spanActive:'0',
                scroll:'',
                activeList:[],
                isContent:false,
                isShowIframe:false,
                iframeSrc:''
            }
        },
        methods: {
            handleChange(num){
                this.spanActive = num
                this.getActivityDet()
            },
            backHandleScroll(){
                this.scroll  = document.documentElement && document.documentElement.scrollTop
            },
            getActivityDet(){
                let data = {
                    type:this.spanActive
                } 
                imgActivity(data).then(res => {
                    if(res.data.code == 200){
                        this.activeList = res.data.data
                        if(this.activeList.length == 0){
                            this.isContent = true
                        }else{
                            this.isContent = false
                        }
                    }
                })
            },
            goToDetail(content){
                this.$router.push({                                                     
                    name: 'ActivityDetails',             // 路由的名字，地址会隐藏                            
                    params: {    
                        content:content,
                    }
                })
            },
            goback(){
                this.$router.push('/Discount')
                this.isShowIframe = false
            },
            showIframe(val){
                this.isShowIframe = val.isShowIframe
                this.iframeSrc = val.link
            },
        },
    }
</script>

<style lang='scss' scoped>
    /deep/.van-nav-bar__content {
        background: rgb(174, 115, 252);
        max-width: 600px;
        margin: 0 auto;
    }
    /deep/.van-nav-bar__title {
        color: #fff;
        font-size: 20px;
    }

    /deep/.van-icon-arrow-left:before {
        color:#fff;
        font-size: 22px;
    }  
    .attributionTabs{
        display: flex;
        justify-content: space-around;
        margin: 15Px;
        width: calc(100% - 30Px);
        height: 22px;
    }
    .attributionTabs span:last-child{
        border-radius: 0 5px 5px 0;
        border-left-width: 0;
    }
    .attributionTabs span:first-child{
        border-radius: 5px 0 0 5px;
    }
    .attributionTabs span{
        cursor: pointer;
        border: 1px solid #ff5000;
        width: 50%;
        height: 25px;
        line-height: 25px;
        text-align: center;
        font-size: 13px;
        color: #ff5000;
        background-color: #fff;
    }
    .attributionTabs span.tabs_active{
        color: #fff;
        background-color: #ff5000;
    }    
    .activityList{
        padding-top: 20px;
    } 
    .activityList .item{
        border-bottom: 1px solid #eee;
        min-height: 100px;
        padding:10px;
    }
    .activityList .item:first-child a{
        padding-top: unset;
    }
    .activityList .item img{
        width: 100%;
    }
    h3{
        color: #999;
        font-size: 20px;
    }
    .iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        border: none;
        max-width: 600px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .backIcon{
        position: absolute;
        font-size: 12px;
        margin: 15px 50px;
        z-index: 100000;
        color: goldenrod;
        background: black;
        border-radius: 4px;
        padding: 2px;
        font-weight: bold;
    }
</style>